import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from '../../axiosMiddleware';

import { DemoPlotActionType } from './types'
import { getDemoPlotError, getDemoPlotSuccess } from './actions'
import { getConfig } from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');


function* handleDemoPlotRequest(action) {

    let { urlArgs } = action.payload;

    let url = API_ENDPOINT.concat(action.payload.url);

    const plotName = action.payload.name;
    const versionId = action.payload.activeVersionId;
    
    if (urlArgs) {
        url = url.concat(`&${new URLSearchParams(urlArgs).toString()}`);
    }

    try {
        const res = yield call(axios.get, url);
        const canStack = res.data.stacked_available;
        const canCumulate = res.data.cumulative_available;
        const groupbyAvailable = res.data.groupby_available;
        if (res.error) {
            yield put(getDemoPlotError(plotName, res.error, versionId, urlArgs));
        } else {
            yield put(getDemoPlotSuccess(plotName, res.data.plots, versionId, urlArgs, canStack, canCumulate, groupbyAvailable));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getDemoPlotError(plotName, err.stack!, versionId, urlArgs));
        } else {
            yield put(getDemoPlotError(plotName, 'An unknown error occured.', versionId, urlArgs));
        }
    }
}

function* watchDemoPlotRequest() {
    yield takeEvery(DemoPlotActionType.GET_DEMO_PLOT_REQUEST, handleDemoPlotRequest)
}

export function* demoPlotSaga() {
    yield all([fork(watchDemoPlotRequest)])
}
