import {Reducer} from 'redux';
import {ReportTemplatesActionType} from "./types";

const initialState = {
    activeTemplateId: null,
    activeTemplate: null,
    reportItems: null,
    loading: null,
    error: null
};

export const reportTemplatesReducer: Reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReportTemplatesActionType.SET_ACTIVE_REPORT_TEMPLATE: {
            return {...state, activeTemplate: action.payload}
        }
        case ReportTemplatesActionType.SET_ACTIVE_REPORT_TEMPLATE_ID: {
            return {...state, activeTemplateId: action.payload}
        }
        case ReportTemplatesActionType.RESET_ACTIVE_REPORT_TEMPLATE: {
            return {...state, activeTemplateId: null}
        }
        case ReportTemplatesActionType.GET_REPORT_ITEMS_LIST: {
            return {...state, loading: true}
        }
        case ReportTemplatesActionType.GET_REPORT_ITEMS_LIST_SUCCESS: {
            return {...state, loading: false, reportItems: action.payload.items}
        }
        case ReportTemplatesActionType.GET_REPORT_ITEMS_LIST_ERROR: {
            return {...state, loading: false, error: action.payload.error}
        }
        default: {
            return state;
        }
    }
};