export enum ActiveDataView {
    GRID = 'GRID',
    PLOT = 'PLOT',
}

export enum CurrentCalcContextActionType {
    SET_CURRENT_INDICATOR = '@@currentCalcContext/INDICATOR_SELECTED',
    LOAD_CURRENT_INDICATOR = '@@currentCalcContext/LOAD_CURRENT_INDICATOR',
    SET_ACTIVE_DATA_VIEW = '@@currentCalcContext/SET_ACTIVE_DATA_VIEW',
    SET_CURRENT_OBJECT = '@@currentCalcContext/OBJECT_SELECTED',
    SET_CURRENT_WELL = '@@currentCalcContext/WELL_SELECTED',
    SET_PROFIT = '@@currentCalcContext/PROFIT_SELECTED',
    VERSION_EXTRA_REQUEST = '@@currentCalcContext/VERSION_EXTRA_REQUEST',
    VERSION_EXTRA_SUCCESS = '@@currentCalcContext/VERSION_EXTRA_SUCCESS',
    VERSION_EXTRA_ERROR = '@@currentCalcContext/VERSION_EXTRA_ERROR',
    INVALIDATE_PLOT = '@@currentCalcContext/INVALIDATE_PLOT',
    GET_COSTS_INFO_REQUEST = '@@currentCalcContext/GET_COSTS_INFO_REQUEST',
    GET_COSTS_INFO_SUCCESS = '@@currentCalcContext/GET_COSTS_INFO_SUCCESS',
    GET_COSTS_INFO_ERROR = '@@currentCalcContext/GET_COSTS_INFO_ERROR',
    CHANGE_SETTINGS = '@@currentCalcContext/CHANGE_SETTINGS',
    GET_ANOMALY_INFO_REQUEST = '@@currentCalcContext/GET_ANOMALY_INFO_REQUEST',
    GET_ANOMALY_INFO_SUCCESS = '@@currentCalcContext/GET_ANOMALY_INFO_SUCCESS',
    GET_ANOMALY_INFO_ERROR = '@@currentCalcContext/GET_ANOMALY_INFO_ERROR',
}

export interface ICalcSettings {
    readonly prec?: number;
    readonly show_zero?: boolean;
}

export type IActiveDataView  = {
    [ActiveDataView.GRID]: boolean;
    [ActiveDataView.PLOT]: boolean;
}

export interface ICurrentCalcContextState {
    readonly currentIndicator: string | null;
    readonly currentTreeNodeId?: string | null;
    readonly driverName?: string;
    readonly activeDataView?: IActiveDataView | null;
    readonly currentObject?;
    readonly currentWell?;
    readonly profit?;
    readonly versionObjects?;
    readonly versionWells?;
    readonly extra?: Map<any, any>;
    readonly extraErrors?;
    readonly costsInfo?: any;
    readonly anomalyInfo?: Map<any, any>;
    readonly loading?: boolean;
    readonly settings?: ICalcSettings;
    readonly noIndicatorData?: boolean | null;
}
