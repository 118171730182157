import React from 'react';
import styled from 'styled-components/macro';
import {Icon} from './icon.styles';

import IconZeroElimination from '../../../assets/images/icons/control/icon-zero-elimination.svg';
import IconINJ from '../../../assets/images/icons/control/icon-inj.svg';
import IconIncrementingZeros from '../../../assets/images/icons/control/icon-incrementing-zeros.svg';
import IconDecreasingZeros from '../../../assets/images/icons/control/icon-decreasing-zeros.svg';
import IconAGG from '../../../assets/images/icons/control/icon-agg.svg';
import IconAggregateColumn from '../../../assets/images/icons/control/icon-aggregate-column.svg';
import IconFillChart from '../../../assets/images/icons/control/icon-fill-chart.svg';
import IconStackedChart from '../../../assets/images/icons/control/icon-stacked-chart.svg';
import IconOneHundred from '../../../assets/images/icons/control/icon-100.svg';

type IconProps = {
    onClick?: any;
    isActive?: boolean;
    disabled?: boolean;
};

export const ZeroEliminationIcon: React.FC<IconProps> = ({onClick, isActive = false, disabled = false}) => (
    <IconWrapper isActive={isActive} disabled={disabled}>
        <IconControl
            disabled={disabled}
            onClick={onClick}
            src={IconZeroElimination}
            alt="icon-start-zero-elimination"
        />
    </IconWrapper>
);

export const INJIcon: React.FC<IconProps> = ({onClick, isActive = false, disabled = false}) => (
    <IconWrapper isActive={isActive} disabled={disabled}>
        <IconControl disabled={disabled} onClick={onClick} src={IconINJ} alt="icon-start-inj" />
    </IconWrapper>
);

export const IncrementingZerosIcon: React.FC<IconProps> = ({onClick, isActive = false, disabled = false}) => (
    <IconWrapper isActive={isActive} disabled={disabled}>
        <IconControl
            disabled={disabled}
            onClick={onClick}
            src={IconIncrementingZeros}
            alt="icon-start-incrementing-zeros"
        />
    </IconWrapper>
);

export const DecreasingZerosIcon: React.FC<IconProps> = ({onClick, isActive = false, disabled = false}) => (
    <IconWrapper isActive={isActive} disabled={disabled}>
        <IconControl
            disabled={disabled}
            onClick={onClick}
            src={IconDecreasingZeros}
            alt="icon-start-decreasing-zeros"
        />
    </IconWrapper>
);

export const AGGIcon: React.FC<IconProps> = ({onClick, isActive = false, disabled = false}) => (
    <IconWrapper isActive={isActive} disabled={disabled}>
        <IconControl disabled={disabled} onClick={onClick} src={IconAGG} alt="icon-start-agg" />
    </IconWrapper>
);

export const AggregateColumnIcon: React.FC<IconProps> = ({onClick, isActive = false, disabled = false}) => (
    <IconWrapper isActive={isActive} disabled={disabled}>
        <IconControl disabled={disabled} onClick={onClick} src={IconAggregateColumn} alt="icon-aggregate-column" />
    </IconWrapper>
);

export const ChartFillIcon: React.FC<IconProps> = ({onClick, isActive = false, disabled = false}) => (
    <IconWrapper isActive={isActive} disabled={disabled}>
        <IconControl disabled={disabled} onClick={onClick} src={IconFillChart} alt="icon-fill-chart" />
    </IconWrapper>
);

export const ChartStackedIcon: React.FC<IconProps> = ({onClick, isActive = false, disabled = false}) => (
    <IconWrapper isActive={isActive} disabled={disabled}>
        <IconControl disabled={disabled} onClick={onClick} src={IconStackedChart} alt="icon-stacked-chart" />
    </IconWrapper>
);

export const OneHundredIcon: React.FC<IconProps> = ({onClick, isActive = false, disabled = false}) => (
    <IconWrapper isActive={isActive} disabled={disabled}>
        <IconControl disabled={disabled} onClick={onClick} src={IconOneHundred} alt="icon-one-hundred" />
    </IconWrapper>
);

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.isActive ? props.theme.colors.yellow : props.outline ? 'none' : props.theme.colors.grey5};
    padding: 3px;

    filter: ${(props) => props.disabled ? 'opacity(50%)' : 'none'};
    cursor: ${(props) => props.disabled ? 'default' : 'pointer'};

    &:hover {
        background-color: ${(props) => (props.isActive ? props.theme.colors.yellowDark : props.theme.colors.grey4)};
    }
`;

const IconControl = styled(Icon)`
    width: 30px;
    height: 30px;
`;
