import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {createStyles, makeStyles, Theme, WithStyles, withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ReportOutlined from '@material-ui/icons/ReportOutlined';
import {getErrors, IErrorsState, resetErrorsData} from '../store/errorsAndWarnings/reducer';
import {ApplicationState} from '../store';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const summaryStyles = {
    listStyle: 'revert'
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface DialogProps {
    open: boolean;
    close;
    currentId: number;
    hasWarnings: boolean;
    hasErrors: boolean;
    hasInfo: boolean;
    entity: string;
    Details?;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            listStyle: 'decimal',
            marginLeft: '10',
        },
        details: {
            whiteSpace: 'pre-wrap',
            margin: '15px 0',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        root: {
            flexGrow: 1,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        warning: {
            color: '#ffe600',
        },
        information: {
            color: '#7BADF7',
        },
        tabRoot: {
            height: '50%',
        },
    })
);

interface Props {
    window?: () => Window;
    children: React.ReactElement;
}

// function ElevationScroll({window, children}: Props) {
//     const trigger = useScrollTrigger();
//
//     return React.cloneElement(children, {
//         elevation: trigger ? 4 : 0,
//     });
// }

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other} style={{height: 500}}>
            {value === index && <Box p={2}>{value === index && children}</Box>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `details-tab-${index}`,
    };
}

export const TaskDetails = ({
    open,
    close,
    currentId,
    hasWarnings,
    hasErrors,
    hasInfo,
    entity,
    Details,
}: DialogProps) => {
    const dispatch = useDispatch();
    const errors = useSelector<ApplicationState, IErrorsState>((store) => store.errors);
    //const withDetails = entity === 'task';
    const classes = useStyles({});
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (hasErrors) {
            setValue(0);
        } else if (hasWarnings) {
            setValue(1);
        } else {
            setValue(2);
        }
    }, [hasErrors, hasWarnings]);

    const handleClose = () => {
        setValue(0);
        dispatch(resetErrorsData());
        close();
    };

    React.useEffect(() => {
        if (currentId !== null) {
            dispatch(getErrors(entity, currentId));
        }
        //eslint-disable-next-line
    }, [entity, currentId]);

    const {t} = useTranslation('components');

    return (
        <Dialog onClose={close} scroll={'paper'} open={open} maxWidth={'md'} transitionDuration={500} fullWidth>
            <div className={classes.root}>
                <DialogTitle onClose={handleClose}>
                    <AppBar position="static" color="default">
                        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                            <Tab
                                label={t('components:errordata.errors', 'Ошибки')}
                                icon={<ErrorOutlineOutlinedIcon color={hasErrors ? 'error' : 'disabled'} />}
                                {...a11yProps(0)}
                                disabled={!hasErrors}
                            />
                            <Tab
                                label={t('components:errordata.warnings', 'Предупреждения')}
                                icon={
                                    <ReportProblemOutlinedIcon
                                        color={hasWarnings ? 'secondary' : 'disabled'}
                                        classes={{colorSecondary: classes.warning}}
                                    />
                                }
                                {...a11yProps(1)}
                                disabled={!hasWarnings}
                            />
                            <Tab
                                label={t('components:errordata.info', 'Информация')}
                                icon={
                                    <ReportOutlined
                                        color={hasInfo ? 'secondary' : 'disabled'}
                                        classes={{colorSecondary: classes.information}}
                                    />
                                }
                                {...a11yProps(2)}
                                disabled={!hasInfo}
                            />
                            {/*{withDetails && <Tab label="Детали" icon={<InfoIcon*/}
                            {/*    color={'primary'}/>} {...a11yProps(2)} />}*/}
                        </Tabs>
                        {/*<IconButton aria-label="close" className={classes.closeButton}*/}
                        {/*            onClick={handleClose}>*/}
                        {/*    <CloseIcon/>*/}
                        {/*</IconButton>*/}
                    </AppBar>
                </DialogTitle>
                <DialogContent>
                    <TabPanel value={value} index={0}>
                        <ErrorData errors={errors} errorLevel={'error'} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ErrorData errors={errors} errorLevel={'warning'} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ErrorData errors={errors} errorLevel={'info'} />
                    </TabPanel>

                    {/*<TabPanel value={value} index={2}>*/}
                    {/*    {Details}*/}
                    {/*</TabPanel>*/}
                </DialogContent>
            </div>
        </Dialog>
    );
};

const formatExtra = (data, dataType) => {
    if (dataType === 'LIST') {
        return (
            <>
                <ul>
                    {data.map((el) => {
                        return <li key={el}>{el.toString()}</li>;
                    })}
                </ul>
            </>
        );
    } else if (dataType === 'DICT') {
        return (
            <>
                <ul>
                    {[...Object.entries(data)].map((el, index) => {
                        return <li key={index}>{el.toString()}</li>;
                    })}
                </ul>
            </>
        );
    } else {
        return Object.keys(data).length === 0 ? '' : data.toString();
    }
};

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        height: '100%',
        '&': {
            height: '85%',
            marginTop: '20px',
            padding: '0 24px 8px 24px!important'
        }
    },
}))(MuiDialogContent);

export const ErrorData = ({errorLevel, errors}) => {
    const classes = useStyles({});
    const {t} = useTranslation('components');

    const isError = String(errorLevel) === 'error';
    const showDetailsString = t(`components.error_data.${isError ? 'error_details' : 'non_error_details'}`);
    
    return errors.data ? (
        <DialogContent>
            <ul>
                {errors.data.map((value) => {
                    return (
                        <div key={'list-item-' + value.id}>
                            {value.level_text.toLowerCase() === errorLevel ? (
                                <li key={'error-list-item-' + value.id} className={classes.list}>
                                    <div
                                        // className={'status-' + value.level_text.toLowerCase()}
                                        key={'error-item-' + value.id}
                                        style={{margin: '15px 10px'}}
                                    >
                                        {value.logger_name ? (
                                            <div>
                                                <span style={{fontWeight: 500}}>{value.logger_name}</span> :{' '}
                                                {value.message}
                                            </div>
                                        ) : (
                                            <span>{value.message}</span>
                                        )}
                                        {value.extra_data_type !== 'NONE' && (
                                            <div className="error-extra" key={'error-item-extra' + value.id}>
                                                <details className={classes.details}>
                                                    <summary style={summaryStyles}>
                                                        {showDetailsString}
                                                    </summary>
                                                    <br />
                                                    {formatExtra(value.extra_data, value.extra_data_type)}
                                                </details>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            ) : null}
                        </div>
                    );
                })}
            </ul>
        </DialogContent>
    ) : null;
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: 0,
            backgroundColor: theme.palette.background.paper,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.getContrastText(theme.palette.background.paper),
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
    );
});
