import React from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import {Popup} from '../Popup/Popup';
// import {UploadComponent} from '../Design/UploadComponent';
import {Importer} from '../Importer';

type ImportFilePopupProps = {
    title?: string;
    setHidePopup: (v: boolean) => void;
    onLoadFiles?: any;
    className?: any;
    closeOutside?: boolean;
};

export const ImportFilePopup: React.FC<ImportFilePopupProps> = ({
    className,
    title,
    setHidePopup,
    onLoadFiles,
    closeOutside,
}) => {
    const {t} = useTranslation(['commons']);

    // const onLoadFile = (files: []) => {
    //     console.log(files);
    //     onLoadFiles(files);
    // };

    return (
        <Popup
            closeOutside={closeOutside}
            className={className}
            header={title ? title : t('components:dropzone.title', 'Импорт файлов')}
            handleClosePopup={setHidePopup}
        >
            {/*<UploadComponentWrapper onLoadFile={onLoadFile} />*/}
            <ImporterWrapper>
                <Importer id={'upliftLiquidLimitation'} />
            </ImporterWrapper>
        </Popup>
    );
};

// const UploadComponentWrapper = styled(UploadComponent)`
//     padding: 75px 20px;
// `;

const ImporterWrapper = styled.div`
    margin-top: 20px;
`;
