import { Reducer } from 'redux';
import { IVersionCardState, VersionCardActionType } from "./types";


export const initialState: IVersionCardState = {
    data: null,
    loading: false,
    error: null
};


const reducer: Reducer<IVersionCardState> = (state = initialState, action): IVersionCardState => {
    switch (action.type) {
        case VersionCardActionType.VERSION_CARD_REQUEST: {
            return { ...state, loading: true, data: null }
        }
        case VersionCardActionType.VERSION_CARD_SUCCESS: {
            return { ...state, loading: false, data: action.payload.data }
        }
        case VersionCardActionType.VERSION_CARD_ERROR: {
            return { ...state, loading: false, error: action.payload.error }
        }
        default: {
            return state
        }
    }
};

export { reducer as versionCardReducer }