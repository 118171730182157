import React, {forwardRef, useEffect, useState} from 'react';
import {useTheme} from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Autocomplete from './Autocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import FilterListIcon from '@material-ui/icons/FilterList';
import Settings from '@material-ui/icons/Settings';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Button} from '../Design/Button';
import {useStyles} from './autocomplete.styles';
import {
    CheckboxCancelIcon,
    CheckboxCheckedIcon,
    CheckboxIcon,
} from '../Design/Checkboxes/CheckboxMock';
import {DEFAULT_LINES, getRowsPerTableCell, RowsPerTableCell, saveRowsPerTableCell} from './RowsPerTableCell';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {CircularProgress} from '@material-ui/core';
import SortComponent, {SortFilterType, SortVariableEnum} from '../FilterColumn/SortComponent';
import {ISortItems} from '../../store/tableSelector/types';
import SortLabel from '../MTableComponents/SortLabel';


const getCurrentSortState = (sorting: ISortItems, stateBranch: string) => {
    const [colSort] = sorting.filter((col) => col.name === stateBranch);
    if (colSort) return {
        id: {'+': 1, '-': 2}[colSort.value],
        title: {'+': '0-9', '-': '9-0'}[colSort.value],
        value: SortVariableEnum[colSort.value],
        isChecked: true
    };
    return null;
}

interface IOption {
    id;
    name;
    color?;
}

interface IFilterProps {
    columnTitle: string;
    filterOptions: IOption[];
    selected;
    handleFilterChange;
    handleLoadFiltersByName?;
    stateBranch;
    filterRef?;
    withExclusion?;
    excludedElements;
    color?;
    sortBy?: ISortItems;
    isColumnSelector?: boolean;
    allowSorting?: boolean;
    filterName?: string;
}

const CustomAutocompleteFilter: React.FC<IFilterProps> = forwardRef(
    (
        {
            columnTitle,
            filterOptions,
            selected,
            excludedElements,
            handleFilterChange,
            handleLoadFiltersByName,
            stateBranch,
            withExclusion,
            sortBy,
            isColumnSelector,
            allowSorting,
            filterName,
        },
        filterRef
    ) => {
        const dispatch = useDispatch();
        const history = useHistory();
        const theme = useTheme();
        const classes = useStyles(theme);
        const {t} = useTranslation(['components', 'commons']);

        const loadingOptions = useSelector<ApplicationState, boolean>(store => store[filterName].loadingOptions);
        const currentSorting = useSelector<ApplicationState, ISortItems>(store => store[filterName].currentSorting);

        const sortState = getCurrentSortState(currentSorting, stateBranch);

        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const [sortTmpState, setSortTmpState] = useState(sortState);

        // Вспомогательные переменные
        const optionsIds = filterOptions.map(o => o.id);
        const selectedIds = selected.map(o => o.id);
        const excludedIds = excludedElements ?? [];
        const otherIds = optionsIds.filter(id => ![...selectedIds, ...excludedIds].includes(id));
        
        const selectedOptions = filterOptions.filter(o => selectedIds.includes(o.id));
        const excludedOptions = filterOptions.filter(o => excludedIds.includes(o.id));
        const otherOptions = filterOptions.filter(o => otherIds.includes(o.id));

        const [pendingValue, setPendingValue] = useState(selectedOptions);
        const [excluded, setExcluded] = useState(withExclusion ? excludedElements : []);
        
        // Состояние ограничения количества строк в ячейках таблицы из localStorage
        let initIsChecked = getRowsPerTableCell(history)?.checked;
        let initMaxValue = getRowsPerTableCell(history)?.value;

        if (initIsChecked === undefined || !initMaxValue === undefined) {
            initIsChecked = true;
            initMaxValue = DEFAULT_LINES;
            saveRowsPerTableCell({history, isChecked: true, maxValue: DEFAULT_LINES, dispatch});
        };

        // Max lines in a row state
        const [isChecked, setIsChecked] = useState<boolean>(initIsChecked);
        const [maxValue, setMaxValue] = useState<string>(initMaxValue);

        useEffect(() => {
            // Как только загрузка опций фильтра завершена
            if (!loadingOptions) {
                setPendingValue(selectedOptions);
            }
            // eslint-disable-next-line
        }, [loadingOptions]);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            if (anchorEl) return setAnchorEl(null);
            setAnchorEl(event.currentTarget);
            setSortTmpState(sortState); // Задать начальное состояние контрола сортировки

            !isColumnSelector && handleLoadFiltersByName(stateBranch, filterName);
        };

        const handleClickAway = () => setAnchorEl(null);

        const handleClear = () => {
            setPendingValue([]);
            setExcluded([]);
        };

        const handleSelectAll = () => {
            setPendingValue([...filterOptions]);
            setExcluded([]);
        };

        const handleExcludeAll = () => {
            setPendingValue([]);
            setExcluded(filterOptions.map((el) => el.id));
        };

        const getOptionLabel = (option) => {
            if (isColumnSelector) {
                return t(`commons:${option.name}`);
            }
            return option.name;
        };

        const handleSortChange = (sortFilter: SortFilterType) => {
            return setSortTmpState(sortFilter);
        };

        const sortOptions = (filterOptions) => {
            if (isColumnSelector) return filterOptions;
            return [...selectedOptions, ...excludedOptions, ...otherOptions];
        };

        const intersect = (newValue, excluded) => {
            return excluded.filter((x) => newValue.map((el) => el.id).includes(x));
        };

        const exclude = (fromArray, what) => {
            return fromArray.filter((el) => el !== what[0]);
        };

        const swap = (arr) => {
            if (arr.length) {
                [arr[0], arr[arr.length - 1]] = [arr[arr.length - 1], arr[0]];
                return arr;
            } else {
                return [];
            }
        };

        const difference = (newValue, pendingValue) => {
            return pendingValue.filter((el) => !newValue.map((x) => x.id).includes(el.id));
        };

        const handleChange = (e, newValue) => {
            // Setting pending value for columns as value before translation:
            if (isColumnSelector) {
                newValue = filterOptions.filter((v) => newValue.map((v) => v.id).includes(v.id));
            }

            if (e.type === 'click') {
                if (withExclusion) {
                    const intersection = intersect(newValue, excluded);
                    const diff = difference(newValue, pendingValue).map((el) => el.id);
                    if (intersection.length) {
                        setExcluded(exclude(excluded, intersection));
                    } else if (diff.length) {
                        setPendingValue(swap(pendingValue.filter((el) => el.id !== diff[0])));
                        setExcluded([...excluded, ...diff]);
                    } else {
                        setPendingValue(swap(newValue.sort((a, b) => a - b)));
                    }
                } else {
                    setPendingValue(swap(newValue.sort((a, b) => a - b)));
                }
            }
        };

        const applyFilter = () => {

            const sorting = [{name: stateBranch, value: sortTmpState?.value}];

            handleFilterChange({pendingValue, excluded}, sorting, stateBranch);

            if (isColumnSelector) {
                setAnchorEl(null);
                saveRowsPerTableCell({history, isChecked, maxValue, dispatch});
            };
        };

        return (
            <>
                <ButtonWrapper onClick={handleClick}>
                    <ButtonBase
                        className={classes.button}
                    >
                        {isColumnSelector ? (
                            <SettingsIcon />
                        ) : (
                            <FilterListIcon className={classes.tag} />
                        )}
                    </ButtonBase>
                
                    {allowSorting && (
                        <SortLabel sortBy={sortBy} colName={stateBranch} />
                    )}
                </ButtonWrapper>
                <Popper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    className={classes.popper}
                    id="filters-popup"
                >
                    {Boolean(anchorEl) && (
                        <ClickAwayListener onClickAway={handleClickAway} mouseEvent={'onMouseDown'}>
                            <FilterWrapper id="filters-popup-inner">
                                <ColumnTitle>
                                    {columnTitle}
                                </ColumnTitle>
                                {allowSorting && (
                                    <SortComponent sortFilter={sortTmpState} updateSortFilter={handleSortChange} />
                                )}
                                <Autocomplete
                                    ref={filterRef}
                                    //@ts-ignore
                                    open
                                    multiple
                                    loading={loadingOptions}
                                    loadingText={<LoadingCircle/>}
                                    classes={{
                                        paper: classes.paper,
                                        option: classes.option,
                                        popperDisablePortal: classes.popperDisablePortal,
                                    }}
                                    value={pendingValue}
                                    onChange={handleChange}
                                    disableCloseOnSelect
                                    disablePortal
                                    renderTags={() => null}
                                    noOptionsText={t(
                                        'components:filter.no_options',
                                        'Нет подходящих значений'
                                    )}
                                    renderOption={(option, {selected}) => (
                                        <>
                                            <Checkbox
                                                icon={<CheckboxIcon />}
                                                checkedIcon={<CheckboxCheckedIcon />}
                                                indeterminateIcon={<CheckboxCancelIcon />}
                                                checked={selected}
                                                disableRipple={true}
                                                className={classes.checkbox}
                                                indeterminate={
                                                    withExclusion
                                                        ? excluded.includes(option.id)
                                                        : false
                                                }
                                            />
                                            <span className={classes.checkboxLabel}>
                                                {getOptionLabel(option)}
                                            </span>
                                        </>
                                    )}
                                    options={loadingOptions ? [] : sortOptions(filterOptions)}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={getOptionLabel}
                                    renderInput={(params) => (
                                        <div>
                                            <InputBase
                                                ref={params.InputProps.ref}
                                                inputProps={params.inputProps}
                                                placeholder={t('components:search', 'Поиск')}
                                                autoFocus
                                                className={classes.inputBase}
                                            />
                                            <div className={classes.headerButtons}>
                                                <TextButton onClick={handleSelectAll}>
                                                    {t(
                                                        'components:filter.select_all',
                                                        'Выбрать все'
                                                    )}
                                                </TextButton>
                                                {withExclusion && (
                                                    <TextButton onClick={handleExcludeAll}>
                                                        {t(
                                                            'components:filter.exclude_all',
                                                            'Исключить все'
                                                        )}
                                                    </TextButton>
                                                )}
                                                <TextButton onClick={handleClear} bold>
                                                    {t('components:filter.clear', 'Очистить')}
                                                </TextButton>
                                            </div>
                                        </div>
                                    )}
                                />
                                <div className={classes.footer}>
                                    {isColumnSelector && (
                                        <RowsPerTableCell
                                            isChecked={isChecked}
                                            setIsChecked={setIsChecked}
                                            maxValue={maxValue}
                                            setMaxValue={setMaxValue}
                                        />
                                    )}

                                    <div className={classes.footerButton}>
                                        <ButtonApply
                                            onClick={applyFilter}
                                            label={t('components:filter.ok', 'Применить')}
                                        />
                                    </div>
                                </div>
                            </FilterWrapper>
                        </ClickAwayListener>
                    )}
                </Popper>
            </>
        );
    }
);

const TextButton = styled.span`
    cursor: pointer;
    color: ${(props) => props.theme.colors.grey1};
    font-weight: bold;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    padding: 4px 8px;
`;

const FilterWrapper = styled.div`
    padding: 18px 24px 24px;
    background: ${(props) => props.theme.colors.grey6};
    font-size: 12px;
    width: 250px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
        0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    user-select: none;

    // List of variants
    ul.MuiAutocomplete-listbox {
        max-height: 190px;
        overflow: scroll;
    }

    & * {
        scrollbar-width: thin;
        scrollbar-color: ${(props) => props.theme.colors.grey4} rgba(0, 0, 0, 0);
    }

    & *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    & *::-webkit-scrollbar-track {
        background: #fff;
    }

    & *::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.grey4};
    }
`;

const ButtonApply = styled(Button)`
    font-size: 14px;
`;

const ColumnTitle = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.textColor.grey};
`;

const SettingsIcon = styled(Settings)`
    color: ${(props) => props.theme.colors.black};
`;

const LoadingCircle = styled(CircularProgress)`
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

export default CustomAutocompleteFilter;
