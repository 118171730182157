import React from 'react';
import {useTranslation} from 'react-i18next';
import {TablePropsType} from '../../pages/calculation-page/GridView';
import {ApplyFiltersFn} from './types';
import {SortFilterType} from './SortComponent';
import {FilterColumnItemType} from './FilterColumn';
import {isCheckedFilter} from './utils';
import {FilterListVariant, FilterListVariantsType} from '../../store/filterVariants/types';
import {Button} from '../Design/Button';
import {ButtonControlWrapper} from './filter.styles';

type ButtonControlProps = {
    props: TablePropsType;
    applyFilters: ApplyFiltersFn;
    clearFilters: () => void;
    handleCancelFilterForm: () => void;
    setActiveFilter: (v: boolean) => void;
    chooseFilters: FilterColumnItemType[];
    field: string;
    sortFilter: SortFilterType;
    listFiltersBuffer: FilterListVariantsType;
};

const ButtonControl: React.FC<ButtonControlProps> = ({
    props,
    applyFilters,
    clearFilters,
    setActiveFilter,
    chooseFilters,
    handleCancelFilterForm,
    field,
    sortFilter,
    listFiltersBuffer
}) => {
    const {t} = useTranslation('components');

    const hidePopup = () => props.api.hidePopupMenu();

    const handleCancel = () => {
        handleCancelFilterForm();
        hidePopup();
    };

    const getOriginalValuesByRoundedValue = (appliedFilters: FilterColumnItemType[]): FilterColumnItemType[] => {
        // Получить оригинальные значения по округленным значениям примененных фильтров
        return appliedFilters.reduce((acc: FilterColumnItemType[], filter: FilterColumnItemType) => {
            if (isCheckedFilter(filter)) {
                const listVariants: FilterListVariant[] = listFiltersBuffer[field] || [];

                const allAvailableVariants = listVariants
                    .filter((variant: FilterListVariant) => variant.roundValue === filter.roundValue)
                    .map((variant: FilterListVariant, index: number) => ({
                        id: index,
                        field: field,
                        originalValue: variant.originalValue,
                        roundValue: variant.roundValue,
                        isChecked: filter.isChecked,
                    }));

                if (allAvailableVariants.length > 0) {
                    acc = [...acc, ...allAvailableVariants];
                }
            } else {
                acc.push(filter);
            }
            return acc;
        }, []);
    };

    const apply = () => {
        const hasSortFilters = sortFilter && Object.keys(sortFilter).length > 0;
        const sortFilters = hasSortFilters ? sortFilter : null;
        const newAppliedFilters = chooseFilters.length > 0 ? chooseFilters : [];

        applyFilters(getOriginalValuesByRoundedValue(newAppliedFilters), sortFilters, field);

        const isActiveFilters = chooseFilters.length > 0 || hasSortFilters;
        setActiveFilter(isActiveFilters);

        hidePopup();
    };

    return (
        <ButtonControlWrapper>
            <Button small={true} onClick={clearFilters} outline={true} label={t('commons:clear', 'Очистить')} />
            <Button small={true} onClick={handleCancel} outline={true} label={t('commons:cancel', 'Отменить')} />
            <Button small={true} onClick={apply} label={t('commons:ok', 'Принять')} />
        </ButtonControlWrapper>
    );
};

export default ButtonControl;
