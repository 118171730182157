export enum ScenarioActionType {
    GET_SCENARIO_DETAILS_REQUEST = '@@scenario/GET_SCENARIO_DETAILS_REQUEST',
    GET_SCENARIO_DETAILS_SUCCESS = '@@scenario/GET_SCENARIO_DETAILS_SUCCESS',
    GET_SCENARIO_DETAILS_ERROR = '@@scenario/GET_SCENARIO_DETAILS_ERROR',
    
    EDIT_SCENARIO_NAME_REQUEST = '@@scenario/EDIT_SCENARIO_NAME_REQUEST',
    EDIT_SCENARIO_NAME_SUCCESS = '@@scenario/EDIT_SCENARIO_NAME_SUCCESS',
    EDIT_SCENARIO_NAME_ERROR = '@@scenario/EDIT_SCENARIO_NAME_ERROR',

    EDIT_SCENARIO_DESCRIPTION_REQUEST = '@@scenario/EDIT_SCENARIO_DESCRIPTION_REQUEST',
    EDIT_SCENARIO_DESCRIPTION_SUCCESS = '@@scenario/EDIT_SCENARIO_DESCRIPTION_SUCCESS',
    EDIT_SCENARIO_DESCRIPTION_ERROR = '@@scenario/EDIT_SCENARIO_DESCRIPTION_ERROR',
}

export interface IScenarioDetails {
    name: string,
    description: string,
    exch_usd_rub_max: number,
    exch_usd_rub_min: number,
    indicator_tables: object,
    macro_urals_price_max: number,
    macro_urals_price_min: number
}

export interface IScenarioState {
    scenario: IScenarioDetails;
    readonly loading: boolean;
    readonly error: string | null;
}