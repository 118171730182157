import {useDispatch, useSelector} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import {ApplicationState} from "../store";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import React from "react";
import {hideSnackbar} from "../store/snackbars/actions";

interface StateProps {
    snackbarOpen: boolean,
    snackbarType: string | null,
    snackbarMessage: string | null
}

type Color = 'error' | 'warning' | 'info' | 'success';

const GlobalSnackbar = () => {

    const dispatch = useDispatch();
    const {snackbarOpen, snackbarType, snackbarMessage} = useSelector<ApplicationState, StateProps>(
        store => store.snackbars
    );


    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(hideSnackbar());
    };

    return (
        <>
            <Snackbar
                key={snackbarMessage}
                //TransitionComponent={SlideTransition}
                open={snackbarOpen} onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Alert onClose={handleClose} severity={snackbarType as Color}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default GlobalSnackbar