export enum WellsActionType {
    GET_WELLS_REQUEST = '@@wells/GET_WELLS_REQUEST',
    GET_WELLS_SUCCESS = '@@wells/GET_WELLS_SUCCESS',
    GET_WELLS_ERROR = '@@wells/GET_WELLS_ERROR',
    SET_WELL_STATE_REQUEST = '@@wells/SET_WELL_STATE_REQUEST',
    SET_WELL_STATE_SUCCESS = '@@wells/SET_WELL_STATE_SUCCESS',
    SET_WELL_STATE_ERROR = '@@wells/SET_WELL_STATE_ERROR',
}

export enum WellType {
    ALL = "ALL",
    INJECT = "INJECT",
    PROD = "PROD",
    CONTROL = "CONTROL"
}

export interface IWell {
    oil_field: string
    prod_site: string
    well_type: WellType
    well: string
    enabled: boolean
    oil_prod: number,
    liquid_prod: number,
    sol_gas_prod: number

}

export type IWells = IWell[]


export interface ISetWellStateResponse {
    ok: boolean,
    result_version: number
}

export interface IWellsState {
    readonly data: IWells
    readonly loadingInProgress: boolean
    readonly updatingInProgress: boolean
    readonly loadingError?: any
    readonly updatingError?: any
}
