import {action} from 'typesafe-actions';
import {IUpload, UploadActionType} from "./types";

export const getUploadsListRequest = () =>
    action(UploadActionType.GET_UPLOADS_LIST_REQUEST);

export const getUploadsListSuccess = (uploads: IUpload[]) =>
    action(UploadActionType.GET_UPLOADS_LIST_SUCCESS, {uploads});

export const getUploadsListError = (error: any) =>
    action(UploadActionType.GET_UPLOADS_LIST_ERROR, {error});

export const deleteUpload = (id: number) =>
    action(UploadActionType.DELETE_UPLOAD, {id});

// export const calculateScenarioRequest = (upload_ids: [number], version_ids: [number]) =>
//     action(UploadActionType.UPLOAD_CREATE_REQUEST, {upload_ids, version_ids,});
//
// export const calculateScenarioSuccess = (task_group_id: number) =>
//     action(UploadActionType.UPLOAD_CREATE_SUCCESS, {task_group_id});
//
// export const calculateScenarioError = (error: any) =>
//     action(UploadActionType.UPLOAD_CREATE_ERROR, {error});
