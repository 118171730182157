import {Reducer} from 'redux';
import {IVersionGroup, IVersionGroupState, VersionGroupActionType} from "./types";

export const initialState: IVersionGroupState = {
    versionGroups: [],
    loading: false,
    error: null
};

const deleteItem = (items: IVersionGroup[], id: number) => {
    return [...items.filter(s => s.id !== id)]
};

const reducer: Reducer<IVersionGroupState> = (state = initialState, action): IVersionGroupState => {
    switch (action.type) {
        case VersionGroupActionType.VERSIONGROUP_CREATE_SUCCESS: {
            return {...state, versionGroups: [...state.versionGroups, action.payload.version_group]}
        }
        case VersionGroupActionType.GET_VERSIONGROUPS_LIST_REQUEST: {
            return {...state, loading: true}
        }
        case VersionGroupActionType.GET_VERSIONGROUPS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                versionGroups: action.payload.versionGroups
            }
        }
        case VersionGroupActionType.GET_VERSIONGROUPS_LIST_ERROR: {
            return {
                ...state, error: action.payload
            }
        }
        case VersionGroupActionType.DELETE_VERSIONGROUP: {
            return {
                ...state, 
                versionGroups: deleteItem(state.versionGroups, action.payload.id)
            }
        }
        default: {
            return state
        }
    }
};

export {reducer as versionGroupReducer}