import {Reducer} from 'redux';
import {ILoginState, LoginActionType} from './types';
import {deleteCookieByName} from '../../helpers/utils';

export const initialUserState: ILoginState = {
    user: null,
    token: localStorage.getItem('token'),
    isAuth: false,
    error: null,
    loading: true
};

export const LoginReducer: Reducer<ILoginState> = (state = initialUserState, action) => {
    switch (action.type) {
        case LoginActionType.GET_USER_SUCCESS: {
            return {
                ...state,
                isAuth: true,
                loading: false,
                ...action.payload,
                token: localStorage.getItem('token')
            };
        }
        case LoginActionType.LOGIN: {
            return {...state, isAuth: false, loading: true, user: null};
        }
        case LoginActionType.GET_USER_REQUEST: {
            return {...state, isAuth: true, loading: true, user: null};
        }
        case LoginActionType.LOGIN_SUCCESS:
            localStorage.clear();
            localStorage.setItem('token', action.payload.login.token);
            return {
                ...state,
                ...action.payload.login,
                isAuth: true,
                loading: false,
                error: null
            };
        case LoginActionType.LOGIN_ERROR:
        case LoginActionType.LOGOUT:
        case LoginActionType.GET_USER_ERROR:
            localStorage.clear();
            deleteCookieByName('jwt_token');
            return {
                ...state,
                token: null,
                isAuth: false,
                loading: false,
                user: null,
                error: action.payload
            };
        default: {
            return state;
        }
    }
};
