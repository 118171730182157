import {action} from 'typesafe-actions';
import {IScenarioDetails, ScenarioActionType} from "./types";

export const getScenarioDetailsRequest = (macroId, params) =>
    action(ScenarioActionType.GET_SCENARIO_DETAILS_REQUEST, {macroId, params});

export const getScenarioDetailsSuccess = (scenario: IScenarioDetails) =>
    action(ScenarioActionType.GET_SCENARIO_DETAILS_SUCCESS, {scenario});

export const getScenarioDetailsError = (error: any) =>
    action(ScenarioActionType.GET_SCENARIO_DETAILS_ERROR, {error});

export const editScenarioNameRequest = (macroId, name) =>
    action(ScenarioActionType.EDIT_SCENARIO_NAME_REQUEST, {macroId, name});

export const editScenarioNameSuccess = (name: string) =>
    action(ScenarioActionType.EDIT_SCENARIO_NAME_SUCCESS, {name});

export const editScenarioNameError = (error: any) =>
    action(ScenarioActionType.EDIT_SCENARIO_NAME_ERROR, {error});

export const editScenarioDescriptionRequest = (macroId, description) =>
    action(ScenarioActionType.EDIT_SCENARIO_DESCRIPTION_REQUEST, {macroId, description});

export const editScenarioDescriptionSuccess = (description: string) =>
    action(ScenarioActionType.EDIT_SCENARIO_DESCRIPTION_SUCCESS, {description});

export const editScenarioDescriptionError = (error: any) =>
    action(ScenarioActionType.EDIT_SCENARIO_DESCRIPTION_ERROR, {error});