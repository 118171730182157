import { action } from 'typesafe-actions';
import { IAttribute, AttributeActionType } from "./types";

export const createAttributeRequest = (name: any, versions: any[]) =>
    action(AttributeActionType.ATTRIBUTE_CREATE_REQUEST, { name, versions });

export const createAttributeSuccess = (attribute: any) =>
    action(AttributeActionType.ATTRIBUTE_CREATE_SUCCESS, { attribute });

export const createAttributeError = (error: any) =>
    action(AttributeActionType.ATTRIBUTE_CREATE_ERROR, { error });

export const getAttributesListRequest = () =>
    action(AttributeActionType.GET_ATTRIBUTES_LIST_REQUEST);

export const getAttributesListSuccess = (attributes: IAttribute[]) =>
    action(AttributeActionType.GET_ATTRIBUTES_LIST_SUCCESS, { attributes });

export const getAttributesListError = (error: any) =>
    action(AttributeActionType.GET_ATTRIBUTES_LIST_ERROR, { error });

export const deleteAttribute = (id: number) =>
    action(AttributeActionType.DELETE_ATTRIBUTE, { id });