import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {getErrors, IErrorsState, resetErrorsData} from '../../../store/errorsAndWarnings/reducer';
import {ApplicationState} from '../../../store';
import {Popup} from '../../../components/Popup/Popup';
import {TabsWrapper, Tab, TabsContent} from './errorData.styles';
import styled from 'styled-components';
import {ErrorData} from '../../../components/ErrorData';

interface DialogProps {
    open: boolean;
    close;
    currentId: number;
    hasWarnings: boolean;
    hasErrors: boolean;
    hasInfo: boolean;
    entity: string;
    Details?;
}

type StateOFTabs = 'error' | 'warning' | 'information';

const STATE_TABS: {error: StateOFTabs; warning: StateOFTabs; information: StateOFTabs} = {
    error: 'error',
    warning: 'warning',
    information: 'information',
};

export const TaskLogsDetails = ({close, currentId, hasWarnings, hasErrors, hasInfo, entity}: DialogProps) => {
    const {t} = useTranslation('components');
    const dispatch = useDispatch();

    const errors = useSelector<ApplicationState, IErrorsState>((store) => store.errors);
    const [stateOfTab, setStateOfTabs] = useState<StateOFTabs>(STATE_TABS.error);

    const handleChange = (newValue: StateOFTabs) => setStateOfTabs(newValue);

    useEffect(() => {
        if (hasErrors) return setStateOfTabs(STATE_TABS.error);
        if (hasWarnings) return setStateOfTabs(STATE_TABS.warning);

        setStateOfTabs(STATE_TABS.information);
    }, [hasErrors, hasWarnings]);

    const handleClose = () => {
        setStateOfTabs(STATE_TABS.error);
        dispatch(resetErrorsData());
        close();
    };

    useEffect(() => {
        if (currentId !== null) {
            dispatch(getErrors(entity, currentId));
        }
        //eslint-disable-next-line
    }, [entity, currentId]);

    const isActive = (value: StateOFTabs, currentState: StateOFTabs): boolean => currentState === value;

    return (
        <PopupWrapper handleClosePopup={handleClose} header={t('commons:report', 'Отчет')}>
            <TabsWrapper>
                <Tab isActive={isActive(stateOfTab, STATE_TABS.error)}
                     onClick={() => hasErrors && handleChange(STATE_TABS.error)}
                     hasMessage={hasErrors}>
                    {t('components:errordata.errors', 'Ошибки')}
                </Tab>
                <Tab
                    isActive={isActive(stateOfTab, STATE_TABS.warning)}
                    onClick={() => hasWarnings && handleChange(STATE_TABS.warning)}
                    hasMessage={hasWarnings}
                >
                    {' '}
                    {t('components:errordata.warnings', 'Предупреждения')}
                </Tab>
                <Tab
                    isActive={isActive(stateOfTab, STATE_TABS.information)}
                    onClick={() => hasInfo && handleChange(STATE_TABS.information)}
                    hasMessage={hasInfo}
                >
                    {t('components:errordata.info', 'Информация')}
                </Tab>
            </TabsWrapper>
            <TabsContent>
                {stateOfTab === STATE_TABS.error && <ErrorData errors={errors} errorLevel={'error'} />}
                {stateOfTab === STATE_TABS.warning && <ErrorData errors={errors} errorLevel={'warning'} />}
                {stateOfTab === STATE_TABS.information && <ErrorData errors={errors} errorLevel={'info'} />}
            </TabsContent>
        </PopupWrapper>
    );
};

const PopupWrapper = styled(Popup)`
    & > div {
        width: 850px;
        height: 800px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
`;
