import styled from 'styled-components/macro';

export const bigSidebarWidth = 100;
export const minSidebarWidth = 50;

export const ContentWrapper = styled.div`
    height: 100%;
    color: ${(props) => props.theme.colors.grey1};
    margin-left: ${(props) => (props.isBigSidebar ? `${bigSidebarWidth}px` : `${minSidebarWidth}px`)};
`;
