export enum VersionGroupActionType {
    VERSIONGROUP_CREATE_REQUEST = '@@versionGroup/VERSIONGROUP_CREATE',
    VERSIONGROUP_CREATE_SUCCESS = '@@versionGroup/VERSIONGROUP_CREATE_SUCCESS',
    VERSIONGROUP_CREATE_ERROR = '@@versionGroup/VERSIONGROUP_CREATE_ERROR',
    
    GET_VERSIONGROUPS_LIST_REQUEST = '@@versionGroup/GET_VERSIONGROUPS_LIST_REQUEST',
    GET_VERSIONGROUPS_LIST_SUCCESS = '@@versionGroup/GET_VERSIONGROUPOS_LIST_SUCCESS',
    GET_VERSIONGROUPS_LIST_ERROR = '@@versionGroup/GET_VERSIONGROUPS_LIST_ERROR',
    
    DELETE_VERSIONGROUP = '@@versionGroup/DELETE_VERSIONGROUP',
}

export interface IVersionGroup {
    id: number,
    name: string,
    hidden?: Boolean
}

export interface IVersionGroupState {
    versionGroups: IVersionGroup[] | null;
    readonly loading: boolean;
    readonly error: string | null;
}