import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects'
import axios from '../../../axiosMiddleware';

import {AnomalyTreeActionType} from './types'
import {anomalyFetchSuccess, anomalyFetchError} from './actions'
import {getConfig} from '../../../config-provider';


function* handleFetch(action) {
    const jwt = yield select(state => state.login.token);

    try {
        const version_id = action.payload.version_id;
        const API_ENDPOINT = `${getConfig('REACT_APP_API_ENDPOINT')}/anomalies/${version_id}/meta?jwt=${jwt}`;
        const res = yield call(axios.get, API_ENDPOINT);

        if (res.error) {
            yield put(anomalyFetchError(res.error))
        } else {
            yield put(anomalyFetchSuccess(res.data, version_id))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(anomalyFetchError(err.stack!))
        } else {
            yield put(anomalyFetchError('An unknown error occured.'))
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
    yield takeEvery(AnomalyTreeActionType.FETCH_REQUEST, handleFetch)
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* anomalyTreeSaga() {
    yield all([fork(watchFetchRequest)])
}

export default anomalyTreeSaga
