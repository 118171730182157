import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '../Design/Button';
import {PopupItem} from '../Popup/popup.styles';
import {
    buildVerboseIndex,
    initialAttrCompState,
    transformData,
    transformScenarioDraft,
} from './utils';
import {
    getDataFromLocalStorage,
    removeDataFromLocalStorage,
    setDataToLocalStorage,
} from '../../helpers/localStorage';
import {getConfig} from '../../config-provider';
import {useDispatch, useSelector} from 'react-redux';
import {getFilterDataRequest} from '../../store/tableSelector/actions';
import {ApplicationState} from '../../store';
import {getPage, IPaginatorState} from '../../store/pagination';
import axios from '../../axiosMiddleware';
import {Select} from '../Design/Select';
import {InputField} from '../Design/InputField';
import styled from 'styled-components/macro';
import {EditIcon} from '../Design/Icons/IconsEditTable';
import {PopupTabs} from '../Popup/PopupTabs';
import {ContentType, PopupContent} from './PopupContent';
import {Box} from '@material-ui/core';
import {IAttribute} from '../../store/attributes/types';
import {BottomMenu} from './ScenarioDialog';
import {DialogContext} from "../../layouts/root-layout/RootLayout";
import {TextArea} from '../Design/TextArea';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');
const TM_FILTER_NAME = 'taxModesFilter';

const localeKeys = {
    taxModesFilter: 'tax_mode',
    emissionScenariosFilter: 'esg',
    costsScenariosFilter: 'cost'
}

const paginatorNames = {
    taxModesFilter: 'taxModes',
    emissionScenariosFilter: 'emissionScenarios',
    costsScenariosFilter: 'costsScenarios'
}

const initTabs = {
    taxModesFilter: [
        {id: 1, label: 'pages:scenarios.dialog.tax_mode.tabs.ndpi'},
        {id: 2, label: 'pages:scenarios.dialog.tax_mode.tabs.ndd'},
        {id: 3, label: 'pages:scenarios.dialog.tax_mode.tabs.profit'},
        {id: 4, label: 'pages:scenarios.dialog.tax_mode.tabs.property'},
        {id: 5, label: 'pages:scenarios.dialog.tax_mode.tabs.other'},
        {id: 6, label: 'pages:scenarios.dialog.tax_mode.tabs.exemptions'},
        {id: 7, label: 'pages:scenarios.dialog.tax_mode.tabs.attributes'},
    ],
    emissionScenariosFilter: [
        {id: 1, label: 'pages:scenarios.dialog.esg.tabs.emission_parameters'},
        {id: 2, label: 'pages:scenarios.dialog.esg.tabs.emission_quotas'},
        {id: 3, label: 'pages:scenarios.dialog.esg.tabs.capex'},
        {id: 4, label: 'pages:scenarios.dialog.esg.tabs.attributes'},
    ],
    costsScenariosFilter: [
        {id: 1, label: 'pages:scenarios.dialog.cost.tabs.opex'},
        {id: 2, label: 'pages:scenarios.dialog.cost.tabs.capex'},
        {id: 3, label: 'pages:scenarios.dialog.cost.tabs.attributes'},
    ],
}

const popupContent = {
    taxModesFilter: [
        {id: 1, tabName: 'ndpi'},
        {id: 2, tabName: 'ndd'},
        {id: 3, tabName: 'profit_tax'},
        {id: 4, tabName: 'property_tax'},
        {id: 5, tabName: 'miscellaneous'},
        {id: 6, tabName: 'disable_exemptions'},
        {id: 7, tabName: 'attributes'},
        {id: 8, tabName: 'summary'},
    ],
    emissionScenariosFilter: [
        {id: 1, tabName: 'emission_parameters'},
        {id: 2, tabName: 'emission_quotas'},
        {id: 3, tabName: 'capex'},
        {id: 4, tabName: 'attributes'},
        {id: 5, tabName: 'summary'},
    ],
    costsScenariosFilter: [
        {id: 1, tabName: 'opex'},
        {id: 2, tabName: 'capex'},
        {id: 3, tabName: 'attributes'},
        {id: 4, tabName: 'summary'},
    ]
}

interface PassedProps {
    handleClose;
    dialogState;
    setDialogState;
    isFullscreen;
    filterName;
}

type ScenarioDetailsProps = PassedProps;

export const ScenarioDetails: React.FC<ScenarioDetailsProps> = ({
    handleClose,
    dialogState,
    setDialogState,
    isFullscreen,
    filterName
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { setIsDialogOpen } = useContext(DialogContext)

    const localeKey = localeKeys[filterName] ?? localeKeys[TM_FILTER_NAME];
    const paginatorName = paginatorNames[filterName] ?? paginatorNames[TM_FILTER_NAME];

    const {editMode, update, scenarioId, parentScenarioName} = dialogState;
    const isTaxMode = filterName === 'taxModesFilter';

    // viewMode - режим просмотра без редактирования
    // fromCopy - режим редактирования копии сценария
    // updtMode - режим редактирования черновика сценария
    // isTaxMode - текущий сценарий явяется НР
    const viewMode = scenarioId && !editMode;
    const fromCopy = parentScenarioName !== null;
    const updtMode = update;

    const storeKey = viewMode ? `${localeKey}_Details` : `${localeKey}_InitTree`;
    const initData = getDataFromLocalStorage(storeKey);
    const editions = getDataFromLocalStorage(`${localeKey}_Editions`);

    const tagsDict = useSelector<ApplicationState, IAttribute[]>(
        (store) => store.attributes.attributes
    );
    const pagination = useSelector<ApplicationState, IPaginatorState>((store) => store.pagination);
    const perPage = useSelector<ApplicationState, number>((store) => store[filterName].perPage);

    const currentPage = getPage(pagination, paginatorName);

    let treeData = initData.structure;

    let initName = '';
    let initDescription = '';
    let initCode = editions[0].name;

    let initTags = [];

    initData.tags.forEach((id) => {
        const tag = tagsDict.find((tag) => tag.id === id);
        if (tag) initTags.push(tag);
    });

    if (viewMode || fromCopy || updtMode) {
        // При создании сценария из копии к названию добавляется "(копия) или (copy)"
        initName = fromCopy ? (
            `${initData.name} (${t('pages:scenarios.dialog.copy')})`
        ) : initData.name;

        initDescription = initData.description;
        
        if (isTaxMode) {
            let edtionId = treeData.edition['inject:worksheet:General:edition_id'].DATA;
            initCode = editions[edtionId].name;
        }
    }

    // Плоский индекс человекочитаемых названий дерева показателей
    let verboseIndex = getDataFromLocalStorage(`${localeKey}_VerboseIndex`);

    if (!treeData.children) {
        // Нормализация данных
        treeData = transformData(treeData, 'structure');

        // Построение индекса человекочитаемых названий дерева показателей
        verboseIndex = buildVerboseIndex(treeData.children);
        setDataToLocalStorage(`${localeKey}_VerboseIndex`, verboseIndex);
    }

    // Начальное состояние компонента выбора атрибутов
    const initAttributes = {
        ...initialAttrCompState(),
        attributes: initTags,
    };

    const [activeTabId, setActiveTabId] = useState<number>(1);
    const [scenarioName, setScenarioName] = useState<string>(initName);
    const [description, setDescription] = useState<string>(initDescription);
    const [codeEdition, setCodeEdition] = useState<string>(initCode);
    const [{attributes}, setAttrCompSt] = useState(initAttributes);

    const editionId = editions.find((e) => e.name === codeEdition).id;

    const tabs = (initTabs[filterName] ?? initTabs[TM_FILTER_NAME]).map(
        (tab) => ({...tab, label: t(tab.label)})
    );

    const [popupContents] = useState<ContentType[]>(popupContent[filterName] ?? popupContent[TM_FILTER_NAME]);
    const activeTabName = popupContents.filter((tab) => tab.id === activeTabId)[0].tabName;
    const summaryTabId = popupContents.filter((tab) => tab.tabName === 'summary')[0].id;

    useEffect(() => {
        // Задать состояние компонента выбора атрибутов
        // при получении нового справочника атрибутов
        const s = initialAttrCompState();
        setAttrCompSt({
            ...s,
            attributes: viewMode ? initTags : attributes,
        });

        //eslint-disable-next-line
    }, [JSON.stringify(tagsDict)]);

    const handleChange = useCallback((activeId) => setActiveTabId(activeId), []);
    const handleNextTab = () => setActiveTabId(activeTabId + 1);
    const handlePrevTab = () => setActiveTabId(activeTabId - 1);

    // Метод для сохранения сценария
    const handleSaveScenario = (asDraft) => {
        const scenarioDraft = getDataFromLocalStorage(`${localeKey}_Draft`);
        const data = scenarioDraft.treeData;

        // Добавление выбранной редакции НК РФ
        if (isTaxMode && codeEdition) {
            data.children
                .find((c) => c.address === 'structure::edition')
                .children.find(
                    (c) => c.address === 'structure::edition::inject:worksheet:General:edition_id'
                ).DATA = editionId;
        }

        // Приведение данных дерева к валидному виду
        const parentNodeName = scenarioDraft.treeData.address;
        const transformedData = transformScenarioDraft(data, parentNodeName);

        const body = {
            tree: {
                ...transformedData,
                name: scenarioName,
                description: description,
                created_by: '',
                created_at: '',
                is_draft: Number(asDraft),
                tags: attributes.map((a) => a.id),
            },
        };

        let url = `${API_ENDPOINT}/scenario/${localeKey}/`;

        if (updtMode) {
            url = `${API_ENDPOINT}/scenario/${localeKey}/${scenarioId}/`;
        }

        axios
            .request({
                method: updtMode ? 'put' : 'post',
                data: body,
                url,
            })
            .then(() => dispatch(getFilterDataRequest(currentPage, perPage, filterName)));

        removeDataFromLocalStorage(`${localeKey}_InitTree`);
        removeDataFromLocalStorage(`${localeKey}_Draft`);

        setIsDialogOpen(false)
        handleClose();
    };

    // Методы диалога редактирования копии сценария
    const handleOpenCopyDialog = async () => {
        const url = `${API_ENDPOINT}/scenario/${localeKey}/${scenarioId}/`;
        const res = await axios.get(url);

        setDataToLocalStorage(`${localeKey}_InitTree`, res.data.tree);

        // Сохраняем Draft чтобы "Сводная информация" не была пустой
        // при переходе на нее из режима просмотра в обход дерева сценария
        const treeData = transformData({...res.data.tree.structure}, 'structure');

        const updatedDraft = {
            treeData: treeData,
            selectedIndicators: {},
        };

        setDataToLocalStorage(`${localeKey}_Draft`, updatedDraft);

        setScenarioName(`${initData.name} (${t('pages:scenarios.dialog.copy')})`);
        setDescription(initData.description);
        setDialogState({
            parentScenarioName: scenarioName,
            scenarioId: null,
            editMode: true,
            update: false,
            open: true,
        });
    };

    return (
        <>
            <PopupItemCustom hidden={activeTabName === 'summary'}>
                
                {isTaxMode && (
                    <Box mr={2}>
                        <Select
                            width={650}
                            placeholder={t('pages:scenarios.dialog.tax_mode.code_version')}
                            variants={editions.map((i) => i.name)}
                            value={codeEdition}
                            onChange={(e) => setCodeEdition(e.target.value)}
                            disabled={viewMode}
                        />
                    </Box>
                )}
                
                <InputField
                    value={scenarioName}
                    onChange={(e) => setScenarioName(e.target.value)}
                    placeholder={t('pages:scenarios.dialog.scenario_name')}
                    icon={<EditIcon />}
                    disabled={viewMode}
                />
            </PopupItemCustom>

            {/* Поле для ввода описания сценария */}
            {!isTaxMode && (
                <PopupItemCustom hidden={activeTabName === 'summary'}>
                    <TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={t('pages:scenarios.dialog.description')}
                        icon={<EditIcon />}
                        disabled={viewMode}
                        height={60}
                    />
                </PopupItemCustom>
            )}

            <PopupItem hidden={activeTabName === 'summary'}>
                <PopupTabs tabs={tabs} activeId={activeTabId} onChange={handleChange} />
            </PopupItem>

            <PopupContent
                activeTabId={activeTabId}
                editMode={editMode}
                treeData={treeData}
                verboseIndex={verboseIndex}
                scenarioName={scenarioName}
                editionId={editionId}
                contents={popupContents}
                attrCompProps={{attributes, setAttrCompSt}}
                isFullscreen={isFullscreen}
                filterName={filterName}
            />

            <BottomMenuWrapper>
                <BottomMenu>
                    <>
                        <Box display="flex">
                            {editMode && ( // Кнопка отображается в режиме редактирования
                                <Button
                                    outline
                                    onClick={() => handleSaveScenario(true)}
                                    label={t('pages:scenarios.dialog.save_draft')}
                                    disabled={scenarioName === ''}
                                />
                            )}
                            <Button
                                onClick={handlePrevTab}
                                label={t('pages:scenarios.dialog.back')}
                                hidden={activeTabId === 1}
                            />
                        </Box>
                        <Box display="flex">
                            <Button
                                onClick={handleNextTab}
                                label={t('pages:scenarios.dialog.next')}
                                hidden={activeTabName === 'summary'}
                            />
                            <Button
                                onClick={() => setActiveTabId(summaryTabId)}
                                label={t(`pages:scenarios.dialog.${localeKey}.summary`)}
                                hidden={activeTabName === 'summary'}
                            />
                            {viewMode && ( // Кнопка отображается в режиме просмотра
                                <Button
                                    onClick={handleOpenCopyDialog}
                                    label={t('pages:scenarios.dialog.create_copy')}
                                />
                            )}
                            {editMode && ( // Кнопка отображается в режиме редактирования
                                <Button
                                    onClick={() => handleSaveScenario(false)}
                                    label={t('pages:scenarios.dialog.save')}
                                    disabled={scenarioName === ''}
                                />
                            )}
                            
                        </Box>
                    </>
                </BottomMenu>
            </BottomMenuWrapper>
        </>
    );
};

const PopupItemCustom = styled(PopupItem)`
    display: ${(props) => (props.hidden ? 'none' : 'flex')};
    margin-top: 25px;
    font-size: 12px;
    justify-content: flex-start;
    align-items: flex-end;

    input {
        width: 420px;
    }

    textarea {
        width: 650px;
    }

    .select-margin-right {
        margin-right: 40px;
    }
`;

const BottomMenuWrapper = styled.div`
    width: 100%;
    z-index: 10;
    background: #fff;
    margin-top: auto;
`;
