import {Reducer} from 'redux';
import {IScenario, IScenarioState, ScenarioCalcActionType} from "./types";

export const initialState: IScenarioState = {
    scenarios: [],
    loading: false,
    error: null
};

const deleteScenario = (scenarios: IScenario[], id: number) => {
    return [...scenarios.filter(s => s.id !== id)];
};

const reducer: Reducer<IScenarioState> = (state = initialState, action): IScenarioState => {
    switch (action.type) {
        case ScenarioCalcActionType.GET_SCENARIOS_LIST_REQUEST: {
            return {
                ...state, 
                loading: true
            }
        }
        case ScenarioCalcActionType.GET_SCENARIOS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                scenarios: [...action.payload.scenarios]
            }
        }
        case ScenarioCalcActionType.GET_SCENARIOS_LIST_ERROR: {
            return {
                ...state, 
                error: action.payload
            }
        }
        case ScenarioCalcActionType.SCENARIO_CALC_CREATE_SUCCESS: {
            return {
                ...state
            }
        }
        case ScenarioCalcActionType.SCENARIO_CALC_CREATE_ERROR: {
            return {
                ...state, error: 
                action.payload
            }
        }
        case ScenarioCalcActionType.DELETE_SCENARIO: {
            return {
                ...state, 
                scenarios: deleteScenario(state.scenarios, action.payload.id)
            }
        }
        default: {
            return state
        }

    }
};

export {reducer as scenarioCalcReducer}