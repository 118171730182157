import * as React from 'react';
import {clearFilters, clearScenarios} from './MyAwesomeLib';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import * as Sentry from '@sentry/browser';
import {getConfig} from '../config-provider';
import {withTranslation} from 'react-i18next';

type State = {
    error;
    errorInfo;
    eventId;
    hasError;
};

type Props = {
    children?: any;
    t;
};

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null, eventId: null, hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId: eventId, error: error, errorInfo: errorInfo});
        });

        clearFilters();
        clearScenarios();
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{padding: '10% 30%'}}>
                    <h1>{this.props.t('components:errorBoundary.text1', 'Что-то пошло не так...')}</h1>

                    <details style={{whiteSpace: 'pre-wrap', margin: '5% 0'}}>
                        <summary>{this.props.t('components:errorBoundary.text2', 'Сведения об ошибке')}</summary>
                        <br />
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo ? this.state.errorInfo.componentStack : null}
                        <br />
                        {getConfig('REACT_APP_SENTRY_DSN') ? (
                            <Button
                                size={'medium'}
                                color="primary"
                                style={{marginTop: 20}}
                                variant="outlined"
                                onClick={() => Sentry.showReportDialog({eventId: this.state.eventId})}
                            >
                                {this.props.t('components:errorBoundary.text3', 'Отправить сообщение об ошибке')}
                            </Button>
                        ) : null}
                    </details>

                    <Button
                        size={'large'}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            window.localStorage.removeItem('indicatorTree:currentIndicator');
                            window.localStorage.removeItem('costsTree:currentIndicator');
                            window.location.reload();
                        }}
                        endIcon={<RefreshIcon />}
                    >
                        {this.props.t('components:errorBoundary.text4', ' Попробуйте перезагрузить страницу')}
                    </Button>
                </div>
            );
        }
        return this.props.children;
    }
}

export default withTranslation('components')(ErrorBoundary);
