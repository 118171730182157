import {action} from 'typesafe-actions';
import {CalcJobErrorsActionType, ICalcJobErrors} from './types';

export const getJobErrors = (id: number) => action(CalcJobErrorsActionType.GET_JOB_ERRORS_REQUEST, id);

export const getJobErrorsSuccess = (data: ICalcJobErrors) =>
    action(CalcJobErrorsActionType.GET_JOB_ERRORS_SUCCESS, data);

export const getJobErrorsError = (message: string) =>
    action(CalcJobErrorsActionType.GET_JOB_ERRORS_ERROR, message);

