import axios from '../../axiosMiddleware';
import {LanguageActionType} from './types';
import {
    getLanguageRequestSuccess, 
    setLanguageRequestSuccess,
    getLanguageRequestError,
    setLanguageRequestError
} from './action';
import {getConfig} from '../../config-provider';
import {all, call, fork, put, takeLatest} from 'redux-saga/effects';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');


function* handleGetUserLanguageRequest() {
    try {
        const res = yield call(axios.get, `${API_ENDPOINT}/managing/language`);
        if (res.error) {
            yield put(getLanguageRequestError(res.error));
        } else {
            yield put(getLanguageRequestSuccess(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getLanguageRequestError(err.stack!));
        } else {
            yield put(getLanguageRequestError('Can\'t load user language'));
        }
    }
}

function* handleSetUserLanguageRequest(action) {
    const url = `${API_ENDPOINT}/managing/language?language=${action.payload.user_language}`;
    
    try {
        const res = yield call(axios.post, url);
        if (res.error) {
            yield put(setLanguageRequestError(res.error))
        } else {
            yield put(setLanguageRequestSuccess())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(setLanguageRequestError(err.stack!))
        } else {
            yield put(setLanguageRequestError('An unknown error occured during language update.'))
        }
    }
}

/////////////////////////
function* watchHandleGetUserLanguageRequestLoaded() {
    yield takeLatest(LanguageActionType.GET_USER_LANGUAGE_REQUEST, handleGetUserLanguageRequest);
}

export function* getUserLanguageRequestLoadedSaga() {
    yield all([fork(watchHandleGetUserLanguageRequestLoaded)]);
}

/////////////////////////
function* watchHandleSetUserLanguageRequestLoaded() {
    yield takeLatest(LanguageActionType.SET_USER_LANGUAGE_REQUEST, handleSetUserLanguageRequest);
}

export function* setUserLanguageRequestLoadedSaga() {
    yield all([fork(watchHandleSetUserLanguageRequestLoaded)]);
}

export function* userLanguageSaga() {
    yield all([
        fork(watchHandleGetUserLanguageRequestLoaded),
        fork(watchHandleSetUserLanguageRequestLoaded)
        ]
    );
}
