import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import styled from 'styled-components/macro';
import { CheckBoxWrapper, CheckmarkCancel } from '../Design/Checkboxes/CheckboxMock';

type AccordionComponentProps = {
    children: React.ReactNode;
};

type AccordionItemProps = {
    children: React.ReactNode;
};

export const AccordionComponent: React.FC<AccordionComponentProps> = ({children}) => {
    if (!children) return null;
    return <Accordion>{children}</Accordion>;
};

export const AccordionSummaryComponent: React.FC<AccordionItemProps> = ({children}) => {
    if (!children) return null;
    return <AccordionSummaryCustom>{children}</AccordionSummaryCustom>;
};

export const AccordionDetailsComponent: React.FC<AccordionItemProps> = ({children}) => {
    if (!children) return null;
    return <AccordionDetailsCustom>{children}</AccordionDetailsCustom>;
};

const AccordionSummaryCustom = styled(AccordionSummary)`
    border: none;
    padding-left: 0;

    ${CheckBoxWrapper} {
      height: 20px;
      width: 20px;
    }

  ${CheckmarkCancel} {
    background-color: ${(props) => props.theme.colors.black};
    
    &:before {
      content: none;
    }
    
    &:after {
      content: '';
      position: absolute;
      left: 9px;
      top: 6px;
      height: 7px;
      width: 2px;
      background-color: ${(props) => props.theme.colors.white};
      transform: rotate(-90deg);
    }
  }
`;

export const AccordionHeader = styled.div`
    background: ${(props) => props.theme.colors.grey6};
    padding: 10px 15px 10px 20px;
    margin-left: 20px;
    width: 100%;
`;

const AccordionDetailsCustom = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 30px;
    overflow-x: scroll;
`;
