import {Reducer} from 'redux'
import {IWellsPlotsState, WellsActionType} from './types'

export const initialWellPlotState: IWellsPlotsState = {
    data: {},
};

export const wellPlotsReducer: Reducer<IWellsPlotsState> = (state = initialWellPlotState, action) => {
    switch (action.type) {
        case WellsActionType.GET_WELL_PLOT_REQUEST: {
            return {...state}
        }
        case WellsActionType.GET_WELL_PLOT_SUCCESS: {
            return {
                ...state,
                data: Object.assign({[action.payload.well]: action.payload.data}, state.data)
            }
        }
        case WellsActionType.GET_WELL_PLOT_ERROR: {
            return {...state}
        }
        default: {
            return state
        }
    }
};
