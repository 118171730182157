import {action} from 'typesafe-actions';
import {CalcVersionErrorsActionType, ICalcVersionErrors} from './types';

export const getVersionErrors = (id: number) => action(CalcVersionErrorsActionType.GET_VERSION_ERRORS_REQUEST, id);

export const getVersionErrorsSuccess = (data: ICalcVersionErrors) =>
    action(CalcVersionErrorsActionType.GET_VERSION_ERRORS_SUCCESS, data);

export const getVersionErrorsError = (message: string) =>
    action(CalcVersionErrorsActionType.GET_VERSION_ERRORS_ERROR, message);

