export enum SnackbarsActionType {
    HIDE_SNACKBAR = '@@snackbars/HIDE_SNACKBAR',
    SHOW_SNACKBAR = '@@snackbars/SHOW_SNACKBAR',
    SNACK_DISPLAY_REQUESTED = '@@snackbars/SNACK_DISPLAY_REQUESTED'
}

export interface ISnackbarsState {
    snackbarOpen: boolean;
    snackbarType: string | null;
    snackbarMessage: string | null
}