import {IUser} from "../tasks/types";

export enum ReportsActionType {
    RETRIEVE_REPORT_REQUEST = '@@reports/RETRIEVE_REPORT_REQUEST',
    RETRIEVE_REPORT_SUCCESS = '@@reports/RETRIEVE_REPORT_SUCCESS',
    RETRIEVE_REPORT_ERROR = '@@reports/RETRIEVE_REPORT_ERROR',
    GET_REPORTS_REQUEST = '@@reports/GET_REPORTS_REQUEST',
    GET_REPORTS_SUCCESS = '@@reports/GET_REPORTS_SUCCESS',
    GET_REPORTS_ERROR = '@@reports/GET_REPORTS_ERROR',
    ADD_REPORT = '@@reports/ADD_REPORT',
    UPDATE_REPORT = '@@reports/UPDATE_REPORT',
    DELETE_REPORT = '@@reports/DELETE_REPORT',
    DELETE_REPORT_DONE = '@@reports/DELETE_REPORT_DONE',
    CLEAR_REPORT_BUFFER = '@@reports/CLEAR_REPORT_BUFFER',
    ORDER_REPORT_REQUEST = '@@reports/ORDER_REPORT_REQUEST',
    DOWNLOAD_REPORT = '@@reports/DOWNLOAD_REPORT',
    GET_REPORT_TAGS = '@@reports/GET_REPORT_TAGS',
    GET_REPORT_TAGS_SUCCESS = '@@reports/GET_REPORT_TAGS_SUCCESS',
    GET_REPORT_TAGS_ERROR = '@@reports/GET_REPORT_TAGS_SUCCESS_ERROR',
}

export interface IReport {
    id: number;
    created_by: IUser;
    created_at: string;
    file_name: string;
    report_hash: string;
    report_type: string;
}

export interface IFullKPIReport {
    id: number;
    report_type: string;
    report_data: any;
}

export interface IReportTag {
    tag_name: string,
    tag_alias: string
}

export interface IReportsState {
    reports: Map<number, IReport> | null;
    readonly reportBuffer: IFullKPIReport[];
    readonly reportTags: IReportTag[];
    readonly loading: boolean;
    readonly error: string | null;
    readonly TOTAL_PAGES: number;
    readonly PER_PAGE: number;
}

export interface IReportRequest {
    reportType: string;
    reportArgs?: any;
}
