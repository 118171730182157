import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

const TableContainer = (props) => {
    return (
        <StyledPaper {...props} />
    )
}

const StyledPaper = styled(Paper)`
  height: 100%;
  box-shadow: none;
  background: none;
  
  > div {
    height: 100%;
  }
`

export default React.memo(TableContainer);