export enum FilterFieldVariantsType {
    FILTER_FIELD_REQUEST = '@@filterVariants/FILTER_FIELD_REQUEST',
    FILTER_FIELD_SUCCESS = '@@filterVariants/FILTER_FIELD_SUCCESS',
    FILTER_ADD_LIST_VARIANTS_SUCCESS = '@@filterVariants/FILTER_ADD_LIST_VARIANTS_SUCCESS',
    FILTER_ADD_LIST_VARIANTS_REQUEST = '@@filterVariants/FILTER_ADD_LIST_VARIANTS_REQUEST',
    FILTER_FIELD_CLEAR = '@@filterVariants/FILTER_CLEAR',
    FILTER_FIELD_ERROR = '@@filterVariants/FILTER_FIELD_ERROR',
}

export interface FilterVariantsType {
    [key: string]: {
        values: string[];
    };
}

export interface FilterListVariantsType {
    [key: string]: FilterListVariant[];
}

export interface FilterListVariant {
    originalValue: string;
    roundValue: string;
}

export interface IFilterVariantsState {
    listFiltersBuffer: FilterListVariantsType
    listFilters: FilterListVariantsType;
    loadingList: boolean;
    loading: boolean;
    error: any;
}

export interface IFilterRequest {
    readonly filter_name: string;
    readonly version_ids: number[];
    readonly mipairs: string[];
    readonly search_value: string;
    readonly precision?: number;
}

export interface IFilterListVariantsRequest {
    readonly columns: string[];
    readonly version_ids: number[];
    readonly mipairs: string[];
    readonly search_value: string;
    readonly precicion?: number;
}
