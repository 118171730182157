import { Reducer } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { addOrUpdate } from '../utils';
import { createSelector } from 'reselect';

const PAGINATOR_STATE = {
    pageMap: new Map([
        ['import', 1],
        ['versions', 1],
        ['versions_selected', 1],
        ['taxModes', 1],
        ['macroScenario', 1],
        ['emissionScenarios', 1],
        ['costsScenarios', 1],
        ['taskQueue', 1],
        ['reports', 1],
        ['portfolioAnalysis', 1],
        ['files', 1]
    ]),
};

export interface IPaginatorState {
    pageMap: Map<string, number>;
}

export const { setPage, resetPage, increment } = createActions({
    SET_PAGE: (key, page) => ({ key, page }),
    RESET_PAGE: (key) => ({ key }),
});

const pageMapSelector = (state, key) => {
    return state.pageMap.get(key);
};

export const getPage = createSelector([pageMapSelector], (page) => {
    return page ? page : 1;
});

const index: Reducer = handleActions(
    {
        [setPage]: (state, { payload: { key, page } }) => {
            return {
                ...state,
                pageMap: addOrUpdate(state.pageMap, key, page),
            };
        },
        [resetPage]: (state, { payload: { key } }) => {
            return {
                ...state,
                pageMap: addOrUpdate(state.pageMap, key, 1),
            };
        },
    },
    PAGINATOR_STATE
);

export { index as paginationReducer };
