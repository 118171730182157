import styled from "styled-components/macro";

export const PopupItem = styled.div`
    margin-top: 25px;
    font-size: 12px;
`;

export const PopupItemFlex = styled(PopupItem)`
    display: flex;
`;

export const ActionButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const ActionButtonWrapperSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Text = styled.p`
    margin: 10px 0;
`;