import React, {useEffect} from 'react';
import {useThrottleFn} from 'ahooks';
import {changeSettings} from '../store/currentGrid/actions';
import styled from 'styled-components/macro';
import {
    PaginationArrowLast,
    PaginationArrowLeft,
    PaginationArrowOne,
    PaginationArrowRight,
} from './Design/Icons/IconsPagination';
import {useDebouncedCallback} from "use-debounce";

export interface IPaginatorProps {
    currentPage: number;
    loading: boolean;
    pageSize: number;
    count: number;
    onPageChange: (page: number) => any;
    parent?: string;
    isDark?: boolean;
}

export interface IPaginatorState {
    pageInput: number | null;
    isValid: boolean;
}

const PAGE_CHANGE_DEBOUNCE_TIME = 800;

const Paginator: React.FC<IPaginatorProps> = ({onPageChange, pageSize, count, currentPage, isDark}) => {
    const [{pageInput, isValid}, setState] = React.useState<IPaginatorState>({
        pageInput: currentPage,
        isValid: true,
    });

    const calculateTotalPages = (count, pageSize) => Math.max(Math.ceil(count / pageSize), 1);
    const totalPages = calculateTotalPages(count, pageSize);

    useEffect(() => {
        setState((state) => ({...state, pageInput: currentPage}));
    }, [currentPage]);

    useEffect(() => {
        // Switch to the last available page
        if (currentPage > totalPages) {
            changeSettings({page: totalPages});
            setPage(totalPages);
        }
        //eslint-disable-next-line
    }, [currentPage, totalPages]);

    const triggerOnPageChangeIfDiffer = (page: number) => {
        if (currentPage !== page) {
            onPageChange(page);
        }
    };

    const [onPageChangeDebounced, cancelOnPageChangeDebounced] = useDebouncedCallback(
        (page: number) => triggerOnPageChangeIfDiffer(page),
        PAGE_CHANGE_DEBOUNCE_TIME
    );

    const onPageInputChange = (page: string | null) => {
        if (!page) {
            setState((state) => ({...state, pageInput: null, isValid: false}));
            cancelOnPageChangeDebounced();
        }
        const intPage = Number(page);
        if (!isNaN(intPage)) {
            if (1 > intPage || intPage > totalPages) {
                setState((state) => ({...state, pageInput: intPage || null, isValid: false}));
                cancelOnPageChangeDebounced();
            } else {
                setPage(intPage, onPageChangeDebounced);
            }
        }
    };

    const setPage = (page: number, pageChanger: (page: number) => any = triggerOnPageChangeIfDiffer) => {
        setState((state) => ({...state, pageInput: page, isValid: true}));
        pageChanger(page);
    };

    const {run} = useThrottleFn(
        (page: number) => {
            if (page > totalPages || page <= 0) return;
            onPageChange(page);
        },
        {wait: 1000}
    );

    const throttleChangePage = run;
    const validPage = isValid ? pageInput : currentPage;

    const handleNextPageClick = () => {
        setState((state) => ({...state, isValid: true}));
        throttleChangePage(validPage + 1)
    }

    const handlePrevPageClick = () => {
        setState((state) => ({...state, isValid: true}));
        throttleChangePage(validPage - 1)
    }

    const handleLastPageClick = () => {
        setState((state) => ({...state, isValid: true}));
        throttleChangePage(totalPages)
    }

    const handleFirstPageClick = () => {
        setState((state) => ({...state, isValid: true}));
        throttleChangePage(1)
    }

    return (
        <PaginatorWrapper>
            <div onClick={handleFirstPageClick}>
                <PaginationArrowOne disabled={validPage === 1} isDark={isDark} />
            </div>
            <div onClick={handlePrevPageClick}>
                <PaginationArrowLeft disabled={validPage === 1} isDark={isDark} />
            </div>

            <PagesText isDark={isDark}>
                <PagesInput
                    disabled={totalPages === 1}
                    isValid={isValid}
                    onChange={(event: any) => onPageInputChange(event.target.value)}
                    value={pageInput !== null ? pageInput.toString() : ''}
                    isDark={isDark}
                />
                <PagesTextSeparator>/</PagesTextSeparator>
                {totalPages}
            </PagesText>

            <div onClick={handleNextPageClick}>
                <PaginationArrowRight disabled={validPage === totalPages} isDark={isDark} />
            </div>
            <div onClick={handleLastPageClick}>
                <PaginationArrowLast disabled={validPage === totalPages} isDark={isDark} />
            </div>
        </PaginatorWrapper>
    );
};

const PaginatorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 180px;
`;

const PagesText = styled.span`
    cursor: default;
    display: flex;
    align-items: center;
    ${props => props.isDark && `color: #fff`};
    font-size: 13px;
`;

const PagesInput = styled.input`
  max-width: 30px;
  width: 100%;
  font-size: 13px;
  padding: 3px 3px 2px;
  margin-right: 3px;
  outline: none;
  border: none;
  ${props => {
    if (!props.isValid) return `background-color: ${props.theme.colors.red}`;
    if (props.isDark) return `background-color: ${props.theme.colors.grey1}`;
    if (!props.isDark) return `background-color: ${props.theme.colors.grey6}`;
    return `border-color: ${props.theme.colors.grey1}`;
  }};
  ${props => props.isDark && `color: #fff`};
`;

const PagesTextSeparator = styled.span`
  margin-right: 3px;
`

export default Paginator;
