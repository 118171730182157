import { action } from 'typesafe-actions'
import { DemoPlotActionType, IDashPlots } from './types'

export const getDemoPlotRequest = (
    name, 
    url, 
    activeVersionId, 
    urlArgs
) => action(DemoPlotActionType.GET_DEMO_PLOT_REQUEST, {
    name, 
    url, 
    activeVersionId, 
    urlArgs
});
export const getDemoPlotSuccess = (
    name, 
    plots: IDashPlots, 
    activeVersionId, 
    urlArgs,
    canStack,
    canCumulate,
    groupbyAvailable
) => action(DemoPlotActionType.GET_DEMO_PLOT_SUCCESS, {
    name,
    plots,
    activeVersionId,
    urlArgs,
    canStack,
    canCumulate,
    groupbyAvailable

});
export const getDemoPlotError = (
    name, 
    loadingError: any, 
    activeVersionId, 
    urlArgs
) => action(DemoPlotActionType.GET_DEMO_PLOT_ERROR, {
    name,
    loadingError,
    activeVersionId,
    urlArgs
});

export const setReportGroup = (name: string) => action(DemoPlotActionType.SET_REPORT_GROUP, { name });

export const invalidatePlot = (name) =>
    action(DemoPlotActionType.INVALIDATE_PLOT, { name });