import React from 'react';
import { MTableCell } from 'material-table';
import styled from 'styled-components';

const TableCell = (props) => {
    return <StyledTableCell {...props} />
}

const StyledTableCell = styled(MTableCell)`
    ${(props) => props?.columnDef?.field === 'hierarchy' && 'padding: 1px 5px !important; text-align: center;'}
    ${(props) => props?.columnDef?.field === 'columns' && 'padding: 4px 5px !important; text-align: center;'}
`

export default React.memo(TableCell)