export enum CalcVersionActionType {
    GET_VERSIONS_REQUEST = '@@calcVersions/GET_VERSIONS_REQUEST',
    GET_VERSIONS_SUCCESS = '@@calcVersions/GET_VERSIONS_SUCCESS',
    GET_VERSIONS_ERROR = '@@calcVersions/GET_VERSIONS_ERROR',
    ADD_CALC_VERSION = '@@calcVersions/ADD_CALC_VERSION',
    DELETE_CALC_VERSION_BY_JOB_ID = '@@calcVersion/DELETE_CALC_VERSION_BY_JOB_ID',
    DELETE_CALC_VERSION_DONE = '@@calcVersion/DELETE_CALC_VERSION_DONE',
    DELETE_CALC_VERSIONS_REQUEST = '@@calcVersion/DELETE_CALC_VERSIONS_REQUEST',
    DELETE_CALC_VERSIONS_ERROR = '@@calcVersion/DELETE_CALC_VERSIONS_ERROR',
    DELETE_CALC_VERSIONS_SUCCESS = '@@calcVersion/DELETE_CALC_VERSIONS_SUCCESS',
    RECALCULATE_VERSIONS = '@@calcVersion/RECALCULATE_VERSIONS',
    RECALCULATE_VERSIONS_DISABLE_HIST = '@@calcVersion/RECALCULATE_VERSIONS_DISABLE_HIST',
    CALCULATE_ANOMALIES = '@@calcVersion/CALCULATE_ANOMALIES',
    SWITCH_OFF_WELLS = '@@calcVersion/SWITCH_OFF_WELLS',
    RUN_UPLIFT_STAGE_1 = '@@calcVersion/RUN_UPLIFT_STAGE_1',
    RUN_UPLIFT_STAGE_2 = '@@calcVersion/RUN_UPLIFT_STAGE_2',
    UPDATE_CALC_VERSION = '@@calcVersion/UPDATE_CALC_VERSION',
    RUN_SIMULATION = '@@calcVersion/RUN_SIMULATION'
}

export interface IDeposit {
    id: number,
    name: string
}

export interface IRegion {
    id: number,
    name: string,
    territory:
        {
            id: number,
            name: string,
        }
}

export interface ILicensedSite {
    id: number,
    name: string,
    region: IRegion
    deposits,
}

export interface ISubsurfaceUser {
    id: number,
    name: string
}

export interface ICalcLink {
    id: number,
    deposit: IDeposit,
    subsurface_user: ISubsurfaceUser,
    licensed_site: ILicensedSite,
    company
}

export interface ICalcVersion {
    id: any;
    name: string;
    created_at: string;
    calc_set: string;
    calc_job: number;
    has_errors: boolean;
    has_warnings: boolean;
    has_info: boolean;
    has_hierarchy: boolean;
    links: ICalcLink[];
    tags;
    version_groups;
    user_name: string | null;
    user: number | null;
    has_anomaly_calculation: boolean;
    release_tag: string;
    parent_version: number | null;
    wells_switch_off;
    tax_regime;
    iteration;
    macro;
    scenarios;
    percent_prod_profitable;
}

export type ICalcVersionResult = ICalcVersion[];

export interface ICalcVersionState {
    calcVersions: Map<number, ICalcVersion> | null;
    readonly loading: boolean;
    readonly error: string | null;
    readonly TOTAL_PAGES: number;
    readonly PER_PAGE: number;
}
