import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {EditIcon} from '../../components/Design/Icons/IconsEditTable';
import {Button} from '../../components/Design/Button';
import {TextareaAutosize} from '@material-ui/core';

const MacroDescEditInfo = (props) => {
    const {
        handleSaveScenarioName,
        handleSaveScenarioDescription,
        isNameInputVisible,
        setIsNameInputVisible,
        isDescriptionInputVisible,
        setIsDescriptionInputVisible,
        scenarioDetails,
    } = props;
    const {t} = useTranslation(['components', 'pages']);

    const [nameInput, setNameInput] = useState('');
    const [descriptionInput, setDescriptionInput] = useState('');
    const nameInputRef = useRef(null);
    const descriptionInputRef = useRef(null);

    useEffect(() => {
        setNameInput(scenarioDetails.name);
        setDescriptionInput(scenarioDetails.description);
    }, [scenarioDetails.name, scenarioDetails.description]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            const NameWrapper = document.getElementById('name-wrapper');
            const DescriptionWrapper = document.getElementById('description-wrapper');

            if (!e.path.includes(NameWrapper)) {
                setIsNameInputVisible(false);
                setNameInput(scenarioDetails.name);
            }
            if (!e.path.includes(DescriptionWrapper)) {
                setIsDescriptionInputVisible(false);
                setDescriptionInput(scenarioDetails.description);
            }
        };

        document.addEventListener('mousedown', handleClickOutside, false);

        return () => document.removeEventListener('mousedown', handleClickOutside, false);
        //eslint-disable-next-line
    }, [scenarioDetails]);

    useEffect(() => {
        if (nameInputRef.current) {
            nameInputRef.current.selectionStart = nameInputRef.current.value.length;
            nameInputRef.current.focus();
        }
    }, [isDescriptionInputVisible, isNameInputVisible])

    return (
        <>
            <NameWrapper id={'name-wrapper'}>
                {isNameInputVisible ? (
                    <>
                        <NameInput
                            value={nameInput}
                            onChange={(e) => setNameInput(e.target.value)}
                            ref={nameInputRef}
                        />
                        <SaveButton
                            small
                            outline
                            label={t('pages:macroScenarios.save_changes', 'Сохранить изменения')}
                            onClick={() => handleSaveScenarioName(nameInput)}
                        />
                    </>
                ) : (
                    <NameValue
                        onClick={() => setIsNameInputVisible(true)}
                    >
                        {nameInput}
                        <NameIconWrapper>
                            <EditIcon />
                        </NameIconWrapper>
                    </NameValue>
                )}
            </NameWrapper>
            <DescriptionWrapper id={'description-wrapper'}>
                {isDescriptionInputVisible ? (
                    <>
                        <DescriptionInput
                            value={descriptionInput}
                            onChange={(e) => setDescriptionInput(e.target.value)}
                            ref={descriptionInputRef}
                        />
                        <SaveButton
                            small
                            outline
                            label={t('pages:macroScenarios.save_changes', 'Сохранить изменения')}
                            onClick={() => handleSaveScenarioDescription(descriptionInput)}
                        />
                    </>
                ) : (
                    <>
                        <DescriptionValue
                            onClick={() => setIsDescriptionInputVisible(true)}
                        >
                            {descriptionInput}
                            <DescriptionIconWrapper>
                                <EditIcon />
                            </DescriptionIconWrapper>
                        </DescriptionValue>
                        
                        {/* BlankSpacer резервирует место для сохранения положения текста при клике */}
                        <BlankSpacer
                            small
                            outline
                            label={t('pages:macroScenarios.save_changes', 'Сохранить изменения')}
                            disabled
                        />
                    </>
                )}
            </DescriptionWrapper>
        </>
    );
};

const DescriptionIconWrapper = styled.div`
  filter: opacity(0);
  margin-top: -5px;
`;

const NameIconWrapper = styled.div`
  filter: opacity(0);
`;

const NameInput = styled.input`
  border: 0;
  background: ${(props) => props.theme.colors.white};
  font-size: 14px;
  margin-right: 17px;
  height: 30px;
  width: 100%;
  padding: 0;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

const SaveButton = styled(Button)`
  min-width: fit-content;
  height: 30px;
`;

const BlankSpacer = styled(SaveButton)`
    border-color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.white};

    &:hover {
        border-color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.white};
    }
`;

const NameValue = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  &:hover ${NameIconWrapper} {
    filter: opacity(1);
    transition: .3s;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 22px;
  padding: 0 0 20px;

  & > textarea, div {
    font-family: 'EYInterstate Light', Helvetica, Arial, sans-serif;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

const DescriptionInput = styled(TextareaAutosize)`
  border: 0;
  font-size: 14px;
  margin-right: 30px;
  line-height: 22px;
  width: 100%;
  resize: vertical;
  
  &:focus {
    outline: none;
  }
`;

const DescriptionValue = styled.div`
  cursor: pointer;
  display: flex;
  align-items: flex-start;

  &:hover ${DescriptionIconWrapper} {
    filter: opacity(1);
    transition: .3s;
  }
`;

export default MacroDescEditInfo;