import { action } from 'typesafe-actions'
import { DeltaPlotActionType, IDeltaPlots } from './types'

export const getDeltaPlotRequest = (versions: number[], miPair: string) => action(DeltaPlotActionType.GET_DELTA_PLOT_REQUEST, {
    versions,
    miPair,
});
export const getDeltaPlotSuccess = (plots: IDeltaPlots) => action(DeltaPlotActionType.GET_DELTA_PLOT_SUCCESS, { plots });
export const getDeltaPlotError = (loadingError: any) => action(DeltaPlotActionType.GET_DELTA_PLOT_ERROR, { loadingError });

export const addToHiddenTraces = (trace: string) => action(DeltaPlotActionType.ADD_TO_HIDDEN_TRACES, { trace })
export const removeFromHiddenTraces = (trace: string) => action(DeltaPlotActionType.REMOVE_FROM_HIDDEN_TRACES, { trace })
export const emptyHiddenTraces = () => action(DeltaPlotActionType.EMPTY_HIDDEN_TRACES)

