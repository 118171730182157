import React from 'react';
import styled from 'styled-components';
import RadioGroup from '../../components/RadioGroup';
import {ControlBlock} from '../calculation-page/calculation-page.styles';
import {
    AGGIcon,
    AggregateColumnIcon,
    DecreasingZerosIcon, IncrementingZerosIcon,
    ZeroEliminationIcon,
} from '../../components/Design/Icons/IconsCalculatelTable';
import {Checkbox} from '../../components/Design/Checkboxes/Checkbox';
import {useTranslation} from 'react-i18next';

const MacroDescTableControls = (props) => {
    const {
        DATA_VIEW_OPTIONS,
        macroTable,
        setMacroTable,
        accumulateData,
        setAccumulateData,
        showNulls,
        setShowNulls,
        aggData,
        setAggData,
        gridPrecision,
        setGridPrecision,
        useTechNames,
        setTechNames,
        showFilled,
        onlyFilled,
    } = props;
    const {t} = useTranslation(['components', 'pages']);

    return (
        <RadioGroupWrapper>
            <div className='macro-desc-radio-group'>
                <RadioGroup
                    options={DATA_VIEW_OPTIONS}
                    selectedOptionKey={macroTable}
                    onChange={({key}) => setMacroTable(key)}
                />
            </div>
            <ControlBlockWrapper>
                <ControlBlock>
                    <div data-tip={t('components:grid_view.cumulative')}>
                        <AggregateColumnIcon
                            isActive={accumulateData}
                            disabled={false}
                            onClick={() => setAccumulateData(prevState => !prevState)}
                        />
                    </div>
                    <div data-tip={t('components:grid_view.exclude_null_values')}>
                        <ZeroEliminationIcon
                            isActive={showNulls}
                            disabled={false}
                            onClick={() => setShowNulls(prevState => !prevState)}
                        />
                    </div>
                    <div data-tip={t('components:grid_view.include_aggregated_data')}>
                        <AGGIcon
                            isActive={aggData}
                            disabled={false}
                            onClick={() => setAggData(prevState => !prevState)}
                        />
                    </div>
                </ControlBlock>
                <ControlBlock style={{marginLeft: '10px'}}>
                    <div data-tip={t('components:grid_view.decrease_fractional_value')}>
                        <DecreasingZerosIcon
                            disabled={gridPrecision === 0}
                            onClick={() => setGridPrecision(prevState => prevState - 1)}
                        />
                    </div>
                    <div data-tip={t('components:grid_view.increase_fractional_value')}>
                        <IncrementingZerosIcon
                            disabled={gridPrecision === 4}
                            onClick={() => setGridPrecision(prevState => prevState + 1)}
                        />
                    </div>
                </ControlBlock>
            </ControlBlockWrapper>
            <TechNamesCheckboxWrapper>
                <Checkbox
                    checked={useTechNames}
                    onChange={() => setTechNames(!useTechNames)}
                    label={t('pages:macroScenarios.tech_names', 'Технические названия')}
                />
            </TechNamesCheckboxWrapper>
            <CheckboxPulledRight>
                <Checkbox
                    checked={showFilled}
                    onChange={onlyFilled}
                    label={t(
                        'pages:macroScenarios.show_filled',
                        'Только заполненные',
                    )}
                />
            </CheckboxPulledRight>
        </RadioGroupWrapper>
    );
};

const ControlBlockWrapper = styled.div`
  display: flex;
  margin: auto;
`;

const TechNamesCheckboxWrapper = styled.div`
  margin-left: auto;
`;

const RadioGroupWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`;

const CheckboxPulledRight = styled.div`
  margin-left: 10px;
`;

export default MacroDescTableControls;
