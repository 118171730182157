import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import {CalcVersionErrorsActionType} from './types';
import {getVersionErrorsError, getVersionErrorsSuccess} from './actions';
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetVersions(action) {
    try {
        const res = yield call(axios.get, `${API_ENDPOINT}/logs/?version=${action.payload}`);
        if (res.error) {
            yield put(getVersionErrorsError(res.error));
        } else {
            yield put(getVersionErrorsSuccess(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getVersionErrorsError(err.stack!));
        } else {
            yield put(getVersionErrorsError('An unknown error occured.'));
        }
    }
}

function* watchGetCalcVersionErrorsRequest() {
    yield takeLatest(CalcVersionErrorsActionType.GET_VERSION_ERRORS_REQUEST, handleGetVersions);
}

function* calcVersionsErrorsSaga() {
    yield all([fork(watchGetCalcVersionErrorsRequest)]);
}

export default calcVersionsErrorsSaga;
