import {Reducer} from 'redux';
import {CalcJobActionType, ICalcJobsState} from './types';
import {addAll, addOnTopJobAndAlike, addOrUpdate, deleteItem} from "../utils";

export const initialState: ICalcJobsState = {
    calcJobs: null,
    loading: false,
    error: null,
    TOTAL_PAGES: 0,
    PER_PAGE: 30
};

const reducer: Reducer<ICalcJobsState> = (state = initialState, action): ICalcJobsState => {
    switch (action.type) {
        case CalcJobActionType.FETCH_REQUEST: {
            return {...state, loading: true};
        }
        case CalcJobActionType.FETCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                calcJobs: addAll(action.payload.results),
                TOTAL_PAGES: Math.ceil(action.payload.count / state.PER_PAGE)
            };
        }
        case CalcJobActionType.FETCH_ERROR: {
            return {...initialState, error: action.payload};
        }
        case CalcJobActionType.ADD_CALC_JOB: {
            return {
                ...state,
                calcJobs: addOnTopJobAndAlike(state.calcJobs, action.payload)
            };
        }

        case CalcJobActionType.UPDATE_CALC_JOB: {
            return {
                ...state,
                calcJobs: addOrUpdate(state.calcJobs, action.payload.id, action.payload.calcJob)
            };
        }
        case CalcJobActionType.DELETE_CALC_JOB: {
            return {
                ...state,
                calcJobs: deleteItem(state.calcJobs, action.payload.id)
            };
        }
        case CalcJobActionType.DELETE_CALC_JOB_DONE: {
            return {
                ...state,
                calcJobs: deleteItem(state.calcJobs, action.payload.id)
            };
        }
        default: {
            return state;
        }
    }
};

export {reducer as calcJobReducer};
