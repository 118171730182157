import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import {PermissionsAction } from './types';
import {getPermissionsSuccess, getPermissionsError } from './actions';
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetPermissions() {    
    try {
        const res = yield call(axios.get, `${API_ENDPOINT}/auth/permissions`);
        if (res.error) {
            yield put(getPermissionsError({error: res.error, permissions: [], adminMenu: false}));
        } else {
            yield put(getPermissionsSuccess(res.data));  
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getPermissionsError({error: err.stack!, permissions: [], adminMenu: false}));
        } else {
            yield put(getPermissionsError({error: 'An unknown error occured.', permissions: [], adminMenu: false}));
        }
    }
}

function* watchGetPermissionsErrorsRequest() {
    yield takeLatest(PermissionsAction.GET_PERMISSIONS_REQUEST, handleGetPermissions);
}

function* PermissionsSaga() {
    yield all([fork(watchGetPermissionsErrorsRequest)]);
}

export default PermissionsSaga;
