import axios from '../axiosMiddleware';
import { doLogout } from '../store/login/action';
import { requestSnackDisplay } from "../store/snackbars/actions";
import { getCookieByName } from '../helpers/utils';

export const setupInterceptors = (store, history) => {

    axios.interceptors.request.use(
        function (config) {
            if (!config.headers.common['Authorization']) {

                let token = getCookieByName('jwt_token');

                if (token) {
                    localStorage.setItem('token', token);
                } else {
                    token = localStorage.getItem('token');
                }
                
                if (token) {
                    config.headers.common['Authorization'] = `JWT ${token}`;
                } else {
                    //history.push('/login');
                    return config;
                }
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if ([401].includes(error.response.status)) {
                history.push('/login');
                store.dispatch(doLogout());
            }
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            const token = localStorage.getItem('token');
            if ([403].includes(error.response.status) && (token !== null)) {
                store.dispatch(requestSnackDisplay('error', `У пользователя нет прав для выполнения действия`))
            }
            return Promise.reject(error);
        }
    );

};
