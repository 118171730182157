import {Reducer} from 'redux'
import {IWellsState, WellsActionType} from './types'

export const initialWellsState: IWellsState = {
    data: [],
    loadingInProgress: false,
    updatingInProgress: false,
    loadingError: null,
    updatingError: null,
};


export const wellsReducer: Reducer<IWellsState> = (state = initialWellsState, action) => {
    switch (action.type) {
        case WellsActionType.GET_WELLS_REQUEST: {
            return {...state, loadingInProgress: true}
        }
        case WellsActionType.GET_WELLS_SUCCESS: {
            return {...state, loadingInProgress: false, ...action.payload}
        }
        case WellsActionType.GET_WELLS_ERROR: {
            return {...state, loadingInProgress: false, ...action.payload}
        }
        case WellsActionType.SET_WELL_STATE_REQUEST: {
            return {...state, loadingInProgress: true}
        }
        case WellsActionType.SET_WELL_STATE_SUCCESS: {
            return {...state, loadingInProgress: false, ...action.payload}
        }
        case WellsActionType.SET_WELL_STATE_ERROR: {
            return {...state, loadingInProgress: false}
        }

        default: {
            return state
        }
    }
};


