import {failStatus, receiveStatus, requestStatus,} from './helpers';

const toggleIds = entityName => (state = {}, action) => {

    if (!(action.type && action.meta && (action.type.includes('ADD') || action.type.includes('REMOVE')) && action.meta.entityName === entityName)) {
        return state;
    }

    if (action.type.startsWith('REQUEST')) {
        return requestStatus(state, action);
    }
    if (action.type.startsWith('SUCCESS')) {
        return receiveStatus(state, action);
    }
    if (action.type.startsWith('FAIL')) {
        return failStatus(state, action);
    }
    return state;
};

export default toggleIds;
