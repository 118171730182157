import styled, {css} from 'styled-components/macro';
import {IconWrapper} from '../../components/Design/Icons/IconsCalculatelTable';
import {PaginationWrapper} from '../../components/Pagination';

export const PageWrapper = styled.nav`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    & * {
        scrollbar-width: thin;
        scrollbar-color: #808ba4 rgba(0, 0, 0, 0);
    }

    & *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    & *::-webkit-scrollbar-track {
        background: #fff;
    }

    & *::-webkit-scrollbar-thumb {
        background-color: #808ba4;
        border-radius: 12px;
    }
`;

export const MainWrapper = styled.div`
    display: flex;
    flex: 1;
    height: 0;
`;

export const TopSettingBlock = styled.div`
    display: flex;
    align-items: center;
    min-height: 29px;

    & > label {
        margin-left: 100px;
    }
`;

export const HeaderTitle = styled.h2`
  font-family: 'EYInterstate Bold', sans-serif;
`;

export const ControlContainer = styled.div`
    margin: 30px 0 0;
    padding: 0 20px;
`;

export const SecondSettingBlock = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 7fr;
    column-gap: 20px;
    margin-top: 35px;
`;

export const TabsControl = styled.div`
    display: flex;
    align-items: center;

    & > div ~ div {
        margin-left: 5px;
    }
`;

export const TableControl = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CentreBlock = styled.div`
    display: flex;

    & > div ~ div {
        margin-left: 30px;
    }
`;

export const RightBlock = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;

    & > div ~ div {
        margin-left: 30px;
    }
`;

export const ControlBlock = styled.div`
    display: flex;
    justify-content: space-between;

    & > div {
        display: flex;
        
        & > div ~ div {
            margin-left: 3px
        }
    }
`;

export const ChartIcon = styled(IconWrapper)`
    padding: 0;
`;

export const CalculateContent = styled.div`
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-height: 0;

    &:nth-child(2) {
        margin-top: 25px;
    }
`;

export const GridPaginationWrapper = styled(PaginationWrapper)`
    justify-content: flex-end;
    padding-top: 15px;
    padding-bottom: 15px;
`;

// Table

export const TableWrapper = styled.div`
    .ag-header-cell div[col-id='AGG'] {
        background: ${(props) => props.theme.colors.grey5};
    }

    .ag-row:not(.ag-row-selected):not(.ag-row-hover) {
        div[col-id='AGG'] {
            background: ${(props) => props.theme.colors.grey6};
        }

        &:nth-child(2n) div[col-id='AGG'] {
            background: ${(props) => props.theme.colors.grey5};
        }
    }

    .ag-header-cell {
        background: ${(props) => props.theme.colors.white};
    }
`;

// Chart
export const ChartWrapper = styled.div`
    width: 100%;
    height: 97%;
    
`;

export const ChartHideInfo = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: ${(props) => props.theme.colors.grey6};
    padding: 10px;
    font-size: 12px;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
`;

// Version selector

export const VersionSelectorWrapper = styled.div`
    display: flex;
    //margin-left: 4px;

    & > div {
        margin-right: 4px;
    }
`;

export const VersionVariants = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.grey3};
    width: 28px;
    height: 36px;
    cursor: pointer;
    opacity: ${(props) => props.pickedVersions ? '100%' : '25%'};

    &:hover {
        background: ${(props) => props.pickedVersions ? props.theme.colors.grey4 : props.theme.colors.grey3};
    }

    img {
        width: 8px;
        height: 8px;
    }
`;

export const VersionList = styled.ul`
    position: absolute;
    top: 40px;
    right: 0;
    width: 340px;
    height: 500px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.grey2};
    overflow-y: scroll;
    font-size: 14px;
    z-index: 10;
`;

export const VersionListItem = styled.li`
    margin: 5px 0;
    padding: 6px 10px;

    &:hover {
        background: ${(props) => props.theme.colors.yellow};
    }

    ${(props) =>
        props.isActive &&
        css`
            background: ${(props) => props.theme.colors.yellow};
        `}
`;

export const ButtonFilterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const ButtonSelectWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.black};
    width: 28px;
    height: 36px;
    cursor: pointer;

    &:hover {
        background: ${(props) => props.theme.colors.grey1};
    }
`;
