import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import {IVersionGroup, VersionGroupActionType} from "./types";
import {
    getVersionGroupsListError,
    getVersionGroupsListSuccess,
    createVersionGroupError,
    createVersionGroupSuccess
} from "./actions";
import {getConfig} from '../../config-provider';
import {requestSnackDisplay} from "../snackbars/actions";
import i18n from '../../i18n';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetRequest() {
    const url = `${API_ENDPOINT}/version/version_group/`;
    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getVersionGroupsListError(res.error))
        } else {
            yield put(getVersionGroupsListSuccess(res.data as IVersionGroup[]))
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(getVersionGroupsListError(e.stack!))
        } else {
            yield put(getVersionGroupsListError("An unknown error occurred."))
        }
    }
}

function* handleDeleteRequest(action) {
    const id = action.payload.id;
    yield call(axios.delete, `${API_ENDPOINT}/version/version_group/${id}/`);
}

function* handleCreateRequest(action) {
    const url = `${API_ENDPOINT}/version/version_group/`;
    const body = {
        "name": action.payload.name,
    };

    try {
        const res = yield call(axios.post, url, body, {headers: {'Content-Type': 'application/json'}});
        if (res.error) {
            yield put(createVersionGroupError(res.error))
        } else {
            yield put(createVersionGroupSuccess(res.data as number));
            yield put(requestSnackDisplay('success', i18n.t('notifications:groupCreated')));
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(createVersionGroupError(e.stack!))
        } else {
            yield put(createVersionGroupError("An unknown error occurred."))
        }
    }
}

function* watchGetRequest() {
    yield takeLatest(VersionGroupActionType.GET_VERSIONGROUPS_LIST_REQUEST, handleGetRequest)
}

function* watchCreateRequest() {
    yield takeLatest(VersionGroupActionType.VERSIONGROUP_CREATE_REQUEST, handleCreateRequest)
}

function* watchDeleteRequest() {
    yield takeLatest(VersionGroupActionType.DELETE_VERSIONGROUP, handleDeleteRequest)
}

export function* versionGroupsSaga() {
    yield all([
        fork(watchGetRequest),
        fork(watchCreateRequest),
        fork(watchDeleteRequest)
    ])
}

