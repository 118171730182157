export type NodeId = string;

export interface IIndicatorTreeNode {
    id: NodeId;
    title: string;
    value?: any; // Compat
    mipair?: string;
    children?: IIndicatorTreeNode[]
}

export enum IndicatorTreeActionType {
    FETCH_REQUEST = '@@indicator-tree/FETCH_REQUEST',
    FETCH_SUCCESS = '@@indicator-tree/FETCH_SUCCESS',
    FETCH_ERROR = '@@indicator-tree/FETCH_ERROR',
    ADD_INDICATOR = '@@indicator-tree/ADD_INDICATOR',
    DELETE_INDICATOR = '@@indicator-tree/DELETE_INDICATOR',
    RESELECT = '@@indicator-tree/RESELECT',
    SET_CURRENT_INDICATOR = '@@indicator-tree/SET_CURRENT_INDICATOR',
    SET_CURRENT_INDICATOR_ID = '@@indicator-tree/SET_CURRENT_INDICATOR_ID',
    SET_INCLUDE_EMPTY_INDICATORS = '@@indicator-tree/SET_INCLUDE_EMPTY_INDICATORS'
}

export interface IndicatorTreeState {
    readonly loading: boolean
    readonly includeEmpty: boolean
    readonly data: IIndicatorTreeNode[]
    readonly errors?: string
    selectedIndicators: Map<string, string> | null,
    currentIndicator: string | null
    currentIndicatorId: string | null
}


