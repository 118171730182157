import React from 'react';
import {useVirtualList} from 'ahooks';
import {useTranslation} from 'react-i18next';
import {CircularProgress} from '@material-ui/core';
import {CheckedFilterItem} from './types';
import {
    FormWrapper,
    HeaderButtonWrapper,
    InputSearch,
    SpinnerWrapper,
    TextButton,
    CheckboxWrapper,
} from './filter.styles';

export type ListFiltersProps = {
    handleCheckbox: (value: CheckedFilterItem) => void;
    setCheckedStatusToFilters: (isChecked: boolean) => void;
    filterList: CheckedFilterItem[];
    isLoadingVariants: boolean;
    searchValue: string;
    onInputChangeHandler: (value: string) => void;
};

const ListFilters: React.FC<ListFiltersProps> = ({
    setCheckedStatusToFilters,
    filterList,
    handleCheckbox,
    isLoadingVariants,
    searchValue,
    onInputChangeHandler
}) => {
    const {t} = useTranslation('components');

    const {list, containerProps, wrapperProps} = useVirtualList(filterList, {
        overscan: 10,
        itemHeight: 35,
    });

    const handleSelectAll = () => setCheckedStatusToFilters(true);
    const handleClear = () => setCheckedStatusToFilters(false);

    return (
        <>
            <InputSearch value={searchValue} onChange={(e) => onInputChangeHandler(e.target.value)} />
            <HeaderButtonWrapper>
                <TextButton onClick={handleSelectAll}>
                    {t('components:filter.select_all', 'Выбрать все')}
                </TextButton>
                <TextButton onClick={handleClear}>
                    {t('components:filter.clear', 'Очистить')}
                </TextButton>
            </HeaderButtonWrapper>

            <div style={{height: 120, marginTop: 15}}>
                {isLoadingVariants && (
                    <SpinnerWrapper>
                        <CircularProgress />
                    </SpinnerWrapper>
                )}
                <FormWrapper {...containerProps}>
                    <div {...wrapperProps}>
                        {!isLoadingVariants && list.map(({data}, index: number) => (
                            <CheckboxWrapper
                                key={index}
                                checked={data.isChecked}
                                onChange={() => handleCheckbox(data)}
                                label={data.roundValue}
                            />
                        ))}
                    </div>
                </FormWrapper>
            </div>
        </>
    );
};

export default ListFilters;
