import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useDebounceFn} from 'ahooks';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {ApplicationState} from '../../store';
import {useTranslation} from 'react-i18next';
import logo from '../../assets/images/icons/logos/b1-logo.svg';
import {
    ItemText,
    ListItem,
    ListMenu,
    ListMenuWrapper,
    LogoImage,
    LogoWrapper,
    MenuCollapseWrapper,
    ShowMoreIconWrapper,
    SidebarWrapper,
} from './sidebar.styles';
import {
    AnomaliesIcon,
    CalculationIcon, CO2Icon, CostsScenarioIcon,
    // CompareVersionsIcon,
    ExpensesIcon,
    GeoMapIcon,
    ImportIcon,
    MacroIcon,
    PortfolioIcon,
    ReportsIcon,
    TaxIcon,
    VersionCardIcon,
    VersionIcon,
} from '../Design/Icons/IconsSidebar';
import {useHistory, useLocation} from 'react-router';
import {ControlPanel} from './ControlPanel';
import {IAppProperties} from '../../store/appProperties/types';

// import {MaterialTooltip} from '../Design/Tooltip';

export interface IMainMenuItem {
    title: string;
    pathname: string;
    icon: any;
}

export interface IMainMenuProps {
    items: IMainMenuItem[];
    location: Partial<Location>;
    onPathNameChange: (pathname: string) => any;
}

type SidebarProps = {
    setOpen: (value: boolean) => void;
    setIsBigSidebar: (value: boolean) => void;
    setToLocalStorage: (value: any) => void;
    open: boolean;
    isBigSidebar: boolean;
};

const RESIZE_EVENT_DEBOUNCE = 100 // ms, Реагируем не на каждое событие resize, чтобы не грузить CPU

const ANIMATION_TIMEBOX_DELAY = 300 // ms, Перед вычислением размеров, даем анимации отработать

const Sidebar: React.FC<SidebarProps> = ({setOpen, open, isBigSidebar, setIsBigSidebar, setToLocalStorage}) => {
    const {t} = useTranslation('pages');
    const history = useHistory();
    const location = useLocation();
    const resizeListenerRef = useRef(null)

    const appProps = useSelector<ApplicationState, IAppProperties>((store) => store.appProperties);
    const anomaliesEnabled = useSelector<ApplicationState, boolean>((store) => store.appProperties.anomaly_enabled);
    const user = useSelector<ApplicationState, any>((store) => store.login.user);

    function calculateMenuItemHeights(listMenu: HTMLElement) {
        const menuItemHeights: number[] = [];
        for (let i = 0; i < listMenu.children.length; i++) {
            menuItemHeights.push(listMenu.children[i].getBoundingClientRect().height);
        }
        return menuItemHeights
    }

    const MENU_ITEMS: IMainMenuItem[] = [
        {
            title: t('pages:GeoMap', 'Карта'),
            pathname: `/assets/geo-map`,
            icon: <GeoMapIcon/>
        },
        {
            title: t('pages:Versions', 'Версии'),
            pathname: '/versions',
            icon: <VersionIcon/>,
        },
        {
            title: t('pages:VersionCard', 'Карточка версии'),
            pathname: '/dash',
            icon: <VersionCardIcon/>,
        },
        {
            title: t('pages:VersionOutcomes'),
            pathname: '/calc',
            icon: <CalculationIcon/>,
        },
        // {
        //     title: t('pages:VersionComparison'),
        //     pathname: `/compare`,
        //     icon: <CompareVersionsIcon/>,
        // },
        {
            title: t('pages:Anomalies', 'Аномалии'),
            pathname: '/anomalies',
            icon: <AnomaliesIcon/>,
        },
        {
            title: t('pages:PortfolioAnalysis', 'Анализ портфеля'),
            pathname: `/portfolio_analysis`,
            icon: <PortfolioIcon/>,
        },
        {
            title: t('pages:CostsRef'),
            pathname: `/costref`,
            icon: <ExpensesIcon/>,
        },
        {
            title: t('pages:Macro', 'Макро сценарии'),
            pathname: '/macro',
            icon: <MacroIcon/>,
        },
        {
            title: t('pages:Emissions', 'Углеродные сценарии'),
            pathname: '/esg_scenario',
            icon: <CO2Icon/>,
        },
        {
            title: t('pages:CostsScenarios', 'Сценарии затрат'),
            pathname: '/costs_scenario',
            icon: <CostsScenarioIcon/>,
        },
        {
            title: t('pages:TaxModes', 'Налоговые режимы'),
            pathname: '/tax_regime',
            icon: <TaxIcon/>,
        },
        {
            title: t('pages:Meta', 'Отчеты'),
            pathname: '/meta',
            icon: <ReportsIcon/>,
        },
        // {
        //     title: t('pages:Files', 'Файлы'),
        //     pathname: '/files',
        //     icon: <ImportIcon/>,
        // },
        {
            title: t('pages:Import', 'Импорт'),
            pathname: '/import',
            icon: <ImportIcon/>,
        },
    ];

    const onPathNameChange = (pathname) => history.push(pathname);

    const sidebarHandler = () => {
        const isActive = !isBigSidebar;

        setToLocalStorage(isActive);
        setIsBigSidebar(isActive);
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    // const st_expire_date = appProps?.expire_date.split('T')[0];

    // const showLicenseExpiredDialogHandler = (isShow: boolean) => setLicenseExpiredDialog(isShow);

    // const version_expired_warning_span = () => {
    //     return (
    //         <>
    //             {isLicenseExpiredDialogOpen && (
    //                 <div className="license-modal">
    //                     <div className="license-modal-content">
    //                         <span className="license-close" onClick={(e) => showLicenseExpiredDialogHandler(true)}>
    //                             &times;
    //                         </span>
    //                         <span>
    //                             <h3>Действие лицензии истекает {st_expire_date}.</h3>
    //                             <br />
    //                             <p>После истечения срока действия лицензии новые расчёты осуществляется не будут.</p>
    //                             Старые расчёты и возможность созывать по ним новые отчёты будут доступны без
    //                             ограничений.
    //                         </span>
    //                     </div>
    //                 </div>
    //             )}
    //             <span className="license-expired" onClick={(e) => showLicenseExpiredDialogHandler(false)}>
    //                 Cрок действия лицензии истекает
    //             </span>
    //         </>
    //     );
    // };

    const onChangeTab = (pathname: string, anomaliesDisabled: boolean) => {
        // setActiveTab(pathname);
        return isActive(pathname) || anomaliesDisabled ? null : onPathNameChange(pathname);
    };

    const isActive = (pathname: string) => location.pathname.startsWith(pathname);

    const [isListMenuOverflow, setIsListMenuOverflow] = useState(false);
    const [hiddenItemsAmount, setHiddenItemsAmount] = useState(0);
    const [isCollapseMenuOpen, setIsCollapseMenuOpen] = useState(false);

    const resolveMenuResize = useDebounceFn((menuItemHeights: number[]) => {
        const containerHeight = document.getElementById('list-menu-wrapper')?.getBoundingClientRect().height;
        const showMoreIconHeight = document.getElementById('show-more-button')?.getBoundingClientRect().height;
        if (containerHeight == null || showMoreIconHeight == null) {
            return;
        }
        // console.log(menuItemHeights)
        // console.log([containerHeight, menuItemHeights.reduce((a, b) => a + b, 0)])
        let hiddenItemsAmount_ = menuItemHeights.length;
        let prevTotalHeight = 0;
        let currTotalHeight = 0;
        for (const height of menuItemHeights) {
            currTotalHeight += height;
            if (currTotalHeight > containerHeight) {
                if (hiddenItemsAmount_ >= menuItemHeights.length) {
                    break
                }
                if (prevTotalHeight + showMoreIconHeight > containerHeight) {
                    hiddenItemsAmount_++;
                }
                break
            }
            hiddenItemsAmount_--;
            prevTotalHeight = currTotalHeight;
        }
        setHiddenItemsAmount(hiddenItemsAmount_);
        setIsListMenuOverflow(hiddenItemsAmount_ > 0);
    }, {wait: RESIZE_EVENT_DEBOUNCE, leading: true, trailing: true});

    useEffect(() => {
        const listMenu = document.getElementById('list-menu');
        setTimeout(() => {
            const menuItemHeights = calculateMenuItemHeights(listMenu)
            const fun = resizeListenerRef.current = () => resolveMenuResize.run(menuItemHeights)
            fun();
            window.addEventListener('resize', fun);
        }, ANIMATION_TIMEBOX_DELAY)
        return () => window.removeEventListener('resize', resizeListenerRef.current);
        // eslint-disable-next-line
    }, [isBigSidebar]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            const MenuCollapseWrapper = document.getElementById('menu-collapse-wrapper');
            const MenuCollapseButton = document.getElementById('show-more-button');
            if (!e.path.includes(MenuCollapseWrapper) && !e.path.includes(MenuCollapseButton)) {
                setIsCollapseMenuOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside, false);
        return () => document.removeEventListener('click', handleClickOutside, false);
    }, []);

    return (
        <SidebarWrapper isBig={isBigSidebar}>
            <LogoWrapper>
                <LogoImage src={logo} alt='b1-logo'/>
                {/*<div style={{color:"white",fontSize:"25pt"}}>B1</div>*/}
            </LogoWrapper>
            <ListMenuWrapper id='list-menu-wrapper'>
                <ListMenu id='list-menu'>
                    {MENU_ITEMS.map((entry: IMainMenuItem, idx: number) => {
                        const anomaliesDisabled = entry.pathname === '/anomalies' && !anomaliesEnabled;
                        return (
                            <ListItem
                                key={idx}
                                data-tip={!isBigSidebar ? entry.title : ''}
                                isBig={isBigSidebar}
                                isActive={isActive(entry.pathname)}
                                onClick={() => onChangeTab(entry.pathname, anomaliesDisabled)}
                                isHidden={MENU_ITEMS.length - hiddenItemsAmount <= idx}
                            >
                                {entry.icon}
                                {isBigSidebar && <ItemText>{entry.title}</ItemText>}
                            </ListItem>
                        );
                    })}
                </ListMenu>
                <ShowMoreIconWrapper id='show-more-button' isTransparent={!isListMenuOverflow}
                                     onClick={() => setIsCollapseMenuOpen(!isCollapseMenuOpen)}>
                    <MoreHorizIcon/>
                </ShowMoreIconWrapper>
            </ListMenuWrapper>
            {isListMenuOverflow && (
                <>
                    <MenuCollapseWrapper isCollapseMenuOpen={isCollapseMenuOpen} id='menu-collapse-wrapper'
                                         isBigSidebar={isBigSidebar}>
                        {MENU_ITEMS.map((entry: IMainMenuItem, idx: number) => {
                            const anomaliesDisabled = entry.pathname === '/anomalies' && !anomaliesEnabled;
                            return MENU_ITEMS.length - hiddenItemsAmount <= idx && (
                                <ListItem
                                    key={idx}
                                    data-tip={!isBigSidebar ? entry.title : ''}
                                    isBig={isBigSidebar}
                                    isActive={isActive(entry.pathname)}
                                    onClick={() => onChangeTab(entry.pathname, anomaliesDisabled)}
                                    isCollapseMenuOpen={isCollapseMenuOpen}
                                >
                                    {entry.icon}
                                    {isBigSidebar && <ItemText>{entry.title}</ItemText>}
                                </ListItem>
                            );
                        })}
                    </MenuCollapseWrapper>
                </>
            )}
            <ControlPanel
                handleDrawerOpen={handleDrawerOpen}
                userName={user?.username}
                isBigSidebar={isBigSidebar}
                appProps={appProps}
                sidebarHandler={sidebarHandler}
                isTasksQueueOpen={open}
            />

            {/*{appProps?.is_expired ? (*/}
            {/*    <span className="license-expired">Cрок действия лицензии истёк {st_expire_date}</span>*/}
            {/*) : appProps?.is_expired_warning ? (*/}
            {/*    version_expired_warning_span()*/}
            {/*) : (*/}
            {/*    ''*/}
            {/*)}*/}
        </SidebarWrapper>
    );
};

export default Sidebar;
