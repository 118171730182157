import React, {useState} from 'react';
import {InputField} from '../../../Design/InputField';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import {Box} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const AttributeCreate = ({
    handleKeyDown,
    handleClickCreateItem,
    setCreateInputVisible,
    isCreateInputVisible,
    contextName,
}) => {
    const {t} = useTranslation(['pages']);
    const [attributeName, setAttributeName] = useState('');

    const handleClickCloseButton = () => {
        setCreateInputVisible(false);
        setAttributeName('');
    };

    const createNewPlaceholder = t(`pages:simulationSettings.${contextName}.create`);

    return (
        <CreateWrapper>
            {!isCreateInputVisible ? (
                <CreateButton onClick={() => setCreateInputVisible(true)}>
                    {createNewPlaceholder}
                </CreateButton>
            ) : (
                <>
                    <InputField
                        value={attributeName}
                        onChange={(e) => setAttributeName(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, attributeName)}
                        placeholder={createNewPlaceholder}
                    />

                    <Box ml={10}>
                        <AddItemIcon onClick={() => handleClickCreateItem(attributeName)} />
                        <CloseEditIcon onClick={handleClickCloseButton} />
                    </Box>
                </>
            )}
        </CreateWrapper>
    );
};

export default AttributeCreate;

const CreateWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.colors.grey3};
`;

const CreateButton = styled.button`
    font-size: ${(props) => props.theme.fontSize.normal};
    font-weight: 700;
    cursor: pointer;
    border: none;
    background: none;

    &::before {
        content: '+ ';
    }
`;

export const AddItemIcon = styled(CheckIcon)`
    background: ${(props) => props.theme.colors.green};
    border-radius: 50%;
    fill: #fff;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    padding: 1px;
    box-sizing: border-box;
    cursor: pointer;
`;

export const CloseEditIcon = styled(CloseIcon)`
    width: 16px;
    height: 16px;
    fill: ${(props) => props.theme.colors.grey1};
    cursor: pointer;
`;
