import { action } from 'typesafe-actions';
import { CostsFilterActionType, ICostsFilterSettings } from './types';

export const costsFilterOptionsRequest = (indicator: string, driverName: string) => action(CostsFilterActionType.GET_OPTIONS_REQUEST, {indicator, driverName});
export const costsFilterOptionsSuccess = (options: string[]) => action(CostsFilterActionType.GET_OPTIONS_SUCCESS, options);
export const costsFilterOptionsError = (loadingError: any) => action(CostsFilterActionType.GET_OPTIONS_ERROR, {loadingError});

export const setCostsFilterSelected = (options: string[], filter: string) => action(CostsFilterActionType.SET_SELECTED_OPTIONS, {options, filter});
export const setCostsFilterGroupby = (groupby: string[]) => action(CostsFilterActionType.SET_COSTS_GROUPBY, {groupby});
export const changeCostsFilterSettings = (newSettings: Partial<ICostsFilterSettings>) => action(CostsFilterActionType.CHANGE_SETTINGS, newSettings);

export const setCostsFilterOutlierCutoff = (cutoff: number[]) => action(CostsFilterActionType.SET_COSTS_OUTLIER_CUTOFF, {cutoff});