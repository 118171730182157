import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { useTranslation } from "react-i18next";
import { IFilter } from "../store/tableSelector/types";
import { useFilterOptions } from "./tableSelector/useFilterOptions";

const filterName = 'macroScenariosFilter';

export const useMacroScenarioFilterMap = () => {

    const { t } = useTranslation(['commons', 'pages']);

    const activeFilters = useSelector<ApplicationState, IFilter>(store => store.macroScenariosFilter.currentFilters);

    const namesFilters = useFilterOptions(filterName, 'names');
    const macroUralsPriceMinFilters = useFilterOptions(filterName, 'macro_urals_price_min');
    const macroUralsPriceMaxFilters = useFilterOptions(filterName, 'macro_urals_price_max');
    const exchUsdRubMinFilters = useFilterOptions(filterName, 'exch_usd_rub_min');
    const exchUsdRubMaxFilters = useFilterOptions(filterName, 'exch_usd_rub_max');
    const statusesFilters = useFilterOptions(filterName, 'status');

    const filterMap = new Map([
        [t('commons:name', 'Название'), {
            filterOptions: namesFilters,
            excluded: activeFilters.excluded_names,
            selected: activeFilters.selected_names,
            stateBranch: 'names',
            withExclusion: true
        }],
        // [t('commons:tags', 'Атрибуты'), {
        //     filterOptions: tagsFilters,
        //     excluded: activeFilters.excluded_tags,
        //     selected: activeFilters.selected_tags,
        //     stateBranch: 'tags',
        //     withExclusion: true
        // }],
        // [t('commons:included_indicators', 'Содержит показатели'), {
        //     filterOptions: indicatorsFilters,
        //     excluded: activeFilters.excluded_indicators,
        //     selected: activeFilters.selected_indicators,
        //     stateBranch: 'modified_indicators',
        //     withExclusion: true
        // }],
        // [t('commons:creation_date', 'Создано'), {
        //     filterOptions: createdAtFilters,
        //     excluded: activeFilters.excluded_created_at,
        //     selected: activeFilters.selected_created_at,
        //     stateBranch: 'created_at',
        //     withExclusion: true
        // }],
        [t('components:macro_filter.macro_urals_price_min', 'Мин. цена Urals, долл. США/барр.'), {
            filterOptions: macroUralsPriceMinFilters,
            excluded: activeFilters.excluded_macro_urals_price_min,
            selected: activeFilters.selected_macro_urals_price_min,
            stateBranch: 'macro_urals_price_min',
            withExclusion: true
        }],
        [t('components:macro_filter.macro_urals_price_max', 'Макс. цена Urals, долл. США/барр.'), {
            filterOptions: macroUralsPriceMaxFilters,
            excluded: activeFilters.excluded_macro_urals_price_max,
            selected: activeFilters.selected_macro_urals_price_max,
            stateBranch: 'macro_urals_price_max',
            withExclusion: true
        }],
        [t('components:macro_filter.exch_usd_rub_min', 'Мин. курс долл. США'), {
            filterOptions: exchUsdRubMinFilters,
            excluded: activeFilters.excluded_exch_usd_rub_min,
            selected: activeFilters.selected_exch_usd_rub_min,
            stateBranch: 'exch_usd_rub_min',
            withExclusion: true
        }],
        [t('components:macro_filter.exch_usd_rub_max', 'Макс. курс долл. США'), {
            filterOptions: exchUsdRubMaxFilters,
            excluded: activeFilters.excluded_exch_usd_rub_max,
            selected: activeFilters.selected_exch_usd_rub_max,
            stateBranch: 'exch_usd_rub_max',
            withExclusion: true
        }],
        [t('commons:status', 'Статус'), {
            filterOptions: statusesFilters,
            excluded: activeFilters.excluded_status,
            selected: activeFilters.selected_status,
            stateBranch: 'status',
            withExclusion: true
        }]
    ]);

    return { filterMap }
};
