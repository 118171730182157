import React from 'react';
import styled from 'styled-components/macro';
import {Icon} from './icon.styles';

// Pagination
import PaginationArrowOneIcon from '../../../assets/images/icons/pagination/pagination-arrow-one-page.svg';
import PaginationArrowOneDisabledIcon from '../../../assets/images/icons/pagination/pagination-arrow-one-page_disabled.svg';

import PaginationArrowLeftIcon from '../../../assets/images/icons/pagination/pagonation-arrow-left.svg';
import PaginationArrowLeftDisabledIcon from '../../../assets/images/icons/pagination/pagonation-arrow-left-disabled.svg';

export const PaginationArrowOne: React.FC<any> = ({disabled, isDark}) => {
    return (
        <IconWrapper disabled={disabled} isDark={isDark}>
            {disabled ? (
                <Icon disabled={true} isDark={isDark} src={PaginationArrowOneDisabledIcon} alt="pagination-arrow-one-disabled" />
            ) : (
                <Icon src={PaginationArrowOneIcon} isDark={isDark} alt="pagination-arrow-one" />
            )}
        </IconWrapper>
    );
};
export const PaginationArrowLeft: React.FC<any> = ({disabled, isDark}) => {
    return (
        <IconWrapper disabled={disabled} isDark={isDark}>
            {disabled ? (
                <Icon disabled={disabled} isDark={isDark} src={PaginationArrowLeftDisabledIcon} alt="pagination-arrow-icon-disabled" />
            ) : (
                <Icon src={PaginationArrowLeftIcon} isDark={isDark} alt="pagination-arrow-icon" />
            )}
        </IconWrapper>
    );
};

export const PaginationArrowRight: React.FC<any> = ({disabled, isDark}) => {
    return (
        <IconWrapperRevert disabled={disabled} isDark={isDark}>
            {disabled ? (
                <Icon disabled={disabled} isDark={isDark} src={PaginationArrowLeftDisabledIcon} alt="pagination-arrow-icon-disabled" />
            ) : (
                <Icon src={PaginationArrowLeftIcon} isDark={isDark} alt="pagination-arrow-icon" />
            )}
        </IconWrapperRevert>
    );
};

export const PaginationArrowLast: React.FC<any> = ({disabled, isDark}) => {
    return (
        <IconWrapperRevert disabled={disabled} isDark={isDark}>
            {disabled ? (
                <Icon
                    disabled={disabled}
                    src={PaginationArrowOneDisabledIcon}
                    alt="pagination-arrow-icon-last-disabled"
                    isDark={isDark}
                />
            ) : (
                <Icon src={PaginationArrowOneIcon} alt="pagination-arrow-last-icon" isDark={isDark} />
            )}
        </IconWrapperRevert>
    );
};

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    color: red;
    margin: 0 3px;

    &:hover {
        cursor: ${(props) => (props.disabled ? '' : 'pointer')};
        ${props => {
            if (props.disabled) return `background-color: none`;
            if (props.isDark) return `background-color: ${props.theme.colors.grey1}`;
            return `background-color: ${props.theme.colors.grey4}`;
        }}
    }
`;

const IconWrapperRevert = styled(IconWrapper)`
    transform: rotate(180deg);
`;
