import {IPaginatedResult} from '../types';
import {FilterSortType} from "../../components/FilterColumn/SortComponent";
import {FilterStoreItemType} from "../../components/FilterColumn/types";

export enum GridActionType {
    CHANGE_SETTINGS = '@@currentGrid/CHANGE_SETTINGS',
    CALC_REQUEST = '@@currentGrid/CALC_REQUEST',
    CALC_SUCCESS = '@@currentGrid/CALC_SUCCESS',
    CALC_ERROR = '@@currentGrid/CALC_ERROR',
    CALC_CLEAR_DATA = '@@currentGrid/CALC_CLEAR_DATA',
    SET_SELECTED_INDEX = '@@currentGrid/SET_SELECTED_INDEX',
    SAVE_FILTERS = '@@currentGrid/SAVE_FILTERS',
}


export interface IGridSettings {
    readonly groupby?: string[] | undefined,
    readonly period__gte?: string
    readonly period__lt?: string
    readonly page?: number,
    readonly page_size?: number
    readonly orient?: 'records' | 'values'
    readonly precision?: number,
    readonly show_zero?: boolean,
    readonly disable_inject?: boolean, 
    readonly cumsum?: boolean,
    readonly agg_column?: boolean
}

export interface IGridRequest {
    readonly mipairs: string[]
    readonly versions: number[]
    readonly settings?: IGridSettings
    readonly filters?: FilterStoreItemType
    readonly sortFilters?: FilterSortType
    readonly width?: number
    readonly height?: number
    readonly dpi?: number
}

export interface IGridResultMeta {
    index_columns: any[]
    values_columns: any[]
    values_columns_type: string[]
}

export interface IGridResult {
    readonly meta: IGridResultMeta
    readonly index: any[]
    readonly values: any[]
}

export type IPaginatedGridResult = IPaginatedResult<IGridResult>;

export type IRowIndex = [string[]?]


export interface ICurrentGridState {
    readonly settings: IGridSettings | null
    readonly data: IPaginatedGridResult | null
    readonly loading: boolean
    readonly errors?: string | null
    readonly selected_rows: IRowIndex
    readonly filters: IGridRequest | null
}

export const GRID_PROP_ORDER = ['VERSION', 'OIL_FIELD','LICENSED_SITE','PROD_SITE', 'WELL_TYPE', 'WELL', 'LAYER']