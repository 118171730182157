import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CreateColumnsFiles} from './CreateColumnsFiles';
import {VersionPageWrapper} from '../version-page/version-page.styles';
import {ApplicationState} from '../../store';
import {getPage, resetPage as resetTaxModesPage, setPage} from '../../store/pagination';
import {Pagination} from '../../components/Pagination';
import {appPropertiesSelector} from '../../store/appProperties/selectors';
import {IAppProperties} from '../../store/appProperties/types';
import {
    setColumnFilter,
    resetFilters
} from '../../store/tableSelector/actions';
import {HeaderControl} from '../version-page/HeaderControl';
import axios from '../../axiosMiddleware';
import {getConfig} from '../../config-provider';
import {setDataToLocalStorage} from '../../helpers/localStorage';
import {FilesTableActionsWrapper} from './FilesTableActionsWrapper';
import {useTranslation} from 'react-i18next';
import {IOptionItems} from '../../store/tableSelector/types';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

interface Row {
    name: string;
    description;
    tags;
    indicators;
    creation_date;
    status;
}

const paginatorName = 'files';
const filterName = 'filesFilter';

const FilesPage = React.memo(() => {
    const dispatch = useDispatch();
    const {t} = useTranslation(['commons', 'components']);

    const language = useSelector<ApplicationState, string>((store) => store.userLanguage.language);
    const appProps = useSelector<ApplicationState, IAppProperties>(appPropertiesSelector);
    const pagination = useSelector<ApplicationState, any>((store) => store.pagination);
    const perPage = useSelector<ApplicationState, number>((store) => store[filterName].perPage);
    const totalPages = useSelector<ApplicationState, number>((store) => store[filterName].totalPages);
    const loading = useSelector<ApplicationState, boolean>((store) => store[filterName].loading);
    const count = useSelector<ApplicationState, number>((store) => store[filterName].count);
    const showColumns = useSelector<ApplicationState, IOptionItems>((store) => store[filterName].displayedColumns);
    
    const {app_environment} = appProps;

    const currentPage = getPage(pagination, paginatorName);

    const [checkedRowIds, setCheckedRowIds] = useState<Row[]>([]);

    const [page, setCurrentPage] = useState(1);
    const [deleteDialogState, setDeleteDialogState] = useState({
        selection: null,
        open: false,
    });

    const [scenarioDialogState, setScenarioDialogState] = useState({
        parentScenarioName: null,
        scenarioId: null,
        editMode: true,
        update: false,
        open: false,
    });

    useEffect(() => {
        if (currentPage !== page) setCurrentPage(currentPage);
        //eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        handleRequestEditions();
        //eslint-disable-next-line
    }, []);

    const handleRequestEditions = async () => {
        const url = `${API_ENDPOINT}/editions`;
        const res = await axios.post(url);

        setDataToLocalStorage('esg_Editions', res.data.editions);
    };

    const resetPage = () => {
        setCurrentPage(1);
        dispatch(resetTaxModesPage(paginatorName));
    };

    const handleClearFilters = () => {
        setCurrentPage(1);
        dispatch(resetFilters(filterName));
        dispatch(setPage({key: paginatorName}));
        clearSelectedRows();
    };

    const handleFilterChange = (data, sorting, columnName) => {
        const {pendingValue: selected, excluded} = data;

        resetPage();
        dispatch(
            setColumnFilter(
                {
                    [`selected_${columnName}`]: selected,
                    [`excluded_${columnName}`]: excluded,
                }, sorting, filterName
            )
        );
    };

    const columns = useMemo(
        () => CreateColumnsFiles(t, showColumns),
        //eslint-disable-next-line
        [app_environment, showColumns, t]
    );

    const onRowSelected = (rows) => {
        setCheckedRowIds(rows.map(row => row.id));
    };

    const clearSelectedRows = () => {
        setCheckedRowIds([]);
    };

    const onRowClick = async () => {};

    return (
        <VersionPageWrapper>
            <HeaderControl
                setDeleteDialogState={setDeleteDialogState}
                deleteDialogState={deleteDialogState}
                setScenarioDialogState={setScenarioDialogState}
                scenarioDialogState={scenarioDialogState}
                checkedRowIds={checkedRowIds}
                handleClearFilters={handleClearFilters}
                filterName={filterName}
            />
            <FilesTableActionsWrapper
                columns={columns}
                onRowSelected={onRowSelected}
                handleFilterChange={handleFilterChange}
                onRowClick={onRowClick}
                filterName={filterName}
            />
            <Pagination
                clearSelectedRows={clearSelectedRows}
                pagination={pagination}
                perPage={perPage}
                totalPages={totalPages}
                selection={checkedRowIds.length}
                count={count}
                loading={loading}
                language={language}
                filterName={filterName}
            />
        </VersionPageWrapper>
    );
});

export default FilesPage;
