import React from 'react';
import Box from '@material-ui/core/Box';


export const BlurredBox = props => {

    const { blur } = props;
    const style = blur ? {
        filter: 'blur(2px)',
        pointerEvents: 'none'
    } : null;

    return (
        <Box
            {...props}
            style={style}
        >
            {props.children}
        </Box>
    )
};