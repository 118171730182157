import React from 'react';
import styled from 'styled-components/macro';

type PopupTabsProps = {
    tabs: PopupTabType;
    activeId: number;
    onChange: any;
};

export type PopupTabType = {
    id: number;
    label: string;
}[];

export const PopupTabs: React.FC<PopupTabsProps> = React.memo(({tabs, onChange, activeId}) => {
    return (
        <TabsWrapper>
            {tabs.map((tab) => (
                <Tab key={tab.id} isActive={tab.id === activeId} onClick={() => onChange(tab.id)}>
                    {tab.label}
                </Tab>
            ))}
        </TabsWrapper>
    );
});

const TabsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
`;

const Tab = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    color: ${(props) => (props.isActive ? props.theme.colors.grey1 : props.theme.colors.grey2)};
    border-bottom: 2px solid
        ${(props) => (props.isActive ? props.theme.colors.yellow : props.theme.colors.white)};
    cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
    font-size: 16px;

    &:nth-child(2) {
        margin-left: 2px;
    }
`;
