import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useState} from 'react';
import ButtonControl from './ButtonControl';
import {TablePropsType} from '../../pages/calculation-page/GridView';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {CheckedFilterItem, ConditionType, ConditionItemType, FilterPayload} from './types';
import SortComponent, {SortFilterType} from './SortComponent';
import ListFilters from './ListFilters';
import {FilterListVariant, FilterListVariantsType} from '../../store/filterVariants/types';
import {IGridSettings} from '../../store/currentGrid/types';
import {getUniqObjectInArrayByField, isCheckedFilter, isConditionFilter} from './utils';
import {
    ConditionItem,
    ConditionList,
    FilterHeader,
    FilterWrapper,
    RemoveIconWrapper,
    PlusWrapper,
} from './filter.styles';
import {InputField} from '../Design/InputField';
import {Select} from './Select';
import {RemoveIcon} from '../Design/Icons/IconsControlTable';
import {PlusIcon} from '../Design/Icons/IconsOther';
import {useDebounceFn} from 'ahooks';

export type FilterColumnItemType = ConditionItemType | CheckedFilterItem;

const conditions: ConditionType[] = ['=', '!=', '<', '<=', '>', '>=', 'empty', 'not_empty'];

const FilterColumn = forwardRef((props: TablePropsType, ref: React.RefObject<any>) => {
    const {colDef, applyFilters, fetchFilterVariants} = props;
    const {headerName} = colDef;
    const currentField = colDef.field;

    const hasConditionFilters = Number.isInteger(Number(currentField)) || currentField === 'AGG';

    const [, setTrigger] = useState(false);
    const [activeFilter, setActiveFilter] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [sortFilter, setSortFilter] = useState<SortFilterType>(null);

    const [variables, setVariables] = useState<CheckedFilterItem[]>([]);
    const [checkedFilters, setCheckedFilters] = useState<CheckedFilterItem[]>([]);

    const [conditionFilters, setConditionFilters] = useState<ConditionItemType[]>([]);
    const [filtersFromStorage, setFiltersFromStorage] = useState<FilterColumnItemType[]>([]);

    const settings = useSelector<ApplicationState, IGridSettings>(
        (state) => state.currentGrid.settings
    );
    const listFilters = useSelector<ApplicationState, FilterListVariantsType>(
        (store) => store.filterVariants.listFilters
    );
    const listFiltersBuffer = useSelector<ApplicationState, FilterListVariantsType>(
        (store) => store.filterVariants.listFiltersBuffer
    );
    const isLoadingVariants = useSelector<ApplicationState, boolean>(
        (state) => state.filterVariants.loading
    );

    const emptyConditions: ConditionType[] = ['empty', 'not_empty'];

    const getListFilterVariantsForCurrentField = (): FilterListVariant[] | null =>
        (listFilters && listFilters[currentField]) || null;

    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params) {
                return params.data;
            },

            isFilterActive() {
                const conditionCorrectFilter: boolean =
                    (hasConditionFilters === true && conditionFilters.length > 0) ||
                    hasConditionFilters === false;
                const hasSortFilter: boolean = sortFilter && Object.keys(sortFilter).length > 0;
                const hasCheckedFilters: boolean = checkedFilters.length > 0;

                return (
                    activeFilter && (conditionCorrectFilter || hasSortFilter || hasCheckedFilters)
                );
            },

            // Set filterVariants value at load moment
            setModel(model: FilterPayload) {
                if (!model?.values) return;

                const {filters, sortColumn} = model.values;

                if (filters?.length > 0) {
                    const sortCheckedFilters = getPriorityFiltersList(
                        setRandomIdsToFilters(filters.filter(isCheckedFilter))
                    );

                    setCheckedFilters(sortCheckedFilters);
                    setFiltersFromStorage(filters);

                    const conditions =
                        hasConditionFilters === true && filters.filter(isConditionFilter);

                    if (hasConditionFilters === true && conditions.length > 0) {
                        setConditionFilters(conditions);
                    } else {
                        clearAndAddEmptyFilter();
                    }
                }

                if (sortColumn) setSortFilter(sortColumn);
                setActiveFilter(
                    (sortColumn && Object.keys(sortColumn).length > 0) || filters?.length > 0
                );
            },
            afterGuiAttached() {
                // Запрос опций фильтра при появлении его окошка на экране
                fetchFilterVariants(currentField, null);
            }
        };
    });

    useLayoutEffect(() => {
        if (hasConditionFilters === true && conditionFilters.length === 0) {
            clearAndAddEmptyFilter();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (activeFilter === true) {
            props.filterChangedCallback();
        }
    }, [activeFilter, props]);

    useEffect(() => {
        setVariables([]);
    }, [currentField, fetchFilterVariants, settings]);

    useEffect(() => {
        (async () => await setAllFilters())();
        //eslint-disable-next-line
    }, [listFilters, filtersFromStorage]);

    // Checked filters is first
    const getPriorityFiltersList = (filters: CheckedFilterItem[]): CheckedFilterItem[] => {
        const checkedFilters = filters.filter((item: CheckedFilterItem) => item.isChecked === true);
        const remainingFilters = filters.filter(
            (item: CheckedFilterItem) => item.isChecked === false
        );

        return [...checkedFilters, ...remainingFilters];
    };

    const getSizeIdFilter = () =>
        conditionFilters.length +
        checkedFilters.length +
        Math.floor((Math.random() * +new Date()) / 10000);

    const setRandomIdsToFilters = <T extends FilterColumnItemType>(filters: T[]): T[] =>
        filters.map((filter: T) => ({...filter, id: getSizeIdFilter()}));

    const setAllFilters = async () => {
        const variants = getListFilterVariantsForCurrentField();
        if (variants === null) return;

        const uniqVariants = getUniqObjectInArrayByField(variants, 'roundValue');

        setTimeout(async () => {
            await initCheckedFilters(uniqVariants);
            initConditionFilters(hasConditionFilters);
        }, 50);
    };

    const initCheckedFilters = (variants: FilterListVariant[]) => {
        // Применить фильтры из state или LS при наличии (в соотв. приоритете) или создать пустые фильтры
        const checkedFiltersResult =
            checkedFilters.length > 0 ? checkedFilters : filtersFromStorage.filter(isCheckedFilter);

        const checkedResult: CheckedFilterItem[] = getPriorityFiltersList(
            createCheckedFilterVariables(variants, checkedFiltersResult)
        );

        setVariables(checkedResult as CheckedFilterItem[]);
        setCheckedFilters(checkedResult);
    };

    const initConditionFilters = (hasConditionFilters: boolean) => {
        if (hasConditionFilters === true) {
            const conditionsStore = filtersFromStorage.filter(isConditionFilter);
            const conditionsFilters = setRandomIdsToFilters(
                conditionsStore.length > 0 ? conditionsStore : conditionsStore
            );
            setConditionFilters(
                conditionsFilters.length > 0 ? conditionsFilters : [emptyConditionFilterItem()]
            );
        }
    };

    const createCheckedFilterVariables = (
        variables: FilterListVariant[],
        checkedFilters: CheckedFilterItem[]
    ): CheckedFilterItem[] => {
        // Создать список уникальных опций фильтра из комбинации опций из двух источников:
        // 1 - список опций, выбранных пользователем. 2 - список опций полученных бэка.

        const listFromBuffer = listFiltersBuffer[currentField]?.filter(item => {
            return checkedFilters.find((element: CheckedFilterItem) => 
                (element.isChecked === true && element.originalValue === item.originalValue));
        }).map(item => ({...item, isChecked: true})) || [];

        const listFromServer = variables.map((variable: FilterListVariant) => {
            const [filteredValue] = checkedFilters.filter(
                (filter: CheckedFilterItem) => filter.roundValue === variable.roundValue
            );

            return {
                field: currentField,
                originalValue: variable.originalValue,
                roundValue: variable.roundValue,
                isChecked: filteredValue?.isChecked || false,
            };
        });

        const listTotal = [...listFromBuffer as CheckedFilterItem[], ...listFromServer].map((item, index) => ({
            ...item,
            id: index
        }));

        return getUniqObjectInArrayByField(listTotal, 'roundValue') as CheckedFilterItem[]
    };

    const setCheckedStatusToFilters = (isChecked: boolean) => {
        setCheckedFilters((checkedFilters: CheckedFilterItem[]) =>
            checkedFilters.map((filter: CheckedFilterItem) => {
                filter.isChecked = isChecked;
                return filter;
            })
        );
    };

    const emptyConditionFilterItem = (): ConditionItemType => ({
        id: getSizeIdFilter(),
        field: currentField,
        value: '',
        condition: conditions[0],
    });

    const addEmptyConditionFilter = () => {
        setConditionFilters((conditionsFilters: ConditionItemType[]) => {
            conditionsFilters.push(emptyConditionFilterItem());
            return conditionsFilters;
        });
        setTrigger((trigger: boolean) => !trigger);
    };

    const clearAndAddEmptyFilter = async () => {
        await clearConditionFilters();
        addEmptyConditionFilter();
    };

    const removeFilter = (id: number) => {
        const result = conditionFilters.filter((filter: FilterColumnItemType) => filter.id !== id);
        setConditionFilters(result);
        if (result.length === 0) addEmptyConditionFilter();
    };

    const clearFiltersHandler = async () => {
        await clearAndAddEmptyFilter();
        clearSortFilter();
        setCheckedStatusToFilters(false);
    };

    const onChangeSelectCondition = (e: React.ChangeEvent<HTMLSelectElement>, id: number) => {
        const newValue = e.target.value as ConditionType;
        const isEmptyFlags = emptyConditions.includes(newValue);

        setConditionFilters((conditionFilters: ConditionItemType[]) =>
            conditionFilters.map((filter: ConditionItemType) => {
                if (filter.id === id) {
                    if (isEmptyFlags) filter.value = '';
                    filter.condition = newValue;
                }
                return filter;
            })
        );
    };

    const onChangeValueHandler = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const newValue = e.target.value;

        // Проверка введенного значения на наличие числа
        if (isNaN(Number(newValue))) {
            return;
        }

        setConditionFilters((conditionFilters: ConditionItemType[]) =>
            conditionFilters.map((filter: ConditionItemType) => {
                if (filter.id === id) filter.value = newValue.trim();
                return filter;
            })
        );
    };

    const clearConditionFilters = () => setConditionFilters([]);
    const clearSortFilter = () => setSortFilter(null);

    const checkInActiveInputField = (filter: ConditionItemType): boolean =>
        emptyConditions.includes(filter.condition);
    const updateSortFilter = (sortFilter: SortFilterType) => setSortFilter(sortFilter);

    const handleCancelFilter = async () => await setAllFilters();

    const handleCheckbox = (value: CheckedFilterItem) => {
        setCheckedFilters((checkedFilters: CheckedFilterItem[]) =>
            checkedFilters.map((filter: CheckedFilterItem) => {
                if (filter.id === value.id) filter.isChecked = !filter.isChecked;
                return filter;
            })
        );
    };

    const prepareChooseFilters = (): FilterColumnItemType[] => [
        ...conditionFilters.filter((item: ConditionItemType) => item.value !== ''),
        ...checkedFilters.filter((item: CheckedFilterItem) => item.isChecked === true),
    ];

    const applyFilterHandler = (
        newFilterData: FilterColumnItemType[],
        sortFilter: SortFilterType | null,
        field: string
    ) => {
        // Применить фильтры на текущем столбце
        applyFilters(newFilterData, sortFilter, field);
        // Set default position for list filter variants

        const listFilterVariantsForCurrentField = getListFilterVariantsForCurrentField();
        const uniqueFilterVariants = getUniqObjectInArrayByField(listFilterVariantsForCurrentField, 'roundValue');

        initCheckedFilters(uniqueFilterVariants);
    };

    const {run} = useDebounceFn(
        (variables: CheckedFilterItem[], newValue: string) => {
            const filters = variables.filter(
                (variable: CheckedFilterItem) =>
                    variable.isChecked === true ||
                    variable.originalValue.toLowerCase().includes(newValue.toLowerCase())
            );
            setCheckedFilters(filters);
            fetchFilterVariants(currentField, newValue);
        },
        {
            wait: 500,
        }
    );

    const onInputChangeHandler = (newValue: string) => {
        setSearchValue(newValue);
        run(variables, newValue);
    };

    return (
        <FilterWrapper>
            <FilterHeader>{headerName}</FilterHeader>
            <SortComponent sortFilter={sortFilter} updateSortFilter={updateSortFilter} />
            <ListFilters
                filterList={checkedFilters}
                setCheckedStatusToFilters={setCheckedStatusToFilters}
                handleCheckbox={handleCheckbox}
                isLoadingVariants={isLoadingVariants}
                searchValue={searchValue}
                onInputChangeHandler={onInputChangeHandler}
            />
            {hasConditionFilters && (
                <>
                    <ConditionList>
                        {conditionFilters.map((filter: ConditionItemType) => (
                            <ConditionItem key={filter.id}>
                                <Select
                                    options={conditions}
                                    value={filter.condition}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        onChangeSelectCondition(e, filter.id)
                                    }
                                />
                                <InputField
                                    disabled={checkInActiveInputField(filter)}
                                    value={filter.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        onChangeValueHandler(e, filter.id)
                                    }
                                />
                                <RemoveIconWrapper>
                                    <RemoveIcon
                                        outline={true}
                                        onClick={() => removeFilter(filter.id)}
                                    />
                                </RemoveIconWrapper>
                            </ConditionItem>
                        ))}
                    </ConditionList>
                    <PlusWrapper>
                        <PlusIcon onClick={addEmptyConditionFilter} />
                    </PlusWrapper>
                </>
            )}
            <ButtonControl
                props={props}
                listFiltersBuffer={listFiltersBuffer}
                chooseFilters={prepareChooseFilters()}
                sortFilter={sortFilter}
                setActiveFilter={setActiveFilter}
                field={currentField}
                applyFilters={applyFilterHandler}
                clearFilters={clearFiltersHandler}
                handleCancelFilterForm={handleCancelFilter}
            />
        </FilterWrapper>
    );
});

export default FilterColumn;
