import React, { useEffect } from 'react';
import DropZone from './DropZone';
import { importInit, importRequest, importReset } from '../store/importer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { ImporterState } from '../store/importer/types';

export interface ImporterProps {
    id: string;
    defaultMessage?: string;
    dragActiveMessage?: string;
    multiple?: boolean;
    disabled?: boolean;
}

export const Importer: React.FC<ImporterProps> = (
    {
        id,
        defaultMessage,
        dragActiveMessage,
        multiple = false,
    }) => {

    const dispatch = useDispatch();

    const importerState = useSelector<ApplicationState, ImporterState>(
        state => state.importerCollection.importers[id]
    );

    useEffect(() => {
        if (importerState === undefined) {
            dispatch(importInit(id))
        }
    }, [id, importerState, dispatch]);

    if (!importerState) {
        return null;
    }

    return (
        <DropZone
            defaultMessage={defaultMessage}
            dragActiveMessage={dragActiveMessage}
            multiple={multiple}
            onDrop={files => files.forEach(f => dispatch(importRequest(id, f)))}
            onReset={() => dispatch(importReset(id))}
            loading={importerState.loading}
            success={importerState.success}
            progress={importerState.progress}
            error={importerState.error}
        />
    );
};
