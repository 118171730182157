export enum DeltaPlotActionType {
    GET_DELTA_PLOT_REQUEST = '@@deltaplot/GET_DELTA_PLOT_REQUEST',
    GET_DELTA_PLOT_SUCCESS = '@@deltaplot/GET_DELTA_PLOT_SUCCESS',
    GET_DELTA_PLOT_ERROR = '@@deltaplot/GET_DELTA_PLOT_ERROR',

    ADD_TO_HIDDEN_TRACES = '@@deltaplot/ADD_TO_HIDDEN_TRACES',
    REMOVE_FROM_HIDDEN_TRACES = '@@deltaplot/REMOVE_FROM_HIDDEN_TRACES',
    EMPTY_HIDDEN_TRACES = '@@deltaplot/EMPTY_HIDDEN_TRACES',
}


export interface LineType {
    color: string,
    with: number
}

export interface IDeltaPlot {
    x: number[],
    y: number[],
    name: string,
    type: string,
    mode: string,
    line: LineType,

}

export type IDeltaPlots = IDeltaPlot[]

export interface IDeltaPlotsState {
    plots: IDeltaPlot[]
    hidden_traces: string[],
}
