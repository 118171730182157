import {action} from 'typesafe-actions';
import {CalcJobActionType, ICalcJob} from './types';

export const fetchCalcJobsRequest = (page) => action(CalcJobActionType.FETCH_REQUEST, page);

export const fetchCalcJobsSuccess = (calcJobs: ICalcJob[]) =>
    action(CalcJobActionType.FETCH_SUCCESS, calcJobs);

export const fetchCalcJobsError = (error: any) => action(CalcJobActionType.FETCH_ERROR);

export const addCalcJob = (calcJob: ICalcJob) => action(CalcJobActionType.ADD_CALC_JOB, calcJob);

export const updateCalcJob = (id: number, calcJob: ICalcJob) =>
    action(CalcJobActionType.UPDATE_CALC_JOB, {id, calcJob});

export const deleteCalcJob = (id: number, delete_version: boolean) =>
    action(CalcJobActionType.DELETE_CALC_JOB, {id, delete_version});

export const deleteCalcJobDone = (id: number) =>
    action(CalcJobActionType.DELETE_CALC_JOB_DONE, {id});
