export enum VersionCardActionType {
    VERSION_CARD_REQUEST = '@@versionCard/VERSION_CARD_REQUEST',
    VERSION_CARD_SUCCESS = '@@versionCard/VERSION_CARD_SUCCESS',
    VERSION_CARD_ERROR = '@@versionCard/VERSION_CARD_ERROR',
};

export interface IVersionCardState {
    readonly data: any;
    readonly loading: boolean;
    readonly error: string | null;
};
