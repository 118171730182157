import React from 'react';
import styled from 'styled-components';
import {ListItemCheckbox} from "./AttributesSummaryListItem";
import {DeleteIcon} from "./VersionGroupComponent";
import {useTranslation} from "react-i18next";

const AttributesLegend = ({contextName}) => {
    const {t} = useTranslation(['components', 'commons', 'pages']);

    return (
        <LegendWrapper>
            <LegendItem>
                <LegendCheckboxMock checked={false}
                                    onChange={() => {}}
                                    isSmaller 
                                    isYellow />
                <LegendCaption>{t(`pages:simulationSettings.${contextName}.selected_tag`)}</LegendCaption>
            </LegendItem>
            <LegendItem>
                <LegendCheckboxMock checked={false}
                                    onChange={() => {}}
                                    isSmaller 
                                    isDarkGrey />
                <LegendCaption>{t(`pages:simulationSettings.${contextName}.tag_is_assigned_to_every_selected_versions`)}</LegendCaption>
            </LegendItem>
            <LegendItem>
                <LegendCheckboxMock checked={false}
                                    onChange={() => {}}
                                    isSmaller
                                    isLightGrey />
                <LegendCaption>{t(`pages:simulationSettings.${contextName}.tag_is_assigned_to_some_selected_versions`)}</LegendCaption>
            </LegendItem>
            <LegendItem>
                <LegendDeleteButtonMock/>
                <LegendCaption>{t(`pages:simulationSettings.${contextName}.remove_tag_from_selected_versions`)}</LegendCaption>
            </LegendItem>
        </LegendWrapper>
    )
}

const LegendWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 30px;
  justify-content: flex-end;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`

const LegendCheckboxMock = styled(ListItemCheckbox)`
  pointer-events: none;
  margin-right: 5px;
  margin-left: 0;
  padding-left: 0;
`

const LegendDeleteButtonMock = styled(DeleteIcon)`
  background: ${props => props.theme.colors.grey4};
  pointer-events: none;
  margin-right: 5px;
  position: relative;
  right: auto;
`

const LegendCaption = styled.span`
  font-size: 12px;
`

export default AttributesLegend;