import {action} from 'typesafe-actions';
import {FilterFieldVariantsType, IFilterListVariantsRequest, IFilterRequest} from './types';

export type ColumnVariantsType = {
    [key: string]: string[];
};

export const getFilterVariantsAction = ({filter_name, version_ids, mipairs, search_value}: IFilterRequest) =>
    action(FilterFieldVariantsType.FILTER_FIELD_REQUEST, {filter_name, version_ids, mipairs, search_value});

export const filterVariantsSuccessAction = (variants: ColumnVariantsType) =>
    action(FilterFieldVariantsType.FILTER_FIELD_SUCCESS, variants);

export const filterListVariantsSuccessAction = (variants: ColumnVariantsType) =>
    action(FilterFieldVariantsType.FILTER_ADD_LIST_VARIANTS_SUCCESS, variants);

export const filterAddListVariantsRequestAction = ({
    columns,
    version_ids,
    mipairs
}: IFilterListVariantsRequest) =>
    action(FilterFieldVariantsType.FILTER_ADD_LIST_VARIANTS_REQUEST, {columns, version_ids, mipairs});

export const filterVariantsError = (message: string) => action(FilterFieldVariantsType.FILTER_FIELD_ERROR, message);
