import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import axios from '../../axiosMiddleware';
import * as _ from "lodash";
import { getConfig } from '../../config-provider';
import { requestSnackDisplay } from "../../store/snackbars/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';
import {getPermissions} from '../../store/permissions/actions';
import {formatDateTime} from "../../helpers/MyAwesomeLib";


const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function RolesTable() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [users, setUsers] = useState(null)
  const [groups, setGroups] = useState(null)
  const [open, setOpen] = useState(false);
  const user = useSelector(store => store.login.user);
  const { t } = useTranslation(['pages', 'commons', 'components']);
  const [adminToChange, setAdminToChange] = useState({})

  useEffect(() => {
    axios.get(`${API_ENDPOINT}/app/admin/users/?ordering=id`).then(
      (response) => setUsers(response.data))

    axios.get(`${API_ENDPOINT}/app/admin/groups/?ordering=id`).then(
      (response) => setGroups(response.data))

  }, [setUsers, setGroups]
  );

  const handleAdminPopUpClose = () => {
    setOpen(false);
  }

  function modifyUserGroup(updatedUsers, i, messageTemplate, info, to_start) {
    axios.patch(`${API_ENDPOINT}/app/admin/users/${updatedUsers[i].id}/`, { groups: updatedUsers[i].groups }).then(
      (res) => {
        dispatch(requestSnackDisplay('success',
          t(messageTemplate, { username: info.username, group: info.group })))
        setUsers(updatedUsers);
        if (to_start) {
          dispatch(getPermissions())
          history.push('/')
        }
      },
      (res) => {
        console.log(res)
        dispatch(requestSnackDisplay('error',
          t('components:roles.error', "Ошибка изменения роли пользователя!")))
        setUsers(users)
      }
    )
  }

  function addToGroupsCheckbox(row, columnId) {
    const updatedUsers = _.cloneDeep(users)

    for (let i = 0; i < updatedUsers.length; i++) {
      if (updatedUsers[i].id === row.id) {
        if (row.groups.includes(columnId.id)) {
          if (((user ? user.username.toLowerCase() : '') === updatedUsers[i].username) & (columnId.name === 'administrator')) {
            setAdminToChange(prevState => ({ ...prevState, 'columnId': _.cloneDeep(columnId) }));
            setAdminToChange(prevState => ({ ...prevState, 'row': _.cloneDeep(row) }));
            setAdminToChange(prevState => ({ ...prevState, 'i': _.cloneDeep(i) }));
            setOpen(true);
          } else {
            updatedUsers[i].groups = row.groups.filter(item => item !== columnId.id)
            modifyUserGroup(updatedUsers, i, 'components:roles.success_remove', { username: row.username, group: columnId.name }, false);
          }
        } else {
          updatedUsers[i].groups.push(columnId.id)
          modifyUserGroup(updatedUsers, i, 'components:roles.success_add', { username: row.username, group: columnId.name }, false);
        }
      }
    }
  }

  if (!users || !groups) {
    return null
  } else {
    return (
      <div className={classes.root} margins={10}>
        <h1 style={{ padding: 20 }}><b>{t('pages:Roles', 'Управление ролями')}</b></h1>
        <Dialog
          open={open}
          onClose={handleAdminPopUpClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{t('components:roles.pop_up.title',
            "Вы уверены что хотите снять с себя привелегии администратора?")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('components:roles.pop_up.text',
                "Если вы согласитесь, то вы потеряете возможность изменять привелегии других пользователей и совершать другие действия доступные только администратору.")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              const updatedUsers = _.cloneDeep(users)
              updatedUsers[adminToChange.i].groups = adminToChange.row.groups.filter(item => item !== adminToChange.columnId.id)
              modifyUserGroup(updatedUsers, adminToChange.i, 'components:roles.success_remove',
                { username: adminToChange.row.username, group: adminToChange.columnId.name }, true);
              handleAdminPopUpClose();
            }} color="primary">
              {t('components:roles.pop_up.yes', "Я согласен (Лишить себя статуса администратора)")}
            </Button>
            <Button onClick={handleAdminPopUpClose} color="primary" autoFocus>
              {t('components:roles.pop_up.no', "Оставаться администратором")}
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container direction="row">
          <Table className={classes.table} size="small">
            <Grid item xs={12}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('commons:User', 'Пользователь')}</TableCell>
                  <TableCell>{t('commons:Email', 'Email')}</TableCell>
                  <TableCell>{t('commons:Date_joined', 'Дата регистрации')}</TableCell>
                  <TableCell>{t('commons:Last_login', 'Последний визит')}</TableCell>
                  {
                    groups.map((column) => (
                      <TableCell key={column.id} align="center" size="small">{column.name}</TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row) => (
                  <TableRow key={row.username}>
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {formatDateTime(row.date_joined)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {formatDateTime(row.last_login)}
                    </TableCell>
                    {groups.map((column) => (
                      <TableCell key={column.id} align="center">
                        <Checkbox
                          checked={row.groups.includes(column.id)}
                          onChange={() => addToGroupsCheckbox(row, column)}
                        />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Grid>
          </Table>
        </Grid>
      </div>
    )
  }
}
