import React, {useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ScenarioEditControl} from './ScenarioEditControl';
import {buildSearchIndex, safeMultiplyBy100} from './utils';
import {getDataFromLocalStorage, setDataToLocalStorage} from '../../helpers/localStorage';
import Checkbox from '@material-ui/core/Checkbox';
import {Checkbox as CheckboxCustom} from '../Design/Checkboxes/Checkbox';
import {
    AccordionHeader,
    AccordionComponent,
    AccordionDetailsComponent,
    AccordionSummaryComponent,
} from '../Accordion/Accordion';
import styled from 'styled-components/macro';

import {getConfig} from '../../config-provider';
import axios from '../../axiosMiddleware';
import {
    CheckboxMinusIcon,
    CheckboxCheckedIcon,
    CheckboxIcon,
} from '../Design/Checkboxes/CheckboxMock';
import {Box, CircularProgress} from '@material-ui/core';
import {BlurredBox} from '../BlurredBox';
import {InputField} from '../Design/InputField';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

const getUrl = (localeKey, requestFrom) => ({
    from_tax_code: `${API_ENDPOINT}/scenario/${localeKey}/default`,
    default_values: `${API_ENDPOINT}/scenario/${localeKey}/default`,
    from_empty: `${API_ENDPOINT}/scenario/${localeKey}/empty`
}[requestFrom]);

const localeKeys = {
    taxModesFilter: 'tax_mode',
    emissionScenariosFilter: 'esg',
    costsScenariosFilter: 'cost'
}

export const ScenarioTree = ({
    treeData, 
    verboseIndex, 
    editionId, 
    treeName, 
    editMode, 
    fullscreenMode, 
    filterName
}) => {
    const {t} = useTranslation();

    const localeKey = localeKeys[filterName];
    const isTaxMode = filterName === 'taxModesFilter';
    const isCostsSc = filterName === 'costsScenariosFilter';
    const isEsgScen = filterName === 'emissionScenariosFilter';

    let scenarioDraft = getDataFromLocalStorage(`${localeKey}_Draft`);
    let selectedIndicatorsInitialState = new Set();
    let indicatorsDataInitialState = {...treeData};

    if (scenarioDraft && editMode) {
        selectedIndicatorsInitialState = new Set(scenarioDraft.selectedIndicators[treeName]);
        indicatorsDataInitialState = scenarioDraft.treeData;
    }

    const [selectedIndicators, setSelectedIndicators] = useState(selectedIndicatorsInitialState);
    const [indicatorsData, setIndicatorsData] = useState(indicatorsDataInitialState);

    const [pasteValue, setPasteValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const rootAddress = `structure::${treeName}`;
    const treeOnThisTab = indicatorsData.children.find((c) => c.address === rootAddress);

    //eslint-disable-next-line
    const searchIndex = useMemo(() => buildSearchIndex(treeOnThisTab.children), [treeName]);

    const leafAddresses = searchIndex
            .filter(a => !a.hasChildren)
            .map(a => a.address);

    const handleValueInputChange = (e) => {
        const {value} = e.target;
        if (!isNaN(value)) {
            setPasteValue(value.trim());
        }
    };

    const handleSelectAll = (e) => {
        if (editMode) {
            const tmp = new Set(selectedIndicators);
            const checked = e.target.checked;

            leafAddresses.forEach((v) => (checked ? tmp.add(v) : tmp.delete(v)));
            setSelectedIndicators(tmp);
        }
    };

    const scrollHandler = (target) => {
        const el = target?.closest('.MuiAccordionDetails-root');
        el.scrollLeft += 1000;
    };

    const allChecked = (): boolean => {
        return leafAddresses.every((v) => selectedIndicators.has(v))
    };

    const setLeafData = (nodeAddress, data) => {
        const tmp = {...indicatorsData};
        const nodesInAddres = nodeAddress.split('::');
        const interimAddresses = [];

        const reducer = (address, nextNodeAddress) => {
            const interimAddress = address + '::' + nextNodeAddress;
            interimAddresses.push(interimAddress);
            return interimAddress;
        };

        nodesInAddres.reduce(reducer);

        let level = 0;

        const _setLeafData = (tree, address, data) => {
            if (!tree.children) {
                tree.DATA = data.DATA;
                tree.META = {...tree.META, ...data.META};
                tree.INPUT_STATUS = data.INPUT_STATUS;
                return tree;
            }

            level += 1;
            const nextLevelNode = tree.children.find((c) => c.address === address);
            _setLeafData(nextLevelNode, interimAddresses[level], data);
        };

        _setLeafData(tmp, interimAddresses[level], data);
        setIndicatorsData(tmp);
    };

    const handleIndicatorsDataRequest = async (addresses, requestFrom) => {

        setIsLoading(true);

        const url = getUrl(localeKey, requestFrom);

        const leafs = addresses
            .filter(a => leafAddresses.includes(a));

        const addresses_and_subtrees = leafs
            .reduce((acc, curr) => {
                acc[curr] = null;
                return acc;
            }, {});

        const body = {
            addresses_and_subtrees: addresses_and_subtrees,
            edition_id: editionId,
        };

        const res = await axios.post(url, body);

        const subTree = res.data.addresses_and_subtrees;
        const entries: [string, any][] = Object.entries(subTree);

        for (const [address, data] of entries) {
            
            const meta = data.META;
            const control = meta.display_as;
            const dimension = meta.dimension;

            if (dimension === '%') {
                if (control === 'value') {
                    data.DATA = safeMultiplyBy100(data.DATA);
                } else {
                    for (const key in data.DATA) {
                        data.DATA[key] = safeMultiplyBy100(data.DATA[key]);
                    }
                }
            }
            setLeafData(address, data);
        }

        setIsLoading(false);
    };

    const handleSetLeafsToValue = () => {
        const addresses = [...selectedIndicators]
            .filter((a: string) => leafAddresses.includes(a));;

        const leafData = {
            DATA: pasteValue,
            INPUT_STATUS: 'manual',
            META: {
                input_status: t('pages:scenarios.dialog.manual'),
            }
        };

        pasteValue && addresses.map((address) => setLeafData(address, leafData));
    };


    const renderTree = (tree) => (
        <>
            {tree.map((node) => {

                const address = node.address;
                const verbose = verboseIndex[address];

                const name = verbose.name;
                const status = node.INPUT_STATUS;
                const dimension = verbose.dimension;
                const isNodeLeaf = node.hasOwnProperty('DATA');
                const hasChildren = node.hasOwnProperty('children');

                /// Функция для получения листьев выбранного узла
                const getNodeLeafAddresses = (node, leafAddresses) => {
                    if (!node.children) {
                        leafAddresses.push(node.address);
                        return leafAddresses;
                    }

                    node.children.forEach((child) => {
                        getNodeLeafAddresses(child, leafAddresses);
                    });
                    return leafAddresses;             
                };

                const nodeLeafAddresses = getNodeLeafAddresses(node, []);

                const handleSelection = (e) => {
                    const tmp = new Set(selectedIndicators);
                    const checked = e.target.checked;

                    if (hasChildren) {
                        nodeLeafAddresses.forEach((a) => (checked ? tmp.add(a) : tmp.delete(a)));
                    } else {
                        checked ? tmp.add(node.address) : tmp.delete(node.address);
                    }

                    setSelectedIndicators(tmp);
                };

                const checked = () => {
                    return nodeLeafAddresses.every((a) => selectedIndicators.has(a));
                };
                    
                const indeterminate = () => {
                    return !checked() && nodeLeafAddresses.some((a) => selectedIndicators.has(a));
                };

                return (
                    <React.Fragment key={node.address}>
                        <AccordionComponent>
                            <AccordionSummaryComponent>
                                <CheckboxCustom
                                    indeterminate={indeterminate()}
                                    value={node.address}
                                    checked={
                                        hasChildren
                                            ? checked()
                                            : selectedIndicators.has(node.address)
                                    }
                                    onChange={handleSelection}
                                    disabled={!editMode}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        return indeterminate() && handleSelection(e);
                                    }
                                    }
                                />
                                <AccordionHeader>
                                    {name}
                                    {dimension ? (
                                        <span style={{color: '#747480'}}>{` (${dimension})`}</span>
                                    ) : null}
                                    {status ? (
                                        <span style={{float: 'right'}}>{t(`pages:scenarios.dialog.${status}`)}</span>
                                    ) : null}
                                </AccordionHeader>
                            </AccordionSummaryComponent>
                            <AccordionDetailsComponent>
                                {isNodeLeaf ? (
                                    <ScenarioEditControl
                                        node={node}
                                        verboseIndex={verboseIndex}
                                        setLeafData={setLeafData}
                                        scrollHandler={scrollHandler}
                                        editMode={editMode}
                                    />
                                ) : (
                                    <div className='inner'>{renderTree(node.children)}</div>
                                )}
                            </AccordionDetailsComponent>
                        </AccordionComponent>
                    </React.Fragment>
                );
            })}
                </>
                );

                if (editMode) {
                    const previousDraft = getDataFromLocalStorage(`${localeKey}_Draft`) || {};
                    const updatedDraft = {
                        treeData: indicatorsData,
                        selectedIndicators: {
                            ...previousDraft.selectedIndicators,
                            [treeName]: [...selectedIndicators],
                        },
                    };
                    setDataToLocalStorage(`${localeKey}_Draft`, updatedDraft);
                }

                const indeterminate = () => {
                    return !allChecked() && selectedIndicators.size > 0
                };

                const btnDisabled = selectedIndicators.size === 0 || !editMode || isLoading;
                const inputDisabled = !editMode || isLoading;

                return (
                    <>
                        <ButtonsWrapper>
                            <Checkbox
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckboxCheckedIcon />}
                                indeterminateIcon={<CheckboxMinusIcon />}
                                checked={allChecked()}
                                disableRipple={true}
                                indeterminate={indeterminate()}
                                onChange={handleSelectAll}
                            />

                            {isTaxMode && (
                                <Button
                                    onClick={() => handleIndicatorsDataRequest([...selectedIndicators], 'from_tax_code')}
                                    disabled={btnDisabled}
                                >{t('pages:scenarios.source.from_code')}</Button>
                            )}

                            {isCostsSc && (
                                <>
                                    <Button
                                        onClick={handleSetLeafsToValue}
                                        disabled={btnDisabled}
                                    >{t('pages:scenarios.source.paste_value')}</Button>
                                    <ValueInputWrapper>
                                        <ValueInput
                                            value={pasteValue}
                                            onChange={handleValueInputChange}
                                            disabled={inputDisabled}
                                        />
                                    </ValueInputWrapper>
                                </>
                            )}

                            <Button
                                onClick={() => handleIndicatorsDataRequest([...selectedIndicators], 'from_empty')}
                                disabled={btnDisabled}
                            >{t('pages:scenarios.source.clear_values')}</Button>

                            {isEsgScen && (
                                <Button
                                    onClick={() => handleIndicatorsDataRequest([...selectedIndicators], 'default_values')}
                                    disabled={btnDisabled}
                                >{t('pages:scenarios.source.default_values')}</Button>
                            )}

                        </ButtonsWrapper>

                        <ScrollBox>
                            
                            {isLoading ? (
                                <CustomCircularProgress />
                            ) : null}

                            <BlurredBox blur={isLoading ? 1 : 0}>
                                <ScrollPopupItem 
                                    isFullscreen={fullscreenMode} 
                                    isTaxMode={isTaxMode}
                                >
                                    {renderTree(treeOnThisTab.children)}
                                </ScrollPopupItem>
                            </BlurredBox>
                        </ScrollBox>
                    </>
                );
            };

const ButtonsWrapper = styled.div`
    display: flex;
    margin-bottom: 25px;

  & > button {

    &:nth-child(2) {
      margin-left: 10px;

      & ~ button {
        margin-left: 30px;
      }
    }
  }

  & > .MuiCheckbox-root {
    padding: 0;
  }
`;

const ScrollPopupItem = styled.div`
    font-size: 12px;
    margin-left: -5px;

    > .MuiAccordion-root > .MuiAccordionSummary-root {
        padding-left: 5px !important;
    }
`;

const Button = styled.button`
    border: none;
    background: transparent;
    color: ${(props) => props.theme.textColor.lightGrey};
    font-family: 'EYInterstate Bold', sans-serif;

    ${(props) => !props.disabled && (
        `&:hover {
            color: ${props.theme.textColor.black};
            cursor: pointer;
        }`
    )}
`;

const CustomCircularProgress = styled(CircularProgress)`
  position: absolute;
  top:  50% !important;
  left: 50% !important;
  z-index: 1;
  margin: auto;
`;

const ScrollBox = styled(Box)`
    overflow: auto;
    position: relative;
`;

const ValueInput = styled(InputField)`
    width: 100%;
`;

const ValueInputWrapper = styled.div`
    width: 80px;
    margin-left: 10px;
`;