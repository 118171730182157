import {action} from 'typesafe-actions';
import {IVersionAttribute, VersionAttrOrGroupsActionType} from './types';

export const getVersionAttrOrGroupsRequest = (
    versions: any[],
    contextName = 'versionAttributes'
) => action(VersionAttrOrGroupsActionType.GET_VERSION_ATTR_OR_GROUPS_REQUEST, {versions, contextName});

export const getVersionAttrOrGroupsSuccess = (
    values: IVersionAttribute[],
    contextName = 'versionAttributes'
) => action(VersionAttrOrGroupsActionType.GET_VERSION_ATTR_OR_GROUPS_SUCCESS, {values, contextName});

export const getVersionAttrOrGroupsError = (error: any, contextName = 'versionAttributes') =>
    action(VersionAttrOrGroupsActionType.GET_VERSION_ATTR_OR_GROUPS_ERROR, {error, contextName});

export const updateVersionAttrOrGroupsRequest = (values: any, contextName = 'versionAttributes') =>
    action(VersionAttrOrGroupsActionType.UPDATE_VERSION_ATTR_OR_GROUPS_REQUEST, {values, contextName});

export const updateVersionAttrOrGroupsSuccess = (response, contextName = 'versionAttributes') =>
    action(VersionAttrOrGroupsActionType.UPDATE_VERSION_ATTR_OR_GROUPS_SUCCESS, {response, contextName});

export const updateVersionAttrOrGroupsError = (error: any, contextName = 'versionAttributes') =>
    action(VersionAttrOrGroupsActionType.UPDATE_VERSION_ATTR_OR_GROUPS_ERROR, {error, contextName});
