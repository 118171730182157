import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import {IVersionAttribute, VersionAttrOrGroupsActionType} from "./types";
import {
    getVersionAttrOrGroupsError,
    getVersionAttrOrGroupsSuccess,
    updateVersionAttrOrGroupsError,
    updateVersionAttrOrGroupsSuccess, getVersionAttrOrGroupsRequest,
} from './actions';
import {requestSnackDisplay} from '../snackbars/actions';
import {getConfig} from '../../config-provider';
import {getFilterDataRequest} from '../tableSelector/actions';
import {getPage} from '../pagination';
import i18n from '../../i18n';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

const urlSuffix = {
    versionAttributes: 'versions_tags',
    versionGroups: 'groups'
}

const getEditSuccessMessage = (versionIds, language, contextName) => {

    const what = {
        versionAttributes: language === 'ru' ? 'Атрибуты' : 'Attributes',
        versionGroups: language === 'ru' ? 'Группы' : 'Groups'
    }[contextName];

    const message = {
        ru: `${what} версий ${versionIds.join(', ')} были успешно отредактированы`,
        en: `${what} of versions ${versionIds.join(', ')} were successfully updated`,
    }[language];

    return message;
}

function* handleGetRequest(action) {
    const {versions, contextName} = action.payload;
    const url = `${API_ENDPOINT}/calc/versions/${urlSuffix[contextName]}`;
    const body = {version_ids: versions};

    try {
        const res = yield call(axios.post, url, body, {headers: {'Content-Type': 'application/json'}});
        if (res.error) {
            yield put(getVersionAttrOrGroupsError(res.error, contextName))
        } else {
            yield put(getVersionAttrOrGroupsSuccess(res.data as IVersionAttribute[], contextName))
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(getVersionAttrOrGroupsError(e.stack!, contextName))
        } else {
            yield put(getVersionAttrOrGroupsError("An unknown error occurred.", contextName))
        }
    }
}

function* handleEditRequest(action) {
    
    const language = i18n.language;
    const pageSize = yield select((store) => store.vFilter.perPage);
    const pagination = yield select((store) => store.pagination);
    const currentPage = getPage(pagination, 'versions');
    
    const {values, contextName} = action.payload;
    const url = `${API_ENDPOINT}/calc/versions/${urlSuffix[contextName]}/edit`;
    const body = {
        version_ids: values.version_ids,
        to_add: values.to_add,
        to_protect: values.to_protect
    };

    try {
        const res = yield call(axios.post, url, body, {headers: {'Content-Type': 'application/json'}});
        if (res.error) {
            yield put(requestSnackDisplay('error', res.error))
        } else {
            yield put(updateVersionAttrOrGroupsSuccess(res.data, contextName))
            yield put(requestSnackDisplay('success', getEditSuccessMessage(values.version_ids, language, contextName)));
            yield put(getVersionAttrOrGroupsRequest(body.version_ids, contextName))
            yield put(getFilterDataRequest(currentPage, pageSize, 'vFilter'));
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(updateVersionAttrOrGroupsError(e.stack!, contextName))
            yield put(requestSnackDisplay('error', e.stack!))
        } else {
            yield put(updateVersionAttrOrGroupsError("An unknown error occurred.", contextName))
            yield put(requestSnackDisplay('error', 'An unknown error occurred.'))
        }
    }
}

function* watchGetRequest() {
    yield takeLatest(VersionAttrOrGroupsActionType.GET_VERSION_ATTR_OR_GROUPS_REQUEST, handleGetRequest)
}

function* watchEditRequest() {
    yield takeLatest(VersionAttrOrGroupsActionType.UPDATE_VERSION_ATTR_OR_GROUPS_REQUEST, handleEditRequest)
}

export function* versionAttributesSaga() {
    yield all([
        fork(watchGetRequest),
        fork(watchEditRequest),
    ])
}

