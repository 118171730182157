import {action} from 'typesafe-actions';
import {ILogin, IUser, LoginActionType} from './types';

// receive user
export const userRequest = () => action(LoginActionType.GET_USER_REQUEST);
export const userSuccess = (user: IUser) => action(LoginActionType.GET_USER_SUCCESS, {user});
export const userError = (error: any) => action(LoginActionType.GET_USER_ERROR, {error});
// logout
export const doLogin = formData => action(LoginActionType.LOGIN, {formData});
// login with token
export const loginSuccess = (login: ILogin) => action(LoginActionType.LOGIN_SUCCESS, {login});
export const loginError = (error: any) => action(LoginActionType.LOGIN_ERROR, {error});
export const doLogout = () => action(LoginActionType.LOGOUT);
