export enum PermissionsAction {
    GET_PERMISSIONS_REQUEST = '@@permissions/GET_PERMISSIONS_REQUEST',
    GET_PERMISSIONS_SUCCESS = '@@permissions/GET_PERMISSIONS_SUCCESS',
    GET_PERMISSIONS_ERROR = '@@permissions/GET_PERMISSIONS_ERROR',
}

export interface IPermissions {
    permissions: Array<string>;
    adminMenu: boolean;
    error: string;
}
