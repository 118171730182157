import {action} from 'typesafe-actions';
import {CalcVersionActionType} from './types';

export const getVersionsRequest = (page) => action(CalcVersionActionType.GET_VERSIONS_REQUEST, page);

export const getVersionsSuccess = (data) =>
    action(CalcVersionActionType.GET_VERSIONS_SUCCESS, data);

export const getVersionsError = (message: string) =>
    action(CalcVersionActionType.GET_VERSIONS_ERROR, message);

export const addCalcVersion = (version: number) =>
    action(CalcVersionActionType.ADD_CALC_VERSION, version);

export const deleteCalcVersionByJobId = (id: number) =>
    action(CalcVersionActionType.DELETE_CALC_VERSION_BY_JOB_ID, {id});

export const deleteCalcVersionDone = (id: number) =>
    action(CalcVersionActionType.DELETE_CALC_VERSION_DONE, {id});

export const deleteCalcVersionsRequest = (ids: [number], withChildren: boolean) =>
    action(CalcVersionActionType.DELETE_CALC_VERSIONS_REQUEST, {ids, withChildren});

export const deleteCalcVersionsError = (error: any) =>
    action(CalcVersionActionType.DELETE_CALC_VERSIONS_ERROR, {error});

export const deleteCalcVersionsSuccess = (ids: [number]) =>
    action(CalcVersionActionType.DELETE_CALC_VERSIONS_SUCCESS, {ids});

export const recalculateVersions = (ids: [number]) => action(CalcVersionActionType.RECALCULATE_VERSIONS, {ids});

export const recalculateVersionsDisableHist = (ids: [number]) => action(CalcVersionActionType.RECALCULATE_VERSIONS_DISABLE_HIST, {ids});

export const calculateAnomalies = (id) => action(CalcVersionActionType.CALCULATE_ANOMALIES, {id});

export const switchOffWells = (ids, maxIter, needRecalc, keepInterimVersions, createReport, doNotRedistribExpenses, switchOffProdSites) => action(CalcVersionActionType.SWITCH_OFF_WELLS, {
    ids,
    maxIter,
    needRecalc,
    keepInterimVersions,
    createReport,
    doNotRedistribExpenses,
    switchOffProdSites
});

export const runUpliftStage1 = (ids, maxIter, needRecalc, startYear, wellsRankMethod, include_opex_into_k_kap) =>
    action(CalcVersionActionType.RUN_UPLIFT_STAGE_1, {
        ids,
        maxIter,
        needRecalc,
        startYear,
        wellsRankMethod,
        include_opex_into_k_kap
    });

export const runUpliftStage2 = (ids, maxIter, startYear, wellsRankMethod, wellsLimitType, liquid_prod_limit_id, include_opex_into_k_kap, enableKkap, needRecalc) =>
    action(CalcVersionActionType.RUN_UPLIFT_STAGE_2, {
        ids,
        maxIter,
        startYear,
        wellsRankMethod,
        wellsLimitType,
        liquid_prod_limit_id,
        include_opex_into_k_kap,
        enableKkap,
        needRecalc
    });

export const runSimulation = (base_versions, general, scenarios, stimulation, switchoff, groups, attributes, recalculate, set_final_tag, disable_historical) =>
    action(CalcVersionActionType.RUN_SIMULATION, {
        base_versions,
        general, 
        scenarios, 
        stimulation, 
        switchoff, 
        groups, 
        attributes, 
        recalculate,
        set_final_tag,
        disable_historical
    });