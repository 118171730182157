import { useSelector } from "react-redux";
import { IOptionItems } from "../../store/tableSelector/types";
import { ApplicationState } from "../../store";


export const useFilterOptions = (filterName, column) => {

    const filterOptions = useSelector<ApplicationState, IOptionItems>(
        store => store[filterName].filterOptions[column]);

    if (filterOptions) {
        return filterOptions;
    }

    return [];
};
