import React, {useEffect, useState} from 'react';
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import {useThrottleFn} from 'ahooks';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {
    getTasksRequest,
    hideSystemTasks,
    removeAllTasksFromSelected,
    revokeSelectedTasksRequest,
    showSystemTasks,
    setSelectedFilters, appendAllTasksToSelectedRequest, resetFilters
} from '../../store/tasks/actions';
import {ITask, ITasksState} from '../../store/tasks/types';
import Paginator from '../../components/Paginator';
import {useTranslation} from 'react-i18next';
import {TaskLogsDetails} from '../../pages/version-page/ErrorData/ErrorData';
import {getPage, setPage} from '../../store/pagination';
import {Menu, MenuItem} from '@material-ui/core';
import {createStyles, useTheme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TaskItem from "./TaskItem";
import FiltersSelect from './FiltersSelect';
import {Checkbox} from "../../components/Design/Checkboxes/Checkbox";
import {DialogContext} from './RootLayout';

const paginatorParent = 'taskQueue';

const drawerWidth = 400;

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px - 22)`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: drawerWidth,
        },
        title: {
            flexGrow: 1,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            overflowY: 'hidden',
            background: "#1A1A24",
            borderLeft: 0
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-start',
            zIndex: theme.zIndex.drawer + 1,
            position: 'fixed',
            width: '100%',
            maxHeight: '50px !important',
            minHeight: '50px !important',
            background: "#1A1A24",
            color: "#B3B3BC",
            fontSize: 18,
        },
        root: {
            minWidth: '24px',
        },
        textItem: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'block',
        },
    }),
);

const TaskQueue = ({open, handleDrawerClose, isBigSidebar}) => {
    const {t} = useTranslation();

    const language = useSelector<ApplicationState, string | null>((store) => store.userLanguage.language);
    const tasksState = useSelector<ApplicationState, ITasksState>((store) => store.tasks);

    const tasksStateStatuses = tasksState.selected_statuses;
    const tasksStateTypes = tasksState.selected_types;
    const tasksStateStats = tasksState.tasks_by_status;
    const tasksStateStatusTable = tasksState.task_status_table;

    const newTasksId = [...tasksStateStatusTable.values()].find(stat => stat.alias === 'NEW')?.id;
    const inProgressTasksId = [...tasksStateStatusTable.values()].find(stat => stat.alias === 'IN_PROGRESS')?.id;

    const statusesList = Array.from(tasksState.task_status_table, (x) => x[1]);
    const typesList = tasksState.tasks_types;

    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles(theme);
    const [actionMenuState, setActionMenuState] = useState(null);
    const [searchQuery, setSearchQuery] = useState('')
    const [isFilterSelected, setIsFilterSelected] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState(
        getPage(
            useSelector<ApplicationState, any>((store) => store.pagination),
            paginatorParent,
        ),
    );

    const handlePageChange = (e) => {
        setCurrentPage(e);
        dispatch(setPage(paginatorParent, e));
    };

    const INITIAL_STATE = {
        hideDialog: true,
        taskId: null,
        errorLevel: null,
        hasWarnings: null,
        hasErrors: null,
        hasInfo: null,
        details: null,
    };

    const [{hideDialog, taskId, hasWarnings, hasErrors, hasInfo, details}, setDialog] = useState(INITIAL_STATE);

    const closeDialog = () => {
        setDialog({...INITIAL_STATE, hideDialog: true});
    };

    const showDialog = (e, task, details) => {
        try{
            if (e.target?.className?.toLowerCase().includes('checkbox')) return;
        }catch(e){
            // Кликнули по иконке
        }

        if (task.has_errors || task.has_warnings || task.has_info) {
            setDialog({
                ...INITIAL_STATE,
                taskId: task.id,
                hideDialog: false,
                hasErrors: task.has_errors,
                hasWarnings: task.has_warnings,
                hasInfo: task.has_info,
                details: details,
            });
        }
    };

    const revokeTask = (task) => {
        dispatch(revokeSelectedTasksRequest(task));
    }

    const handleFilterChange = (statuses, types) => {
        if (statuses.includes('show_system')) dispatch(showSystemTasks())
        if (statuses.includes('show_my_tasks')) dispatch(hideSystemTasks())
        dispatch(getTasksRequest(1, statuses, types, searchQuery));
        dispatch(setSelectedFilters(statuses, types));
    }

    const {run} = useThrottleFn(
        (query) => {
            dispatch(getTasksRequest(currentPage, tasksStateStatuses, tasksStateTypes, query))
        },
        {wait: 1500}
    );

    const throttleSearchChange = run;

    const handleSearchChange = (e) => {
        const query = e.target.value
        setSearchQuery(query);
        throttleSearchChange(query)
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [currentPage, tasksState])

    useEffect(() => {
        dispatch(getTasksRequest(currentPage, tasksStateStatuses, tasksStateTypes));
        //eslint-disable-next-line
    }, [currentPage, language]);

    return (
        <div>
        {!hideDialog && (
            <TaskLogsDetails
                open={!hideDialog}
                close={closeDialog}
                currentId={taskId}
                entity={'task'}
                hasErrors={hasErrors}
                hasWarnings={hasWarnings}
                hasInfo={hasInfo}
            />
            )}

        <DialogContext.Consumer>{({isDialogOpen}) => (
            <TaskQueueWrapper
                className={classes.drawer}
                variant='persistent'
                anchor='left'
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
                $isDialogOpen={isDialogOpen}
                $isBigSidebar={isBigSidebar}
            >
                <TaskQueueHeader>
                    {t('components:headers.task_manager', 'Менеджер задач')}
                    {tasksStateStats && <StatsWrapper>
                        {newTasksId &&
                        <StatsItem><StatsName>{t('commons:task.in_queue')}:</StatsName><StatsValue>{tasksStateStats[newTasksId]}</StatsValue></StatsItem>}
                        {inProgressTasksId &&
                        <StatsItem><StatsName>{t('commons:task.in_progress')}:</StatsName><StatsValue>{tasksStateStats[inProgressTasksId]}</StatsValue></StatsItem>}
                    </StatsWrapper>}
                </TaskQueueHeader>
                <HeaderCheckboxesWrapper>
                    <SearchInput placeholder={t('commons:task.search')} value={searchQuery}
                                 onChange={handleSearchChange}/>
                    <FiltersSelect onChange={handleFilterChange} t={t} statusesList={statusesList} typesList={typesList}
                                   placeholder={t('commons:task.filters')}
                                   initialStatuses={tasksStateStatuses} initialTypes={tasksStateTypes}
                                   tasksState={tasksState} isFilterSelected={isFilterSelected} setIsFilterSelected={setIsFilterSelected} />
                    {Boolean(tasksState.tasks.size) &&
                    <CheckboxDark checked={tasksState.selected_tasks.length >= tasksState.tasks_size}
                                  onChange={() => {
                                      if (tasksState.selected_tasks.length >= tasksState.tasks_size) {
                                          dispatch(removeAllTasksFromSelected());
                                      } else {
                                          dispatch(appendAllTasksToSelectedRequest(tasksStateStatuses, tasksStateTypes, searchQuery));
                                      }
                                  }} label={t('components:drawer.select_all', 'Выделить все')}/>
                    }
                    {tasksState.selected_tasks.length > 0 && (
                        <RevokeTasksButton
                            onClick={() => {
                                dispatch(revokeSelectedTasksRequest(tasksState.selected_tasks));
                                setActionMenuState(null);
                            }}
                        >
                            {t('components:drawer.revoke_tasks', 'Снять задачи')}
                        </RevokeTasksButton>
                    )}
                    {(isFilterSelected && Boolean(tasksState.tasks.size)) && <ResetFilterButton
                        onClick={() => {
                            dispatch(resetFilters());
                            setActionMenuState(null);
                        }}
                    >
                        {t('components:drawer.reset_filter', 'Сбросить фильтр')}
                    </ResetFilterButton>}
                </HeaderCheckboxesWrapper>
                <TaskQueueBody>
                    <Menu
                        id='simple-menu'
                        anchorEl={actionMenuState}
                        keepMounted
                        open={Boolean(actionMenuState)}
                        onClose={() => setActionMenuState(null)}
                    >
                        <MenuItem
                            onClick={() => {
                                dispatch(revokeSelectedTasksRequest(tasksState.selected_tasks));
                                setActionMenuState(null);
                            }}
                        >
                            {t('components:drawer.revoke_tasks', 'Снять задачи')}
                        </MenuItem>
                    </Menu>
                    {Boolean(tasksState.tasks.size) ? (
                        <List style={{padding: 0}}>
                            <ReactTooltip place={'right'} delayShow={500} effect={'solid'} isCapture={false}
                                          className="react-tooltip" id="taskTooltip"
                            />
                            {Array.from(tasksState.tasks.values()).map((task: ITask) => (
                                <TaskItem key={task.id} task={task} tasksState={tasksState} t={t}
                                          revokeTask={revokeTask}
                                          onClick={(e) => showDialog(e, task, details)}
                                          hasErrors={task.has_errors}
                                          hasWarnings={task.has_warnings}
                                          hasInfo={task.has_info}/>
                            ))}
                        </List>
                    ) : (
                        <EmptyResultsPlaceholder>{t('commons:task.no_tasks_found_for_search_criteria')}</EmptyResultsPlaceholder>
                    )}
                </TaskQueueBody>
                <Divider/>
                <div
                    className='paginator-wrapper paginator-centered'
                    style={{
                        backgroundColor: '#1A1A24',
                        padding: '5px 0',
                        display: 'flex',
                        alignItems: 'center',
                        width: 'auto',
                    }}
                >
                    <Paginator
                        onPageChange={(e) => {
                            handlePageChange(e);
                        }}
                        currentPage={currentPage}
                        loading={tasksState.loading}
                        pageSize={tasksState.PER_PAGE}
                        count={tasksState.PER_PAGE * tasksState.TOTAL_PAGES}
                        isDark={true}
                    />
                </div>
            </TaskQueueWrapper>
        )}
        </DialogContext.Consumer>
        </div>
    );
};

const HeaderCheckboxesWrapper = styled.div`
  display: flex;
  padding: 16px 14px;
  flex-wrap: wrap;
  border-top: 1px solid #2E2E38;
`

const TaskQueueWrapper = styled(Drawer)`
  .MuiDrawer-paper {
    border-left: 1px solid ${props => props.theme.colors.grey1};
    ${props => props.$isBigSidebar ? `left: 100px;` : `left: 50px;`}
    z-index: 1001;
    user-select: none;
  }

  ${props => {
    if (props.$isDialogOpen && props.open) return 'display: none;'
    if (!props.$isDialogOpen && props.open) return 'disply: block;'
  }
  }
`

const SearchInput = styled.input`
  height: 38px;
  padding: 12px 16px;
  box-sizing: border-box;
  width: calc(100% - 44px);
  font-size: 14px;
  margin-bottom: 16px;
  background: #EEEEF2;
  outline: none;
  border: none;
`

const TaskQueueHeader = styled.div`
  padding: 14px;
  font-size: 16px;
  color: #B3B3BC;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TaskQueueBody = styled.div`
  position: relative;
  height: 95%;
  overflow-y: scroll;
  overflow-x: hidden;
  text-overflow: ellipsis;
  scrollbar-width: thin;
  scrollbar-color: #808ba4 rgba(0, 0, 0, 0);

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #1A1A24;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5f5f5f;
    border-radius: 12px;
  }

  .react-tooltip {
    max-width: 450px;
  }
`;

const RevokeTasksButton = styled.button`
  all: unset;
  color: #fff;
  font-family: 'EYInterstate Bold', Helvetica, Arial, sans-serif;
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  align-self: center;
  margin-left: auto;

  &:hover {
    color: ${props => props.theme.colors.red};
  }
  
  & ~ button {
    margin-left: 30px;
  }
`

const ResetFilterButton = styled.button`
  all: unset;
  color: #fff;
  font-family: 'EYInterstate Bold', Helvetica, Arial, sans-serif;
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  align-self: center;
  margin-left: auto;

  &:hover {
    color: ${props => props.theme.colors.yellow};
  }
`

const CheckboxDark = styled(Checkbox)`
  color: #fff;
`

const EmptyResultsPlaceholder = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
`

const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatsItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 13px;
  
  :last-child {
    margin-right: 0;
  }
`

const StatsName = styled.span`
  color: ${props => props.theme.colors.grey2};
  margin-right: 3px;
`

const StatsValue = styled.span`
  color: #fff;
`

export default TaskQueue;
