import { Reducer } from 'redux';
import { IAttribute, IAttributeState, AttributeActionType } from "./types";

export const initialState: IAttributeState = {
    attributes: [],
    loading: false,
    error: null
};

const deleteItem = (items: IAttribute[], id: number) => {
    return [...items.filter(s => s.id !== id)]
};

const reducer: Reducer<IAttributeState> = (state = initialState, action): IAttributeState => {
    switch (action.type) {
        case AttributeActionType.ATTRIBUTE_CREATE_REQUEST: {
            return { ...state, loading: true }
        }
        case AttributeActionType.ATTRIBUTE_CREATE_SUCCESS: {
            return { ...state, loading: false, attributes: [...state.attributes, action.payload.attribute] }
        }
        case AttributeActionType.GET_ATTRIBUTES_LIST_REQUEST: {
            return { ...state, loading: true }
        }
        case AttributeActionType.GET_ATTRIBUTES_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                attributes: [...action.payload.attributes]
            }
        }
        case AttributeActionType.GET_ATTRIBUTES_LIST_ERROR: {
            return {
                ...state, error: action.payload
            }
        }
        case AttributeActionType.DELETE_ATTRIBUTE: {
            return {
                ...state,
                attributes: deleteItem(state.attributes, action.payload.id)
            }
        }
        default: {
            return state
        }
    }
};

export { reducer as attributesReducer }