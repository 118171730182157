import styled, {css} from 'styled-components';

export const TabsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;

export const Tab = styled.div`
    padding-bottom: 3px;
    cursor: pointer;

    ${(props) =>
        props.isActive &&
        css`
            border-bottom: 2px solid ${(props) => props.theme.colors.yellow};
            font-weight: bold;
        `}
    
    ${(props) => !props.hasMessage && 
    css`
        opacity: 50%;
        color: #5A5A5A;
    `}
`;

export const TabsContent = styled.div`
  height: 100%;
`;
