import { Action, AnyAction, combineReducers, Dispatch } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { referencesReducer, referencesSaga, ReferencesState } from './references';
import { ICurrentGridState } from './currentGrid/types';
import { ICurrentPlotState } from './currentPlot/types';
import { currentGridReducer } from './currentGrid/reducer';
import calcSaga from './currentGrid/sagas';
import { currentPlotReducer } from './currentPlot/reducer';
import currentPlotSaga from './currentPlot/sagas';
import { createCurrentContextWithNameData } from './currentCalcContext/reducer';
import { ICurrentCalcContextState } from './currentCalcContext/types';
import { costsFilterReducer } from './costsFilter/reducer';
import { ICostsFilterState } from './costsFilter/types';
import { costsFilterSaga } from './costsFilter/sagas';
import { calcVersionReducer } from './calcVersion/reducer';
import { ICalcVersionState } from './calcVersion/types';
import calcVersionsSaga from './calcVersion/sagas';
import { importerReducer } from './importer/reducer';
import { ImporterCollectionState } from './importer/types';
import importerSaga from './importer/sagas';
import { calcJobReducer } from './calcJob/reducer';
import calcJobSaga, { deleteCalcJobSaga } from './calcJob/sagas';
import { ICalcJobsState } from './calcJob/types';
import topLevelSagas from './topLevelSagas';
import pushNotificationSaga from './pushNotificationSaga';
import { IWellsState } from './wells/types';
import { IWellsPlotsState } from './wellsPlots/types';
import { wellsReducer } from './wells/reducer';
import { wellPlotsReducer } from './wellsPlots/reducer';
import { setWellSaga, wellsSaga } from './wells/sagas';
import { wellPlotSaga } from './wellsPlots/sagas';
import { ILoginState } from './login/types';
import { LoginReducer } from './login/reducer';
import { UserLoadedSaga, userLoginSaga } from './login/sagas';
import { userLanguageSaga } from './language/sagas';
import { IAppProperties } from './appProperties/types';
import { appPropertiesReducer } from './appProperties/reducer';
import { appVersionSaga } from './appProperties/sagas';
import { calcVersionErrorsReducer } from './calcVersionErrors/reducer';
import { deltaPlotsReducer } from './deltaPlots/reducer';
import { demoPlotsReducer } from './dashPlots/reducer';
import { ICalcVersionErrorsState } from './calcVersionErrors/types';
import calcVersionErrorsSaga from './calcVersionErrors/sagas';
import { deltaPlotSaga } from './deltaPlots/sagas';
import { demoPlotSaga } from './dashPlots/sagas';
import { IDeltaPlotsState } from './deltaPlots/types';
import { IDemoPlotsState } from './dashPlots/types';
import { versionExtraSaga } from './currentCalcContext/sagas';
import { IFilterState } from './tableSelector/types';
import { createTableSelectorWithNameData } from './tableSelector/reducer';
import { tableSelectorSaga } from './tableSelector/sagas';
import { IScenarioState } from './scenarioCalc/types';
import { scenarioCalcReducer } from './scenarioCalc/reducer';
import { scenarioCalcSaga } from './scenarioCalc/sagas';
import { scenarioSaga } from './scenario/sagas';
import { scenarioReducer } from './scenario/reducer';
import { IStimulationAnalysisState } from './stimulationToolbar/types';
import { stimulationAnalysisReducer } from './stimulationToolbar/reducer';
import { stimulationAnalysisSaga } from './stimulationToolbar/sagas';

import { snackbarsReducer } from './snackbars/reducer';
import { ISnackbarsState } from './snackbars/types';
import { ITasksState } from './tasks/types';
import { tasksReducer } from './tasks/reducer';
import tasksSaga from './tasks/sagas';
import { IReportsState } from './reports/types';
import { reportReducer } from './reports/reducer';
import reportSaga from './reports/sagas';
import snackbarSaga from './snackbars/sagas';
import { ICalcJobErrorsState } from './calcJobErrors/types';
import { calcJobErrorsReducer } from './calcJobErrors/reducer';
import calcJobsErrorsSaga from './calcJobErrors/sagas';
import { IPermissions } from './permissions/types';
import { permissionsReducer } from './permissions/reducer';
import PermissionsSaga from './permissions/sagas';
import errorsSaga from './errorsAndWarnings/sagas';
import { errorsReducer, IErrorsState } from './errorsAndWarnings/reducer';
import { IPaginatorState, paginationReducer } from './pagination';
import { IUploadState } from './uploaded/types';
import { uploadsReducer } from './uploaded/reducer';
import { uploadsSaga } from './uploaded/sagas';
import { entitiesReducer } from './reusable/reducers';
import { reportTemplatesReducer } from './reportTemplates/reducer';
import { reportItemsSaga } from './reportTemplates/saga';
import { userLanguageReducer } from './language/reducer';
import { IUserLanguageState } from './language/types';
import { IFilterVariantsState } from './filterVariants/types';
import { filterVariantsReducer } from './filterVariants/reducer';
import filterVariantSaga from './filterVariants/sagas';
import { IVersionGroupState } from './versionGroup/types';
import { versionGroupReducer } from './versionGroup/reducer';
import { versionGroupsSaga } from './versionGroup/sagas';
import { attributesSaga } from './attributes/sagas';
import { attributesReducer } from './attributes/reducer';
import { IAttributeState } from './attributes/types';
import { versionCardReducer } from './versionCard/reducer';
import { IVersionCardState } from './versionCard/types';
import { versionCardSaga } from './versionCard/sagas';
import {IVersionAttributeState} from './versionAttributes/types';
import {createAttributesReducesWithNameData} from './versionAttributes/reducer';
import {versionAttributesSaga} from './versionAttributes/sagas';
import { rowsPerTableCellReducer } from './rowsPerTableCell/reducer';
import { IRowsPerTableCellState } from './rowsPerTableCell/types';

// The top-level state object
export interface ApplicationState {
    calcJob: ICalcJobsState;
    calcVersion: ICalcVersionState;
    currentGrid: ICurrentGridState;
    currentPlot: ICurrentPlotState;
    currentCalculationContext: ICurrentCalcContextState;
    currentCostsContext: ICurrentCalcContextState;
    currentAnomaliesContext: ICurrentCalcContextState;
    references: ReferencesState;
    importerCollection: ImporterCollectionState;
    router: RouterState;
    wells: IWellsState;
    wellsPlots: IWellsPlotsState;
    login: ILoginState;
    appProperties: IAppProperties;
    calcVersionErrors: ICalcVersionErrorsState;
    deltaPlots: IDeltaPlotsState;
    stimulationAnalysis: IStimulationAnalysisState;
    demoPlots: IDemoPlotsState;
    scenarios: IScenarioState;
    scenario;
    snackbars: ISnackbarsState;
    tasks: ITasksState;
    reports: IReportsState;
    calcJobErrors: ICalcJobErrorsState;
    permissions: IPermissions;
    errors: IErrorsState;
    pagination: IPaginatorState;
    uploads: IUploadState;
    userLanguage: IUserLanguageState;
    filterVariants: IFilterVariantsState;
    costsFilter: ICostsFilterState;
    costsCompFilter: IFilterState;
    rowsPerTableCell: IRowsPerTableCellState;
    vFilter: IFilterState;
    vFilterSelected: IFilterState;
    group1Filter: IFilterState;
    group2Filter: IFilterState;
    taxModesFilter: IFilterState;
    macroScenariosFilter: IFilterState;
    emissionScenariosFilter: IFilterState;
    costsScenariosFilter: IFilterState;
    reportFilter: IFilterState;
    portfolioAnalysisFilter: IFilterState;
    filesFilter: IFilterState;
    reportTemplates;
    entities;
    versionGroupList: IVersionGroupState;
    attributes: IAttributeState;
    versionCard: IVersionCardState;
    versionAttributes: IVersionAttributeState;
    versionGroups: IVersionAttributeState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        calcVersion: calcVersionReducer,
        currentGrid: currentGridReducer,
        currentPlot: currentPlotReducer,
        currentCalculationContext: createCurrentContextWithNameData('indicatorTree'),
        currentCostsContext: createCurrentContextWithNameData('costsTree'),
        currentAnomaliesContext: createCurrentContextWithNameData('anomalyTree'),
        costsFilter: costsFilterReducer,
        references: referencesReducer,
        importerCollection: importerReducer,
        calcJob: calcJobReducer,
        wells: wellsReducer,
        wellsPlots: wellPlotsReducer,
        login: LoginReducer,
        appProperties: appPropertiesReducer,
        calcVersionErrors: calcVersionErrorsReducer,
        deltaPlots: deltaPlotsReducer,
        stimulationAnalysis: stimulationAnalysisReducer,
        demoPlots: demoPlotsReducer,
        scenarios: scenarioCalcReducer,
        scenario: scenarioReducer,
        snackbars: snackbarsReducer,
        tasks: tasksReducer,
        reports: reportReducer,
        calcJobErrors: calcJobErrorsReducer,
        permissions: permissionsReducer,
        errors: errorsReducer,
        pagination: paginationReducer,
        uploads: uploadsReducer,
        entities: entitiesReducer,
        reportTemplates: reportTemplatesReducer,
        userLanguage: userLanguageReducer,
        filterVariants: filterVariantsReducer,
        rowsPerTableCell: rowsPerTableCellReducer,
        vFilter: createTableSelectorWithNameData('vFilter'),
        vFilterSelected: createTableSelectorWithNameData('vFilterSelected'),
        group1Filter: createTableSelectorWithNameData('group1Filter'),
        group2Filter: createTableSelectorWithNameData('group2Filter'),
        costsCompFilter: createTableSelectorWithNameData('costsCompFilter'),
        taxModesFilter: createTableSelectorWithNameData('taxModesFilter'),
        macroScenariosFilter: createTableSelectorWithNameData('macroScenariosFilter'),
        emissionScenariosFilter: createTableSelectorWithNameData('emissionScenariosFilter'),
        costsScenariosFilter: createTableSelectorWithNameData('costsScenariosFilter'),
        portfolioAnalysisFilter: createTableSelectorWithNameData('portfolioAnalysisFilter'),
        filesFilter: createTableSelectorWithNameData('filesFilter'),
        versionGroupList: versionGroupReducer,
        attributes: attributesReducer,
        versionCard: versionCardReducer,
        versionAttributes: createAttributesReducesWithNameData('versionAttributes'),
        versionGroups: createAttributesReducesWithNameData('versionGroups')
    });

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
    yield all([
        fork(costsFilterSaga),
        fork(userLanguageSaga),
        fork(referencesSaga),
        fork(calcSaga),
        fork(currentPlotSaga),
        fork(calcVersionsSaga),
        fork(importerSaga),
        fork(calcJobSaga),
        fork(topLevelSagas),
        fork(pushNotificationSaga),
        fork(wellsSaga),
        fork(setWellSaga),
        fork(wellPlotSaga),
        fork(UserLoadedSaga),
        fork(userLoginSaga),
        fork(deleteCalcJobSaga),
        fork(appVersionSaga),
        fork(calcVersionErrorsSaga),
        fork(deltaPlotSaga),
        fork(demoPlotSaga),
        fork(stimulationAnalysisSaga),
        fork(versionExtraSaga),
        fork(tableSelectorSaga),
        fork(scenarioCalcSaga),
        fork(scenarioSaga),
        fork(tasksSaga),
        fork(reportSaga),
        fork(snackbarSaga),
        fork(calcJobsErrorsSaga),
        fork(PermissionsSaga),
        fork(errorsSaga),
        fork(uploadsSaga),
        fork(reportItemsSaga),
        fork(filterVariantSaga),
        fork(versionGroupsSaga),
        fork(versionAttributesSaga),
        fork(attributesSaga),
        fork(versionCardSaga)
    ]);
}
