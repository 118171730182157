import * as React from "react";
import {CSSProperties} from "react";
import {Trans} from 'react-i18next';

const FilterLabel = ({filterContent}) => {
    const styles = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        maxWidth: '150px',
        color: '#188CE5',
        fontSize: 12
    } as CSSProperties;

    const {selected, excluded, filterOptions, hideSelection} = filterContent;

    const excludedObjects = excluded ? excluded.map(id => filterOptions.filter(item => item.id === id)).map(i => i[0]) : null;

    const MultiFilteredValues = () => {
        const restSelected = selected?.length - 1;
        const restExcluded = excluded?.length - 1;

        return (
            <div style={{
                alignItems: 'baseline',
                overflowWrap: 'normal',
                whiteSpace: 'pre',
                display: 'contents'
            }}>
                {selected?.[0] && !hideSelection ? (<div style={{
                    ...styles,
                    whiteSpace: 'pre'
                }}>
                    {restSelected === 0 ? <Trans>{selected[0].name.concat('')}</Trans> :
                        <Trans>{selected[0].name.concat('')} и еще {{restSelected}} др </Trans>}
                </div>) : null}
                {excludedObjects?.[0] && !hideSelection ? <div style={{
                    ...styles,
                    whiteSpace: 'pre',
                    color: '#FF4136'
                }}>{restExcluded === 0 ? <Trans>{excludedObjects[0].name.concat('')}</Trans> :
                    <Trans>{excludedObjects[0].name.concat('')} и еще {{restExcluded}} др </Trans>}
                </div> : null}
            </div>
        )
    };

    return (
        <>
            <MultiFilteredValues/>
        </>
    )
};

export default FilterLabel