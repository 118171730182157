import React from 'react';
import styled, {css} from 'styled-components/macro';
import {Checkmark} from './Checkbox';

export const CheckboxIcon = (props) => (
    <CheckBoxWrapper>
        <Checkmark />
    </CheckBoxWrapper>
);

export const CheckboxCheckedIcon = () => (
    <CheckBoxWrapper>
        <CheckmarkActive />
    </CheckBoxWrapper>
);

export const CheckboxBlackCheckedIcon = () => (
    <CheckBoxWrapper>
        <CheckmarkActive isBlack={true} />
    </CheckBoxWrapper>
);

export const CheckboxCancelIcon = () => (
    <CheckBoxWrapper>
        <CheckmarkCancel />
    </CheckBoxWrapper>
);

export const CheckboxMinusIcon = () => (
    <CheckBoxWrapper>
        <CheckmarkMinus />
    </CheckBoxWrapper>
);

export const CheckBoxWrapper = styled.div`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;

  input:hover {
    background: transparent;
  }
`;

const checkmarkStyles = css`
  height: 20px;
  width: 20px;
`;

const CheckmarkActive = styled.span`
  ${checkmarkStyles};
  background-color: ${(props) =>
          props.isBlack ? props.theme.colors.black : props.theme.colors.yellow};

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 7px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid ${(props) => props.theme.colors.black};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const CheckmarkCancel = styled.span`
  ${checkmarkStyles};
  background-color: ${(props) => props.theme.colors.red};

  &:before {
    content: '';
    position: absolute;
    left: 9px;
    top: 4px;
    height: 12px;
    width: 2px;
    background-color: ${(props) => props.theme.colors.white};
    transform: rotate(45deg);
  }

  &:after {
    content: '';
    position: absolute;
    left: 9px;
    top: 4px;
    height: 12px;
    width: 2px;
    background-color: ${(props) => props.theme.colors.white};
    transform: rotate(-45deg);
  }
`;

const CheckmarkMinus = styled.span`
  ${checkmarkStyles};
  background-color: ${(props) => props.theme.colors.black};

  &:after {
    content: '';
    position: absolute;
    left: 9px;
    top: 6px;
    height: 7px;
    width: 2px;
    background-color: ${(props) => props.theme.colors.white};
    transform: rotate(-90deg);
  }
`;
