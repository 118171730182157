import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Text } from '../Popup/popup.styles';
import { FlexContainer } from '../../theme/main.styles';
import { getDataFromLocalStorage } from '../../helpers/localStorage';
import { getEditedIndicators, transformData } from './utils';

const localeKeys = {
    taxModesFilter: 'tax_mode',
    emissionScenariosFilter: 'esg',
    costsScenariosFilter: 'cost'
}

export const ScenarioSummary = ({ attributes, scenarioName, tabNames, editionId, editMode, filterName }) => {

    const { t } = useTranslation();

    const localeKey = localeKeys[filterName];
    const storeKey = editMode ? `${localeKey}_Draft`: `${localeKey}_Details`;

    const scenarioEditions = getDataFromLocalStorage(`${localeKey}_Editions`);
    const scenarioData = getDataFromLocalStorage(storeKey);

    if (!scenarioEditions || !scenarioData) return null;

    let {treeData} = scenarioData;

    if (!treeData) {
        // Нормализация данных
        treeData = transformData(scenarioData.structure, 'structure');
    }

    const codeEdition = scenarioEditions[editionId].name;
    const summaryData = getEditedIndicators(treeData, tabNames);

    const createItems = (indicators) => {

        return indicators.map(i => {
            const key = i.address;
            const name = i.META.display_name;
            const status = i.META.input_status;

            return <GreyText key={key}>{`${name}: ${status}`}</GreyText>
        });
    }

    const createSummaryItems = (summaryData) => (
        tabNames.map((tabName) => (
            <FlexItem hidden={!summaryData[tabName].length} key={tabName}>
                <h3>{t(`pages:scenarios.dialog.${localeKey}.tabs.${tabName}`)}</h3>
                <>{createItems(summaryData[tabName])}</>
            </FlexItem>
        ))
    );

    return (
        <>
            <Flex>
                <FlexItem hidden={scenarioName === ''}>
                    <h3>{t('pages:scenarios.dialog.scenario_name')}</h3>
                    <GreyText>{scenarioName}</GreyText>
                </FlexItem>

                {filterName === 'taxModesFilter' &&
                    <FlexItem>
                        <h3>{t('pages:scenarios.dialog.tax_mode.code_version')}</h3>
                        <GreyText>{codeEdition}</GreyText>
                    </FlexItem>
                }

                {createSummaryItems(summaryData)}

                <FlexItem hidden={!attributes.length}>
                    <h3>{t(`pages:scenarios.dialog.${localeKey}.tabs.attributes`)}</h3>
                    <>{attributes.map(a => <GreyText key={a.id}>{a.name}</GreyText>)}</>
                </FlexItem>
            </Flex>
        </>
    );
};


const Flex = styled(FlexContainer)`
    display: grid;
    align-items: start;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    justify-content: space-between;
    padding: 3em;

    & input {
        width: 100%;
    }
`;

const FlexItem = styled.div`
    margin-bottom: 2em;
`;

const GreyText = styled(Text)`
    color: ${(props) => props.theme.colors.grey2};
`;
