import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import {IUpload, UploadActionType} from "./types";
import {getUploadsListError, getUploadsListSuccess} from "./actions";
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetRequest() {
    const url = `${API_ENDPOINT}/uplift/liquid-prod-limit/`;
    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getUploadsListError(res.error))
        } else {
            yield put(getUploadsListSuccess(res.data.liquid_prod_limit_list as IUpload[]))
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(getUploadsListError(e.stack!))
        } else {
            yield put(getUploadsListError("An unknown error occurred."))
        }
    }
}

function* handleDeleteRequest(action) {
    const id = action.payload.id;
    yield call(axios.delete, `${API_ENDPOINT}/uplift/liquid-prod-limit/${id}/`);
}

// function* handleCalcCreateRequest(action) {
//
//     const url = `${API_ENDPOINT}/scenario/calc`;
//     const body = {
//         "scenario_ids": action.payload.scenario_ids,
//         "version_ids": action.payload.version_ids
//     };
//
//     try {
//         const res = yield call(axios.post, url, body, {headers: {'Content-Type': 'application/json'}});
//         if (res.error) {
//             yield put(calculateScenarioError(res.error))
//         } else {
//             yield put(calculateScenarioSuccess(res.data as number));
//             yield put(requestSnackDisplay('success', 'Пересчет версий по выбранному сценарию запущен'))
//         }
//     } catch (e) {
//         if (e instanceof Error) {
//             yield put(calculateScenarioError(e.stack!))
//         } else {
//             yield put(calculateScenarioError("An unknown error occurred."))
//         }
//     }
// }

function* watchGetRequest() {
    yield takeLatest(UploadActionType.GET_UPLOADS_LIST_REQUEST, handleGetRequest)
}

function* watchDeleteRequest() {
    yield takeLatest(UploadActionType.DELETE_UPLOAD, handleDeleteRequest)
}


// function* watchCalcCreateRequest() {
//     yield takeLatest(UploadActionType.SCENARIO_CALC_CREATE_REQUEST, handleCalcCreateRequest)
// }

export function* uploadsSaga() {
    yield all([fork(watchGetRequest),
        //fork(watchCalcCreateRequest),
        fork(watchDeleteRequest)])
}

