import React from 'react';
import {Icon} from './icon.styles';

import RollBackImage from '../../../assets/images/icons/other/roll-back.svg';
import RollUpImage from '../../../assets/images/icons/other/roll-up.svg';
import RollDownImage from '../../../assets/images/icons/other/roll-down.svg';
import ExitImage from '../../../assets/images/icons/other/icon-exit.svg';
import CloseImage from '../../../assets/images/icons/other/icon-close.svg';
import PlusImage from '../../../assets/images/icons/other/icon-plus.svg';
import ResizeImage from '../../../assets/images/icons/other/icon-resize.svg';
import DownImage from '../../../assets/images/icons/other/icon-down.svg';
import FullscreenImage from '../../../assets/images/icons/other/icon-expand-fullscreen.svg';
import IconTasksQueue from '../../../assets/images/icons/control/icon-tasks-queue.svg';
import FilterWhiteImage from "../../../assets/images/icons/table/icon-filter-white.svg";

import MinimizeIcon from '@material-ui/icons/Minimize';

type IconProps = {
    onClick?: any;
    style?: any;
};

export const TasksQueueIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={IconTasksQueue} alt="tasks-queue-icon"/>
);
export const RollBackIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={RollBackImage} alt="roll-back-icon"/>
);
export const RollUpIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={RollUpImage} alt="roll-up-icon"/>
);
export const RollDownIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={RollDownImage} alt="roll-down-icon"/>
);
export const ExitIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={ExitImage} alt="exit-icon"/>
);
export const CloseIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={CloseImage} alt="close-icon"/>
);
export const FilterIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={FilterWhiteImage} alt="filter-icon"/>
);
export const FullscreenExpandIcon: React.FC<IconProps> = ({onClick, style}) => (
    <Icon onClick={onClick} src={FullscreenImage} alt="fullscreen-expand-icon" style={style}/>
);

export const FullscreenShrinkIcon: React.FC<IconProps> = ({onClick, style}) => (
    <MinimizeIcon onClick={onClick} style={style}/>
);

export const PlusIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={PlusImage} alt="plus-icon"/>
);
export const ResizeIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={ResizeImage} alt="resize-icon"/>
);
export const DownIcon: React.FC<IconProps> = ({onClick}) => (
    <Icon onClick={onClick} src={DownImage} alt="down-icon"/>
);
