import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import { IAttribute, AttributeActionType } from "./types";
import {
    getAttributesListError,
    getAttributesListSuccess,
    createAttributeError,
    createAttributeSuccess
} from "./actions";
import { getConfig } from '../../config-provider';
import { requestSnackDisplay } from "../snackbars/actions";
import i18n from '../../i18n';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetRequest() {
    const url = `${API_ENDPOINT}/calc/versions_filter/__filters__/tags`;
    const body = {
        all_existing: true
    };

    try {
        const res = yield call(axios.post, url, body);
        if (res.error) {
            yield put(getAttributesListError(res.error))
        } else {
            yield put(getAttributesListSuccess(res.data.values as IAttribute[]))
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(getAttributesListError(e.stack!))
        } else {
            yield put(getAttributesListError("An unknown error occurred."))
        }
    }
}

function* handleDeleteRequest(action) {
    const id = action.payload.id;
    yield call(axios.delete, `${API_ENDPOINT}/version/version_group/${id}/`);
}

function* handleCreateRequest(action) {
    const url = `${API_ENDPOINT}/calc/tags/`;
    const body = {
        "name": action.payload.name,
    };

    try {
        const res = yield call(axios.post, url, body, { headers: { 'Content-Type': 'application/json' } });
        if (res.error) {
            yield put(createAttributeError(res.error))
            yield put(requestSnackDisplay('error', res.error))
        } else {
            // API возвращает массив с одним элементом
            yield put(createAttributeSuccess(res.data[0]));
            yield put(requestSnackDisplay('success', i18n.t('notifications:attributeCreated')));
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(createAttributeError(e.stack!))
            yield put(requestSnackDisplay('error', e.stack!))
        } else {
            yield put(createAttributeError("An unknown error occurred."))
            yield put(requestSnackDisplay('error', 'An unknown error occurred.'))
        }
    }
}

function* watchGetRequest() {
    yield takeLatest(AttributeActionType.GET_ATTRIBUTES_LIST_REQUEST, handleGetRequest)
}

function* watchCreateRequest() {
    yield takeLatest(AttributeActionType.ATTRIBUTE_CREATE_REQUEST, handleCreateRequest)
}

function* watchDeleteRequest() {
    yield takeLatest(AttributeActionType.DELETE_ATTRIBUTE, handleDeleteRequest)
}

export function* attributesSaga() {
    yield all([
        fork(watchGetRequest),
        fork(watchCreateRequest),
        fork(watchDeleteRequest)
    ])
}

