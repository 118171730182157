import styled from 'styled-components/macro';
import React from 'react';
import AttributesSummaryListItem from './AttributesSummaryListItem';

const AttributesSummary = ({
    initialParameters,
    handleDelete,
    editMode = true,
    handleValueChange,
    isTagUpdated,
    isScenarioMode,
}) => {
    return (
        <AttributesSummaryWrapper>
            {initialParameters.length > 0 &&
                initialParameters.map((value, index) => {
                    return (
                        <AttributesSummaryListItem
                            key={index}
                            handleDelete={handleDelete}
                            editMode={editMode}
                            value={value}
                            index={index}
                            handleValueChange={handleValueChange}
                            isTagUpdated={isTagUpdated}
                            isScenarioMode={isScenarioMode}
                        />
                    );
                })}
        </AttributesSummaryWrapper>
    );
};

export default AttributesSummary;

export const AttributesSummaryWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;
