interface Unique {
    id: number;
}

export function addAll<T extends Unique>(data: T[]): Map<number, T> {
    return new Map(data.map(x => [x.id, x]));
}

export function addOnTop(prevData, newData) {
    let newMap = new Map().set(prevData.activeID, newData);
    return new Map([...prevData.data, ...newMap]);
}

export function addOnTopJobAndAlike<T extends Unique>(prevData: Map<number, T>, newData: T): Map<number, T> {
    if (prevData == null || prevData.has(newData.id)) {
        return prevData;
    }
    return new Map([[newData.id, newData], ...Array.from(prevData.entries())]);
}

export function addOrUpdate<K, T>(prevData: Map<K, T>, id: K, newData: T): Map<K, T> {
    if (!prevData) {
        return new Map([[id, newData]])
    }
    return new Map(prevData.set(id, newData));
}

export function deleteItem<T extends Unique>(prevData: Map<number, T>, id: number): Map<number, T> {
    const newData = new Map(prevData);
    newData.delete(id);
    return newData;
}