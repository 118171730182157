import styled from 'styled-components/macro';
import CustomSearchBox from '../../components/CustomSearchBox';

export const VersionPageWrapper = styled.div`
    position: relative;
    padding: 20px 30px 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
  
    & * {
        scrollbar-color: ${(props) => props.theme.colors.grey4} rgba(0, 0, 0, 0);
    }

    & *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
  
    & *::-webkit-scrollbar-track {
        background: #fff;
    }

    & *::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.grey4};
    }
`;

export const TableWrapper = styled.div`
    font-size: 14px;
    // Affects the scrolling of the page
    height: calc(100vh - 205px);
    background-image: linear-gradient(
          45deg
          , #ffffff 38.89%, #EEEEF2 38.89%, #EEEEF2 50%, #ffffff 50%, #ffffff 88.89%, #EEEEF2 88.89%, #EEEEF2 100%);
    background-size: 10px 10px;
    overflow: auto;

    // hack to keep header sticky without sideeffects and prevent scroll to top on row check
    > div > div > div > div:not([class^="popup"]), > div > div > div:not([class^="popup"]), > div > div:not([class^="popup"]) {
      ${props => !props.isLoading ? `
        overflow: unset !important
        ` : `
        overflow: hidden;
        position: fixed;
      `};
    }

    .MuiCircularProgress-root {
      position: absolute;
      top: calc(50% - 120px);
      left: calc(50% - 105px);
    }
  
    td .table-minimize-value {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: '';
        -webkit-line-clamp: ${(props) => props.rowCount};
        -webkit-box-orient: vertical;

        div {
            display: inline;
        }
    }

    tr,
    td {
        border: none;
    }

    tbody {
        tr {
          background: #fff;
          
          :nth-child(2n) {
            background: ${(props) => props.theme.colors.grey8};
          }
        }
    }

    td {
        padding: 5px 16px;
        vertical-align: top;
        font-size: 12px;

        svg {
            display: none;
        }
    }

    thead th {
        border-right: 1px solid ${(props) => props.theme.colors.grey5};

        svg {
            cursor: pointer;
        }

        div {
            font-family: 'EYInterstate Bold', sans-serif;
            font-weight: bold;
        }
    }

    .MuiTableRow-root.MuiTableRow-hover:hover {
      background-color: rgb(245 245 245); 
    }

    .MuiTouchRipple-root {
        display: none;
    }

    .MuiIconButton-colorSecondary:hover {
        background-color: inherit !important;
    }

    .MuiTableRow-head {
        input {
            opacity: 0 !important;
        }
    }

    table {
        cursor: default;
      
        tbody tr:only-child td:only-child {
          border-bottom: 0;
        }

        tbody input {
            position: relative;
            opacity: 1;

            &:after {
                display: block;
                content: '';
                position: absolute;
                left: -7px;
                top: -9px;
                width: 20px;
                height: 20px;
                background-color: ${(props) => props.theme.colors.grey3};
                cursor: pointer;
            }

            &:checked:before {
                display: block;
                content: '';
                position: absolute;
                left: -7px;
                top: -9px;
                width: 20px;
                height: 20px;
                background-color: ${(props) => props.theme.colors.yellow};
                cursor: pointer;
            }

            &:checked:after {
                content: '';
                position: absolute;
                display: block;
                left: -1px;
                top: -6px;
                width: 5px;
                height: 10px;
                border: solid ${(props) => props.theme.colors.black};
                background-color: transparent;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
    }
`;

export const ControlWrapper = styled.div`
    margin: 0;
`;

export const TopBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SecondBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
`;

export const PanelWrapper = styled.div`
    display: flex;
    align-items: center;

    & > * {
        margin-left: 15px;
    }
`;

export const Header = styled.h1`
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize.fontSize};
`;

export const SearchInput = styled(CustomSearchBox)`
    width: 250px;
`;
