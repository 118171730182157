import React, {useState, useEffect} from 'react';
import {ExitIcon, RollBackIcon, TasksQueueIcon} from '../Design/Icons/IconsOther';
import LanguageSelector from '../../layouts/root-layout/LanguageSelector';
import {doLogout} from '../../store/login/action';
import {useDispatch, useSelector} from 'react-redux';
import {IAppProperties} from '../../store/appProperties/types';
import {
    ControlPanelWrapper,
    ContainerWrapper,
    IconWrapper,
    TaskStatsChip,
    TaskStatsChipContainer,
    UserImage,
    UserInfoWrapper,
    UserInfo,
    UserMenu,
    UserName,
    BottomContainer,
    LogoutText,
    SidebarControl,
    ControlContainer,
    TopContainer,
    UserDescription,
    UserStatus,
    MiddleContainer,
    VersionBlock,
    UserLogo, UserLogoWrapper
} from './sidebar.styles';
import {useTranslation} from 'react-i18next';
import {RolesIcon} from '../Design/Icons/IconsSidebar';
import {useHistory} from 'react-router';
import releaseData from '../../release.json';
import {ApplicationState} from "../../store";
import {ITasksState} from "../../store/tasks/types";
import {getTasksStatsRequest} from "../../store/tasks/actions";

type ControlPanelProps = {
    isBigSidebar: boolean;
    appProps: IAppProperties;
    userName: string;
    sidebarHandler: () => void;
    handleDrawerOpen: () => void;
    isTasksQueueOpen?: boolean;
};

const TaskStatsChips = () => {
    const dispatch = useDispatch();

    const tasksState = useSelector<ApplicationState, ITasksState>((store) => store.tasks);
    const tasksStateStatuses = tasksState.selected_statuses;
    const tasksStateTypes = tasksState.selected_types;
    const tasksStateStats = tasksState.tasks_by_status;
    const tasksStateStatusTable = tasksState.task_status_table;

    const newTasksId = [...tasksStateStatusTable.values()].find(stat => stat.alias === 'NEW')?.id;
    const inProgressTasksId = [...tasksStateStatusTable.values()].find(stat => stat.alias === 'IN_PROGRESS')?.id;

    useEffect(() => {
        dispatch(getTasksStatsRequest(tasksStateStatuses, tasksStateTypes, null))
        //eslint-disable-next-line
    }, [])

    return tasksStateStats && (
        <TaskStatsChipContainer>
            {/*Pending count*/}
            {newTasksId && <TaskStatsChip>{tasksStateStats[newTasksId]}</TaskStatsChip>}
            {/*In progress tasks */}
            {inProgressTasksId &&
                <TaskStatsChip highlight={tasksStateStats[inProgressTasksId] > 0}>
                    {tasksStateStats[inProgressTasksId]}
                </TaskStatsChip>
            }
        </TaskStatsChipContainer>
    )
}

export const ControlPanel: React.FC<ControlPanelProps> = ({
                                                              isBigSidebar,
                                                              handleDrawerOpen,
                                                              appProps,
                                                              userName,
                                                              sidebarHandler,
                                                              isTasksQueueOpen
                                                          }) => {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [isShowUserMenu, setIsShowUserMenu] = useState(false);

    useEffect(() => {
        const handleClickOutside = (e) => {
            const UserInfoWrapper = document.getElementById('user-info');
            if (!e.path.includes(UserInfoWrapper)) {
                setIsShowUserMenu(false);
            }
        };
        document.addEventListener('click', handleClickOutside, false);
        return () => document.removeEventListener('click', handleClickOutside, false);
    }, []);

    const userMenuHandler = () => setIsShowUserMenu((isShow) => !isShow);
    const goToRoles = () => history.push(`/roles`);
    const onLogout = () => dispatch(doLogout());

    return (
        <ControlPanelWrapper id="user-info">
            <IconWrapper isActive={isBigSidebar} onClick={sidebarHandler}>
                <RollBackIcon/>
            </IconWrapper>
            <SidebarControl isBigSidebar={isBigSidebar}>
                <ControlContainer
                    isBigSidebar={isBigSidebar}
                    onClick={goToRoles}
                    data-tip={t('pages:Roles', 'Управление ролями')}
                >
                    <RolesIcon/>
                </ControlContainer>
                <ControlContainer
                    isBigSidebar={isBigSidebar}
                    onClick={handleDrawerOpen}
                    isActive={isTasksQueueOpen}
                    data-tip={t('components:headers.TaskQueue', 'Список активных задач')}
                >
                    <TasksQueueIcon/>
                    <TaskStatsChips/>
                </ControlContainer>
            </SidebarControl>
            <UserInfoWrapper>
                <UserInfo onClick={userMenuHandler}>
                    <UserImage>{userName?.substr(0, 1).toUpperCase()}</UserImage>
                    {isBigSidebar && <UserName>{userName}</UserName>}
                </UserInfo>
                <UserMenu isBig={isBigSidebar} isShow={isShowUserMenu}>
                    <UserLogoWrapper>
                        <UserLogo/>
                    </UserLogoWrapper>
                    <ContainerWrapper>
                        <TopContainer>
                            <UserImage>{userName?.substr(0, 1).toUpperCase()}</UserImage>
                            <UserDescription>
                                {userName && <UserName>{userName}</UserName>}
                                <UserStatus>{t('components:controlPanel.online', 'онлайн')}</UserStatus>
                            </UserDescription>
                        </TopContainer>
                        <MiddleContainer>
                            {/*<span>История действий</span>*/}
                            {Boolean(appProps?.version_back) &&
                            <VersionBlock><span>{t('components:controlPanel.system_version', 'Версия системы')}:</span>
                                <span>{appProps.version_back}</span></VersionBlock>}
                            {Boolean(appProps?.version) &&
                            <VersionBlock><span>{t('components:controlPanel.model_version', 'Версия модели')}:</span>
                                <span>{appProps.version}</span></VersionBlock>}
                            {Boolean(releaseData) &&
                            <VersionBlock><span>{t('components:controlPanel.ui_version', 'Версия интерфейса')}:</span>
                                <span>{releaseData.frontendVersion}</span></VersionBlock>}
                            {/*<a onClick={goToRoles}>{t('pages:Roles', 'Управление ролями')}</a>*/}
                            {/*<span>Приватность</span>*/}
                        </MiddleContainer>
                        <BottomContainer>
                            {appProps.auth_enabled &&
                            <LogoutText onClick={onLogout}>
                                {t('pages:Logout', 'Выйти')}
                                <ExitIcon/>
                            </LogoutText>
                            }
                            {Boolean(appProps?.enable_multilang) && <LanguageSelector/>}
                        </BottomContainer>
                    </ContainerWrapper>
                </UserMenu>
            </UserInfoWrapper>
        </ControlPanelWrapper>
    );
};
