import axios from '../../axiosMiddleware';
import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import i18n from '../../i18n';

import { StimulationActionType } from './types';
import {analysisSuccess, analysisError, analysisDeletionSuccess} from './actions';

import { getConfig } from '../../config-provider';
import { requestSnackDisplay } from '../snackbars/actions';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleAnalysisRequest(action) {
    const url = `${API_ENDPOINT}/reporting/create-report/full_kpi_report`;
    const body = {
        first_group_version_ids: action.payload.group1,
        second_group_version_ids: action.payload.group2,
        first_group_name: action.payload.group1Name,
        second_group_name: action.payload.group2Name
    }
    if (!action.payload.generate_name) {
        body['name'] = action.payload.name;
        body['generate_name'] = action.payload.generate_name;
    }
    
    try {
        const res = yield call(axios.post, url, body);
        res.error ? yield put(requestSnackDisplay('error', `${i18n.t('notifications:reports.error')} ${res.error}`)) :
            yield put(requestSnackDisplay('success', i18n.t('notifications:reports.started')));
    } catch (e) {
        if (e instanceof Error) {
            yield put(requestSnackDisplay('error', `${i18n.t('notifications:reports.error')} ${e}`));
        } else {
            yield put(requestSnackDisplay('error', i18n.t('notifications:reports.error')));
        }
    }
}

function* handleAnalysisDeletionRequest(action) {
    const url = `${API_ENDPOINT}/reporting/delete-report/full_kpi_report`;

    const body = { ids: action.payload.ids }

    try {
        const res = yield call(axios.post, url, body);
        res.error ? yield put(requestSnackDisplay('error', `${i18n.t('notifications:reports.error')} ${res.error}`)) :
        yield put(analysisDeletionSuccess());
        yield put(requestSnackDisplay('success', i18n.t('notifications:report_successfully_deleted')));
    } catch (e) {
        if (e instanceof Error) {
            yield put(requestSnackDisplay('error', `${i18n.t('notifications:reports.error')} ${e}`));
        } else {
            yield put(requestSnackDisplay('error', i18n.t('notifications:reports.error')));
        }
    }
}

function* handleAnalysisLegacyRequest(action) {
    const url = `${API_ENDPOINT}/reporting/create-report/full_kpi_report_legacy`;
    const body = {
        first_group_version_ids: action.payload.group1,
        second_group_version_ids: action.payload.group2
    }
    try {
        const res = yield call(axios.post, url, body);
        res.error ? yield put(analysisError(res.error)) :
            yield put(analysisSuccess(res.data))
    } catch (e) {
        if (e instanceof Error) {
            yield put(analysisError(e));
        } else {
            yield put(analysisError('An unknown error occured.'));
        }
    }
}

function* watchAnalysisRequest() {
    yield takeLatest(StimulationActionType.ANALYSIS_REQUEST, handleAnalysisRequest);
}

function* watchAnalysisDeletionRequest() {
    yield takeLatest(StimulationActionType.ANALYSIS_DELETION_REQUEST, handleAnalysisDeletionRequest);
}

function* watchAnalysisLegacyRequest() {
    yield takeLatest(StimulationActionType.ANALYSIS_LEGACY_REQUEST, handleAnalysisLegacyRequest);
}

export function* stimulationAnalysisSaga() {
    yield all([
        fork(watchAnalysisLegacyRequest),
        fork(watchAnalysisRequest),
        fork(watchAnalysisDeletionRequest)
    ])
}