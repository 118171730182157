import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from '../Popup/Popup';
import { Button } from '../Design/Button';
import { PopupItem, ActionButtonWrapper } from '../Popup/popup.styles';


const titles = {
    taxModesFilter: 'components:delete_selected_tax_modes',
    macroScenariosFilter: 'components:delete_selected_macro_scenarios',
    emissionScenariosFilter: 'components:delete_selected_esg_scenarios',
    costsScenariosFilter: 'components:delete_selected_costs_scenarios'
};

interface PassedProps {
    dialogState;
    handleClose;
    handleSubmit;
    filterName;
}

type ScenariosDeleteDialogProps = PassedProps;

export const ScenariosDeleteDialog: React.FC<ScenariosDeleteDialogProps> = ({ 
    dialogState, 
    handleClose, 
    handleSubmit, 
    filterName 
}) => {
    const { t } = useTranslation(['commons', 'components']);
    const { selection } = dialogState;
    const title = t(titles[filterName], {count: selection.length});

    return (
        <>
            <Popup handleClosePopup={handleClose} header={title}>
                <PopupItem>
                    <ActionButtonWrapper>
                        <Button outline onClick={handleClose} label={t('commons:cancel')} />
                        <Button onClick={() => handleSubmit(selection, false)} label={t('commons:delete')} />
                    </ActionButtonWrapper>
                </PopupItem>
            </Popup>
        </>
    );
};
