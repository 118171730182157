import React from 'react';
import {v4 as uuid4} from 'uuid';
import classNames from 'classnames';

export interface IRadioGroupOption {
    key: any;
    value: any;
    disabled: boolean;
}

export interface IRadioGroupProps {
    options: IRadioGroupOption[];
    selectedOptionKey?: any;
    onChange?: ({key: any}) => any;
}

const RadioGroup: React.FC<IRadioGroupProps> = React.memo(({options, selectedOptionKey, onChange}) => {
    const radioGroupName = `radio-group-${uuid4()}`;

    const onRadioButtonChange = (key) => {
        if (onChange) {
            onChange({key});
        }
    };

    const isSelected = (key) => key === selectedOptionKey;

    return (
        <div className="ey-radio-group">
            {options.map((option) => (
                <label
                    key={option.key}
                    className={classNames(
                        'radio-label',
                        {selected: isSelected(option.key)},
                        {inactive: option.disabled}
                    )}
                >
                    <div className="radio-content">{option.value}</div>
                    <input
                        name={radioGroupName}
                        type="radio"
                        value={option.key}
                        disabled={option.disabled}
                        checked={isSelected(option.key)}
                        onChange={() => onRadioButtonChange(option.key)}
                    />
                </label>
            ))}
        </div>
    );
});

export default RadioGroup;
