import {IndicatorTreeActionType, IndicatorTreeState} from './types'

export const initialState: IndicatorTreeState = {
    data: [],
    errors: undefined,
    loading: false,
    includeEmpty: false,
    selectedIndicators: new Map<string, string>(),
    currentIndicator: null,
    currentIndicatorId: null
};

function addInd(state: Map<string, string>, indicator, title) {
    return new Map(state.set(indicator, title))

}

function deleteInd(state: Map<string, string>, indicator) {
    state.delete(indicator);
    return new Map(state.entries())
}


function createTreeWithNameData(tree = '') {
    
    return (state = initialState, action) => {

        const { treeName } = action.payload ? action.payload : '';
        if (treeName !== tree) return state

        switch (action.type) {
            case IndicatorTreeActionType.FETCH_REQUEST: {
                return {...state, loading: true, includeEmpty: action.payload.includeEmpty}
            }
            case IndicatorTreeActionType.FETCH_SUCCESS: {
                return {...state, loading: false, data: action.payload.data}
            }
            case IndicatorTreeActionType.FETCH_ERROR: {
                return {...state, loading: false, errors: action.payload.data}
            }
            case IndicatorTreeActionType.ADD_INDICATOR: {
                return {
                    ...state,
                    selectedIndicators: addInd(state.selectedIndicators, action.payload.indicator, action.payload.title)
                }
            }
            case IndicatorTreeActionType.DELETE_INDICATOR: {
                return {
                    ...state,
                    selectedIndicators: deleteInd(state.selectedIndicators, action.payload.indicator)
                }
            }
            case IndicatorTreeActionType.RESELECT: {
                return {...state, selectedIndicators: action.payload.selection}
            }
            case IndicatorTreeActionType.SET_INCLUDE_EMPTY_INDICATORS: {
                return {...state, includeEmpty: !action.payload.includeEmpty}
            }
            default: {
                return state
            }
        }
    };
}

export {createTreeWithNameData}
