import {all, fork, put, takeLatest} from 'redux-saga/effects';

import {ImportActionType} from './importer/types';
import {CalcJobActionType, ICalcJob} from './calcJob/types';
import {addCalcJob} from './calcJob/actions';
import {addCalcVersion, deleteCalcVersionByJobId} from './calcVersion/actions';

function* handleCalcJobUpdate(action) {
    const calcJob = action.payload.calcJob as ICalcJob;
    if (calcJob.status === 'SUCCESS') {
        yield put(addCalcVersion(calcJob.calc_version));
    }
}

function* watchCalcJobUpdate() {
    yield takeLatest(CalcJobActionType.UPDATE_CALC_JOB, handleCalcJobUpdate);
}

function* handleAddJobOnImportSuccess(action) {
    if (action.payload.type === 'calcJob') {
        const calcJob = action.payload.job as ICalcJob;
        yield put(addCalcJob(calcJob));
    }
}

function* watchImportSuccess() {
    yield takeLatest(ImportActionType.IMPORT_SUCCESS, handleAddJobOnImportSuccess);
}

function* handleDeleteCalcJobAndCalcVersion(action) {
    const {id, delete_version} = action.payload;
    if (delete_version) {
        yield put(deleteCalcVersionByJobId(id));
    }
}

function* watchDeleteCalcJobAndCalcVersion() {
    yield takeLatest(CalcJobActionType.DELETE_CALC_JOB, handleDeleteCalcJobAndCalcVersion);
}

function* topLevelSagas() {
    yield all([
        fork(watchImportSuccess),
        fork(watchCalcJobUpdate),
        fork(watchDeleteCalcJobAndCalcVersion)
    ]);
}

export default topLevelSagas;
