import {Reducer} from 'redux';
import {ScenarioActionType, IScenarioState} from './types';

export const initialState: IScenarioState = {
    scenario: {
        name: '',
        description: '',
        exch_usd_rub_max: null,
        exch_usd_rub_min: null,
        indicator_tables: [],
        macro_urals_price_max: null,
        macro_urals_price_min: null,
    },
    loading: false,
    error: null
};

const reducer: Reducer<IScenarioState> = (state = initialState, action): IScenarioState => {
    switch (action.type) {
        case ScenarioActionType.GET_SCENARIO_DETAILS_REQUEST: {
            return {
                ...state,
                loading: true,
                scenario: initialState.scenario
            };
        }
        case ScenarioActionType.GET_SCENARIO_DETAILS_SUCCESS: {
            return {
                ...state, 
                loading: false,
                scenario: { 
                    ...action.payload.scenario 
                }
            };
        }
        case ScenarioActionType.GET_SCENARIO_DETAILS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        }
        case ScenarioActionType.EDIT_SCENARIO_NAME_REQUEST: {
            return {
                ...state,
                loading: true
            };
        }
        case ScenarioActionType.EDIT_SCENARIO_NAME_SUCCESS: {
            return {
                ...state,
                loading: false,
                scenario: {
                    ...state.scenario, 
                    name: action.payload.name
                }
            };
        }
        case ScenarioActionType.EDIT_SCENARIO_NAME_ERROR: {
            return {
                ...state,
                loading: false, 
                error: action.payload
            };
        }

        case ScenarioActionType.EDIT_SCENARIO_DESCRIPTION_SUCCESS: {
            return {
                ...state,
                loading: true,
                scenario: {
                    ...state.scenario, 
                    description: action.payload.description
                }
            };
        }
        case ScenarioActionType.EDIT_SCENARIO_DESCRIPTION_ERROR: {
            return {
                ...state,
                loading: false, 
                error: action.payload
            };
        }

        default: {
            return state;
        }

    }
};

export {reducer as scenarioReducer};