import React from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js-basic-dist-min';
import isEqual from 'lodash/isEqual';

const Plot = createPlotlyComponent(Plotly);

const MacroDescPlot = (props) => {
    const { plotlyChart } = props;

    return (
        <Plot
            style={{width: '100%', height: '100%'}}
            data={[plotlyChart.data]}
            layout={plotlyChart.layout}
            useResizeHandler={true}
        />
    )
}

export default React.memo(MacroDescPlot, (prev, next) => isEqual(prev, next));