import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import axios from '../../axiosMiddleware';

import {WellsActionType} from './types'
import {getWellPlotError, getWellPlotSuccess} from './actions'
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleWellPlotRequest(action) {
    const {versionId, well} = action.payload;
    try {
        const res = yield call(axios.get, `${API_ENDPOINT}/wells/${versionId}/plot/`, {params: {well}});
        if (res.error) {
            yield put(getWellPlotError(res.error))
        } else {
            yield put(getWellPlotSuccess(well, res.data.data))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getWellPlotError(err.stack!))
        } else {
            yield put(getWellPlotError('An unknown error occured.'))
        }
    }
}

function* watchWellPlotRequest() {
    yield takeLatest(WellsActionType.GET_WELL_PLOT_REQUEST, handleWellPlotRequest)
}

export function* wellPlotSaga() {
    yield all([fork(watchWellPlotRequest)])
}
