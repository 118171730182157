import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import queryString from 'query-string';
import axios from '../../../axiosMiddleware';

import {IndicatorTreeActionType} from './types'
import {fetchError, fetchSuccess} from './actions'
import {getConfig} from '../../../config-provider';

const API_ENDPOINT = `${getConfig('REACT_APP_API_ENDPOINT')}/references`;

function* handleFetch(action) {
    //yield delay(400);

    try {
        // debugger
        const query = {
            tree_name: action.payload.treeName,
            include_empty: action.payload.includeEmpty
        };

        const versionIds = action.payload.versionIds;
        if (versionIds) {
            query['version_ids'] = versionIds;
        }

        const qs = queryString.stringify(query);
        
        const res = yield call(axios.get, `${API_ENDPOINT}/indicator-tree?${qs}`);

        if (res.error) {
            yield put(fetchError(res.error))
        } else {
            yield put(fetchSuccess(res.data.data, res.data.treeName))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!))
        } else {
            yield put(fetchError('An unknown error occured.'))
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
    yield takeEvery(IndicatorTreeActionType.FETCH_REQUEST, handleFetch)
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* indicatorTreeSaga() {
    yield all([fork(watchFetchRequest)])
}

export default indicatorTreeSaga
