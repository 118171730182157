import {
    createMinimizeCell,
    formatTooltip,
    createArrayDataForCell,
    createCellFromArray,
} from '../../utils/materialTable/createTable';

export const CreateColumnsCosts = (t, tags, columns) => {
    const taxModeTags = tags.filter((tag) => ['Налоговый режим', 'Tax regime'].includes(tag.name.split(':')[0]));
    const taxModeIds = taxModeTags.map((tag) => tag.id);
    const otherTags = tags.filter((tag) => ![...taxModeIds].includes(tag.id));

    return [
        {
            title: t('commons:name', 'Название'),
            field: 'name',
            filtering: true,
            sorting: true,
            editable: 'never' as 'never',
            hidden: !columns.map((col) => col.name).includes('name'),
            render: (rowData) => {
                return createMinimizeCell(rowData.name);
            },
        },
        {
            title: t('commons:description', 'Описание'),
            field: 'description',
            filtering: false,
            editable: 'never' as 'never',
            hidden: !columns.map((col) => col.name).includes('description'),
            render: (rowData) => {
                return createMinimizeCell(rowData.description);
            },
        },
        {
            title: t('commons:tags', 'Атрибуты'),
            field: 'tags',
            filtering: true,
            hidden: !columns.map((col) => col.name).includes('tags'),
            render: (rowData) => {
                return createArrayDataForCell(rowData.tags, otherTags, false, 'name_short');
            },
        },
        {
            title: t('commons:included_indicators', 'Содержит показатели'),
            field: 'indicators',
            filtering: true,
            hidden: !columns.map((col) => col.name).includes('included_indicators'),
            cellStyle: {width: '500px'},
            render: (rowData) => {
                const {tooltip, data} = createCellFromArray(rowData.indicators);
                return createMinimizeCell(data, formatTooltip(tooltip));
            },
        },
        {
            title: t('commons:creation_date', 'Создано'),
            field: 'created_at',
            filtering: true,
            sorting: true,
            editable: 'never' as 'never',
            hidden: !columns.map((col) => col.name).includes('creation_date'),
            render: (rowData) => {
                return createMinimizeCell(rowData.created_at);
            },
        },
        {
            title: t('commons:status', 'Статус'),
            field: 'status',
            editable: 'never' as 'never',
            filtering: true,
            hidden: !columns.map((col) => col.name).includes('status'),
            render: (rowData) => {
                return createMinimizeCell(rowData.status);
            },
        },
    ];
};
