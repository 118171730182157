import {Reducer} from 'redux';
import {FilterFieldVariantsType, IFilterVariantsState} from './types';

export const initialState: IFilterVariantsState = {
    listFiltersBuffer: {},
    listFilters: {},
    loading: false,
    loadingList: false,
    error: null,
};

export const filterVariantsReducer: Reducer<IFilterVariantsState> = (
    state = initialState,
    action
): IFilterVariantsState => {
    switch (action.type) {
        case FilterFieldVariantsType.FILTER_FIELD_REQUEST: {
            return {...state, loading: true, error: null};
        }
        case FilterFieldVariantsType.FILTER_FIELD_SUCCESS: {
            const currentField = Object.keys(action.payload)[0];
            const existingValues = currentField in state.listFilters ? state.listFilters[currentField] : [];
            const newValues = action.payload[currentField];

            const result = [...newValues, ...existingValues].reduce((result, item) => {

                const foundValue = result.find(element => element.originalValue === item.originalValue);

                if (!foundValue) {
                    result.push(item);
                }

                return result
            }, []);
            
            return {
                ...state,
                loading: false,
                listFiltersBuffer: {...state.listFilters, ...{[currentField]: result}},
                listFilters: {...state.listFilters, ...action.payload},
                error: null,
            };
        }
        case FilterFieldVariantsType.FILTER_ADD_LIST_VARIANTS_REQUEST: {
            return {...state, loadingList: true, error: null};
        }
        case FilterFieldVariantsType.FILTER_ADD_LIST_VARIANTS_SUCCESS: {
            return {...state, loadingList: false, listFilters: {...state.listFilters, ...action.payload}, error: null};
        }
        case FilterFieldVariantsType.FILTER_FIELD_CLEAR: {
            return {...state, loading: false, listFilters: {}, error: null};
        }
        case FilterFieldVariantsType.FILTER_FIELD_ERROR: {
            return {...state, loading: false, listFilters: {}, error: action.payload};
        }
        default: {
            return state;
        }
    }
};
