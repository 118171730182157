import { action } from 'typesafe-actions';
import { VersionCardActionType } from "./types";

export const versionCardRequest = (id: number) =>
    action(VersionCardActionType.VERSION_CARD_REQUEST, { id });

export const versionCardSuccess = (data: any) =>
    action(VersionCardActionType.VERSION_CARD_SUCCESS, { data });

export const versionCardError = (error: any) =>
    action(VersionCardActionType.VERSION_CARD_ERROR, { error });
