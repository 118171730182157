export enum LanguageActionType {
    SET_USER_LANGUAGE_REQUEST = '@@language/SET_USER_LANGUAGE_REQUEST',
    GET_USER_LANGUAGE_REQUEST = '@@language/GET_USER_LANGUAGE_REQUEST',

    SET_LANGUAGE_ERROR = '@@language/SET_LANGUAGE_ERROR',
    SET_LANGUAGE_SUCCESS = '@@language/SET_LANGUAGE_SUCCESS',

    GET_LANGUAGE_ERROR = '@@language/GET_LANGUAGE_ERROR',
    GET_LANGUAGE_SUCCESS = '@@language/GET_LANGUAGE_SUCCESS',
}

export interface IUserLanguageState {
    readonly language: 'ru' | 'en' | null;
    readonly error?: any;
    readonly loading: boolean;
}
