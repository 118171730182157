import { END, eventChannel } from 'redux-saga';
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import axios from '../../axiosMiddleware';

import { ImportActionType } from './types'
import { importError, importProgress, importSuccess, } from './actions'
import { getConfig } from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');


// TODO: Удалить при переходе на единый API-метод для всех загрузок
const API_ENDPOINT_BY_IMPORTER_ID = {
    'calcJob': {
        url: `${API_ENDPOINT}/importer/upload`,
        method: 'POST'
    },
    'upliftLiquidLimitation': {
        url: `${API_ENDPOINT}/uplift/upload-liquid-prod-limit`,
        method: 'POST'
    },
    'scenario': {
        url: `${API_ENDPOINT}/scenario/import`,
        method: 'POST',
    },
    'scenario_macro': {
        url: `${API_ENDPOINT}/scenario/macro/`,
        method: 'POST',
    },

};

function doUpload(importerId: string, file: File, onUploadProgress: (p: ProgressEvent) => any) {
    const data = new FormData();
    data.append('file', file);
    return axios({
        ...API_ENDPOINT_BY_IMPORTER_ID[importerId],
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress,
    });
}

function createUploader(importerId: string, file: File) {
    let emit;
    const channel = eventChannel((emitter) => {
        emit = emitter;
        return () => {
        };
    });
    const uploadProgressCallback = ({ total, loaded }) => {
        const percentage = Math.round((loaded * 100) / total);
        emit({ importerId, percentage });
        if (percentage === 100) emit(END);
    };
    const promise = doUpload(importerId, file, uploadProgressCallback);
    return [promise, channel];
}

function* handleUploadRequest(request) {
    const importerId = request.payload.id as string;
    const file = request.payload.file as File;
    try {
        const [promise, channel] = yield call(createUploader, importerId, file);
        yield fork(watchUploadProgress, channel);
        const res = yield call(() => promise);
        if (res.error) {
            yield put(importError(importerId, res.error.message))
        } else {
            yield put(importSuccess(importerId, res.data))
        }
    } catch (err) {
        if (err.response) {
            if (!err.response.data.error && !err.response.data.detail) yield put(importError(importerId, err.response.statusText))
            const message = err.response.data.detail ? err.response.data.detail : err.response.data.error.message;
            yield put(importError(importerId, message))
        } else if (err instanceof Error) {
            yield put(importError(importerId, err.stack!))
        } else {
            yield put(importError(importerId, 'An unknown error occurred.'))
        }
    }
}

function* watchUploadProgress(channel) {
    while (true) {
        const { importerId, percentage } = yield take(channel);
        yield put(importProgress(importerId, percentage));
    }
}

function* watchUpload() {
    yield takeLatest(ImportActionType.IMPORT_REQUEST, handleUploadRequest)
}

function* importerSaga() {
    yield all([fork(watchUpload)])
}

export default importerSaga
