import { Reducer } from 'redux'
import { ImporterCollectionState, ImportActionType, ImporterState } from './types'

const importerInitialState: ImporterState = {
    loading: false,
    progress: null,
    success: null,
    error: null,
};

export const initialState: ImporterCollectionState = {
    importers: {}
};

export const importerReducer: Reducer<ImporterCollectionState> = (state = initialState, action) => {
    switch (action.type) {
        case ImportActionType.IMPORT_INIT: {
            return {
                importers: {
                    ...state.importers,
                    [action.payload.id]: {
                        ...importerInitialState,
                    }
                }
            }
        }
        case ImportActionType.IMPORT_REQUEST: {
            return {
                importers: {
                    ...state.importers,
                    [action.payload.id]: {
                        ...state.importers[action.payload.id],
                        loading: true,
                    }
                }
            }
        }
        case ImportActionType.IMPORT_PROGRESS: {
            return {
                importers: {
                    ...state.importers,
                    [action.payload.id]: {
                        ...state.importers[action.payload.id],
                        progress: action.payload.progress,
                    }
                }
            }
        }
        case ImportActionType.IMPORT_SUCCESS: {
            return {
                importers: {
                    ...state.importers,
                    [action.payload.id]: {
                        ...state.importers[action.payload.id],
                        success: true,
                        loading: false,
                    }
                }
            }
        }
        case ImportActionType.IMPORT_ERROR: {
            return {
                importers: {
                    ...state.importers,
                    [action.payload.id]: {
                        ...state.importers[action.payload.id],
                        success: false,
                        loading: false,
                        error: action.payload.error,
                    }
                }
            }
        }
        case ImportActionType.IMPORT_RESET: {
            return {
                importers: {
                    ...state.importers,
                    [action.payload.id]: {
                        ...importerInitialState,
                    }
                }
            }
        }
        default: {
            return state
        }
    }
};
