import React from 'react';
import { default as moment, Moment } from 'moment';
import {getConfig} from "../config-provider";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { EmptyTableMessageWrapper } from '../theme/main.styles';

export const formatDateTime = (
        value: string | Moment | Date | null, 
        inputFormat = null, 
        outputFormat = 'DD.MM.YYYY HH:mm:ss'
    ) => {

    if (value == null) {
        return '-';
    }

    if (inputFormat) {
        // Fallback фикс для строк в формате отличном от UTC 
        return moment(value, inputFormat).format(outputFormat);
    }

    return moment
        .utc(value)
        .local()
        //.add(3, 'h')
        .format(outputFormat);
};

export const formatFloat = (value: number | null) => {
    if (value == null) {
        return '-';
    }
    return value.toString().replace('.', ',');
};

export const floatFormat = (value: number | null, decimals = null, show_zero = true) => {
    if (value == null) {
        return '-';
    }
    if (decimals === null) {
        if (String(value).includes('.')) {
            decimals = String(value).split('.')[1].length;
        }
    }
    let formattedValue = String(Math.abs(value).toFixed(decimals));
    if (parseFloat(formattedValue) === 0 && !show_zero) {
        return '-'
    }
    if (value < 0) {
        formattedValue = `(${formattedValue})`;
    }
    formattedValue = formattedValue.replace('.', ',');
    return formattedValue;
};

export const gridHeaderTranslation = new Map([
        ["PROD_SITE", "Объект"],
        ["OIL_FIELD", "МР"],
        ["WELL_TYPE", "Тип скважины"],
        ["WELL", "Скважина"],
        ["LAYER", "Залежь"],
        ["LICENSED_SITE", "ЛУ"]
    ]
);

export const versionTableLocalization = {
    body: {
        emptyDataSourceMessage: (<EmptyTableMessageWrapper>Нет версий для заданных критериев поиска</EmptyTableMessageWrapper>),
        editTooltip: 'Редактировать',
        editRow: {
            cancelTooltip: 'Отменить',
            saveTooltip: 'Сохранить'
        },
        filterRow: {
            filterTooltip: 'Поиск на странице'
        }
    },
    header: {
        actions: ''
    },
    toolbar: {
        searchPlaceholder: 'Поиск на странице',
        searchTooltip: 'Поиск',
        nRowsSelected: 'Выбрано версий : {0}'
    }
};

export const tableEmptyMessageEn = {
    body: {
        emptyDataSourceMessage: <EmptyTableMessageWrapper>No records to display</EmptyTableMessageWrapper>
    }
}

export const taxModesTableLocalization = {
    body: {
        emptyDataSourceMessage: (<EmptyTableMessageWrapper>Нет налоговых режимов для заданных критериев поиска</EmptyTableMessageWrapper>),
        editTooltip: 'Редактировать',
        editRow: {
            cancelTooltip: 'Отменить',
            saveTooltip: 'Сохранить'
        },
        filterRow: {
            filterTooltip: 'Поиск на странице'
        }
    },
    header: {
        actions: ''
    },
    toolbar: {
        searchPlaceholder: 'Поиск на странице',
        searchTooltip: 'Поиск',
        nRowsSelected: 'Выбрано налоговых режимов : {0}'
    }
};

export const macroScenariosLocalization = {
    body: {
        emptyDataSourceMessage: (<EmptyTableMessageWrapper>Нет макро сценариев для заданных критериев поиска</EmptyTableMessageWrapper>),
    },
    header: {
        actions: ''
    },
    toolbar: {
        searchTooltip: 'Поиск',
        nRowsSelected: 'Выбрано макро сценариев: {0}'
    }
};

export const emissionScenariosLocalization = {
    body: {
        emptyDataSourceMessage: (<EmptyTableMessageWrapper>Нет углеродных сценариев для заданных критериев поиска</EmptyTableMessageWrapper>),
    },
    header: {
        actions: ''
    },
    toolbar: {
        searchTooltip: 'Поиск',
        nRowsSelected: 'Выбрано углеродных сценариев: {0}'
    }
};

export const costsScenariosLocalization = {
    body: {
        emptyDataSourceMessage: (<EmptyTableMessageWrapper>Нет сценариев изменения затрат для заданных критериев поиска</EmptyTableMessageWrapper>),
    },
    header: {
        actions: ''
    },
    toolbar: {
        searchTooltip: 'Поиск',
        nRowsSelected: 'Выбрано сценариев изменения затрат: {0}'
    }
};

export const portfolioAnalysisTableLocalization = {
    body: {
        emptyDataSourceMessage: (<EmptyTableMessageWrapper>Нет данных для отображения</EmptyTableMessageWrapper>),
        editTooltip: 'Редактировать',
        editRow: {
            cancelTooltip: 'Отменить',
            saveTooltip: 'Сохранить'
        },
        filterRow: {
            filterTooltip: 'Поиск на странице'
        }
    },
    header: {
        actions: ''
    },
    toolbar: {
        searchPlaceholder: 'Поиск на странице',
        searchTooltip: 'Поиск',
        nRowsSelected: 'Выбрано : {0}'
    }
};

export const filesLocalization = {
    body: {
        emptyDataSourceMessage: (<EmptyTableMessageWrapper>Нет файлов для заданных критериев поиска</EmptyTableMessageWrapper>),
    },
    header: {
        actions: ''
    },
    toolbar: {
        searchTooltip: 'Поиск',
        nRowsSelected: 'Выбрано файлов: {0}'
    }
};


export const setLocalStorageItems = (appVersion) => {
    localStorage.setItem('lastUsedHost', getConfig('REACT_APP_API_ENDPOINT'));
    localStorage.setItem('appVersion', appVersion);
};

export const cleanInstall = (appVersion) => {
    localStorage.removeItem('filter_vFilter');
    localStorage.removeItem('filter_vFilterSelected');
    localStorage.removeItem('filter_costsCompFilter');
    localStorage.removeItem('filter_group1Filter');
    localStorage.removeItem('filter_group2Filter');
    localStorage.removeItem('filter_taxModesFilter');
    localStorage.removeItem('filter_macroScenariosFilter');
    localStorage.removeItem('filter_emissionScenariosFilter');
    localStorage.removeItem('filter_costsScenariosFilter');
    localStorage.removeItem('filter_portfolioAnalysisFilter');
    localStorage.removeItem('filter_reportFilter');
    localStorage.removeItem('filter_filesFilter');
    console.log('Storage cleared due to new app version or api endpoint change');
    setLocalStorageItems(appVersion)
};

export const clearFilters = () => {
    localStorage.removeItem('filter_vFilter');
    localStorage.removeItem('filter_vFilterSelected');
    localStorage.removeItem('filter_costsCompFilter');
    localStorage.removeItem('filter_group1Filter');
    localStorage.removeItem('filter_group2Filter');
    localStorage.removeItem('filter_taxModesFilter');
    localStorage.removeItem('filter_macroScenariosFilter');
    localStorage.removeItem('filter_emissionScenariosFilter');
    localStorage.removeItem('filter_costsScenariosFilter');
    localStorage.removeItem('filter_portfolioAnalysisFilter');
    localStorage.removeItem('filter_reportFilter');
    localStorage.removeItem('filter_filesFilter');
    console.log('Filter cleared due to data incompatibility');
};

export const clearScenarios = () => {
    localStorage.removeItem('tax_mode_VerboseIndex');
    localStorage.removeItem('tax_mode_InitTree');
    localStorage.removeItem('tax_mode_Editions');
    localStorage.removeItem('tax_mode_Details');
    localStorage.removeItem('tax_mode_Draft');

    localStorage.removeItem('esg_VerboseIndex');
    localStorage.removeItem('esg_InitTree');
    localStorage.removeItem('esg_Editions');
    localStorage.removeItem('esg_Details');
    localStorage.removeItem('esg_Draft');

    localStorage.removeItem('cost_VerboseIndex');
    localStorage.removeItem('cost_InitTree');
    localStorage.removeItem('cost_Editions');
    localStorage.removeItem('cost_Details');
    localStorage.removeItem('cost_Draft');
    console.log('Scenarios data cleared due to data incompatibility');
};

export const useTableStyles = makeStyles((theme: Theme) =>
    createStyles({
            root: {
                paddingRight: theme.spacing(1)
            },
            highlight:
                theme.palette.type === 'light'
                    ? {
                        color: theme.palette.secondary.main,
                        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                        '& .MuiTypography-h6': {fontSize: 16}
                    }
                    : {
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.secondary.dark,
                        '& .MuiTypography-h6': {fontSize: 16}
                    },

            spacer: {
                flex: '1 1 10%'
            },
            actions: {
                color: theme.palette.text.secondary,
            },
            title: {
                width: '50%'
            },
            searchField: {
                minWidth: 150,
                paddingLeft: theme.spacing(2)
            },
            formControlLabel: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
        }
    )
);
