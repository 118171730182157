import { createSelector } from 'reselect';
import { ApplicationState } from '../index';
import { formatDateTime } from '../../helpers/MyAwesomeLib';
import { ITaxMode, IMacroScenario, IEmissionScenario, ICostsScenario, IFile } from './types';
import { ICalcVersion } from '../calcVersion/types';

const vFilter = {
    asTree: (state: ApplicationState) => state.vFilter.asTree,
    treeData: (state: ApplicationState) => state.vFilter.treeData,
    pageData: (state: ApplicationState) => state.vFilter.pageData,
};

const taxModesFilter = {
    pageData: (state: ApplicationState) => state.taxModesFilter.pageData,
};

const macroScenarioFilter = {
    pageData: (state: ApplicationState) => state.macroScenariosFilter.pageData,
};

const emissionScenariosFilter = {
    pageData: (state: ApplicationState) => state.emissionScenariosFilter.pageData,
};

const costsScenariosFilter = {
    pageData: (state: ApplicationState) => state.costsScenariosFilter.pageData,
};

const portfolioAnalysisFilter = {
    pageData: (state: ApplicationState) => state.portfolioAnalysisFilter.pageData,
};

const filesFilter = {
    pageData: (state: ApplicationState) => state.filesFilter.pageData,
};

export const versionsRowsSelector = createSelector(
    [vFilter.asTree, vFilter.treeData, vFilter.pageData],
    (asTree, treeData, pageData) => {
        const array: ICalcVersion[] = asTree && treeData.length > 0 ? treeData : pageData;
        return array.map((v) => {
            return {
                id: v.id,
                release_tag: v.release_tag,
                name: v.name,
                created_at: formatDateTime(v.created_at),
                company: [...new Set(v.links.map((i) => i.company.name))],
                subsurface_user: [...new Set(v.links.map((i) => i.subsurface_user.name))],
                licensed_site: [...new Set(v.links.map((i) => i.licensed_site.name))],
                deposit: [...new Set(v.links.map((i) => i.deposit.name))],
                region: [...new Set(v.links.map((i) => i.licensed_site.region.name))],
                tax_regime: v.scenarios.filter((sc) => sc.type === 'TAX_MODE'),
                tax_regime_attr: v.tax_regime,
                macro: v.macro,
                iteration: v.iteration,
                wells_switch_off: v.wells_switch_off,
                tags: v.tags,
                percent_prod_profitable: v.percent_prod_profitable,
                version_groups: v.version_groups,
                user_name: v.user_name,
                has_anomaly_calculation: v.has_anomaly_calculation,
                parent_version: v.parent_version,
                has_warnings: v.has_warnings,
                has_errors: v.has_errors,
                has_info: v.has_info,
                has_hierarchy: v.has_hierarchy,
            };
        });
    }
);

export const portfolioAnalysisRowsSelector = createSelector(
    [
        portfolioAnalysisFilter.pageData
    ], 
    (pageData) => {
        const array: any[] = pageData;
        
        return array.map((v) => {
            return {
                id: v.id,
                name: v.name,
                user: v.created_by?.username,
                created_at: formatDateTime(v.created_at),
                company_group1: v.company_group1,
                company_group2: v.company_group2,
                macro_group1: v.macro_group1,
                macro_group2: v.macro_group2,
                tax_group1: v.tax_group1,
                tax_group2: v.tax_group2,
            };
    });
});

export const taxModesRowsSelector = createSelector([taxModesFilter.pageData], (pageData) => {
    const array: ITaxMode[] = pageData;

    return array.map((v) => {
        return {
            id: v.id,
            name: v.name,
            created_at: formatDateTime(v.created_at),
            is_draft: v.is_draft,
            indicators: v.modified_indicators,
            status: v.status,
            tags: v.tags,
        };
    });
});

export const macroScenarioRowsSelector = createSelector([macroScenarioFilter.pageData], (pageData) => {
    const array: IMacroScenario[] = pageData;

    return array.map((v) => {
        return {
            id: v.id,
            name: v.name,
            // created_at: formatDateTime(v.created_at),
            // indicators: v.modified_indicators,
            description: v.description,
            // tags: v.tags,
            macro_urals_price_min: v.macro_urals_price_min,
            macro_urals_price_max: v.macro_urals_price_max,
            exch_usd_rub_min: v.exch_usd_rub_min,
            exch_usd_rub_max: v.exch_usd_rub_max,
            status: v.status,

        };
    });
});

export const emissionScenarioRowsSelector = createSelector([emissionScenariosFilter.pageData], (pageData) => {
    const array: IEmissionScenario[] = pageData;

    return array.map((v) => {
        return {
            id: v.id,
            name: v.name,
            description: v.description,
            // indicators: v.modified_indicators,
            tags: v.tags,
            created_at: formatDateTime(v.created_at),
            status: v.status
        };
    });
});

export const costsScenarioRowsSelector = createSelector([costsScenariosFilter.pageData], (pageData) => {
    const array: ICostsScenario[] = pageData;

    return array.map((v) => {
        return {
            id: v.id,
            name: v.name,
            description: v.description,
            indicators: v.modified_indicators,
            tags: v.tags,
            created_at: formatDateTime(v.created_at),
            status: v.status
        };
    });
});

export const filesRowsSelector = createSelector([filesFilter.pageData], (pageData) => {
    const array: IFile[] = pageData;

    return array.map((v) => {
        return {
            id: v.id,
            name: v.file_name,
            doc_type: v.doc_type_label,
            created_at: formatDateTime(v.created_at)
        };
    });
});