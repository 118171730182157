import {useEffect} from 'react';
import {loadCurrentIndicator, setCurrentIndicator} from '../../store/currentCalcContext/actions';

import {getStatusTableRequest} from '../../store/tasks/actions';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {useHistory} from 'react-router';
import {getLanguageRequest} from '../../store/language/action';

function resolveOptions(loc) {
    const options = loc.pathname.split('::').map((option) => (option === 'null' ? null : option));
    if (options.length === 4) {
        const [, indicator, treeNodeId, versionsIds] = options;
        return [indicator, treeNodeId, versionsIds.split(',').map((id) => +id)];
    } else {
        const [, indicator, treeNodeId, versionId] = options;
        return [indicator, treeNodeId, versionId];
    }
}

export const RootLoader = () => {
    const {location} = useHistory();
    const dispatch = useDispatch();

    const selectedVersionIds = useSelector<ApplicationState, number[]>((store) => store.vFilter.selectedRowsIds);
    const activeVersionsIds = useSelector<ApplicationState, number[]>((store) => store.vFilter.activeRowsIds);

    let [indicator, treeNodeId, versionsIds = []] = resolveOptions(location);

    if (!versionsIds.every((id) => selectedVersionIds.includes(id))) {
        if (activeVersionsIds.length) {
            versionsIds = activeVersionsIds;
        } else if (selectedVersionIds.length > 0) {
            versionsIds = versionsIds.filter((id) => selectedVersionIds.includes(id));
        } else {
            versionsIds = null;
        }
    }

    useEffect(() => {
        dispatch(getLanguageRequest());
        dispatch(getStatusTableRequest());
        dispatch(loadCurrentIndicator());
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (indicator && versionsIds) {
            dispatch(setCurrentIndicator(indicator, treeNodeId));
        }
        //eslint-disable-next-line
    }, [indicator, versionsIds]);

    return null;
};
