import {Reducer} from 'redux';
import {IIndicatorMeta, IIndicatorMetaState, IndicatorMetaActionType} from './types';

export const initialState: IIndicatorMetaState = {
    data: new Map<string, IIndicatorMeta>(),
    error: null,
    loading: false
};

const reducer: Reducer<IIndicatorMetaState> = (state = initialState, action) => {
    switch (action.type) {
        case IndicatorMetaActionType.REFRESH_ACTION: {
            return {
                ...state,
                loading: false,
                data: new Map<string, IIndicatorMeta>()
            };
        }
        case IndicatorMetaActionType.FETCH_REQUEST: {
            return {...state, loading: true, data: new Map<string, IIndicatorMeta>()};
        }
        case IndicatorMetaActionType.FETCH_SUCCESS: {
            const {indicatorNames, data} = action.payload;
            const metaData = new Map();

            // eslint-disable-next-line
            indicatorNames.map((name, i) => {
                metaData.set(name, data.indicators[i]);
            });

            return {
                ...state,
                loading: false,
                data: metaData
            };
        }
        case IndicatorMetaActionType.FETCH_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case IndicatorMetaActionType.CLEAR_ACTION: {
            return {...state, data: new Map<string, IIndicatorMeta>()};
        }
        default: {
            return state;
        }
    }
};

export {reducer as indicatorMetaReducer};
