import React, {useState, useEffect} from 'react';
import {Select} from './Design/Select';
import Paginator from './Paginator';
import {useDispatch} from 'react-redux';
import {
    getFilterDataRequest,
    setRowsPerPage,
} from '../store/tableSelector/actions';
import {getPage, IPaginatorState, setPage} from '../store/pagination';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components/macro';
import {FlexContainer} from '../theme/main.styles';


const selectionLabels = {
    vFilter: 'components:version_selector.selection',
    taxModesFilter: 'components:tax_mode_selector.selection',
    macroScenariosFilter: 'components:macro_selector.selection',
    emissionScenariosFilter: 'components:emission_selector.selection',
    costsScenariosFilter: 'components:costs_selector.selection',
    portfolioAnalysisFilter: 'components:portfolio_selector.selection',
    filesFilter: 'components:files_selector.selection'
};

const countLabels = {
    vFilter: 'components:versions_in_system',
    taxModesFilter: 'components:tax_modes_in_system',
    macroScenariosFilter: 'components:macros_in_system',
    emissionScenariosFilter: 'components:esg_scenarios_in_system',
    costsScenariosFilter: 'components:costs_scenarios_in_system',
    portfolioAnalysisFilter: 'components:portfolio_analysis_in_system',
    filesFilter: 'components:files_in_system'
};

const pageNames = {
    vFilter: 'versions',
    taxModesFilter: 'taxModes',
    macroScenariosFilter: 'macroScenario',
    emissionScenariosFilter: 'emissionScenarios',
    costsScenariosFilter: 'costsScenarios',
    portfolioAnalysisFilter: 'portfolioAnalysis',
    filesFilter: 'files'
};

type PaginationProps = {
    loading: boolean;
    selection: number;
    count: number;
    perPage: number;
    totalPages: number;
    pagination: IPaginatorState;
    language: string;
    filterName: string;
    clearSelectedRows?: () => void;
};

export const Pagination: React.FC<PaginationProps> = React.memo(
    ({
        loading,
        selection,
        count,
        perPage,
        totalPages,
        pagination,
        language,
        filterName,
        clearSelectedRows,
    }) => {
        const {t} = useTranslation();
        const dispatch = useDispatch();

        const currentPage = getPage(pagination, pageNames[filterName]);

        const [versPerPage, setVersPerPage] = useState(perPage);
        const [page, setCurrentPage] = useState(currentPage);

        useEffect(() => {
            dispatch(getFilterDataRequest(page, perPage, filterName));
            //eslint-disable-next-line
        }, [page, perPage, language]);

        const handleRowsPerPageChange = (e) => {
            const page = e.target.value;
            setVersPerPage(page);
            dispatch(setRowsPerPage(page, filterName));
        };

        const handlePageChange = (page: number) => {
            clearSelectedRows && clearSelectedRows();
            setCurrentPage(page);
            dispatch(setPage(pageNames[filterName], page));
        };

        return (
            <PaginationWrapper>
                <div>
                    <Text>{t(countLabels[filterName], {count})}</Text>
                    {selection ? (
                        <Text>{t(selectionLabels[filterName], {selection})}</Text>
                    ) : (
                        <div></div>
                    )}
                </div>
                <FlexContainer>
                    <Select
                        variants={[30, 50, 100]}
                        onChange={handleRowsPerPageChange}
                        value={versPerPage}
                        outline={true}
                        inline={true}
                        emptyBg={true}
                        width={50}
                        placeholder={t('components:rowsOnPage', 'Количество на странице')}
                    />
                    <PaginatorWrapper>
                        <Paginator
                            onPageChange={handlePageChange}
                            currentPage={page}
                            loading={loading}
                            pageSize={perPage}
                            count={perPage * totalPages}
                        />
                    </PaginatorWrapper>
                </FlexContainer>
            </PaginationWrapper>
        );
    }
);

export const PaginationWrapper = styled.div`
    position: sticky;
    left: 0;
    bottom: 0;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    background-color: ${(props) => props.theme.colors.white};
    border-top: 2px solid ${(props) => props.theme.colors.grey2};
    font-size: 13px;
    z-index: 10;
`;

const PaginatorWrapper = styled.div`
    margin-left: 30px;
`;

export const Text = styled.span`
    color: ${(props) => props.theme.textColor.grey};

    &:nth-child(2) {
        margin-left: 40px;
    }
`;
