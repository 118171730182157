import React from 'react';
import styled from 'styled-components/macro';
import {CheckboxCancelIcon} from './CheckboxMock';

type CheckboxProps = {
    checked: boolean;
    onChange?: any;
    className?: any;
    label?: string;
    isBlack?: boolean;
    value?: string;
    disabled?: boolean;
    indeterminate?: boolean;
    isRounded?: boolean;
    isGreen?: boolean;
    isDarkGrey?: boolean;
    isLightGrey?: boolean;
    isYellow?: boolean;
    isSmaller?: boolean;
    onClick?: any;
};

export const Checkbox: React.FC<CheckboxProps> = ({
                                                      className,
                                                      isBlack = false,
                                                      checked,
                                                      onChange,
                                                      label,
                                                      value,
                                                      disabled,
                                                      indeterminate = false,
                                                      isRounded = false,
                                                      isGreen = false,
                                                      isSmaller = false,
                                                      isDarkGrey= false,
                                                      isLightGrey= false,
                                                      isYellow= false,
                                                      onClick
                                                  }) => (
    <Container className={className} isGreen={isGreen} isRounded={isRounded} isDarkGrey={isDarkGrey} isSmaller={isSmaller} onClick={onClick}>
            <>
                <CheckboxElement
                    id='checkbox'
                    type='checkbox'
                    onChange={onChange}
                    checked={checked}
                    value={value}
                    disabled={disabled}
                    isSmaller={isSmaller}
                />
                {!indeterminate
                  ? <Checkmark isBlack={isBlack} isRounded={isRounded} isSmaller={isSmaller}
                               isGreen={isGreen} isDarkGrey={isDarkGrey} isLightGrey={isLightGrey} isYellow={isYellow} />
                  : <CheckboxCancelIcon />
                }
                <Label>{label}</Label>
            </>

    </Container>
);

const CheckboxElement = styled.input``;

export const Checkmark = styled.span`
  ${props => props.isRounded && `border-radius: 50%`};
  height: ${props => props.isSmaller ? '16px' : '20px'};
  width: ${props => props.isSmaller ? '16px' : '20px;'};

  ${props => {
    if (props.isBlack) return `background-color: ${props.theme.colors.black};`;
    if (props.isDarkGrey) return `background-color: ${props.theme.colors.grey1};`;
    if (props.isLightGrey) return `background-color: ${props.theme.colors.grey4};`;
    if (props.isYellow) return `background-color: ${props.theme.colors.yellow};`;
    if (props.isGreen) return `background-color: ${props.theme.colors.grey2};`;
    return `background-color: ${props.theme.colors.grey3};`;
  }}
  
  &:after {
    content: '';
    position: relative;
    display: none;
  }
`;

export const Container = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  user-select: none;

  ${CheckboxElement} {
    position: relative;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  ${CheckboxElement}:checked ~ ${Checkmark} {
    background-color: ${(props) => props.isGreen ? props.theme.colors.green : props.theme.colors.yellow};
  }

  & ${CheckboxElement}:checked ~ ${Checkmark}:after {
    display: block;
  }

  & ${Checkmark}:after {
    border: solid ${(props) => (props.isBlack || props.isGreen || props.isDarkGrey ? props.theme.colors.white : props.theme.colors.black)};
    ${props => props.isSmaller ? `
            left: 6px;
            top: 3px;
            width: 3px;
            height: 7px;
            border-width: 0 1px 1px 0;
        ` : `
            left: 7px;
            top: 2px;
            width: 5px;
            height: 10px;
            border-width: 0 2px 2px 0;
        `
    }
    transform: rotate(45deg);
  }

  &:hover ${CheckboxElement} ~ ${Checkmark} {
    ${props => !props.isGreen && `outline: 4px solid rgb(225 225 225 / 25%)`};
  }
`;

const Label = styled.span`
${(props) => props.children &&
  'margin-left: 10px;' 
}`;