export enum DemoPlotActionType {
    GET_DEMO_PLOT_REQUEST = '@@demoplot/GET_DEMO_PLOT_REQUEST',
    GET_DEMO_PLOT_SUCCESS = '@@demoplot/GET_DEMO_PLOT_SUCCESS',
    GET_DEMO_PLOT_ERROR = '@@demoplot/GET_DEMO_PLOT_ERROR',
    SET_REPORT_GROUP = '@@demoplot/SET_REPORT_GROUP',
    SET_STACK_GROUP = '@@demoplot/SET_STACK_GROUP',
    INVALIDATE_PLOT = '@@demoplot/INVALIDATE_PLOT',
}


export interface LineType {
    color: string,
    with: number
}

export interface IPlot {
    x: number[],
    y: number[],
    name: string,
    type: string,
    mode: string,
    line: LineType,
    stackgroup: string,
}

export type IDashPlots = Map<string, IPlot[]>

export interface IDemoPlotsState {
    plots: IDashPlots
    error: Map<any, any>;
    loading: Map<string, boolean>;
    reportGroup: string
}
