import React, {useEffect, useState} from 'react';
import {FilterSortType} from '../../components/FilterColumn/SortComponent';
import {FilterStoreItemType} from '../../components/FilterColumn/types';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components/macro';
import {IRadioGroupOption} from '../../components/RadioGroup';
import {useHistory, useParams} from 'react-router';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '../calculation-page/GridView.scss';
import MacroDescEditInfo from './MacroDescEditInfo';
import MacroDescHeaderPrices from './MacroDescHeaderPrices';
import MacroDescTable from './MacroDescTable';
import MacroDescTableControls from './MacroDescTableControls';
import CallReceivedIcon from '@material-ui/icons/CallReceived';

import {
    editScenarioDescriptionRequest,
    editScenarioNameRequest,
    getScenarioDetailsRequest,
} from '../../store/scenario/actions';

import MacroDescPlot from './MacroDescPlot';
import {useDispatch, useSelector} from 'react-redux';
import {IScenarioState} from '../../store/scenario/types';

export type updateCalculateData = (
    conditions?: FilterStoreItemType | null,
    sortFilters?: FilterSortType,
) => void;

export const MacroDescriptionPage = () => {
    const {t} = useTranslation(['components', 'pages']);
    const dispatch = useDispatch();
    const history = useHistory();

    const scenarioDetails = useSelector<IScenarioState, any>((store) => store.scenario).scenario;
    const [macroTable, setMacroTable] = useState('macro'); // или macro_indices
    const [showFilled, setShowFilled] = useState(true);
    const [gridPrecision, setGridPrecision] = useState(4);
    const [showNulls, setShowNulls] = useState(false);
    const [accumulateData, setAccumulateData] = useState(false);
    const [aggData, setAggData] = useState(false);
    const [incidesPage, setIncidesPage] = useState(true);
    const [isNameInputVisible, setIsNameInputVisible] = useState(false);
    const [isDescriptionInputVisible, setIsDescriptionInputVisible] = useState(false);

    const [tableIndex, setTableIndex] = useState(null);
    const [macroIndices, setMacroIndices] = useState(null);

    const [useTechNames, setTechNames] = useState(false);
    const {macroId} = useParams<{macroId: string}>();
    const [plotlyChart, setPlotlyChart] = useState({
        data: {},
        layout: {},
    });

    const loading = scenarioDetails.loading;

    const checkIfEmpty = (obj) => {
        const objKeys = Object.keys(obj);
        return objKeys.some((item) => obj[item].length);
    };

    const formatToPlotly = (data) => {
        const x = [];
        const y = [];

        const newLayout = {};

        for (const [key, value] of Object.entries(data)) {
            if (key === 'Показатель' || key === 'Indicator') {
                newLayout['title'] = value;
            } else if (key === 'Ед. изм.') {
                newLayout['yaxis'] = {
                    title: {
                        text: value,
                    },
                };
            } else {
                x.push(key);
                y.push(value);
            }
        }
        setPlotlyChart({
            data: {
                x: x,
                y: y,
                type: 'scatter',
                mode: 'lines+markers',
            },
            layout: newLayout,
        });
    };

    const [gridApi, setGridApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if (gridApi && !loading) {
            gridApi.forEachNodeAfterFilter(node => {
                if (node.rowIndex === 0) {
                    formatToPlotly(node.data);
                    node.setSelected(true);
                }
            })
        };
    }, [gridApi, loading]);


    useEffect(() => {
        const params = {
            tech_names: useTechNames,
            filled_only: showFilled,
            prec: gridPrecision,
            show_zero: showNulls,
            acc: accumulateData,
            agg: aggData,
        };
        dispatch(getScenarioDetailsRequest(macroId, params));
        //eslint-disable-next-line
    }, [macroId, useTechNames, showFilled, macroId, gridPrecision, showNulls, accumulateData, aggData]);

    useEffect(() => {
        scenarioDetails['indicator_tables']?.[macroTable]?.index && setTableIndex(scenarioDetails['indicator_tables'][macroTable].index);
        scenarioDetails['indicator_tables']?.[macroTable]?.values && setMacroIndices(scenarioDetails['indicator_tables'][macroTable].values);
        if (scenarioDetails?.['indicator_tables']?.['macro_indices']?.values && checkIfEmpty(scenarioDetails['indicator_tables']['macro_indices'].values)) {
            setIncidesPage(false);
        }
        //eslint-disable-next-line
    }, [scenarioDetails]);

    useEffect(() => {
        scenarioDetails['indicator_tables']?.[macroTable]?.index && setTableIndex(scenarioDetails['indicator_tables'][macroTable].index);
        scenarioDetails['indicator_tables']?.[macroTable]?.values && setMacroIndices(scenarioDetails['indicator_tables'][macroTable].values);
        //eslint-disable-next-line
    }, [macroTable]);

    const handleSaveScenarioName = (name) => {
        setIsNameInputVisible(false);
        dispatch(editScenarioNameRequest(macroId, name));
    };

    const handleSaveScenarioDescription = (description) => {
        setIsDescriptionInputVisible(false);
        dispatch(editScenarioDescriptionRequest(macroId, description));
    };

    const DATA_VIEW_OPTIONS: IRadioGroupOption[] = [
        {
            key: 'macro',
            value: t('pages:macroScenarios.macro_indciators', 'Макропоказатели'),
            disabled: false,
        },
        {
            key: 'macro_indices',
            value: t('pages:macroScenarios.macro_indexes', 'Макроэкономические индексы'),
            disabled: incidesPage,
        },
    ];

    const onlyFilled = () => {
        setShowFilled(!showFilled);
    };

    return (
        <div>
            {scenarioDetails && (
                <HeaderWrapper>
                    <HeaderTopWrapper>
                        <BackButton
                            onClick={() => {
                                history.push('/macro');
                            }}
                        >
                            <ArrowLeftIcon />
                        </BackButton>
                        <ManageScenariosTitle
                            onClick={() => {
                                history.push('/macro');
                            }}
                        >
                            {t('pages:MacroManagment', 'Управление сценариями')}
                        </ManageScenariosTitle>
                        <ShowScenarioTitle>
                            <TitlesSeparator>/</TitlesSeparator>
                            {t('pages:macroScenarios.view_scenario', 'Просмотр сценария')}
                        </ShowScenarioTitle>
                        <HeaderRightSection>
                            <MacroDescHeaderPrices macro_urals_price_min={scenarioDetails.macro_urals_price_min}
                                                   macro_urals_price_max={scenarioDetails.macro_urals_price_max}
                                                   exch_usd_rub_min={scenarioDetails.exch_usd_rub_min}
                                                   exch_usd_rub_max={scenarioDetails.exch_usd_rub_max} />
                        </HeaderRightSection>
                    </HeaderTopWrapper>
                    <HeaderNamesWrapper>
                        <MacroDescEditInfo
                            handleSaveScenarioName={handleSaveScenarioName}
                            handleSaveScenarioDescription={handleSaveScenarioDescription}
                            isNameInputVisible={isNameInputVisible}
                            setIsNameInputVisible={setIsNameInputVisible}
                            isDescriptionInputVisible={isDescriptionInputVisible}
                            setIsDescriptionInputVisible={setIsDescriptionInputVisible}
                            scenarioDetails={scenarioDetails}
                        />
                    </HeaderNamesWrapper>
                    <>
                        {plotlyChart && (
                            <MacroDescPlot plotlyChart={plotlyChart} />
                        )}
                        <MacroDescTableControls
                            DATA_VIEW_OPTIONS={DATA_VIEW_OPTIONS} macroTable={macroTable} setMacroTable={setMacroTable}
                            accumulateData={accumulateData} setAccumulateData={setAccumulateData} showNulls={showNulls}
                            setShowNulls={setShowNulls} aggData={aggData} setAggData={setAggData}
                            gridPrecision={gridPrecision} setGridPrecision={setGridPrecision}
                            useTechNames={useTechNames} setTechNames={setTechNames} showFilled={showFilled}
                            onlyFilled={onlyFilled} />
                        {/*formatData(scenarioDetails)*/}
                        {(scenarioDetails?.['indicator_tables']?.[macroTable] && tableIndex && macroIndices) &&

                        <MacroDescTable macroDescription={scenarioDetails} index={tableIndex}
                                        macroIndices={macroIndices}
                                        formatToPlotly={formatToPlotly} onGridReady={onGridReady} />
                        }
                    </>
                </HeaderWrapper>
            )}
        </div>
    );
};

const HeaderWrapper = styled.div`
  padding: 24px 27px
`;

const HeaderTopWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 60px;
`;

const BackButton = styled.div`
  margin-right: 10px;
  margin-left: -5px;
  color: ${(props) => props.theme.colors.grey1};
  cursor: pointer;
  align-self: flex-end;

  & > svg {
    height: 16px;
  }
`;

const ManageScenariosTitle = styled.p`
  font-weight: 700;
  cursor: pointer;
  color: ${(props) => props.theme.colors.lightGrey};

  &:hover {
      color: ${(props) => props.theme.colors.grey1};
  }
`;

const ShowScenarioTitle = styled.h1`
  font-size: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey1};
`;

const TitlesSeparator = styled.span`
  margin: 0 8px;
  color: ${(props) => props.theme.colors.lightGrey};
`;

const HeaderRightSection = styled.div`
  margin-left: auto;
  display: flex;
  align-self: baseline;
  font-size: 12px;
`;


const HeaderNamesWrapper = styled.div`
`;

const ArrowLeftIcon = styled(CallReceivedIcon)`
  transform: rotate(45deg);
`;