import React, {useState} from 'react';
import styled, {css} from 'styled-components/macro';
import {downloadUsingToken} from '../../helpers/utils';
import {recalculateVersions} from '../../store/calcVersion/actions';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {IAppProperties} from '../../store/appProperties/types';
import { useTranslation } from 'react-i18next';
import {MaterialTooltip} from '../../components/Design/Tooltip';

import {
    ConfigureAttributesIcon,
    ConfigureGroupsIcon,
    DownloadIcon,
    RecalculateCurrentReleaseIcon,
    RemoveIcon, RunCalcIcon,
    ShutdownOfWellsIcon,
} from '../../components/Design/Icons/IconsControlTable';

export const TableControl = ({
    setFormDialogState,
    setDeleteDialogState,
    deleteDialogState,
    checkedRowIds,
    filterName
}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [isShowChild, setIsShowChild] = useState(false);
    const [timeId, setTimeId] = useState<any>(0);

    const appProps = useSelector<ApplicationState, IAppProperties>((store) => store.appProperties);
    const pageData = useSelector<ApplicationState, any>((store) => store[filterName].pageData);

    const {wells_switchoff_enabled, download_limit} = appProps;

    const handleClickOpenWellsSwitchOffDialog = () => {
        setFormDialogState((s) => ({
            ...s,
            selection: checkedRowIds,
            isWellsSwithOffDialogOpen: true,
        }));
    };

    const handleClickOpenConfigureSimulationDialog = () => {
        setFormDialogState((s) => ({
            ...s,
            selection: checkedRowIds,
            isConfigureSimulationDialogOpen: true,
        }));
    };

    const handleClickOpenDelete = () => {
        setDeleteDialogState({...deleteDialogState, selection: checkedRowIds, open: true});
    };
        
    const handleRecalculate = () => {
        dispatch(recalculateVersions(checkedRowIds));
    };

    const handleDownloadVersion = () => {
        downloadUsingToken({ version_hash: checkedRowIds.toString() }, dispatch);
    };

    const handleConfigureAttributes = () => {
        setFormDialogState((s) => ({
            ...s,
            selection: checkedRowIds,
            isConfigureAttributesDialogOpen: true,
        }));
    }

    const handleConfigureGroups = () => {
        setFormDialogState((s) => ({
            ...s,
            selection: checkedRowIds,
            isConfigureGroupsDialogOpen: true,
        }));
    }

    // Метод загрузки файла(ов):
    const handleDownloadFile = () => {
        let payload: any = { 
            scenario_hash: checkedRowIds.toString() 
        };

        if (filterName === 'filesFilter') {

            const checkedRows = pageData.filter((row) => checkedRowIds.includes(row.id));
            const scenario_hash = checkedRows.filter((row) => row.doc_type === 'MACRO_SCENARIO').map((row) => row.source_id).toString();
            const report_hash = checkedRows.filter((row) => row.doc_type === 'ANALYTICS_REPORT').map((row) => row.source_id).toString();
            const version_hash = checkedRows.filter((row) => row.doc_type === 'SOURCE_DATA').map((row) => row.source_id).toString();
            const liquid_prod_limit_hash = checkedRows.filter((row) => row.doc_type === 'LIQUID_PROD_LIMIT').map((row) => row.source_id).toString();
            
            payload = {
                ...(scenario_hash && {scenario_hash}),
                ...(report_hash && {report_hash}),
                ...(version_hash && {version_hash}),
                ...(liquid_prod_limit_hash && {liquid_prod_limit_hash})
            };
        }
        downloadUsingToken(payload, dispatch, false);
    };

    let tableActions = [];

    if (filterName === 'vFilter') {
        tableActions = [
            {
                text: t('pages:versions.tooltip.run_calc', ),
                icon: <RunCalcIcon />,
                onClick: handleClickOpenConfigureSimulationDialog,
            },
            wells_switchoff_enabled && {
                text: t('pages:versions.tooltip.switch_off_wells', ),
                icon: <ShutdownOfWellsIcon />,
                onClick: handleClickOpenWellsSwitchOffDialog,
            },
            {
                id: 1,
                type: 'parent',
                text: t('pages:versions.tooltip.recalculate', 'Пересчитать выбранные версии в текущем релизе'),
                icon: <RecalculateCurrentReleaseIcon />,
                onClick: handleRecalculate,
            },
            {
                id: 2,
                type: 'parent',
                text: t('components:configureVersions.versionAttributes', 'Управление атрибутами версий'),
                icon: <ConfigureAttributesIcon />,
                onClick: handleConfigureAttributes,
            },
            {
                id: 3,
                type: 'parent',
                text: t('components:configureVersions.versionGroups', 'Управление группами версий'),
                icon: <ConfigureGroupsIcon />,
                onClick: handleConfigureGroups,
            },
            {
                text: t('pages:versions.tooltip.download', 'Скачать исходный файл'),
                disabled: checkedRowIds.length > download_limit,
                icon: <DownloadIcon />,
                onClick: handleDownloadVersion,
            },
            {
                text: t('pages:versions.tooltip.delete_versions', 'Удалить выбранные версии'),
                icon: <RemoveIcon />,
                onClick: handleClickOpenDelete,
            },
        ];

    } else if (filterName === 'taxModesFilter') {

        tableActions = [{
            text: t('pages:scenarios.tooltip.delete_tax_modes', 'Удалить выбранные налоговые режимы'),
            icon: <RemoveIcon />,
            onClick: handleClickOpenDelete,
        }];

    } else if (filterName === 'emissionScenariosFilter') {

        tableActions = [{
            text: t('pages:esg.tooltip.delete_esg_scenarios', 'Удалить выбранные углеродные сценарии'),
            icon: <RemoveIcon />,
            onClick: handleClickOpenDelete,
        }];

    } else if (filterName === 'costsScenariosFilter') {

        tableActions = [{
            text: t('pages:cost.tooltip.delete_costs_scenarios', 'Удалить выбранные сценарии изменения затрат'),
            icon: <RemoveIcon />,
            onClick: handleClickOpenDelete,
        }];

    } else if (filterName === 'macroScenariosFilter') {
        
        tableActions = [{
            text: t('pages:macroScenarios.tooltip.download', 'Скачать исходныe файлы'),
            disabled: checkedRowIds.length > download_limit,
            icon: <DownloadIcon />,
            onClick: handleDownloadFile
        },
        {
            text: t('pages:macroScenarios.tooltip.delete', 'Удалить выбранные макро сценарии'),
            icon: <RemoveIcon />,
            onClick: handleClickOpenDelete
        }];
    } else if (filterName === 'filesFilter') {
        
        tableActions = [{
            text: t('pages:files.tooltip.download', 'Скачать исходныe файлы'),
            disabled: checkedRowIds.length > download_limit,
            icon: <DownloadIcon />,
            onClick: handleDownloadFile
        }];
    }


    const onMouseEnterHandler = () => {
        clearTimeout(timeId);
        setIsShowChild(true);
    };

    const onMouseLeaveHandler = (e) => {
        if (e.target.getAttribute('type') !== 'parent') {
            const id = setTimeout(() => {
                setIsShowChild(false);
            }, 1000);

            setTimeId(id);
        }
    };

    const createIMenuItem = (item) => {
        return (
            <MaterialTooltip title={item.text} key={item.parentId}>
                <Item
                    isShowChild={isShowChild}
                    type={item.type}
                    onClick={!item.disabled ? item.onClick : () => {}}
                    disabled={item.disabled}
                >
                    {item.icon}
                </Item>
            </MaterialTooltip>
        );
    };

    return (
        <TableControlWrapper>
            {tableActions.map((item, index) => (
                <React.Fragment key={index}>
                    {item.type === 'parent' ? (
                        <FoldContainer onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
                            {createIMenuItem(item)}
                            {tableActions
                                .filter((el) => !!el.parentId && el.parentId === item.id)
                                .map((el) => createIMenuItem(el))}
                        </FoldContainer>
                    ) : (
                        createIMenuItem(item)
                    )}
                </React.Fragment>
            ))}
        </TableControlWrapper>
    );
};

const TableControlWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
`;

const Item = styled.div`
    margin: 0 6px;
    cursor: pointer;
    position: relative;

    ${(props) =>
        props?.disabled &&
        css`
            opacity: 50%;
        `}

    ${(props) =>
        !props.type &&
        css`
            display: block;
        `}

    ${(props) =>
        props.type === 'child' &&
        props.isShowChild === true &&
        css`
            display: block;
            position: absolute;
            top: -42px;
            left: 0;
        `}

    ${(props) =>
        props.type === 'child' &&
        props.isShowChild === false &&
        css`
            display: none;
        `}
`;

const FoldContainer = styled.div`
    position: relative;
`;
