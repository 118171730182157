import {action} from 'typesafe-actions'
import {AnomalyTreeActionType, IAnomalyTreeNode} from './types'

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//export const fetchRequest = (version_id) => action(AnomalyTreeActionType.FETCH_REQUEST, payload: {});

export const anomalyFetchRequest = (version_id: number) => action(AnomalyTreeActionType.FETCH_REQUEST,
    {version_id: version_id}
);
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.
export const anomalyFetchSuccess = (data: IAnomalyTreeNode[] | null, version_id: number) => action(AnomalyTreeActionType.FETCH_SUCCESS, {
    data: data,
    version_id: version_id
});
export const anomalyFetchError = (message: string) => action(AnomalyTreeActionType.FETCH_ERROR, message);


export const setCurrentAnomaly = (indicator: string) =>
    action(AnomalyTreeActionType.SET_CURRENT_ANOMALY, indicator);