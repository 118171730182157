import React from 'react';
import styled from 'styled-components/macro';

interface LinkProps {
    onClick: any;
    text: string;
    disabled?: boolean;
}

const Link: React.FC<LinkProps> = ({text, onClick, disabled = false}) => {
    return <StyledLink onClick={onClick}>{text}</StyledLink>;
};

const StyledLink = styled.div`
    &:hover {
        text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
        cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
    }
`;

export default Link;
