import React from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { useTranslation } from "react-i18next";
import {Link} from "@material-ui/core";

export interface IDropZoneProps {
    defaultMessage?: string;
    dragActiveMessage?: string;
    multiple?: boolean;
    loading?: boolean;
    success?: boolean;
    disabled?: boolean;
    progress?: number | null;
    error?: any;
    onDrop?: (files: File[], type?) => any;
    onReset?;
}

const DropZone: React.FC<IDropZoneProps> = ({
                                                defaultMessage,
                                                dragActiveMessage,
                                                multiple,
                                                loading,
                                                disabled,
                                                success,
                                                progress,
                                                error,
                                                onDrop,
                                                onReset
                                            }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple,
        disabled: loading || error || success || disabled
    });


    const { t } = useTranslation('components');

    function renderChildren() {
        if (error != null) {
            return (
                <p className='message error'>
                    {t('components:dropzone.error', 'Ошибка при загрузке файла!')} {error}
                    <Link className='reset-link' onClick={onReset} >
                        {t('components:dropzone.retry', '[Попробовать снова]')}
                    </Link>
                </p>
            );
        }
        if (success) {
            return (
                <p className='message success'>
                    {t('components:dropzone.success', 'Загрузка завершена')}
                    <Link className='reset-link' onClick={onReset}>
                        {t('components:dropzone.upload_more', '[Загрузить еще файл]')}
                    </Link>
                </p>
            );
        }
        if (progress) {
            return <p
                className='message progress'>{t('components:dropzone.downloading', 'Загрузка')} {progress} %</p>;
        }
        if (isDragActive) {
            return <p
                className='message'>{t('components:dropzone.dragActiveMessage', { dragActiveMessage })}</p>;
        }
        return <p
            className='message'>{t('components:dropzone.defaultMessage', { defaultMessage })}</p>;
    }

    return (
        <div className='ey-dropzone' {...getRootProps()}>
            <div className={classNames('dropzone-inner', { 'drag-active': isDragActive })}>
                <input {...getInputProps()} />
                {renderChildren()}
            </div>
        </div>
    );
};

DropZone.defaultProps = {
    defaultMessage: 'Нажмите, чтобы выбрать файл или перетащите файл сюда',
    dragActiveMessage: 'Поместите файл сюда',
    multiple: false,
    loading: false,
    disabled: false,
    progress: null,
    success: false,
    error: null,
    onDrop: () => null,
    onReset: () => null
};

export default DropZone;
