import {action} from 'typesafe-actions';
import {IFullKPIReport, IReport, IReportRequest, IReportTag, ReportsActionType} from "./types";

export const getReportsRequest = page => action(ReportsActionType.GET_REPORTS_REQUEST, page);

export const getReportsSuccess = (reports: any) =>
    action(ReportsActionType.GET_REPORTS_SUCCESS, reports);

export const addReport = (report: IReport) => action(ReportsActionType.ADD_REPORT, report);

export const updateReport = (id: number, report: IReport) =>
    action(ReportsActionType.UPDATE_REPORT, {id, report});


export const deleteReportDone = (id: number) =>
    action(ReportsActionType.DELETE_REPORT_DONE, {id});

export const clearReportBuffer = () =>
    action(ReportsActionType.CLEAR_REPORT_BUFFER);

export const getReportsError = (error) =>
    action(ReportsActionType.GET_REPORTS_ERROR, error);

export const orderReportRequest = (reportRequest: IReportRequest) =>
    action(ReportsActionType.ORDER_REPORT_REQUEST, reportRequest);

export const getReportTags = () => action(ReportsActionType.GET_REPORT_TAGS);

export const getReportTagsSuccess = (tags: IReportTag[]) =>
    action(ReportsActionType.GET_REPORT_TAGS_SUCCESS, {tags});

export const getReportTagsError = (error) =>
    action(ReportsActionType.GET_REPORT_TAGS_ERROR, {error});

export const retrieveReportRequest = (id: number) => 
    action(ReportsActionType.RETRIEVE_REPORT_REQUEST, {id});

export const retrieveReportSuccess = (reports: IFullKPIReport[]) =>
    action(ReportsActionType.RETRIEVE_REPORT_SUCCESS, reports);

export const retrieveReportError = (error) => 
    action(ReportsActionType.RETRIEVE_REPORT_ERROR, {error});
