export enum UploadActionType {
    UPLOAD_CREATE_REQUEST = '@@uploads/UPLOAD_CREATE',
    UPLOAD_CREATE_SUCCESS = '@@uploads/UPLOAD_CREATE_SUCCESS',
    UPLOAD_CREATE_ERROR = '@@uploads/UPLOAD_CREATE_ERROR',
    GET_UPLOADS_LIST_REQUEST = '@@uploads/GET_UPLOADS_LIST_REQUEST',
    GET_UPLOADS_LIST_SUCCESS = '@@uploads/GET_UPLOADS_LIST_SUCCESS',
    GET_UPLOADS_LIST_ERROR = '@@uploads/GET_UPLOADS_LIST_ERROR',
    DELETE_UPLOAD = '@@uploads/DELETE_UPLOAD',
}

export interface IUpload {
    id: number,
    file_name: string,
    // description: string,
    // created_at: Date,
}

export interface IUploadState {
    uploads: IUpload[] | null;
    readonly loading: boolean;
    readonly error: string | null;
}