import {Reducer} from 'redux';
import {AppPropertiesAction, IAppProperties} from './types';

export const initialState: IAppProperties = {
    version: null,
    auth_enabled: null,
    app_environment: null,
    anomaly_enabled: false,
    wells_switchoff_enabled: null,
    uplift1_enabled: null,
    uplift2_enabled: null,
    enable_multilang: true,
    is_expired: false,
    expire_date: "2040-01-01T00:00:00",
    is_expired_warning: false,
    expire_date_warning: "2040-01-01T00:00:00",
    download_limit: 30,
    loading: false
};

export const appPropertiesReducer: Reducer<IAppProperties> = (state = initialState, action) => {
    switch (action.type) {
        case AppPropertiesAction.GET_APP_PROPERTIES_REQUEST: {
            return {
                ...state,
                auth_enabled: null,
                loading: true
            };
        }
        case AppPropertiesAction.GET_APP_PROPERTIES_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        }
        default: {
            return state;
        }
    }
};
