export enum AttributeActionType {
    ATTRIBUTE_CREATE_REQUEST = '@@attribute/ATTRIBUTE_CREATE_REQUEST',
    ATTRIBUTE_CREATE_SUCCESS = '@@attribute/ATTRIBUTE_CREATE_SUCCESS',
    ATTRIBUTE_CREATE_ERROR = '@@attribute/ATTRIBUTE_CREATE_ERROR',

    GET_ATTRIBUTES_LIST_REQUEST = '@@attribute/GET_ATTRIBUTES_LIST_REQUEST',
    GET_ATTRIBUTES_LIST_SUCCESS = '@@attribute/GET_ATTRIBUTES_LIST_SUCCESS',
    GET_ATTRIBUTES_LIST_ERROR = '@@attribute/GET_ATTRIBUTES_LIST_ERROR',

    DELETE_ATTRIBUTE = '@@attribute/DELETE_ATTRIBUTE',
}

export interface IAttribute {
    id: number,
    name: string,
    name_short?: string,
    value?: string,
    calctag_type?: number,
    hidden?: boolean,
    overrides?: any
}

export interface IAttributeState {
    attributes: IAttribute[] | null;
    readonly loading: boolean;
    readonly error: string | null;
}