import React, {Suspense} from 'react';
import * as ReactDOM from 'react-dom';
import {createHashHistory} from 'history';

import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './configure-store';
// We use hash history because this example is going to be hosted statically.
// Normally you would use browser history.
import './i18n'
import Fallback from "./helpers/SuspenseFallback";

const history = createHashHistory();

const initialState = (window as any).initialReduxState;
const store = configureStore(history, initialState);

// const ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');
//const REACT_APP_SENTRY_DSN = getConfig('REACT_APP_SENTRY_DSN');
//TODO: get sentry init from config
//Sentry.init({dsn: REACT_APP_SENTRY_DSN});


ReactDOM.render(
    <Suspense fallback={<Fallback/>}>
        <App store={store} history={history}/>
    </Suspense>
    , document.getElementById('root'));

//setupInterceptors(store, history);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
