import React from 'react';
import {Table} from '../../components/NewTable';

export const FilesTableActionsWrapper: React.FC<any> = ({
    columns,
    onRowSelected,
    handleFilterChange,
    filterName,
    onRowClick,
}) => {
    return (
        <>
            <Table
                columns={columns}
                onRowSelected={onRowSelected}
                handleFilterChange={handleFilterChange}
                onRowClick={onRowClick}
                filterName={filterName}
            />
            {/* Компоненты диалоговых окон для фильтра файлов */}
            {filterName === 'filesFilter' && <></>}
        </>
    );
};
