import {SortFilterType} from './SortComponent';
import {FilterType} from '../../pages/calculation-page/GridView';
import {FilterColumnItemType} from './FilterColumn';

export type ColumnVariableValue = {
    originalValue: string;
    roundValue: string;
};

export type CheckedFilterItem = {
    id: number;
    field: string;
    isChecked: boolean;
} & ColumnVariableValue;

export type ConditionItemType = {
    id: number;
    field: string;
    condition: ConditionType;
    value: string;
};

export enum ConditionEnum {
    '=' = 'eq',
    '!=' = 'neq',
    '>' = 'gt',
    '>=' = 'gte',
    '<' = 'lt',
    '<=' = 'lte',
    'empty' = 'isnull',
    'not_empty' = 'isnull',
}

export type FilterStoreItemType = {
    [key: string]: FilterColumnItemType[];
};

export type ConditionType = '=' | '!=' | '<' | '<=' | '>' | '>=' | 'empty' | 'not_empty';

export type ApplyFiltersFn = (
    newFilterData: FilterColumnItemType[],
    sortFilter: SortFilterType | null,
    field: string
) => void;

export type FilterPayload = {
    filterType: FilterType;
    values: {
        filters: FilterColumnItemType[];
        sortColumn?: SortFilterType;
    };
};
