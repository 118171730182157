import React from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import zipWith from 'lodash/zipWith';

const MacroDescTable = (props) => {
    const {index, macroIndices, onGridReady, formatToPlotly} = props;

    const commonProps = {
        resizable: true,
        minWidth: 70,
        maxWidth: 300,
        editable: false,
        filter: false,
    };

    const combineColsWithValues = (obj, rowCount) => {
        const colKeys = Object.keys(obj);
        const data = [];

        for (let i = 0; i < rowCount; i++) {
            let col = {};

            for (let colKey of colKeys) {
                col[colKey] = obj[colKey][i];
            }
            data.push(col);
        }
        return data;
    };

    const rowCount = index.indicator.values.length;
    const combinedIndexNames: any[] = index.indicator.values.map(x => {
        const item = {};
        item[index.indicator.display_name] = x;
        return item;
    });
    const combinedIndexUnits: any[] = index.unit.values.map(x => {
        const item = {};
        item[index.unit.display_name] = x;
        return item;
    });
    const combinedValues = combineColsWithValues(macroIndices, rowCount);
    const rowData = zipWith(combinedIndexNames, combinedIndexUnits, combinedValues, (indexName, indexUnit, value) => ({
        ...indexName,
        ...indexUnit,
        ...value,
    }));

    const columnDefs = [
        ...[index.indicator.display_name, index.unit.display_name].map((col) => ({
            ...commonProps,
            pinned: 'left',
            headerName: col,
            field: col,
            width: 150,
        })),
        ...Object.keys(combinedValues['0']).map((col) => ({
            ...commonProps,
            ...(col === 'AGG' && {pinned: 'left'}),
            headerName: col,
            field: col,
            width: 150,
        })),
    ];

    return (
        <div className='macro-scenarios-table-wrapper'>
            <div className='ag-theme-balham grid-view'>
                <AgGridReact
                    rowData={rowData}
                    rowSelection={'single'}
                    domLayout='autoHeight'
                    onRowClicked={(event) => {
                        formatToPlotly(event.data);
                    }}
                    onGridReady={onGridReady}
                >
                    {columnDefs.map((column) => (
                        <AgGridColumn {...column} key={column.field} sortable={true} />
                    ))}
                </AgGridReact>
            </div>
        </div>
    );
}

export default MacroDescTable;