import React, {useState, useEffect} from 'react';
import {TabCustom, SortWrapper, SortItem} from './filter.styles';
import {FilterASCIcon, FilterDESCIcon} from '../Design/Icons/IconsEditTable';

export type SortFilterObjectType = SortFilterType & {position: number};

export type FilterSortType = {
    [key: string]: SortFilterObjectType;
};

export enum SortVariableEnum {
    '+' = '+',
    '-' = '-',
}

export type SortFilterType = {
    id: number;
    title: string;
    value: SortVariableEnum;
    isChecked: boolean;
};

export type SortModelType = {
    colId: string;
    sort: 'asc' | 'desc';
    sortIndex: number;
};

type SortComponentProps = {
    sortFilter: SortFilterType;
    updateSortFilter: (sortFilter: SortFilterType) => void;
};

const SortComponent: React.FC<SortComponentProps> = ({sortFilter, updateSortFilter}) => {
    const sortVariants: SortFilterType[] = [
        {
            id: 1,
            title: '0-9',
            value: SortVariableEnum['+'],
            isChecked: false,
        },
        {
            id: 2,
            title: '9-0',
            value: SortVariableEnum['-'],
            isChecked: false,
        },
    ];

    const [currentSortFilters, setCurrentSortFilters] = useState<SortFilterType[]>(sortVariants);

    useEffect(() => {
        if (sortFilter && Object.keys(sortFilter).length > 0) {
            const result = currentSortFilters.map((currentFilter: SortFilterType) => {
                if (currentFilter.id === sortFilter.id) currentFilter.isChecked = sortFilter.isChecked;
                return currentFilter;
            });
            return setCurrentSortFilters(result);
        }

        setCurrentSortFilters(sortVariants);
        //eslint-disable-next-line
    }, [sortFilter]);

    const onChangeHandler = (sortFilter: SortFilterType) => {
        const filters = currentSortFilters.map((filter: SortFilterType) => {
            filter.isChecked = filter.id === sortFilter.id ? filter.isChecked !== true : false;
            return filter;
        });
        const [checkedFilter] = filters.filter((filter: SortFilterType) => filter.isChecked === true);

        setCurrentSortFilters(filters);
        updateSortFilter(checkedFilter);
    };

    const getIcon = (value: SortVariableEnum) => value === SortVariableEnum['-'] ? <FilterDESCIcon /> : <FilterASCIcon />

    return (
        <SortWrapper>
            {currentSortFilters.map((item: SortFilterType) => (
                <SortItem key={item.id}>
                    <TabCustom
                        isActive={item.isChecked}
                        onClick={() => onChangeHandler(item)}
                        icon={getIcon(item.value)}
                        text={item.title}
                    />
                </SortItem>
            ))}
        </SortWrapper>
    );
};

export default SortComponent;
