import {all, fork} from 'redux-saga/effects'

import {IndicatorTreeState} from './indicator-tree/types';
import {AnomalyTreeState} from './anomalies-tree/types';
import {combineReducers} from 'redux';
import {createTreeWithNameData} from './indicator-tree/reducer';
import {anomalyTreeReducer} from './anomalies-tree/reducer';
import indicatorTreeSaga from './indicator-tree/sagas';
import {IIndicatorMetaState} from './indicator-meta/types';
import {indicatorMetaReducer} from './indicator-meta/reducer';
import indicatorMetaSaga from './indicator-meta/sagas';
import anomalyTreeSaga from "./anomalies-tree/sagas";

export interface ReferencesState {
    indicatorTree: IndicatorTreeState;
    anomalyTree: AnomalyTreeState;
    indicatorMeta: IIndicatorMetaState;
}

export const referencesReducer = combineReducers({
    indicatorTree: createTreeWithNameData('indicatorTree'),
    costsTree: createTreeWithNameData('costsTree'),
    anomalyTree: anomalyTreeReducer,
    indicatorMeta: indicatorMetaReducer,
});

export function* referencesSaga() {
    yield all([
        fork(indicatorTreeSaga),
        fork(anomalyTreeSaga),
        fork(indicatorMetaSaga)
    ])
}
