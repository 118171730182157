import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ScenarioCellsAdder} from './ScenarioCellsAdder';
import {InputField} from '../Design/InputField';
import styled from 'styled-components/macro';
import {Select} from '../Design/Select';
import {getKeyByValue} from '../VersionsComponents/ConfigureSimulationDialog/Utils/utils';

const MAX_COLUMN_COUNT = 182;

export const ScenarioEditControl = ({node, verboseIndex, setLeafData, editMode, scrollHandler}) => {
    const {t} = useTranslation();

    const address = node.address;

    const data = node.DATA;
    const choices = verboseIndex[address].choices;
    const control = verboseIndex[address].displayAs;
    const status = node.INPUT_STATUS;

    const refEditWrap = useRef(null);

    // Статус данных - "Значения из версии", "Значения из Кодекса", "Значения пользователя"
    const [inputStatus, setInputStatus]: [string, any] = useState(status);

    // Состояние контроллов
    const [tableState, setTableState]: [any, any] = useState(data || {});
    const [valueState, setValueState]: [string, any] = useState(data);
    const [choiceState, setChoiceState]: [number, any] = useState(data);

    // Временное состояние контроллов перед коммитом в state родителя (дерева)
    const [tmpTableState, setTmpTableState]: [any, any] = useState(tableState);
    const [tmpValueState, setTmpValueState]: [any, any] = useState(valueState);

    // Вспомогательные переменные
    const tmp = Object.assign({}, tableState);
    const tableKeys = Object.keys(tableState);

    const firstKey = Math.min(...tableKeys.map((k) => Number(k)));
    const lastKey = Math.max(...tableKeys.map((k) => Number(k)));

    useEffect(() => {
        // Задать данные при получении новых значений из другого источника
        const dataContainsUpdates = JSON.stringify(data) !== JSON.stringify(tableState);
        const valueContainsUpdates = data !== valueState;
        const choiceContainsUpdates = data !== choiceState;

        // Если тип контролла yearly_table или custom_table задать его значения
        if ((control === 'yearly_table' || control === 'custom_table') && dataContainsUpdates) {
            setTableState({...data});
            setTmpTableState({...data});
        }

        // Если тип контролла value задать value
        if (control === 'value' && valueContainsUpdates) {
            setValueState(data);
            setTmpValueState(data);
        }

        // Если тип контролла choices задать choice
        if (control === 'choices' && choiceContainsUpdates) {
            setChoiceState(data);
        }

        setInputStatus(status);

        //eslint-disable-next-line
    }, [JSON.stringify(data)]);

    useEffect(() => {
        // Вызывается при вводе пользователем данных
        const newData = {
            yearly_table: tmp,
            custom_table: tmp,
            choices: choiceState,
            value: valueState,
        }[control];

        const leafData = {
            ...node,
            DATA: newData,
            INPUT_STATUS: inputStatus,
            META: {
                ...node.META,
                input_status: t(`pages:scenarios.dialog.${inputStatus}`),
            },
        };
        setLeafData(node.address, leafData);
        //eslint-disable-next-line
    }, [JSON.stringify(tmp), valueState, choiceState, inputStatus]);

    // методы контрола choices
    const handleChoiceChange = (value) => {
        if (value === -1) value = null;
        setInputStatus('manual');
        setChoiceState(value);
    };

    // методы контрола value
    const handleValueChange = (value) => {
        if (!isNaN(value)) {
            setTmpValueState(value.trim());
        }
    };

    const handleValueBlur = (value) => {
        setValueState(value);
        setInputStatus('manual');
    };

    // методы контрола table
    const isTimelineExtensionValid = (how, inputValue, add) => {
        const changeBy = how === 'to_year' ? inputValue - lastKey : inputValue;

        // Невалидный кейс
        if (add && changeBy < 0) return false;

        let max = how === 'to_year' ? firstKey + MAX_COLUMN_COUNT : tableKeys.length;
        let min = how === 'to_year' ? firstKey - 1 : 0;

        if (add && how === 'by_count') {
            max = Math.max(MAX_COLUMN_COUNT - tableKeys.length + 1, 0);
        }

        return inputValue > min && inputValue < max;
    };

    const handleDeleteColumn = () => {
        if (isTimelineExtensionValid('by_count', 1, false)) {
            delete tmp[lastKey];
            setTableState(tmp);
            setTmpTableState(tmp);
            setInputStatus('manual');
        }
    };

    const handleAddColumn = () => {
        if (isTimelineExtensionValid('by_count', 1, true)) {
            tmp[lastKey + 1] = tmp[lastKey];
            setTableState(tmp);
            setTmpTableState(tmp);
            setInputStatus('manual');

            setTimeout(() => scrollHandler(refEditWrap.current), 0);
        }
    };

    const handleTimelineChange = (how, inputValue, add) => {
        const changeByAbs = how === 'to_year' ? Math.abs(inputValue - lastKey) : inputValue;
        if (isTimelineExtensionValid(how, inputValue, add)) {
            handleChangeTimeline(changeByAbs, add);
            setInputStatus('manual');
        }
    };

    const handleChangeTimeline = (changeByAbs, add) => {
        for (let i = 0; i < changeByAbs; i++) {
            if (add) {
                tmp[lastKey + i + 1] = tmp[lastKey];
            } else {
                delete tmp[lastKey - i];
            }
        }
        setTableState(tmp);
        setTmpTableState(tmp);
    };

    const handleTableChange = (value, key) => {
        if (!isNaN(value)) {
            const tmp = {...tmpTableState};
            tmp[key] = value.trim();
            setTmpTableState(tmp);
        }
    };

    const handleTableBlur = (value, key) => {
        const tmp = {...tmpTableState};
        tmp[key] = value;
        setTableState(tmp);
        setInputStatus('manual');
    };

    // Тип контролла - варианты выбора (choices)
    if (control === 'choices') {

        const keys = Object.keys(choices);
        // @ts-ignore
        const keyVariants = Object.assign(...keys.map(key => ({[key]: `${choices[key]} (${key})`})));
        const variants = Object.values(keyVariants);
        const choice = keyVariants[choiceState];

        return (
            <SelectCustom
                onChange={(e) => {
                    const newChoice = getKeyByValue(keyVariants, e.target.value);
                    return handleChoiceChange(newChoice);
                }}
                value={choice || ''}
                disabled={!editMode}
                variants={variants}
                width={400}
            />
        );
    }

    // Тип контролла - поле ввода (value)
    if (control === 'value') {
        return (
            <InputField
                isWhite
                value={tmpValueState ?? ''}
                disabled={!editMode}
                onChange={(e) => handleValueChange(e.target.value)}
                onBlur={(e) => handleValueBlur(e.target.value)}
            />
        );
    }

    // Тип контролла - таблица (yearly_table или custom_table)
    return (
        <EditorWrapper ref={refEditWrap}>
            {/* Тип контролла - таблица по годам (yearly_table) */}
            <>
                {control === 'yearly_table' && editMode && (
                    <CalcWrapper>
                        <ButtonControl
                            disabled={tableKeys.length === 1}
                            onClick={handleDeleteColumn}
                        >
                            -
                        </ButtonControl>
                        <ButtonControl
                            disabled={tableKeys.length === MAX_COLUMN_COUNT}
                            onClick={handleAddColumn}
                        >
                            +
                        </ButtonControl>
                        <ScenarioCellsAdder handleTimelineChange={handleTimelineChange} />
                    </CalcWrapper>
                )}
            </>
            <Fields>
                {Object.keys(tmpTableState).map((key, i) => {
                    const value = tmpTableState[key] ?? '';
                    return (
                        <Wrapper key={i}>
                            <Year>{key}</Year>
                            <InputField
                                isWhite={i % 2 === 0}
                                value={value}
                                disabled={!editMode}
                                onChange={(e) => handleTableChange(e.target.value, key)}
                                onBlur={(e) => handleTableBlur(e.target.value, key)}
                            />
                        </Wrapper>
                    );
                })}
            </Fields>
        </EditorWrapper>
    );
};

const EditorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
`;

const Fields = styled.div`
    display: grid;
    grid-column-gap: 0;
    grid-auto-flow: column;

    input {
        height: 30px;
        width: 100%;
        border: 1px solid #ccc;
        border-right: 0;
        padding-left: 8px;

        &:hover {
            background: ${(props) => props.theme.colors.white};
        }
    }
`;

const CalcWrapper = styled.div`
    position: absolute;
    top: 40px;
    left: 55px;
    display: flex;
    align-items: flex-start;
`;

export const ButtonControl = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => (props.disabled ? props.theme.colors.grey3 : props.theme.colors.black)};
    cursor: pointer;
    user-select: none;

    & ~ & {
        margin-left: 3px;
    }
`;

const Wrapper = styled.div`
    width: 100px;

    &:last-child {
        input {
            border-right: 1px solid #ccc;
        }
    }
`;

const Year = styled.div`
    background-color: ${(props) => props.theme.colors.grey5};
    padding: 10px;
`;

const SelectCustom = styled(Select)`
    background: ${(props) => props.theme.colors.white};
`;
