import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import {CalcVersionActionType} from './types';
import {
    deleteCalcVersionsError,
    deleteCalcVersionsSuccess,
    getVersionsError,
    getVersionsSuccess,
} from './actions';
import {getConfig} from '../../config-provider';
import {deleteFilterRowsSuccess} from "../tableSelector/actions";
import {requestSnackDisplay} from "../snackbars/actions";
import i18n from '../../i18n';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');
const PER_PAGE = 30;

function* handleDeleteRequest(action) {

    const url = `${API_ENDPOINT}/calc/versions-bulk-delete`;
    const toDelete = {
        "version_ids": action.payload.ids,
        "delete_children": action.payload.withChildren
    };
    try {
        yield put(requestSnackDisplay('info', i18n.t('notifications:versionDelete.info')));
        const res = yield call(axios.delete, url, {
            headers: {'Content-Type': 'application/json'},
            data: toDelete
        });
        if (res.error) {
            yield put(deleteCalcVersionsError(res.error));
            yield put(requestSnackDisplay('error', `${i18n.t('notifications:versionDelete.error')} ${res.error}`));
        } else {
            yield put(deleteFilterRowsSuccess(res.data.calc_version_ids));
            yield put(deleteCalcVersionsSuccess(res.data.calc_version_ids));
            yield put(requestSnackDisplay('success', i18n.t('notifications:versionDelete.success')));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(deleteCalcVersionsError(err.stack!));
            yield put(requestSnackDisplay('error', `${i18n.t('notifications:versionDelete.error')} ${err.stack}`));
        } else {
            yield put(deleteCalcVersionsError('An unknown error occurred.'));
        }
    }
}

function* handleGetVersions(action) {
    const page = action.payload;
    const url = `${API_ENDPOINT}/calc/versions/?page=`.concat(page.toString(), '&page_size=', PER_PAGE.toString());

    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getVersionsError(res.error));
        } else {
            yield put(getVersionsSuccess(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getVersionsError(err.stack!));
        } else {
            yield put(getVersionsError('An unknown error occured.'));
        }
    }
}

function* handleRecalculateRequest(action) {
    const url = `${API_ENDPOINT}/calculations/run-calc/`;
    const body = {
        base_versions: action.payload.ids,
        recalculate: true,
    };
    try {
        const res = yield call(axios.post, url, body, { headers: { 'Content-Type': 'application/json' } });
        res.error ? yield put(requestSnackDisplay('error', `${i18n.t('notifications:recalculate.error')} ${res.error}`)) :
            yield put(requestSnackDisplay('info', i18n.t('notifications:recalculate.info')));
    } catch (e) {
        if (e instanceof Error) yield put(requestSnackDisplay('error', `${i18n.t('notifications:recalculate.error')} ${e.stack}`));
    }
}

function* handleRecalculateVersionsDisableHistRequest(action) {
    const url = `${API_ENDPOINT}/calc/disable-historic-data`;
    const body = {
        "version_ids": action.payload.ids
    };
    try {
        const res = yield call(axios.post, url, body, { headers: { 'Content-Type': 'application/json' } });
        res.error ? yield put(requestSnackDisplay('error', `${i18n.t('notifications:recalculate.error')} ${res.error}`)) :
            yield put(requestSnackDisplay('info', i18n.t('notifications:recalculate.info')));
    } catch (e) {
        if (e instanceof Error) yield put(requestSnackDisplay('error', `${i18n.t('notifications:recalculate.error')} ${e.stack}`));
    }
}

//eslint-disable-next-line
function* handleCalculateAnomaliesRequest(action) {
    return axios({
        method: 'get',
        url: `${API_ENDPOINT}/start_anom/?id=${action.payload.id}`,
        headers: { 'Content-Type': 'application/json' },
    }).then((r) => r.data)
        .catch((r) => {
            console.error(r);
        })
}

//eslint-disable-next-line
function* handleSwitchOffWellsRequest(action) {
    return axios({
        method: 'post',
        url: `${API_ENDPOINT}/calculations/run-calc/`,
        headers: { 'Content-Type': 'application/json' },
        data: {
            base_versions: action.payload.ids,
            recalculate: Boolean(action.payload.needRecalc),
            switchoff: [
                {
                    type: "switchoff",
                    params: {
                        "iter_limit": action.payload.maxIter,
                        "do_not_redistrib_expenses": action.payload.doNotRedistribExpenses,
                        "switch_off_prod_sites": action.payload.switchOffProdSites,
                        "keep_interim_versions": action.payload.keepInterimVersions,
                        "create_report": action.payload.createReport,
                    },
                },

            ],
        }
    }).then((r) => r.data)
        .catch((r) => {
            console.error(r);
        })
}

//eslint-disable-next-line
function* handleRunUpliftStage1Request(action) {
    return axios({
        method: 'post',
        url: `${API_ENDPOINT}/calculations/run-calc/`,
        headers: { 'Content-Type': 'application/json' },
        data: {
            base_versions: action.payload.ids,
            recalculate: Boolean(action.payload.needRecalc),
            stimulation: [
                {
                    type: "uplift1",
                    params: {
                        iter_limit: action.payload.maxIter,
                        analysis_start: action.payload.startYear,
                        wells_rank_method: action.payload.wellsRankMethod,
                        include_opex_into_k_kap: Boolean(action.payload.include_opex_into_k_kap),
                    },
                },

            ],
        }
    }).then((r) => r.data)
        .catch((r) => {
            console.error(r);
        })
}

//eslint-disable-next-line
function* handleRunUpliftStage2Request(action) {
    return axios({
        method: 'post',
        url: `${API_ENDPOINT}/calculations/run-calc/`,
        headers: { 'Content-Type': 'application/json' },
        data: {
            base_versions: action.payload.ids,
            recalculate: Boolean(action.payload.needRecalc),
            stimulation: [
                {
                    type: "uplift2",
                    params: {
                        iter_limit: action.payload.maxIter,
                        analysis_start: action.payload.startYear,
                        wells_rank_method: action.payload.wellsRankMethod,
                        include_opex_into_k_kap: Boolean(action.payload.include_opex_into_k_kap),
                        wells_limit_type: action.payload.wellsLimitType,
                        enable_k_kap: action.payload.enableKkap,
                        liquid_prod_limit_id: Boolean(action.payload.liquid_prod_limit_id) ? action.payload.liquid_prod_limit_id : null
                    },
                },

            ],
        }
    }).then((r) => r.data)
        .catch((r) => {
            console.error(r);
        })
}

//eslint-disable-next-line
function* handleRunSimulationRequest(action) {
    return axios({
        method: 'post',
        url: `${API_ENDPOINT}/calculations/run-calc/`,
        headers: {'Content-Type': 'application/json'},
        data: {
            base_versions: action.payload.base_versions,
            general: action.payload.general,
            scenarios: Boolean(action.payload.scenarios) ? action.payload.scenarios : null,
            stimulation: Boolean(action.payload.stimulation) ? action.payload.stimulation : null,
            switchoff: Boolean(action.payload.switchoff) ? action.payload.switchoff : null,
            groups: Boolean(action.payload.groups) ? action.payload.groups : null,
            attributes: Boolean(action.payload.attributes) ? action.payload.attributes : null,
            recalculate: Boolean(action.payload.recalculate),
            set_final_tag: action.payload.set_final_tag,
            disable_historical: action.payload.disable_historical
        }
    }).then((r) => r.data)
        .catch((r) => {
            console.log(r);
        })
}

function* watchGetCalcVersionsRequest() {
    yield takeLatest(CalcVersionActionType.GET_VERSIONS_REQUEST, handleGetVersions);
}

function* watchRecalculateRequest() {
    yield takeLatest(CalcVersionActionType.RECALCULATE_VERSIONS, handleRecalculateRequest)
}

function* watchRecalculateVersionsDisableHistRequest() {
    yield takeLatest(CalcVersionActionType.RECALCULATE_VERSIONS_DISABLE_HIST, handleRecalculateVersionsDisableHistRequest)
}

function* watchDeleteCalcVersionsRequest() {
    yield takeLatest(CalcVersionActionType.DELETE_CALC_VERSIONS_REQUEST, handleDeleteRequest);
}

function* watchCalculateAnomaliesRequest() {
    yield takeLatest(CalcVersionActionType.CALCULATE_ANOMALIES, handleCalculateAnomaliesRequest);
}

function* watchSwitchOffWellsRequest() {
    yield takeLatest(CalcVersionActionType.SWITCH_OFF_WELLS, handleSwitchOffWellsRequest);
}

function* watchRunUpliftStage1Request() {
    yield takeLatest(CalcVersionActionType.RUN_UPLIFT_STAGE_1, handleRunUpliftStage1Request);
}

function* watchRunUpliftStage2Request() {
    yield takeLatest(CalcVersionActionType.RUN_UPLIFT_STAGE_2, handleRunUpliftStage2Request);
}

function* watchRunSimulationRequest() {
    yield takeLatest(CalcVersionActionType.RUN_SIMULATION, handleRunSimulationRequest);
}


function* calcVersionsSaga() {
    yield all([
        fork(watchGetCalcVersionsRequest),
        fork(watchDeleteCalcVersionsRequest),
        fork(watchRecalculateRequest),
        fork(watchRecalculateVersionsDisableHistRequest),
        fork(watchCalculateAnomaliesRequest),
        fork(watchRunUpliftStage1Request),
        fork(watchRunUpliftStage2Request),
        fork(watchSwitchOffWellsRequest),
        fork(watchRunSimulationRequest)
        ]
    );
}

export default calcVersionsSaga;
