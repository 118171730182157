import {action} from 'typesafe-actions'
import {IIndicatorTreeNode, IndicatorTreeActionType} from './types'

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
export const fetchRequest = (treeName: string, versionIds: number[], includeEmpty: boolean) => action(IndicatorTreeActionType.FETCH_REQUEST, {treeName, versionIds, includeEmpty});

// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.
export const fetchSuccess = (data: IIndicatorTreeNode[], treeName = 'indicatorTree') => action(IndicatorTreeActionType.FETCH_SUCCESS, {data, treeName});
export const fetchError = (message: string, treeName = 'indicatorTree') => action(IndicatorTreeActionType.FETCH_ERROR, {message, treeName});

export const addIndicator = (indicator: string, title: string, treeName = 'indicatorTree') => action(IndicatorTreeActionType.ADD_INDICATOR, {
    indicator,
    title,
    treeName
});
export const deleteIndicator = (indicator: string, treeName = 'indicatorTree') => action(IndicatorTreeActionType.DELETE_INDICATOR, {indicator, treeName});
export const reselectIndicator = (selection, treeName = 'indicatorTree') => action(IndicatorTreeActionType.RESELECT, {selection, treeName});
//export const setCurrentIndicator = (indicator: string) => action(IndicatorTreeActionType.SET_CURRENT_INDICATOR, {indicator})
//export const setCurrentIndicatorId = (id: string) => action(IndicatorTreeActionType.SET_CURRENT_INDICATOR_ID, {id})
export const setIncludeEmptyIndicators = (includeEmpty: boolean, treeName = 'indicatorTree') => action(IndicatorTreeActionType.SET_INCLUDE_EMPTY_INDICATORS, {includeEmpty, treeName});