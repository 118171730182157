import {Reducer} from 'redux';
import {CalcVersionActionType, ICalcVersion, ICalcVersionState} from './types';
import {addAll} from "../utils";

function deleteVersions(prevVersions: Map<number, ICalcVersion>, ids: [number]) {
    const newVersions = new Map(prevVersions);
    ids.map(id => newVersions.delete(id));
    return newVersions;
}

function deleteSingleVersion(prevVersions: Map<number, ICalcVersion>, id: number) {
    const newVersions = new Map(prevVersions);
    newVersions.delete(id);
    return newVersions;
}

export const initialState: ICalcVersionState = {
    calcVersions: null,
    loading: false,
    error: null,
    TOTAL_PAGES: 0,
    PER_PAGE: 30,
};

const reducer: Reducer<ICalcVersionState> = (state = initialState, action) => {
    switch (action.type) {
        case CalcVersionActionType.GET_VERSIONS_REQUEST: {
            return {...state, loading: true};
        }
        case CalcVersionActionType.GET_VERSIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                calcVersions: addAll(action.payload.results),
                TOTAL_PAGES: Math.ceil(action.payload.count / state.PER_PAGE)
            };
        }
        case CalcVersionActionType.GET_VERSIONS_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case CalcVersionActionType.DELETE_CALC_VERSIONS_SUCCESS: {
            return {...state, calcVersions: deleteVersions(state.calcVersions, action.payload.ids)}
        }
        case CalcVersionActionType.DELETE_CALC_VERSION_DONE: {
            return {
                ...state,
                calcVersions: deleteSingleVersion(state.calcVersions, action.payload.id)
            }
        }
        default: {
            return state;
        }
    }
};

export {reducer as calcVersionReducer};
