import {eventChannel} from 'redux-saga';
import {all, call, fork, put, select, take, takeLatest} from 'redux-saga/effects';
import {v4 as uuid4} from 'uuid';
import {addCalcJob, deleteCalcJobDone, updateCalcJob} from './calcJob/actions';
import {deleteCalcVersionDone, deleteCalcVersionsSuccess} from './calcVersion/actions';
import {getConfig} from '../config-provider';
import {addTask, deleteTask, updateTask} from './tasks/actions';
import {addReport, deleteReportDone, updateReport} from './reports/actions';
import {requestSnackDisplay} from './snackbars/actions';
import ReconnectingWebSocket from 'reconnecting-websocket';
import {AppPropertiesAction} from './appProperties/types';
import {LoginActionType} from './login/types';
import {TasksActionType} from './tasks/types';
import {StimulationActionType} from './stimulationToolbar/types';
import {wsPortfolioAnalysisSuccess} from './stimulationToolbar/actions';
import i18n from '../i18n';

const NOTIFICATIONS_ENDPOINT = getConfig('REACT_APP_PUSH_NOTIFICATIONS_ENDPOINT');

const HEARTBEAT_INTERVAL = 2000;


function websocketInitChannel(auth_enabled, userId) {
    if ((auth_enabled === null || auth_enabled) && !userId) {
        return null;
    } else {
        return eventChannel(emitter => {
            const options = {
                connectionTimeout: 5000,
                maxRetries: 10,
            };
            const ws = new ReconnectingWebSocket(NOTIFICATIONS_ENDPOINT, [], options);
            ws.onmessage = event => {
                const {topic, message} = JSON.parse(event.data);
                emitter({topic, message});
            };

            let interval;

            ws.onopen = () => {
                interval = setInterval(() => {
                    ws.send(
                        JSON.stringify({
                            topic: 'oto:push-notifications:client:heartbeat',
                            correlationId: uuid4(),
                        }),
                    );
                }, HEARTBEAT_INTERVAL);
            };

            ws.onclose = () => {
                clearInterval(interval);
            };

            return () => {
            };
        });
    }
}

// const CALC_JOB_MOCK = {
//   id: 3,
//   status: 'SUCCESS',
//   calc_version: {
//     id: 100,
//     calc_job: 3,
//     name: 'ТЕСТ!!!',
//     created_at: '2019-09-06T08:46:29.493901+03:00',
//     calc_set: 1,
//     parent_version: null,
//     version_type: 1,
//     links: []
//   },
//   source_name: 'Forms26_03092019.xlsx',
//   set_version_name: '',
//   error: '',
//   created_at: '2019-09-06T06:55:27.778219+03:00',
//   started_at: '2019-09-06T06:56:13.713516+03:00',
//   duration: 200
// };
const snackType = (message, status) => {
    if (status.get(message.status).alias === `SUCCESS`) {
        return 'success';
    } else if (status.get(message.status).alias === `IN_PROGRESS`) {
        return 'warning';
    } else if (status.get(message.status).alias === `ERROR`) {
        return 'error';
    } else {
        return 'warning';
    }
};

function* pushNotificationHandler() {
    const authEnabled = yield select(state => state.appProperties.auth_enabled);
    const userId = yield select(state => state.login.user ? state.login.user.id : null);
    const channel = yield call(websocketInitChannel, authEnabled, userId);
    const statusTable = yield select(state => state.tasks.task_status_table);
    const currentUser = (message) => {
        if (authEnabled) {
            if (!userId) {
                return false;
            } else if (!message.created_by) {
                return false;
            } else if (userId === message.created_by.id) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };
    // yield delay(2000)
    // yield put(updateCalcJob(CALC_JOB_MOCK.id, CALC_JOB_MOCK as any));
    while (true && channel && statusTable.size) {
        let {topic, message} = yield take(channel);
        const split = topic.split('::')[0];

        switch (split) {
            case 'oto:push-notifications:calc-job:status-change':
                yield put(updateCalcJob(message.id, message));
                break;
            case 'oto:push-notifications:taskman:create':
                yield put(addTask(message));
                if (currentUser(message)) yield put(requestSnackDisplay('info', `${i18n.t('notifications:messages.taskCreated')} ${message.task_type_verbose_name}: ${i18n.t('notifications:messages.file')} ${message.payload_label}`));
                break;
            case 'oto:push-notifications:taskman:status-change':
                yield put(updateTask(message.id, message));
                if (currentUser(message)) yield put(requestSnackDisplay(snackType(message, statusTable), `${i18n.t('notifications:messages.taskStatus')} ${message.task_type_verbose_name} : ${message.payload_label} ${i18n.t('notifications:messages.changed')} : ${statusTable.get(message.status).name}`));
                break;
            case 'oto:push-notifications:taskman:delete':
                yield put(deleteTask(message.id));
                break;
            case 'oto:push-notifications:calc-job:create':
                yield put(addCalcJob(message));
                break;
            case 'oto:push-notifications:calc-job:delete':
                if (message.isVersionDeleted) {
                    yield put(deleteCalcVersionDone(message.versionId));
                }
                yield put(deleteCalcJobDone(message.calcJobId));
                break;
            case 'oto:push-notifications:analytics-report:delete':
                yield put(deleteReportDone(message.reportId));
                break;
            case 'oto:push-notifications:calc-version:delete':
                yield put(deleteCalcVersionsSuccess(message.calc_version_ids));
                break;
            case 'oto:push-notifications:analytics-report:create':
                yield put(addReport(message));
                break;
            case 'oto:push-notifications:analytics-report:status-change':
                yield put(updateReport(message.id, message));
                break;
            case 'oto:push-notifications:analytics-report:full-kpi-report:create':
                yield put(wsPortfolioAnalysisSuccess('portfolioAnalysisFilter'));
                yield put(requestSnackDisplay('success', `${i18n.t('notifications:messages.report')} ${message.name} ${i18n.t('notifications:messages.finished')}`));
                break;
            case 'oto:push-notifications:server:heartbeat':
                break;
            default:
                console.log(`Unknown message type ${split}`);
                yield;
        }
    }
}

function* watchAppVersionRequest() {
    yield takeLatest([AppPropertiesAction.GET_APP_PROPERTIES_SUCCESS, LoginActionType.LOGIN_SUCCESS, TasksActionType.GET_STATUS_TABLE_SUCCESS, StimulationActionType.ANALYSIS_REQUEST], pushNotificationHandler);
}

export default function* pushNotificationSaga() {
    yield all([fork(watchAppVersionRequest)]);
}
