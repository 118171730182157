import React from 'react';
import styled from 'styled-components';
import { Icon } from './icon.styles';

import IconStartCalculate from '../../../assets/images/icons/control/icon-start-calculate.svg';
import IconAddToGroup from '../../../assets/images/icons/control/icon-add-to-group.svg';
import IconAnomalies from '../../../assets/images/icons/control/icon-calculate-anomalies.svg';
import IconDownload from '../../../assets/images/icons/control/icon-download.svg';
import IconRemove from '../../../assets/images/icons/control/icon-remove.svg';
import IconShutdownOfWells from '../../../assets/images/icons/control/icon-shutdown-of-wells.svg';
import IconRunCalc from '../../../assets/images/icons/control/icon-run-calc.svg';
import IconRecalculateCurrentRelease from '../../../assets/images/icons/control/icon-recalculate-current-release.svg';
import IconRecalculateHistoryValue from '../../../assets/images/icons/control/icon-recalculate-history-value.svg';
import IconConfigureAttributes from '../../../assets/images/icons/control/icon-configure-attributes.svg';
import IconConfigureGroups from '../../../assets/images/icons/control/icon-configure-groups.svg';

type IconProps = {
    onClick?: any;
    outline?: boolean;
    disabled?: boolean;
};

export const StartCalculateIcon: React.FC<IconProps> = ({ onClick, outline = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={onClick} src={IconStartCalculate} alt="start-calculate-icon" />
    </IconWrapper>
);

export const RecalculateCurrentReleaseIcon: React.FC<IconProps> = ({ onClick, outline = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={onClick} src={IconRecalculateCurrentRelease} alt="recalculate-current-release-icon" />
    </IconWrapper>
);

export const RecalculateHistoryValue: React.FC<IconProps> = ({ onClick, outline = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={onClick} src={IconRecalculateHistoryValue} alt="recalculate-history-value-icon" />
    </IconWrapper>
);

export const AddToGroupIcon: React.FC<IconProps> = ({ onClick, outline = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={onClick} src={IconAddToGroup} alt="add-in-group-icon" />
    </IconWrapper>
);

export const ConfigureAttributesIcon: React.FC<IconProps> = ({ onClick, outline = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={onClick} src={IconConfigureAttributes} alt="configure-attributes" />
    </IconWrapper>
);

export const ConfigureGroupsIcon: React.FC<IconProps> = ({ onClick, outline = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={onClick} src={IconConfigureGroups} alt="configure-groups" />
    </IconWrapper>
);

export const AnomaliesIcon: React.FC<IconProps> = ({ onClick, outline = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={onClick} src={IconAnomalies} alt="anomalies-icon" />
    </IconWrapper>
);

export const DownloadIcon: React.FC<IconProps> = ({ onClick, outline = false, disabled = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={disabled ? {} : onClick} src={IconDownload} alt="download-icon" />
    </IconWrapper>
);

export const RemoveIcon: React.FC<IconProps> = ({ onClick, outline = false, disabled = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={disabled ? {} : onClick} src={IconRemove} alt="remove-icon" />
    </IconWrapper>
);

export const ShutdownOfWellsIcon: React.FC<IconProps> = ({ onClick, outline = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={onClick} src={IconShutdownOfWells} alt="shutdown-of-wells-icon" />
    </IconWrapper>
);

export const RunCalcIcon: React.FC<IconProps> = ({ onClick, outline = false }) => (
    <IconWrapper outline={outline}>
        <IconControl onClick={onClick} src={IconRunCalc} alt="run-calc-icon" />
    </IconWrapper>
);

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => (props.outline ? 'none' : props.theme.colors.grey4)};
    border-radius: 50%;
    padding: 3px;
    opacity: ${(props) => (props.disabled ? '50%' : '100%')};
`;

const IconControl = styled(Icon)`
    width: 30px;
    height: 30px;
`;
