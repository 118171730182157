import {all, call, fork, put, takeLatest, select} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import * as queryString from 'query-string';
import {TasksActionType} from "./types";
import {
    appendAllTasksToSelectedSuccess,
    getStatusTableSuccess,
    getTasksError, getTasksSizeError, getTasksSizeRequest, getTasksSizeSuccess, getTasksStatsSuccess,
    getTasksSuccess, getTasksTypesSuccess, revokeSelectedTasksSuccess
} from "./actions";
import {getConfig} from "../../config-provider";

const getTaskState = (state) => state.tasks
const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');
const PER_PAGE = 30;


function* handleGetTasksRequest(action) {
    const taskState = yield select(getTaskState);
    const page = action?.payload?.page || taskState.page
    const statuses = action?.payload?.selected_statuses || taskState.selected_statuses;
    const types = action?.payload?.selected_types || taskState.selected_types;
    const searchQuery = action?.payload?.searchQuery;

    let queryParams = queryString.stringify({
        page: page,
        page_size: PER_PAGE,
        hide_system_tasks: !taskState.show_system_tasks,
        show_only_my_tasks: taskState.show_my_tasks,
        status__in: statuses,
        type__in: types,
        search: searchQuery
    }, {arrayFormat: 'comma', skipEmptyString: true});

    const url = `${API_ENDPOINT}/task/tasks/?${queryParams}`;

    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getTasksError(res.error))
        } else {
            yield put(getTasksSizeRequest(statuses, types, searchQuery))
            yield put(getTasksSuccess(res.data, page))
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(getTasksError(error.stack!))
        } else {
            yield put(getTasksError('An unknown error occurred.'))
        }
    }
}

function* handleGetStatusTableRequest() {
    const url = `${API_ENDPOINT}/task/task_status/`;
    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getTasksError(res.error))
        } else {
            yield put(getStatusTableSuccess(res.data))
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(getTasksError(error.stack!))
        } else {
            yield put(getTasksError('An unknown error occurred.'))
        }
    }
}

function* handleGetTasksStatsRequest(action) {
    const taskState = yield select(getTaskState);
    const searchQuery = action?.payload?.searchQuery;

    let queryParams = queryString.stringify({
        hide_system_tasks: taskState.system_tasks_hidden,
        show_only_my_tasks: taskState.show_my_tasks,
        search: searchQuery
    }, {arrayFormat: 'comma', skipEmptyString: true});

    const url = `${API_ENDPOINT}/task/statistics/?${queryParams}`;

    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getTasksError(res.error))
        } else {
            yield put(getTasksStatsSuccess(res.data))
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(getTasksError(error.stack!))
        } else {
            yield put(getTasksError('An unknown error occurred.'))
        }
    }
}

function* handleRevokeTasksRequest(action) {
    const url = `${API_ENDPOINT}/task/revoke`;

    try {
        const res = yield call(axios.post, url, {'task_ids': action.payload})
        if (res.error) {
            yield put(getTasksError(res.error))
        } else {
            yield put(revokeSelectedTasksSuccess())
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(getTasksError(error.stack!))
        } else {
            yield put(getTasksError('An unknown error occurred.'))
        }
    }
}

function* handleGetTasksTypesRequest() {
    const url = `${API_ENDPOINT}/task/task_type`;
    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getTasksError(res.error))
        } else {
            yield put(getTasksTypesSuccess(res.data))
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(getTasksError(error.stack!))
        } else {
            yield put(getTasksError('An unknown error occurred.'))
        }
    }
}

function* handleSelectAllTasks(action) {
    const taskState = yield select(getTaskState);
    const page = action?.payload?.page || taskState.page;
    const statuses = action?.payload?.selected_statuses || taskState.selected_statuses;
    const types = action?.payload?.selected_types || taskState.selected_types;
    const searchQuery = action?.payload?.searchQuery;

    let queryParams = queryString.stringify({
        page: page,
        page_size: PER_PAGE,
        hide_system_tasks: taskState.system_tasks_hidden,
        show_only_my_tasks: taskState.show_my_tasks,
        status__in: statuses,
        type__in: types,
        search: searchQuery
    }, {arrayFormat: 'comma', skipEmptyString: true});

    const url = `${API_ENDPOINT}/task/task_ids/?${queryParams}`;

    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getTasksError(res.error))
        } else {
            yield put(appendAllTasksToSelectedSuccess(res.data))
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(getTasksError(error.stack!))
        } else {
            yield put(getTasksError('An unknown error occurred.'))
        }
    }
}

function* handleGetTasksAmountBySelectedFilters(action) {
    const taskState = yield select(getTaskState);
    const page = action?.payload?.page || taskState.page;
    const statuses = action?.payload?.selected_statuses || taskState.selected_statuses;
    const types = action?.payload?.selected_types || taskState.selected_types;
    const searchQuery = action?.payload?.searchQuery;

    let queryParams = queryString.stringify({
        page: page,
        page_size: PER_PAGE,
        hide_system_tasks: taskState.system_tasks_hidden,
        show_only_my_tasks: taskState.show_my_tasks,
        status__in: statuses,
        type__in: types,
        search: searchQuery
    }, {arrayFormat: 'comma', skipEmptyString: true});

    const url = `${API_ENDPOINT}/task/statistics/?${queryParams}`;

    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getTasksSizeError(res.error))
        } else {
            yield put(getTasksSizeSuccess(res.data.tasks_total))
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(getTasksSizeError(error.stack!))
        } else {
            yield put(getTasksSizeError('An unknown error occurred.'))
        }
    }
}

function* watchRevokeTasksRequest() {
    yield takeLatest(TasksActionType.REVOKE_SELECTED_TASKS_REQUEST, handleRevokeTasksRequest)
}

function* watchGetTasksRequest() {
    yield takeLatest([TasksActionType.GET_TASKS_REQUEST, TasksActionType.ADD_TASK, TasksActionType.REVOKE_SELECTED_TASKS_SUCCESS, TasksActionType.RESET_FILTERS], handleGetTasksRequest)
    yield takeLatest(TasksActionType.GET_TASKS_REQUEST, handleGetTasksTypesRequest)
    yield takeLatest(TasksActionType.GET_TASKS_REQUEST, handleGetStatusTableRequest)
    yield takeLatest(TasksActionType.GET_TASKS_SIZE_REQUEST, handleGetTasksAmountBySelectedFilters)
    yield takeLatest([TasksActionType.GET_TASKS_REQUEST, TasksActionType.GET_TASKS_STATS_REQUEST, TasksActionType.ADD_TASK, TasksActionType.REVOKE_SELECTED_TASKS_SUCCESS, TasksActionType.UPDATE_TASK, TasksActionType.DELETE_TASK, TasksActionType.RESET_FILTERS], handleGetTasksStatsRequest)
}

function* watchStatusTableRequest() {
    yield takeLatest(TasksActionType.GET_STATUS_TABLE, handleGetStatusTableRequest)
}

function* watchSelectAllTasks() {
    yield takeLatest(TasksActionType.APPEND_ALL_TASKS_TO_SELECTED_REQUEST, handleSelectAllTasks)
}

function* tasksSaga() {
    yield all([fork(watchGetTasksRequest), watchStatusTableRequest(), watchRevokeTasksRequest(), watchSelectAllTasks()]);
}

export default tasksSaga