import React from 'react';
import ReactTooltip from 'react-tooltip';
import {Tooltip as MTooltip} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';

type TooltipProps = {
    place?: 'right' | 'left' | 'bottom' | 'top';
    isCapture?: boolean;
    delayShow?: number;
    effect?: 'solid' | 'float';
    children?: any
};

type MaterialTooltipProps = {
    placement?: "right" | "left" | "bottom" | "top" | "top-start" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end";
    enterDelay?: number;
    title: string;
    children: React.ReactElement;
    arrow?: boolean;
    disablePortal?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = ({
    place = 'right',
    isCapture = false,
    delayShow = 750,
    effect = 'solid',
    children
}) => (
    <ReactTooltip
        className="react-tooltip"
        place={place}
        isCapture={isCapture}
        delayShow={delayShow}
        effect={effect}
        children={children}
    />
);

export const MaterialTooltip: React.FC<MaterialTooltipProps> = ({
        placement = "top",
        enterDelay = 750,
        title,
        children,
        arrow = true,
        disablePortal = false
    }) => {
    const classes = useStylesTooltip();
    const popperProps = {disablePortal};

    return (
        <MTooltip
            classes={classes}
            className="react-tooltip"
            placement={placement}
            enterDelay={enterDelay}
            title={title}
            arrow={arrow}
            PopperProps={popperProps}
        >{children}</MTooltip>
    )
};

const useStylesTooltip = makeStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '13px',
        borderRadius: '2px',
        padding: '10px 15px',
        maxWidth: 'none',
        // width: '100%',
    }
}));