import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../store';
import {setFilterSearch} from '../store/tableSelector/actions';
import {useTranslation} from 'react-i18next';
import {useDebounceFn} from 'ahooks';
import {InputField} from './Design/InputField';

const GROUP_1_FILTER_NAME = 'group1Filter';
const GROUP_2_FILTER_NAME = 'group2Filter';

interface PassedProps {
    filterName: string;
    className?: any;
}

type SearchProps = PassedProps;

const CustomSearchBox: React.FC<SearchProps> = ({className, filterName}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const search = useSelector<ApplicationState, string>(
        (store) => store[filterName].currentFilters.search);
    
    const [searchState, setSearchState] = useState(search);

    const isGroupFilter = [GROUP_1_FILTER_NAME, GROUP_2_FILTER_NAME].includes(filterName);

    useEffect(() => {
        setSearchState(search);
    }, [search]);

    const {run} = useDebounceFn((search: string) => {
        if (isGroupFilter) {
            dispatch(setFilterSearch(search, GROUP_1_FILTER_NAME));
            dispatch(setFilterSearch(search, GROUP_2_FILTER_NAME));
        } else {
            dispatch(setFilterSearch(search, filterName));
        }
    }, {
        wait: 1250,
    });

    return (
        <InputField
            className={className}
            value={searchState}
            onChange={(event) => {
                setSearchState(event.target.value);
                run(event.target.value);
            }}
            placeholder={t('components:search', 'Поиск')}
        />
    );
};

export default CustomSearchBox;
