import React from 'react';
import {Icon} from './icon.styles';

import AnalyticImage from '../../../assets/images/icons/menu/icon_analytic_grey.svg';
import ImportImage from '../../../assets/images/icons/menu/icon_import.svg';
import AnomaliesImage from '../../../assets/images/icons/menu/icon-anomalies.svg';
import CalculationImage from '../../../assets/images/icons/menu/icon-calculation.svg';
import ExpensesImage from '../../../assets/images/icons/menu/icon-expenses.svg';
import ReportsImage from '../../../assets/images/icons/menu/icon-reports.svg';
import VersionImage from '../../../assets/images/icons/menu/icon-version.svg';
import VersionCardImage from '../../../assets/images/icons/menu/icon-version-card.svg';
// import ReleaseImage from '../../../assets/images/icons/menu/icon-release.svg';
import RolesImage from '../../../assets/images/icons/menu/icon-roles.svg';
import SettingImage from '../../../assets/images/icons/menu/icon-setting.svg';
import TaxImage from '../../../assets/images/icons/menu/icon-tax.svg';
import MacroImage from '../../../assets/images/icons/menu/icon-macro.svg';
import CO2Image from '../../../assets/images/icons/menu/icon-co2.svg';
import CostsScenarioImage from '../../../assets/images/icons/menu/icon-costs.svg';
import GeoMapImage from '../../../assets/images/icons/menu/icon-globe.svg';
import PortfolioImage from '../../../assets/images/icons/menu/icon-portfolio.svg';
import CompareVersionsImage from '../../../assets/images/icons/menu/icon-compare-versions.svg';

export const AnalyticIcon: React.FC = () => <Icon src={AnalyticImage} alt="analytic-icon" />;
export const AnomaliesIcon: React.FC = () => <Icon src={AnomaliesImage} alt="anomalies-icon" />;
export const CalculationIcon: React.FC = () => <Icon src={CalculationImage} alt="calculation-icon" />;
export const ExpensesIcon: React.FC = () => <Icon src={ExpensesImage} alt="expenses-icon" />;
export const ReportsIcon: React.FC = () => <Icon src={ReportsImage} alt="reports-icon" />;
export const VersionIcon: React.FC = () => <Icon src={VersionImage} alt="version-icon" />;
// export const ReleaseIcon: React.FC = () => <Icon src={ReleaseImage} alt="release-icon" />;
export const ImportIcon: React.FC = () => <Icon src={ImportImage} alt="import-icon" />;
export const RolesIcon: React.FC = () => <Icon src={RolesImage} alt="roles-icon" />;
export const SettingIcon: React.FC = () => <Icon src={SettingImage} alt="setting-icon" />;
export const TaxIcon: React.FC = () => <Icon src={TaxImage} alt="tax-icon" />;
export const MacroIcon: React.FC = () => <Icon src={MacroImage} alt="macro-icon" />;
export const CO2Icon: React.FC = () => <Icon src={CO2Image} alt="CO2-icon" />;
export const CostsScenarioIcon: React.FC = () => <Icon src={CostsScenarioImage} alt="costsscenario-icon" />;
export const GeoMapIcon: React.FC = () => <Icon src={GeoMapImage} alt="geomap-icon" />;
export const PortfolioIcon: React.FC = () => <Icon src={PortfolioImage} alt="portfolio-icon" />;
export const CompareVersionsIcon: React.FC = () => <Icon src={CompareVersionsImage} alt="compare-versions" />;
export const VersionCardIcon: React.FC = () => <Icon src={VersionCardImage} alt="version-card" />;
