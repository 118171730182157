const _window = window as any;

if (process.env.REACT_APP_API_ENDPOINT && process.env.REACT_APP_API_ENDPOINT.length) {
    if (_window.REACT_APP_API_ENDPOINT) {
        throw Error(`Опция конфигурации REACT_APP_API_ENDPOINT не может быть одновременно установлена и в .env и в ./public/config.js`)
    }
    _window.REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
}

if (process.env.REACT_APP_PUSH_NOTIFICATIONS_ENDPOINT && process.env.REACT_APP_PUSH_NOTIFICATIONS_ENDPOINT.length) {
    if (_window.REACT_APP_PUSH_NOTIFICATIONS_ENDPOINT) {
        throw Error(`Опция конфигурации REACT_APP_PUSH_NOTIFICATIONS_ENDPOINT не может быть одновременно установлена и в .env и в ./public/config.js`)
    }
    _window.REACT_APP_PUSH_NOTIFICATIONS_ENDPOINT = process.env.REACT_APP_PUSH_NOTIFICATIONS_ENDPOINT
}

if (process.env.REACT_APP_USE_AG_GRID_ENTERPRISE && process.env.REACT_APP_USE_AG_GRID_ENTERPRISE.length) {
    if (_window.REACT_APP_USE_AG_GRID_ENTERPRISE) {
        throw Error(`Опция конфигурации REACT_APP_USE_AG_GRID_ENTERPRISE не может быть одновременно установлена и в .env и в ./public/config.js`)
    }
    _window.REACT_APP_USE_AG_GRID_ENTERPRISE = process.env.REACT_APP_USE_AG_GRID_ENTERPRISE
}

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_DSN.length) {
    if (_window.REACT_APP_SENTRY_DSN) {
        throw Error(`Опция конфигурации SENTRY_DSN не может быть одновременно установлена и в .env и в ./public/config.js`)
    }
    _window.REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
}
if (process.env.REACT_APP_AUTH_ENDPOINT && process.env.REACT_APP_AUTH_ENDPOINT.length) {
    if (_window.REACT_APP_AUTH_ENDPOINT) {
        throw Error(`Опция конфигурации REACT_APP_AUTH_ENDPOINT не может быть одновременно установлена и в .env и в ./public/config.js`)
    }
    _window.REACT_APP_AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT
}

export function getConfig(key: string) {
    return _window[key]
}
