import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import axios from '../../axiosMiddleware';

import {IWells, WellsActionType} from './types'
import {getWellsError, getWellsSuccess, setWellStateError, setWellStateSuccess,} from './actions'
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleWellsRequest(action) {
    const {versionId} = action.payload;
    try {
        const res = yield call(axios.get, `${API_ENDPOINT}/wells/${versionId}`,);
        if (res.error) {
            yield put(getWellsError(res.error))
        } else {
            yield put(getWellsSuccess(res.data.wells as IWells))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getWellsError(err.stack!))
        } else {
            yield put(getWellsError('An unknown error occured.'))
        }
    }
}

function* handleSetWellRequest(action) {
    const {versionId, well, state} = action.payload;
    try {
        const res = yield call(axios.post, `${API_ENDPOINT}/wells/${versionId}?well=${well}&enabled=${state}`);
        if (res.error) {
            yield put(setWellStateError(res.error))
        } else {
            yield put(setWellStateSuccess(res.data))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(setWellStateError(err.stack!))
        } else {
            yield put(setWellStateError('An unknown error occured.'))
        }
    }
}


function* watchWellsRequest() {
    yield takeLatest(WellsActionType.GET_WELLS_REQUEST, handleWellsRequest)
}

function* watchSetWellRequest() {
    yield takeLatest(WellsActionType.SET_WELL_STATE_REQUEST, handleSetWellRequest)
}

export function* wellsSaga() {
    yield all([fork(watchWellsRequest)])
}

export function* setWellSaga() {
    yield all([fork(watchSetWellRequest)])
}
