export enum CostsFilterActionType {
    GET_OPTIONS_REQUEST = '@@costsFilter/GET_OPTIONS_REQUEST',
    GET_OPTIONS_SUCCESS = '@@costsFilter/GET_OPTIONS_SUCCESS',
    GET_OPTIONS_ERROR = '@@costsFilter/GET_OPTIONS_ERROR',

    SET_SELECTED_OPTIONS = '@@costsFilter/SET_SELECTED_OPTIONS',
    SET_COSTS_GROUPBY = '@@costsFilter/SET_COSTS_GROUPBY',
    CHANGE_SETTINGS = '@@costsFilter/CHANGE_SETTINGS',

    SET_COSTS_OUTLIER_CUTOFF = '@@costsFilter/SET_COSTS_OUTLIER_CUTOFF'
}

export interface ICostsFilterSettings {
    readonly page: number
    readonly page_size: number
    readonly show_zero: boolean
    readonly count: number
}

export interface ICostsFilterState {
    loading: boolean
    regions: string[]
    prodSiteTypes: string[]
    selectedRegions: string[]
    selectedProdSiteTypes: string[]
    groupby: string[],
    outlierCutoff: number, 
    settings: ICostsFilterSettings
}