import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Draggable} from 'react-beautiful-dnd';
import {MaterialTooltip} from '../Design/Tooltip';
import CustomAutocompleteFilter from '../Autocomplete/CustomAutocomplete';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import FilterLabel from './FilterLabel';
import {
    CheckboxCheckedIcon,
    CheckboxIcon,
    CheckboxMinusIcon,
} from '../Design/Checkboxes/CheckboxMock';

const MTableHeader = React.memo((props) => {
    const {
        orderBy,
        cols,
        draggable,
        showActionsColumn,
        hasSelection,
        actionsHeaderIndex,
        hasDetailPanel,
        isTreeData,
        detailPanelColumnAlignment,
        customSelectionHeaderCell,
        columns,
        sortBy,
        orderDirection,
        filterMap,
        filterRef,
        filterName,
        localization,
        showSelectAllCheckbox,
        selectedCount,
        dataCount,
        onAllSelected,
        handleFilterChange,
        handleLoadFiltersByName
    } = props;
    const renderHeader = useMemo(() => {
        const size = 'small';

        if (Boolean(cols) && cols.every((col) => Boolean(col.tableData))) {
            const mapArr = cols
                .filter((columnDef) => !columnDef.hidden && !(columnDef.tableData.groupOrder > -1))
                .sort((a, b) => a.tableData.columnOrder - b.tableData.columnOrder)
                .map((columnDef, index) => {
                    let content = columnDef.title;
                    const shortName = columnDef.shortName;

                    if (draggable) {
                        content = (
                            <Draggable
                                key={columnDef.tableData.id}
                                draggableId={columnDef.tableData.id.toString()}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {columnDef.title}
                                    </div>
                                )}
                            </Draggable>
                        );
                    }

                    if (columnDef.tooltip) {
                        content = (
                            <MaterialTooltip title={columnDef.tooltip}>
                                <span>{content}</span>
                            </MaterialTooltip>
                        );
                    }
                    const redOrGrey = () =>
                        columnDef.filtering && filterMap.get(content)?.excluded?.length
                            ? {color: '#FF4136'}
                            : {color: '#586069'};
                    const filterColor =
                        columnDef.filtering && filterMap.get(content)?.selected?.length
                            ? {color: '#188CE5'}
                            : redOrGrey();

                    const filterContent = filterMap.get(content);

                    const {
                        filterOptions = [],
                        selected = [],
                        stateBranch = '',
                        excluded = [],
                        withExclusion = false,
                        isColumnSelector = false,
                    } = filterContent || {};

                    return (
                        <TableCell
                            key={columnDef.tableData.id}
                            style={{
                                width: columnDef.field === 'columns' ? "1%" : 50,
                                padding: columnDef.field === 'columns' ? '5px 0px' : '5px 15px',
                            }}
                            size={size}
                            className={columnDef.field + '-column-header'}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignContent: 'space-between',
                                    flexDirection: 'column',
                                }}
                            >
                                
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        overflowWrap: 'normal',
                                        whiteSpace: 'nowrap',
                                        color: filterColor,
                                        fontSize: '12px',
                                    }}
                                >
                                        {typeof content === 'string' ? (
                                            <MaterialTooltip title={content} disablePortal>
                                                <div>{shortName ? shortName : content}</div>
                                            </MaterialTooltip>
                                        ) : (
                                            <div>{shortName ? shortName : content}</div>
                                        )}
                                        

                                    {columnDef.filtering && (
                                        <>
                                            <CustomAutocompleteFilter
                                                columnTitle={columnDef.title}
                                                filterOptions={filterOptions}
                                                selected={selected}
                                                stateBranch={stateBranch}
                                                handleFilterChange={handleFilterChange}
                                                handleLoadFiltersByName={handleLoadFiltersByName}
                                                excludedElements={excluded}
                                                filterRef={filterRef}
                                                filterName={filterName}
                                                withExclusion={withExclusion}
                                                sortBy={sortBy}
                                                isColumnSelector={isColumnSelector}
                                                allowSorting={columnDef.sorting}
                                            />
                                        </>
                                    )}
                                </div>
                                {filterContent && columnDef.filtering && (
                                    <FilterLabel filterContent={filterContent} />
                                )}
                            </div>
                        </TableCell>
                    );
                });
            return mapArr;
        } else return [];
        //eslint-disable-next-line
    }, [cols, draggable, filterMap, orderBy, orderDirection, filterName]);

    const renderActionsHeader = useMemo(() => {
        const localizationCombined = {...MTableHeader.defaultProps.localization, ...localization};
        return (
            <TableCell
                key="key-actions-column"
                padding="checkbox"
                className="actions-column-header"
                style={{
                    width: "1%",
                    textAlign: 'center',
                    boxSizing: 'border-box',
                    verticalAlign: 'top',
                }}
            >
                <TableSortLabel disabled>{localizationCombined.actions}</TableSortLabel>
            </TableCell>
        );
        //eslint-disable-next-line
    }, [localization]);

    const renderSelectionHeader = useMemo(() => {
        return customSelectionHeaderCell ? (
            <TableCell padding="none" key="key-selection-column">
                {customSelectionHeaderCell}
            </TableCell>
        ) : (
            <TableCell
                padding="none"
                key="key-selection-column"
                style={{paddingLeft: 9}}
                className="selection-column-header"
                width="1%"
            >
                {showSelectAllCheckbox && (
                    <Checkbox
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                        indeterminateIcon={<CheckboxMinusIcon />}
                        indeterminate={selectedCount > 0 && selectedCount < dataCount}
                        checked={dataCount > 0 && selectedCount === dataCount}
                        onChange={(event, checked) => onAllSelected && onAllSelected(checked)}
                    />
                )}
            </TableCell>
        );
        //eslint-disable-next-line
    }, [selectedCount, dataCount, customSelectionHeaderCell]);

    const renderDetailPanelColumnCell = () => {
        return <TableCell padding="none" key="key-detail-panel-column" />;
    };

    const headers = renderHeader;
    if (hasSelection || customSelectionHeaderCell) {
        headers.splice(0, 0, renderSelectionHeader);
    }

    if (showActionsColumn) {
        if (actionsHeaderIndex >= 0) {
            let endPos = 0;
            if (hasSelection) {
                endPos = 1;
            }
            headers.splice(actionsHeaderIndex + endPos, 0, renderActionsHeader);
        } else if (actionsHeaderIndex === -1) {
            headers.push(renderActionsHeader);
        }
    }

    if (hasDetailPanel) {
        if (detailPanelColumnAlignment === 'right') {
            headers.push(renderDetailPanelColumnCell());
        } else {
            headers.splice(0, 0, renderDetailPanelColumnCell());
        }
    }

    if (isTreeData > 0) {
        headers.splice(0, 0, <TableCell padding="none" key={'key-tree-data-header'} />);
    }

    columns
        .filter((columnDef) => columnDef.tableData.groupOrder > -1)
        .forEach((columnDef) => {
            headers.splice(
                0,
                0,
                <TableCell padding="checkbox" key={'key-group-header' + columnDef.tableData.id} />
            );
        });
    return (
        <TableHead
            style={{
                position: 'sticky',
                top: 0,
                background: '#fff',
                zIndex: '10',
                boxShadow: 'inset 0 1px 0 #cdcdd1, inset 0 -1px 0 #cdcdd1',
            }}
        >
            <TableRow>{headers}</TableRow>
        </TableHead>
    );
});

MTableHeader.defaultProps = {
    dataCount: 0,
    hasSelection: false,
    headerStyle: {},
    selectedCount: 0,
    sortBy: [],
    localization: {
        actions: '',
    },
    orderBy: undefined,
    orderDirection: 'asc',
    actionsHeaderIndex: 0,
    detailPanelColumnAlignment: 'left',
    draggable: true,
    options: {padding: ''},
    actions: [],
    filterMap: null,
    filterRef: null,
    handleFilterChange: null,
    handleLoadFiltersByName: null,
};

MTableHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    dataCount: PropTypes.number,
    hasDetailPanel: PropTypes.bool.isRequired,
    detailPanelColumnAlignment: PropTypes.string,
    hasSelection: PropTypes.bool,
    headerStyle: PropTypes.object,
    localization: PropTypes.object,
    selectedCount: PropTypes.number,
    sortBy: PropTypes.array,
    onAllSelected: PropTypes.func,
    onOrderChange: PropTypes.func,
    orderBy: PropTypes.number,
    orderDirection: PropTypes.string,
    actionsHeaderIndex: PropTypes.number,
    showActionsColumn: PropTypes.bool,
    showSelectAllCheckbox: PropTypes.bool,
    draggable: PropTypes.bool,
    tooltip: PropTypes.string,
    handleFilterChange: PropTypes.func,
    handleLoadFiltersByName: PropTypes.func,
    filterMap: PropTypes.object,
    filterName: PropTypes.string,
    filterRef: PropTypes.object,
    customSelectionHeaderCell: PropTypes.object,
    cols: PropTypes.array,
};

export default compose(withTranslation())(MTableHeader);
