import { Reducer } from 'redux'
import { DeltaPlotActionType, IDeltaPlotsState } from './types'

export const initialDeltaPlotState: IDeltaPlotsState = {
    plots: [],
    hidden_traces: [],
};

export const deltaPlotsReducer: Reducer<IDeltaPlotsState> = (state = initialDeltaPlotState, action) => {
    switch (action.type) {
        case DeltaPlotActionType.GET_DELTA_PLOT_REQUEST: {
            return { ...state }
        }
        case DeltaPlotActionType.GET_DELTA_PLOT_SUCCESS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case DeltaPlotActionType.GET_DELTA_PLOT_ERROR: {
            return { ...state }
        }
        case DeltaPlotActionType.ADD_TO_HIDDEN_TRACES: {
            return {
                ...state,
                hidden_traces: [...new Set([...state.hidden_traces, action.payload.trace])],
            }
        }
        case DeltaPlotActionType.REMOVE_FROM_HIDDEN_TRACES: {
            return {
                ...state,
                hidden_traces: state.hidden_traces.filter(item => item !== action.payload.trace),
            }
        }
        case DeltaPlotActionType.EMPTY_HIDDEN_TRACES: {
            return {
                ...state,
                hidden_traces: [],
            }
        }
        default: {
            return state
        }
    }
};
