import {all, fork, put, takeLatest, select} from 'redux-saga/effects';
import {getConfig} from '../../config-provider';
import axios from '../../axiosMiddleware';
import {filterListVariantsSuccessAction, filterVariantsError, filterVariantsSuccessAction} from './actions';
import {FilterFieldVariantsType} from './types';
import {roundVariants} from '../../components/FilterColumn/utils';
import {createFiltersQuery} from '../currentGrid/sagas';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

type FilterResponseType = {
    data: {
        filters: {
            [key: string]: {
                values: (string | number)[];
            };
        };
    };
};

async function fetchFilters({
    filter_name, 
    version_ids, 
    mipairs,
    filters,
    search_value,
    groupby,
    disable_inject,
    cumsum,
    precision
}) {
    const _groupby = groupby && groupby.length && groupby.join(',');
    const filterQuery = filters && {...createFiltersQuery(filters)};
    const {data}: FilterResponseType = await axios.post(
        `${API_ENDPOINT}/calculate/__filters__`,
        {
            ...filterQuery,
            filter_name,
            version_ids, 
            mipairs, 
            search_value,
            groupby: _groupby ? _groupby : undefined,
            disable_inject,
            cumsum,
            precision
        }
    );

    const [field, value] = Object.entries(data.filters)[0];
    return {[field]: roundVariants(value.values, precision)};
}

async function fetchAllFilterVariants({
    columns,
    version_ids,
    mipairs,
    filters,
    search_value,
    groupby,
    disable_inject,
    cumsum,
    precision
}) {
    return await Promise.all(
        columns.map((filter_name: string) => fetchFilters({
            filter_name, 
            version_ids, 
            mipairs,
            filters,
            search_value,
            groupby,
            disable_inject,
            cumsum,
            precision
        }))
    ).then((data) => {
        return data.reduce((acc: any, item) => {
            const field = Object.keys(item)[0];
            acc[field] = item[field];
            return acc;
        }, {});
    });
}

function* handleFetchCurrentFilterVariants(request) {
    const filters = yield select(store => store.currentGrid.filters.filters);
    const settings = yield select(store => store.currentGrid.settings);
    const {groupby, disable_inject, cumsum, precision} = settings;

    try {
        const filtersRes = yield fetchFilters({
            ...request.payload,
            filters,
            groupby,
            disable_inject,
            cumsum,
            precision
        });
        yield put(filterVariantsSuccessAction(filtersRes));
    } catch (err) {
        if (err instanceof Error) {
            yield put(filterVariantsError(err.stack!));
        } else {
            yield put(filterVariantsError('An unknown error occurred.'));
        }
    }
}

function* handleFetchListFilters(request) {
    const filters = {};
    const precision = yield select((store) => store.currentGrid.settings.precision);

    try {
        const filtersRes = yield fetchAllFilterVariants({...request.payload, filters, precision});
        yield put(filterListVariantsSuccessAction(filtersRes));
    } catch (err) {
        if (err instanceof Error) {
            yield put(filterVariantsError(err.stack!));
        } else {
            yield put(filterVariantsError('An unknown error occurred.'));
        }
    }
}

function* watchUpload() {
    yield takeLatest(FilterFieldVariantsType.FILTER_FIELD_REQUEST, handleFetchCurrentFilterVariants);
    yield takeLatest(FilterFieldVariantsType.FILTER_ADD_LIST_VARIANTS_REQUEST, handleFetchListFilters);
}

function* filterVariantSaga() {
    yield all([fork(watchUpload)]);
}

export default filterVariantSaga;
