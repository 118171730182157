export enum PlotActionType {
    PLOT_REQUEST = '@@currentPlot/PLOT_REQUEST',
    PLOT_SUCCESS = '@@currentPlot/PLOT_SUCCESS',
    PLOT_ERROR = '@@currentPlot/PLOT_ERROR',
    CHANGE_SETTINGS = '@@currentPlot/CHANGE_SETTINGS',
}

export interface IPlotSettings {
    readonly groupby?: string[],
    readonly acc?: boolean
}


export interface IPlotRequest {
    readonly width?: number
    readonly height?: number
    readonly dpi?: number
}

export interface IPlotResult {
    readonly plots
    readonly raster_data?
    readonly agg_method: { name: string, tooltip: string }
    readonly is_scalar: boolean
    readonly groupby_available: string[]
}

export interface ICurrentPlotState {
    readonly data: IPlotResult | null
    readonly loading: boolean
    readonly errors?: string | null
    readonly settings: IPlotSettings
}