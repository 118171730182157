import { getDataFromLocalStorage, setDataToLocalStorage } from '../../helpers/localStorage';
import { ActiveDataView, CurrentCalcContextActionType, ICurrentCalcContextState } from './types'

const DEFAULT_INDICATOR = 'CashflowModule:net_cash_flow';
const DEFAULT_INDICATOR_COSTS = 'OpExModule:opex_electricity_oil_prod_cost';
const DEFAULT_INDICATOR_ANOMALIES = null;

const DEFAULT_INDICATOR_MAP = {
    'indicatorTree': DEFAULT_INDICATOR,
    'costsTree': DEFAULT_INDICATOR_COSTS,
    'anomalyTree': DEFAULT_INDICATOR_ANOMALIES,
}

interface ICurrentIndicatorData {
    currentIndicator: string,
    currentTreeNodeId: string | null,
    driverName: string
}

export const initialStateCalc: ICurrentCalcContextState = {
    currentIndicator: DEFAULT_INDICATOR,
    currentTreeNodeId: null,
    activeDataView: {
        [ActiveDataView.GRID]: true,
        [ActiveDataView.PLOT]: false,
    },
    currentObject: '',
    currentWell: '',
    profit: "CF",
    versionObjects: null,
    versionWells: null,
    extra: new Map<any, any>(),
    extraErrors: new Map<any, any>(),
};

export const initialStateCosts: ICurrentCalcContextState = {
    currentIndicator: DEFAULT_INDICATOR_COSTS,
    currentTreeNodeId: null,
    driverName: null,
    costsInfo: new Map<any, any>(),
    settings: {
        show_zero: true,
        prec: 2
    },
    loading: false,
    noIndicatorData: null,
};

export const initialStateAnomalies: ICurrentCalcContextState = {
    currentIndicator: DEFAULT_INDICATOR_ANOMALIES,
    anomalyInfo: new Map<any, any>(),
};

const initialState = {
    indicatorTree: initialStateCalc,
    costsTree: initialStateCosts,
    anomalyTree: initialStateAnomalies
}

const addData = (prevData: Map<any, any>, name, newData) => {
    return new Map(prevData.set(name, newData))
};

const saveDataToStorage = (data: ICurrentIndicatorData, contextName: string) => {
    setDataToLocalStorage(`${contextName}:currentIndicator`, data);
};

const loadCurrentIndicator = (contextName: string): ICurrentIndicatorData => {
    const defaultIndicator = DEFAULT_INDICATOR_MAP[contextName];

    let data: any = getDataFromLocalStorage(`${contextName}:currentIndicator`);

    if (data && !data['currentIndicator']) {
        data = {...data, currentIndicator: defaultIndicator};
    };

    return data;
};


function createCurrentContextWithNameData(contextName = '') {

    return (state = initialState[contextName], action) => {

        const { treeName } = action.payload ? action.payload : '';
        if (treeName !== contextName) return state;

        switch (action.type) {
            case CurrentCalcContextActionType.SET_CURRENT_INDICATOR: {
                saveDataToStorage({
                    currentIndicator: action.payload.indicator,
                    currentTreeNodeId: action.payload.treeNodeId,
                    driverName: action.payload.driverName
                }, contextName);
                return {
                    ...state,
                    currentIndicator: action.payload.indicator,
                    currentTreeNodeId: action.payload.treeNodeId,
                    driverName: action.payload.driverName
                }
            }
            case CurrentCalcContextActionType.LOAD_CURRENT_INDICATOR: {
                return {
                    ...state,
                    ...loadCurrentIndicator(contextName)
                }
            }
            case CurrentCalcContextActionType.SET_ACTIVE_DATA_VIEW: {
                const {dataView} = action.payload
                const activeDataView = {...state.activeDataView, ...{[dataView]: !state.activeDataView[dataView]}}

                return { ...state, activeDataView }
            }
            case CurrentCalcContextActionType.SET_CURRENT_OBJECT: {
                return { ...state, currentObject: action.payload }
            }
            case CurrentCalcContextActionType.SET_CURRENT_WELL: {
                return { ...state, currentWell: action.payload }
            }
            case CurrentCalcContextActionType.SET_PROFIT: {
                return { ...state, profit: action.payload }
            }
            case CurrentCalcContextActionType.VERSION_EXTRA_SUCCESS: {
                return {
                    ...state, extra: addData(state.extra, action.payload.version, action.payload.data),
                    // currentObject: action.payload.data.objects[0],
                    // currentWell: action.payload.data.wells[0]
                }
            }
            case CurrentCalcContextActionType.VERSION_EXTRA_ERROR: {
                return {
                    ...state,
                    extraErrors: addData(state.extra, action.payload.version, action.payload.data)
                }
            }
            case CurrentCalcContextActionType.GET_COSTS_INFO_REQUEST: {
                if (contextName === 'costsTree') {
                    return {
                        ...state,
                        // costsInfo: new Map<any, any>(),
                        loading: true
                    }
                }
                break
            }
            case CurrentCalcContextActionType.GET_COSTS_INFO_SUCCESS: {
                if (contextName === 'costsTree') {
                    return {
                        ...state,
                        loading: false,
                        costsInfo: action.payload.costsInfo,
                        noIndicatorData: false,
                    }
                }
                break
            }
            case CurrentCalcContextActionType.GET_COSTS_INFO_ERROR: {
                if (contextName === 'costsTree') {
                    if (action.payload.loggingError.errorSlug === 'ERR_NOT_FOUND') {
                        return {
                            ...state,
                            loading: false,
                            noIndicatorData: true,
                        }
                    }
                    return {
                        ...state,
                        loading: false
                    }
                }
                break
            }
            case CurrentCalcContextActionType.CHANGE_SETTINGS: {
                if (contextName === 'costsTree') {
                    return {
                        ...state,
                        settings: {
                            ...state.settings,
                            ...action.payload.newSettings
                        }
                    }
                }
                break
            }
            case CurrentCalcContextActionType.GET_ANOMALY_INFO_REQUEST: {
                if (contextName === 'anomalyTree') {
                    return {
                        ...state,
                        loading: true
                    }
                }
                break
            }
            case CurrentCalcContextActionType.GET_ANOMALY_INFO_SUCCESS: {
                if (contextName === 'anomalyTree') {
                    return {
                        ...state,
                        loading: false,
                        anomalyInfo: action.payload.anomalyInfo
                    }
                }
                break
            }
            case CurrentCalcContextActionType.GET_ANOMALY_INFO_ERROR: {
                if (contextName === 'anomalyTree') {
                    return {
                        ...state,
                        loading: false
                    }
                }
                break
            }
            default: {
                return state
            }
        }
    };
}

export { createCurrentContextWithNameData }
