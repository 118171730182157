import React from "react";
import styled from "styled-components";
import {default as moment} from "moment";
import {useDispatch} from "react-redux";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import {ITask} from "../../store/tasks/types";
import {formatDateTime} from "../../helpers/MyAwesomeLib";
import {Checkbox} from "../../components/Design/Checkboxes/Checkbox";
import {appendTaskToSelected, removeTaskFromSelected} from "../../store/tasks/actions";

const TaskItem = ({task, tasksState, t, revokeTask, onClick, hasErrors, hasWarnings, hasInfo}) => {
    const dispatch = useDispatch();
    const formatDate = (date) => new Date(date).toLocaleString('ru-RU');

    const getTargetVersionLabel = (task) => {
        const target = task.related_calc_info.calcversion_id;
        if (target instanceof Array) {
            if (target.length > 1) {
                return t('commons:calcversion_ids', 'Целевые версии');
            }
        }
        return t('commons:calcversion_id', 'Целевая версия');
    };

    const getTargetVersionValue = (task) => {
        const target = task.related_calc_info.calcversion_id;
        let value = target;
        if (target instanceof Array) {
            value = target.join(', ');
        }
        return value;
    };

    const getTaskRelatedVersionsLabel = (task) => {
      const parentVersionID = task.related_calc_info.calcversion_parent_version_id;
      const targetVersionID = getTargetVersionValue(task);
      let result = t('commons:version_id', 'ID версии') + ': ';
      if (targetVersionID) {
        if (parentVersionID) {
          result = result + parentVersionID.toString() + ' \u2192 ' + targetVersionID.toString();
        } else {
          result = result + targetVersionID.toString();
        }
      } else {
        result = '';
      }
      return result;
    }

    const TooltipText = (task: ITask) => {
        return `
            <div style='word-break:break-all;'>
                <div>
                    <div>
                        ${t('commons:Name', 'Имя')}: ${task.payload_label ? task.payload_label : task.id}
                    </div>
                    ${task.created_by ?
            `<div>
                            ${t('commons:User', 'Пользователь')}: ${task.created_by.username}
                        </div>` : ``
        }
                    <div>
                        ${t('commons:Started_at', 'Начато')} : ${formatDateTime(task.started_at)}
                    </div>
                    ${task.finished_at ?
            `<div>
                            ${t('commons:Duration', 'Длительность')}:
                            ${countTimeSpent(task)}
                        </div>` : ``
        }
                    ${task.related_calc_info.calcversion_id ?
            `<div>
                            ${getTargetVersionLabel(task)}: ${getTargetVersionValue(task)}
                        </div>` : ``
        }
                    ${task.related_calc_info.calcversion_parent_version_id ?
            `<div>
                            ${t('commons:calcversion_parent_version_id', 'Родительская версия')}:
                            ${task.related_calc_info.calcversion_parent_version_id}
                        </div>` : ``
        }
                </div>
            </div>
        `;
    };

    const taskIsInProgress = (task) => {
        const status = tasksState.task_status_table?.get(task.status)?.alias;
        return status !== 'SUCCESS' && status !== 'REVOKED';
    }

    const countTimeSpent = (task) => {
        const start = moment(new Date(task.started_at));
        const finish = moment(new Date(task.finished_at));
        if (start && finish) {
            const diff = finish.diff(start);
            return moment.utc(diff).format("HH:mm:ss");
        }
        return null;
    }

    const handleTaskCheckboxChange = () => {
        tasksState.selected_tasks.includes(task.id)
            ? dispatch(removeTaskFromSelected(task))
            : dispatch(appendTaskToSelected(task));
    }

    const StatusBadge = (hasInfo, hasWarnings, hasErrors) => {
        if (hasErrors) return <StatusErrorIcon />
        if (hasWarnings) return <StatusWarningIcon />
        if (hasInfo) return <StatusInfoIcon />
    }

    return (
        <div key={task.id} style={{display: 'flex', flexDirection: 'row'}}
             data-html={'true'} data-for="taskTooltip"
             data-tip={TooltipText(task)}>
            <TaskItemWrapper onClick={onClick} isClickable={hasErrors || hasWarnings || hasInfo}>
                <TaskControlsColumn>
                    <TaskCheckbox checked={tasksState.selected_tasks.includes(task.id)}
                                  onChange={handleTaskCheckboxChange}/>
                </TaskControlsColumn>
                <TaskDetailsColumn>
                    <TaskHeader>
                        <TaskIDAndType>
                          <TaskID>{"#" + task.id.toString() + " "}</TaskID>
                          {task.task_type_verbose_name}
                        </TaskIDAndType>
                        <TaskRelatedVersions>{getTaskRelatedVersionsLabel(task)}</TaskRelatedVersions>
                    </TaskHeader>
                    <TaskDetailsWrapper>
                        {task.created_by?.username && <TaskDetail>
                            <TaskDetailPropName>{t('commons:Started_by', 'Назначил') + ':'}</TaskDetailPropName>
                            <TaskDetailValue>{task.created_by?.username}</TaskDetailValue>
                        </TaskDetail>}
                        {task.started_at &&
                        <TaskDetail><TaskDetailPropName>{t('commons:Started_at', 'Начато') + ':'}</TaskDetailPropName><TaskDetailValue>{formatDate(task.started_at)}</TaskDetailValue></TaskDetail>}
                    </TaskDetailsWrapper>
                </TaskDetailsColumn>
                <TaskStatusColumn>
                    {tasksState.task_status_table.size ? (
                        <TaskStatusWrapper>
                            <TaskStatus
                                status={tasksState.task_status_table.get(task.status).alias}>{t(`commons:task.${tasksState.task_status_table.get(task.status).alias}`)}</TaskStatus>
                            <StatusIconsWrapper>
                                {StatusBadge(hasInfo, hasWarnings, hasErrors)}
                            </StatusIconsWrapper>
                        </TaskStatusWrapper>
                    ) : null}
                    {(!taskIsInProgress(task) && countTimeSpent(task)) &&
                    <TaskTimeSpent>
                        <TimeIcon/>
                        <TaskTimeValue>{countTimeSpent(task)}</TaskTimeValue>
                        {/*{t('commons:task.s.', 'с.')}*/}
                    </TaskTimeSpent>}
                </TaskStatusColumn>
            </TaskItemWrapper>
        </div>
    )
}

const TaskHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const TaskID = styled.span`
  color: #747480;
`;

const TaskIDAndType = styled.p`
  font-size: 14px;
  line-height: 1.33;
  color: #B3B3BC;
  margin-right: 12px;
  margin-bottom: 2px;
`;

const TaskRelatedVersions = styled.p`
  font-size: 12px;
  color: #B3B3BC;
  font-family: 'EYInterstate Light', sans-serif;
  overflow: hidden;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 6px;
  margin-right: 6px;
`;

const TaskItemWrapper = styled.div`
  padding: 10px 8px;
  margin-top: 8px;
  margin-left: 6px;
  background: #2E2E38;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
    &:hover {
        background: #222228;
    }
`;

const TaskDetailsWrapper = styled.div`
  display: flex;
`;

const TaskDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 21px;
`;

const TaskDetailPropName = styled.div`
  font-size: 12px;
  color: #B3B3BC;
`;

const TaskDetailValue = styled.div`
  font-size: 12px;
  color: #747480;
  font-family: 'EYInterstate Light', sans-serif;
`;

const TaskTimeValue = styled.span`
  //text-overflow: ellipsis;
`;

const   TaskStatus = styled.div`
  font-size: 12px;
  font-family: 'EYInterstate Bold', sans-serif;
  background-color: ${props => {
    if (props.status === "SUCCESS") return props.theme.colors.green;
    if (props.status === "REVOKED" || props.status === "ERROR") return props.theme.colors.red;
    return props.theme.colors.grey2;
  }};
  color: white;
  align-items: center;
  position: relative;
  font-weight: var(--weight-light);
  justify-content: center;
  border-radius: 15px;
  margin-right: 4px;
  padding: 1px 4px;
  width: 93%;
  text-align: center;

`;

const TaskDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 68%;
`;

const TaskStatusColumn = styled.div`
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TaskControlsColumn = styled.div`
  width: 8%;
  display: flex;
  flex-direction: column;
`;

const TaskTimeSpent = styled.div`
  font-size: 12px;
  color: #B3B3BC;
  display: flex;
  align-items: center;
  font-family: 'EYInterstate Light', sans-serif;
`;

const TimeIcon = styled(AccessTimeIcon)`
  font-size: 12px;
  color: #B3B3BC;
  margin-right: 2px;
`;

const TaskCheckbox = styled(Checkbox)`
  margin-top: 3px;
`

const TaskStatusWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const StatusIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  
  svg {
    margin-right: 3px;
    width: 24px;
    height: 24px;
  }
`

const StatusWarningIcon = styled(WarningIcon)`
  fill: ${props => props.theme.colors.orange};
`

const StatusErrorIcon = styled(ErrorIcon)`
  fill: ${props => props.theme.colors.red};
`

const StatusInfoIcon = styled(InfoIcon)`
  fill: ${props => props.theme.colors.blue};
`

export default TaskItem;