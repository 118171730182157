import {action} from 'typesafe-actions';
import {IScenario, ScenarioCalcActionType} from "./types";

export const calculateScenarioRequest = (scenario_ids: [number], version_ids: [number]) =>
    action(ScenarioCalcActionType.SCENARIO_CALC_CREATE_REQUEST, {scenario_ids, version_ids,});

export const calculateScenarioSuccess = (task_group_id: number) =>
    action(ScenarioCalcActionType.SCENARIO_CALC_CREATE_SUCCESS, {task_group_id});

export const calculateScenarioError = (error: any) =>
    action(ScenarioCalcActionType.SCENARIO_CALC_CREATE_ERROR, {error});

export const getScenariosListRequest = () =>
    action(ScenarioCalcActionType.GET_SCENARIOS_LIST_REQUEST);

export const getScenariosListSuccess = (scenarios: IScenario[]) =>
    action(ScenarioCalcActionType.GET_SCENARIOS_LIST_SUCCESS, {scenarios});

export const getScenariosListError = (error: any) =>
    action(ScenarioCalcActionType.GET_SCENARIOS_LIST_ERROR, {error});

export const deleteScenario = (id: number) =>
    action(ScenarioCalcActionType.DELETE_SCENARIO, {id});