import axios from 'axios';
import { getConfig } from './config-provider';

axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');
const ENABLE_CSRF = getConfig('REACT_APP_ENABLE_CSRF');

async function getCsrfToken() {
  const response = await fetch(`${API_ENDPOINT}/csrf/`, {
    credentials: 'include',
    headers: {
      'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '-1'
    }
  });
  const data = await response.json();
  return data.csrfToken;
}


// Add a request interceptor
axios.interceptors.request.use(async function (config) {
  // Do something before request is sent
  const unsafeRequest = config.method === 'post' ||
                        config.method === 'patch' ||
                        config.method === 'delete' ||
                        config.method === 'put';

  if (unsafeRequest && ENABLE_CSRF) {
    config.headers = {
      ...config.headers,
      'X-CSRFToken': await getCsrfToken()
    }
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error);
});

export default axios;