import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ApplicationState} from '../store';
import {IFilterOptions} from '../store/tableSelector/types';
import {setDisplayedColumns} from '../store/tableSelector/actions';
import CustomAutocompleteFilter from './Autocomplete/CustomAutocomplete';

const excludedElements = [];
const stateBranch = 'columns';

interface ColumnSelectorProps {
    filterName: string
};

const ColumnSelector: React.FC<ColumnSelectorProps> = ({filterName}) => {
    const {t} = useTranslation(['components']);
    const dispatch = useDispatch();

    const columns = useSelector<ApplicationState, any>(
        (store) => store[filterName].columns
    );
    const displayedColumns = useSelector<ApplicationState, IFilterOptions>(
        (store) => store[filterName].displayedColumns
    );

    const columnTitle = t('components:filter.columns');

    const handleColumnsChange = (data) => {
        const newColumns = data.pendingValue;
        dispatch(setDisplayedColumns(newColumns, filterName));
    };

    return (
        <CustomAutocompleteFilter
            columnTitle={columnTitle}
            filterOptions={columns}
            selected={displayedColumns}
            excludedElements={excludedElements}
            handleFilterChange={handleColumnsChange}
            stateBranch={stateBranch}
            isColumnSelector
            filterName={filterName}
        />
    );
};

export default ColumnSelector;
