import axios from '../../axiosMiddleware';
import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { getConfig } from '../../config-provider';

import { costsFilterOptionsSuccess, costsFilterOptionsError } from './actions';
import { CostsFilterActionType } from './types';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleOptionsRequest(action) {
    const indicator = action.payload.indicator.split(':');
    const body = {driver_name: action.payload.driverName}
    const url = `${API_ENDPOINT}/costref/${indicator[0]}/${indicator[1]}/filters`;
    try {
        const res = yield call(axios.post, url, body);
        res.error ? yield put(costsFilterOptionsError(res.error)) :
            yield put(costsFilterOptionsSuccess(res.data));
    } catch (e) {
        if (e instanceof Error) {
            yield put(costsFilterOptionsError(e.stack!));
        } else {
            yield put(costsFilterOptionsError('An unknown error occured.'));
        }
    }
}

function* watchOptionsRequest() {
    yield takeLatest(CostsFilterActionType.GET_OPTIONS_REQUEST, handleOptionsRequest);
}

export function* costsFilterSaga() {
    yield all([
        fork(watchOptionsRequest)
    ])
}