import React, {useState, useEffect} from 'react';
import {ApplicationState} from '../../store';
import {connect} from 'react-redux';
import logo from '../../assets/images/icons/logos/b1-logo.svg';

import {doLogin, loginError} from '../../store/login/action';
import {useTranslation} from "react-i18next";
import {getConfig} from "../../config-provider";
import {getCookieByName} from '../../helpers/utils';
import {TextField} from "@material-ui/core";

const REDIRECT_TO_AZURE = getConfig('REACT_APP_USE_EY_GS_AUTH_ENDPOINT');
const AUTH_ENDPOINT = getConfig('REACT_APP_AUTH_ENDPOINT');

const LoginPage: React.FC<any> = ({doLogin, error, auth_enabled}) => {

    const jwtToken = getCookieByName('jwt_token');

    useEffect(() => {
        if (REDIRECT_TO_AZURE && !jwtToken) {
            window.location.href = AUTH_ENDPOINT;
            return null;
        }
        //eslint-disable-next-line
    }, []);

    const [user, setUser] = useState({
        username: '',
        password: ''
    });
    const {t} = useTranslation('auth');

    const {username, password} = user;

    const onChange = e => {
        setUser({...user, [e.target.name]: e.target.value});
    };

    const showError = msg => {
        return <span>{msg}</span>;
    };

    const onSubmit = e => {
        e.preventDefault();
        doLogin({
            username,
            password
        });
    };

    return (
        <>
            {auth_enabled === null ? null : (
                <div className='login-page'>
                    <div className='logo'>
                        <img src={logo} alt='logo АС ТЭРА'/>
                    </div>
                    <h1>{t('auth:header', 'Вход в АС ТЭРА')}</h1>
                    <div className='login-error'>
                        {error ? showError(t('auth:error', '* Неправильно введен логин или пароль')) : null}
                    </div>
                    <form className='login-form' onSubmit={onSubmit} autoComplete='off'>
                        <div className='form-group'>
                            <TextField
                                fullWidth
                                className='login-form__input'
                                placeholder={t('auth:username', 'Ваше имя...')}
                                name='username'
                                value={username}
                                onChange={onChange}
                            />
                        </div>
                        <div className='form-group'>
                            <TextField
                                type='password'
                                fullWidth
                                className='login-form__input'
                                placeholder={t('auth:password', 'Ваш пароль...')}
                                name='password'
                                value={password}
                                onChange={onChange}
                            />
                        </div>
                        <button type='submit' className='btn-login'>
                            {t("auth:login", "Вход")}
                        </button>
                    </form>
                </div>
            )}
        </>
    );
};

const mapStateToProps = ({login, appProperties}: ApplicationState) => ({
    isAuth: login.isAuth,
    error: login.error,
    auth_enabled: appProperties.auth_enabled
});

const mapDispatchToProps = {
    doLogin,
    loginError
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
