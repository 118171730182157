export enum WellsActionType {

    GET_WELL_PLOT_REQUEST = '@@wells/GET_WELL_PLOT_REQUEST',
    GET_WELL_PLOT_SUCCESS = '@@wells/GET_WELL_PLOT_SUCCESS',
    GET_WELL_PLOT_ERROR = '@@wells/GET_WELL_PLOT_ERROR',
}

interface LineType {
    color: string,
    with: number
}

export interface IWellPlot {
    x: number[],
    y: number[],
    name: string,
    type: string,
    mode: string,
    line: LineType,

}

export type IWellPlots = IWellPlot[]


export interface IWellsPlotsState {
    readonly data: { [key: string]: IWellPlots }
}
