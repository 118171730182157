import React from 'react';
import styled from 'styled-components/macro';

type ButtonProps = {
    startIcon?: any;
    onClick: any;
    label: string;
    outline?: boolean;
    className?: any;
    disabled?: boolean;
    hidden?: boolean;
    small?: boolean;
};

export const Button: React.FC<ButtonProps> = React.memo(
    ({className, startIcon, label, onClick, outline = false, disabled = false, hidden = false, small = false}) => {
        return (
            <ButtonStyle
                small={small}
                outline={outline}
                disabled={disabled}
                hidden={hidden}
                onClick={onClick}
                className={className}
            >
                {startIcon} {label}
            </ButtonStyle>
        );
    }
);

const ButtonStyle = styled.button`
    display: ${(props) => (props.hidden ? 'none' : 'flex')};
    align-items: center;
    padding: ${(props) => (props.small ? '5px 10px' : '10px 20px')};
    cursor: ${(props) => (props.disabled ? '' : 'pointer')};
    transition: ${(props) => props.theme.transition.normal};
    border: 1px solid
        ${(props) => (props.disabled ? props.theme.colors.grey5 : props.theme.colors.black)};
    color: ${(props) =>
        props.disabled
            ? props.theme.textColor.lightGrey
            : props.outline
            ? props.theme.textColor.black
            : props.theme.textColor.white};

    background: ${(props) =>
        props.disabled
            ? props.outline
                ? 'transparent'
                : props.theme.colors.grey5
            : props.outline
            ? 'transparent'
            : props.theme.colors.black};

    &:hover {
        color: ${(props) =>
            props.disabled
                ? props.theme.textColor.lightGrey
                : props.disabled
                ? props.theme.colors.grey3
                : props.theme.colors.black};
        background-color: ${(props) =>
            props.disabled
                ? props.outline
                    ? 'transparent'
                    : props.theme.colors.grey5
                : props.theme.colors.yellow};
        border-color: ${(props) =>
            props.disabled ? props.theme.colors.grey5 : props.theme.colors.yellow};
    }

    &:active {
        background-color: ${(props) =>
            props.disabled
                ? props.outline
                    ? 'transparent'
                    : props.theme.colors.grey5
                : props.outline
                ? 'transparent'
                : props.theme.colors.yellowDark};
        border-color: ${(props) =>
            props.disabled ? props.theme.colors.grey5 : props.theme.colors.yellowDark};
    }
`;
