import React from 'react';

function NotFound() {
    return (
        <div className='not-found'>
            <span className='error'>404</span>
            <p>Запрашиваемая Вами страница не найдена</p>
        </div>
    );
}

export default NotFound;
