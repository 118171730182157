export interface IIndicatorMeta {
    display_name: string | null
    dimension: string | null
    agg_method: string | null
}

export enum IndicatorMetaActionType {
    FETCH_REQUEST = '@@indicatorMeta/FETCH_REQUEST',
    FETCH_SUCCESS = '@@indicatorMeta/FETCH_SUCCESS',
    FETCH_ERROR = '@@indicatorMeta/FETCH_ERROR',
    REFRESH_ACTION = '@@indicatorMeta/REFRESH_ACTION',
    CLEAR_ACTION = '@@indicatorMeta/CLEAR_ACTION'
}

export interface IIndicatorMetaState {
    readonly loading: boolean
    readonly data: Map<string, IIndicatorMeta>
    readonly error?: any
}
