import {Reducer} from 'redux'
import {AnomalyTreeActionType, AnomalyTreeState} from './types'

export const initialState: AnomalyTreeState = {
    data: [],
    errors: undefined,
    currentAnomaly: null,
    loading: false
};

function fst_pair(d: any) {
    if (d === undefined)
        return null;
    if (d === null)
        return null;
    if (Array.isArray(d)) {
        return fst_pair(d[0])
    }
    if (d.hasOwnProperty('mipair')) {
        return d.mipair
    }
    if (d.hasOwnProperty('children')) {
        return fst_pair(d.children)
    }
    return null

}

const reducer: Reducer<AnomalyTreeState> = (state = initialState, action) => {
    switch (action.type) {
        case AnomalyTreeActionType.FETCH_REQUEST: {
            return {...state, loading: true, currentAnomaly: null}
        }
        case  AnomalyTreeActionType.SET_CURRENT_ANOMALY: {
            return {
                ...state,
                currentAnomaly: action.payload
            };
        }
        case AnomalyTreeActionType.FETCH_SUCCESS: {
            let fst = fst_pair(action.payload.data);
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                currentAnomaly: fst,
            };
        }
        case AnomalyTreeActionType.FETCH_ERROR: {
            return {...state, loading: false, errors: action.payload}
        }
        default: {
            return state
        }
    }
};

export {reducer as anomalyTreeReducer}
