import { action } from 'typesafe-actions';
import { LanguageActionType } from './types';


export const getLanguageRequest = () =>
    action(LanguageActionType.GET_USER_LANGUAGE_REQUEST);
export const getLanguageRequestSuccess = (language: string) => 
    action(LanguageActionType.GET_LANGUAGE_SUCCESS, {language});
export const getLanguageRequestError = (error: any) => 
    action(LanguageActionType.GET_LANGUAGE_ERROR, {error});

export const setLanguageRequest = (user_language: any) =>
    action(LanguageActionType.SET_USER_LANGUAGE_REQUEST, {user_language});
export const setLanguageRequestSuccess = () => 
    action(LanguageActionType.SET_LANGUAGE_SUCCESS);
export const setLanguageRequestError = (error: any) => 
    action(LanguageActionType.SET_LANGUAGE_ERROR, {error});
    