import {action} from 'typesafe-actions';
import {FilterActionType, IFilter} from './types';

export const getFilterDataRequest = (
    page: number, 
    pageSize: number, 
    filterName: string = 'vFilter'
) => (
    action(FilterActionType.GET_FILTER_DATA_REQUEST, {page, pageSize, filterName}));

export const getFilterDataSuccess = (
    pageData: any,
    selectedData: any,
    rowIds: number[], 
    filterName: string = 'vFilter',
    isPickerMode: boolean = false
) => (
    action(FilterActionType.GET_FILTER_DATA_SUCCESS, {
        pageData, selectedData, rowIds, filterName, isPickerMode}));

export const getFilterDataError = (error, filterName = 'vFilter') => (
    action(FilterActionType.GET_FILTER_DATA_ERROR, {error, filterName}));

export const setColumnFilter = (filter: Partial<IFilter>, sorting = [], filterName = 'vFilter') =>
    action(FilterActionType.SET_COLUMN_FILTER, {filter, sorting, filterName});

export const setFilterSearch = (value: string, filterName = 'vFilter') =>
    action(FilterActionType.SET_SEARCH, {value, filterName});

export const setSelectedRows = (rowIds: number[], filterName = 'vFilter') =>
    action(FilterActionType.SET_SELECTED_ROWS, {rowIds, filterName});
export const addToSelectedRows = (rowId: number, filterName = 'vFilter') =>
    action(FilterActionType.ADD_TO_SELECTED_ROWS, {rowId, filterName});
export const setActiveRows = (rowsIds: number[], filterName = 'vFilter') =>
    action(FilterActionType.SET_ACTIVE_ROWS, {rowsIds, filterName});

export const setActiveRowsRequest = (rowsIds: number[], filterName = 'vFilter') =>
    action(FilterActionType.SET_ACTIVE_ROWS_REQUEST, {rowId: rowsIds, filterName});
export const setActiveRowsSuccess = (rowsData: any, filterName = 'vFilter') =>
    action(FilterActionType.SET_ACTIVE_ROWS_SUCCESS, {rowData: rowsData, filterName});
export const clearActiveRows = (filterName = 'vFilter') =>
    action(FilterActionType.CLEAR_ACTIVE_ROWS, {filterName});
export const setActiveRowsError = (error: any, filterName = 'vFilter') =>
    action(FilterActionType.SET_ACTIVE_ROWS_ERROR, {error, filterName});

export const resetFilters = (filterName = 'vFilter') =>
    action(FilterActionType.RESET_FILTERS, {filterName});

export const loadFilter = (filter: any, filterName = 'vFilter') =>
    action(FilterActionType.LOAD_FILTER, {filter, filterName});

export const getFilterOptionsRequest = (column: string, filterName = 'vFilter') =>
    action(FilterActionType.GET_FILTER_OPTIONS_REQUEST, {column, filterName});
export const getFilterOptionsSuccess = (column: string, options, filterName = 'vFilter', hasBlanks = false, haveBlanks = null) => 
    action(FilterActionType.GET_FILTER_OPTIONS_SUCCESS, {column, options, filterName, hasBlanks, haveBlanks});
export const getFilterOptionsError = (error: any, filterName = 'vFilter') =>
    action(FilterActionType.GET_FILTER_OPTIONS_ERROR, {error, filterName});

export const deleteFilterRowsSuccess = (ids: [number], filterName = 'vFilter') =>
    action(FilterActionType.DELETE_FILTER_ROWS_SUCCESS, {ids, filterName});

export const setAsTree = (asTree: boolean, filterName = 'vFilter') =>
    action(FilterActionType.SET_AS_TREE, {asTree, filterName});

export const hierarchyTreeRequest = (rowId: number, filterName = 'vFilter') =>
    action(FilterActionType.HIERARCHY_TREE_REQUEST, {rowId, filterName});
export const hierarchyTreeSuccess = (data: any, filterName = 'vFilter') => 
    action(FilterActionType.HIERARCHY_TREE_SUCCESS, {data, filterName});
export const hierarchyTreeError = (error: any, filterName = 'vFilter') =>
    action(FilterActionType.HIERARCHY_TREE_ERROR, {error, filterName});

export const setRowsPerPage = (perPage = 30, filterName = 'vFilter') =>
    action(FilterActionType.SET_ROWS_PER_PAGE, {perPage, filterName});
export const setFilterCheckedIds = (checkedIds: number[], filterName = 'vFilter') =>
    action(FilterActionType.SET_FILTER_CHECKED_IDS, {checkedIds, filterName});
export const setCheckedOnly = (checkedOnly: boolean, filterName = 'vFilter') =>
    action(FilterActionType.SET_CHECKED_ONLY, {checkedOnly, filterName});

export const setIsPickerMode = (isPickerMode: boolean, filterName = 'vFilter') =>
    action(FilterActionType.SET_IS_PICKER_MODE, {isPickerMode, filterName});

export const setDisplayedColumns = (newColumns: boolean, filterName = 'vFilter') =>
    action(FilterActionType.SET_DISPLAYED_COLUMNS, {newColumns, filterName});