export interface ISearchIndexItem {
    address: string
    hasChildren: boolean
}

export type SearchIndex = ISearchIndexItem[];


export function buildSearchIndex(tree): SearchIndex {
    const searchIndex = [];

    function _buildSearchIndex(tree, searchIndex) {
        for (const node of tree) {
            searchIndex.push({ 
                address: node.address, 
                hasChildren: hasChildren(node) 
            });
            if (hasChildren(node)) {
                _buildSearchIndex(node.children, searchIndex)
            }
        }
    }

    _buildSearchIndex(tree, searchIndex);

    return searchIndex
};

export function hasChildren(n) {
    return Boolean(n.children && n.children.length)
};


export function buildVerboseIndex(tree) {
    const index = {};

    function _buildVerboseIndex(tree, index) {
        for (const node of tree) {

            index[node.address] = {
                 name: node.META.display_name,
                 status: node.META.input_status,
                 choices: node.META.choices,
                 dimension: node.META.dimension,
                 displayAs: node.META.display_as,
                 hasChildren: hasChildren(node)
            }

            if (hasChildren(node)) {
                _buildVerboseIndex(node.children, index)
            }
        }
    }

    _buildVerboseIndex(tree, index);

    return index
};

export const safeMultiplyBy100 = (value) => {
    if (!value || isNaN(value)) return value;
    return Number((value * 100).toFixed(10));
};

export const safeDivideBy100 = (value) => {
    if (!value || isNaN(value)) return value;
    return Number((value / 100).toFixed(10));
};

// Function transforms data used in ScenarioTree
export const transformData = (data, parentNodeAddress) => {

    const keys = Object.keys(data).filter(key => key !== 'META');
    data.address = parentNodeAddress;

    if (!('DATA' in data)) {

        data.children = [];

        for (const key of keys) {

            const address = parentNodeAddress.concat(`::${key}`);
            const child = data[key];

            transformData(child, address);
            data.children.push(child);

            delete data[key];
        }

    } else {

        const meta = data.META;
        const control = meta.display_as;
        const dimension = meta.dimension;

        if (dimension === '%') {
            if (control === 'value') {
                data.DATA = safeMultiplyBy100(data.DATA);
            } else {
                for (const key in data.DATA) {
                    data.DATA[key] = safeMultiplyBy100(data.DATA[key]);
                }
            }
        }
    }
    
    return data
};


export const transformScenarioDraft = (data, parentNodeName) => {

    const transformedData = {};

    const _transformScenarioDraft = (data, parentNodeName, transformedData) => {

        transformedData[parentNodeName] = {};

        if ('DATA' in data) {

            const control = data.META.display_as;
            const dimension = data.META.dimension;

            if (control === 'yearly_table' || control === 'custom_table') {
                const keys = Object.keys(data.DATA);
                const indicatorData = { ...data.DATA };

                keys.forEach(key => indicatorData[key] = parseFloat(indicatorData[key]));
                if (dimension === '%') {
                    keys.forEach(key => indicatorData[key] = safeDivideBy100(indicatorData[key]));
                }

                data.DATA = indicatorData;
                
            } else if (control === 'value') {

                data.DATA = parseFloat(data.DATA);
                if (dimension === '%') {
                    data.DATA = safeDivideBy100(data.DATA);
                }
                
            }

            const tmp = { ...data }
            delete tmp.address
            transformedData[parentNodeName] = tmp
            return transformedData[parentNodeName]
        }

        const META = data.META;

        if (META) {
            transformedData[parentNodeName].META = META;
        }

        for (const node of data.children) {
            const nodeName = node.address.split('::').pop();
            _transformScenarioDraft(node, nodeName, transformedData[parentNodeName]);
        }
    }

    _transformScenarioDraft(data, parentNodeName, transformedData);

    return transformedData
};

export const initialAttrCompState = () => {

    return {
        general: {
            profitability_method: 'from_version',
            historical_cashflow_method: 'from_version',
            start_milestone: 'from_version',
            end_milestone: 'from_version',
            forecast_from: 'from_version',
            wacc: 'from_version',
            wacc_government_revenue: 'from_version',
        },
        scenarios: {
            macro: [],
            tax: [],
            cost: [],
            esg: []
        },
        stimulation: [],
        switchoff: [],
        groups: [],
        attributes: [],
        recalculate: false,
    }
};

export const getEditedIndicators = (data, tabNames) => {

    // @ts-ignore
    const summary = Object.assign(...tabNames.map(tabName => ({[tabName]: []})));

    const _getEditedIndicators = (data) => {

        if ('DATA' in data) {
            
            const branch = data.address.split('::')[1];
            const edited = data.INPUT_STATUS !== 'from_version';
            
            if (branch in summary && edited) {
                summary[branch].push(data);
            }
        }
        
        if (data.children) {
            for (const node of data.children) {
                _getEditedIndicators(node);
            }
        }
    }

    _getEditedIndicators(data);

    return summary;
};
