import React from 'react';
import styled from 'styled-components';

export const Select = ({options, onChange, value}) => {
    return (
        <SelectWrapper>
            <SelectItem onChange={(e) => onChange(e)} value={value}>
                {options.map((option) => (
                    <Option key={option}>{option}</Option>
                ))}
            </SelectItem>
        </SelectWrapper>
    );
};

const SelectWrapper = styled.div`
    width: 45px;
`;

const SelectItem = styled.select`
    border: none;
    width: 100%;
    padding: 11px 5px !important;
    background-color: ${(props) => props.theme.colors.grey5};
`;

const Option = styled.option``;
