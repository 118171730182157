import React from 'react';

import {Store} from 'redux';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {History} from 'history';

import Routes from './routes';
import {ApplicationState} from './store';
import {setupInterceptors} from './helpers/setupInterceptor';

import {userRequest} from './store/login/action';
import {appVersionRequest} from './store/appProperties/action';
import {getPermissions} from './store/permissions/actions';

import ErrorBoundary from './helpers/ErrorBoundary';
import GlobalSnackbar from './components/GlobalSnackbar';

import {ThemeProvider} from 'styled-components';
import {ThemeProvider as MatThemeProvider} from '@material-ui/core';
import {EYtheme, matTheme} from './theme/EYtheme';

import {getCookieByName} from './helpers/utils';

import './App.scss';

interface AppProps {
    store: Store<ApplicationState>;
    history: History;
}

const App: React.FC<AppProps> = ({store, history}) => {
    setupInterceptors(store, history);

    let token = getCookieByName('jwt_token');

    if (token) {
        localStorage.setItem('token', token);
    } else {
        token = localStorage.getItem('token');
    }

    if (token) {
        store.dispatch(userRequest());
        store.dispatch(getPermissions());
    }
    store.dispatch(appVersionRequest());

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={EYtheme}>
                    <MatThemeProvider theme={matTheme}>
                        <ErrorBoundary>
                            <GlobalSnackbar/>
                            <Routes/>
                        </ErrorBoundary>
                    </MatThemeProvider>
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>
    );
};

export default App;
