import React from 'react';
import styled from 'styled-components/macro';

type TextAreaProps = {
    value?: string;
    onChange?: any;
    defaultValue?: string;
    onBlur?: any;
    onKeyDown?: any;
    className?: any;
    disabled?: boolean;
    placeholder?: string;
    ref?: any;
    icon?: any;
    isWhite?: boolean;
    fullWidth?: boolean;
    height?: number;
    isResizable?: boolean;
};

export const TextArea: React.FC<TextAreaProps> = React.memo(
    ({
        className,
        value,
        defaultValue,
        onChange,
        onKeyDown,
        onBlur,
        placeholder,
        disabled,
        ref,
        icon,
        isWhite = false,
        fullWidth = false,
        height = 40,
        isResizable = false
    }) => {
        return (
            <InputWrapper fullWidth={fullWidth}>
                <TextInput
                    ref={ref}
                    className={className}
                    disabled={disabled}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    icon={icon}
                    isWhite={isWhite}
                    fullWidth={fullWidth}
                    height={height}
                    isResizable={isResizable}
                />
                {icon && !disabled && <Icon>{icon}</Icon>}
            </InputWrapper>
        );
    }
);

const InputWrapper = styled.div`
    position: relative;
    ${props => props.fullWidth && 'width: 100%;'}
`;

const TextInput = styled.textarea`
    background: ${(props) => (props.isWhite ? props.theme.colors.white : props.theme.colors.grey5)};
    color: ${(props) => props.theme.textColor.grey};
    font-size: ${(props) => props.theme.fontSize.normal};
    padding: 12px ${(props) => (props.icon && !props.disabled ? '35px' : '15px')} 12px 15px;
    ${props => props.fullWidth && 'width: 100%;'}
    height: ${props => props.height}px;
    resize: ${props => props.isResizable ? 'both' : 'none'};

    box-sizing: border-box;
    border: none;
    
    &:hover {
        background: ${(props) => !props.disabled && props.theme.colors.grey4};
    }
    
    &:focus {
        background: ${(props) => props.theme.colors.white};
    }
`;

const Icon = styled.div`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
`;
