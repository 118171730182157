export enum ImportActionType {
    IMPORT_INIT = '@@importer/IMPORT_INIT',
    IMPORT_REQUEST = '@@importer/IMPORT_REQUEST',
    IMPORT_PROGRESS = '@@importer/IMPORT_PROGRESS',
    IMPORT_SUCCESS = '@@importer/IMPORT_SUCCESS',
    IMPORT_ERROR = '@@importer/IMPORT_ERROR',
    IMPORT_RESET = '@@importer/IMPORT_RESET',
}

export interface ImporterState {
    readonly loading: boolean
    readonly progress: number | null
    readonly success: boolean | null
    readonly error?: any;
}

export interface ImporterCollectionState {
    readonly importers: {
        [id: string]: ImporterState
    }
}
