import React from 'react';
import { MTableBodyRow } from 'material-table';
import isEqual from 'lodash/isEqual';

const TableRow = (props) => {   
    return <MTableBodyRow {...props} />
}

const areEqual = (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps)
}

export default React.memo(TableRow, areEqual)