import React, {useEffect, useMemo, useState} from 'react';
import {
    GRID_PROP_ORDER,
    IGridRequest,
    IGridSettings,
    IPaginatedGridResult,
    IRowIndex,
} from '../../store/currentGrid/types';
import {useDispatch, useSelector} from 'react-redux';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {
    getDataFromLocalStorage,
    removeDataFromLocalStorage,
    setDataToLocalStorage,
} from '../../helpers/localStorage';
import {ApplicationState} from '../../store';
import {useTranslation} from 'react-i18next';
import {Button} from '../../components/Design/Button';
import FilterColumn, {FilterColumnItemType} from '../../components/FilterColumn/FilterColumn';
import {LinearProgress} from '@material-ui/core';
import {ColumnApi, GridApi, IFilterParams} from 'ag-grid-community';
import {
    calcClearData,
    calcRequest,
    saveCalcFilters,
    setSelectedIndex,
} from '../../store/currentGrid/actions';
import {
    filterAddListVariantsRequestAction,
    getFilterVariantsAction,
} from '../../store/filterVariants/actions';
import {IIndicatorMeta} from '../../store/references/indicator-meta/types';
import {
    FilterSortType,
    SortFilterType,
    SortModelType,
    SortVariableEnum,
} from '../../components/FilterColumn/SortComponent';
import {FilterListVariant, FilterListVariantsType} from '../../store/filterVariants/types';
import {dataToGrid, isCheckedFilter, isConditionFilter} from '../../components/FilterColumn/utils';
import {FilterPayload} from '../../components/FilterColumn/types';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {TableWrapper} from './calculation-page.styles';

import './GridView.scss';
import {RowSelectedEvent} from 'ag-grid-community/dist/lib/events';
import {useDebounceFn} from 'ahooks';

export type TableFilterPropsType = {
    agGridReact: {
        gridOptions: {
            columnDefs: [any];
        };
        props: any;
    };
};

export type ApplyFiltersFn = (
    newFilterData: FilterColumnItemType[],
    sortFilter: SortFilterType | null,
    field: string
) => void;
export type FetchFilterVariantsFnType = (field: string) => void;

export type updateFiltersDataType = {
    fetchFilterVariants: (currentField: string, searchValue: string) => void;
    FetchFilterVariantsFnType;
    applyFilters: ApplyFiltersFn;
};

export type TablePropsType = IFilterParams & TableFilterPropsType & updateFiltersDataType;

export type FilterStoreItemType = {
    [key: string]: FilterColumnItemType[];
};

export type FilterType = 'customFilterColumn';

export type FilterPayloadType = {
    [key: string]: FilterPayload;
};

type onGridReadyParamsType = {
    api: GridApi;
    columnApi: ColumnApi;
    type: string;
};

type GridViewProps = {
    data: IPaginatedGridResult;
    indicatorName: string;
    moduleName: string;
    meta_agg_method: string;
    settings: IGridSettings;
    activeVersionsIds: number[];
    currentIndicator: any;
};

export const ALLOW_NUMBER_LIST_RULES = [-1, 0, 1].map(String);
const FILTER_KEY = 'columnFilters';


const getIndexValuesByRowsData = (rows) => {
    const selectedIdxs: IRowIndex = [];
    rows.forEach((row) => {
        const selectedIdx = [];
        GRID_PROP_ORDER.forEach((idx) => {
            const value = row[idx];
            value && selectedIdx.push(value);
        })
        selectedIdxs.push(selectedIdx);
    });
    return selectedIdxs;
};


const GridView: React.FC<GridViewProps> = ({
    data,
    indicatorName,
    settings,
    meta_agg_method,
    moduleName,
    activeVersionsIds,
    currentIndicator,
}) => {
    const {t} = useTranslation('components');
    const dispatch = useDispatch();

    const FILTER_TYPE: FilterType = 'customFilterColumn';

    const indicatorError = useSelector<ApplicationState, string | null>(
        (store) => store.currentGrid.errors
    );
    const isLoading = useSelector<ApplicationState, boolean>((store) => store.currentGrid.loading);
    const language = useSelector<ApplicationState, string | null>(
        (store) => store.userLanguage.language
    );
    const meta = useSelector<ApplicationState, Map<string, IIndicatorMeta>>(
        (store) => store.references.indicatorMeta.data
    );
    const loadingList = useSelector<ApplicationState, boolean>(
        (store) => store.filterVariants.loadingList
    );
    const listVariablesFilters = useSelector<ApplicationState, FilterListVariantsType>(
        (store) => store.filterVariants.listFilters
    );
    const selected_index = useSelector<ApplicationState, IRowIndex>(
        (store) => store.currentGrid.selected_rows
    );

    const [isLoadedData, setIsLoadedData] = useState<boolean>(false);
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [currentFilters, setCurrentFilters] = useState<FilterStoreItemType>({});
    const [currentSortFilters, setCurrentSortFilters] = useState<FilterSortType>({});
    const [notMatchesFilters, setNotMatchesFilters] = useState<FilterStoreItemType>({});
    const [scrollPosition, setScrollPosition] = useState(0);

    // Local storage
    const portableFiltersKey = (): string => `${FILTER_KEY}:${indicatorName}`;
    const sortFiltersKey = (): string => `${portableFiltersKey()}:sort`;
    const currentVersionFiltersKey = (): string => `${portableFiltersKey()}:${activeVersionsIds.sort()}`;

    const localStorageCurrentVersionFilters = (): FilterStoreItemType =>
        getDataFromLocalStorage(currentVersionFiltersKey()) || {};
    const localStoragePortableFilters = (): FilterStoreItemType =>
        getDataFromLocalStorage(portableFiltersKey()) || {};
    const localStorageSortFilters = (): FilterSortType =>
        getDataFromLocalStorage(sortFiltersKey()) || {};

    const [columnDefs, rowData] = useMemo(
        () => dataToGrid(data, t, settings, language),
        [data, settings, t, language]
    );

    const getFilterPopupElement = (): any => document.querySelector('.ag-menu');
    const getTaleElement = (): any => document.querySelector('.ag-root-wrapper');

    const triggerFilterPopup = (value: number) => {
        const filterPopupElement = getFilterPopupElement();
        if (!filterPopupElement) return;

        filterPopupElement.style.opacity = value;
    };

    const positionFilterPopupDebounce = () => {
        const filterPopupElement = getFilterPopupElement();
        const tableElement = getTaleElement();

        if (!filterPopupElement) return;

        const popupPosition = filterPopupElement.getBoundingClientRect();
        const tablePosition = tableElement.getBoundingClientRect();

        const tablePositionTop = tablePosition.top;
        const tablePositionBottom = tablePosition.bottom;
        const tablePositionRight = tablePosition.right;
        const popupPositionLeft = popupPosition.left;
        const popupHeight = filterPopupElement.scrollHeight;
        const popupWidth = popupPosition.width;

        // Сдвиг окна фильтра по вертикали:
        if (tablePositionTop + popupHeight > tablePositionBottom) {
            const offsetPopup = tablePositionTop + popupHeight - tablePositionBottom;
            filterPopupElement.style.top = `-${offsetPopup}px`;
        } else {
            filterPopupElement.style.top = `${36}px`;
        }

        // Сдвиг окна фильтра по горизонтали:
        if (popupPositionLeft + popupWidth > tablePositionRight) {
            const offsetPopup = popupPositionLeft + popupWidth - tablePositionRight;
            filterPopupElement.style.left = `${parseInt(filterPopupElement.style.left) - offsetPopup}px`;
        }

        triggerFilterPopup(1);
    };

    const header = document.querySelector('.ag-header');

    // Position filter popup
    useEffect(() => {
        const clickHandler = ({target}) => {
            // @ts-ignore
            if (!target.classList.contains('ag-icon-menu')) return;

            setTimeout(() => {
                positionFilterPopupDebounce();
            }, 150);
        };

        header?.addEventListener('click', clickHandler);
        return () => header?.removeEventListener('click', clickHandler);
        //eslint-disable-next-line
    }, [header]);

    useEffect(() => {
        if (!activeVersionsIds.length || meta_agg_method === 'None') return;

        const mainFilterStorage: FilterStoreItemType = localStoragePortableFilters();
        const filterSortModel: FilterSortType = localStorageSortFilters();
        setCurrentFilters(mainFilterStorage);

        const [matchesFilters] = getMatchesPreviousFilters();
        updateCalculateDataDebounce.run(matchesFilters, filterSortModel);
        //eslint-disable-next-line
    }, [meta_agg_method, 
        indicatorName, 
        activeVersionsIds, 
        language, 
        settings.groupby,
        settings.period__gte,
        settings.period__lt,
        settings.page,
        settings.page_size,
        settings.orient,
        settings.precision,
        settings.show_zero,
        settings.disable_inject,
        settings.cumsum,
        settings.agg_column
    ]);

    useEffect(() => {
        const mainFilterStorage: FilterStoreItemType = localStoragePortableFilters();
        const filterSortModel: FilterSortType = localStorageSortFilters();

        const fields = [
            ...getKeysFromObject(mainFilterStorage),
            ...getKeysFromObject(filterSortModel),
        ];
        fetchListVariantsByColumns(fields);
        //eslint-disable-next-line
    }, [language]);

    useEffect(() => {
        if (loadingList !== false) return;

        // const filterSortStorage: FilterSortType = localStorageSortFilters();
        const [matchesFilters, notMatchesFilters] = getMatchesPreviousFilters();

        setCurrentFilters(matchesFilters);
        setNotMatchesFilters(notMatchesFilters);

        // updateCalculateDataDebounce.run(matchesFilters, filterSortStorage);
        //eslint-disable-next-line
    }, [loadingList]);

    useEffect(() => {
        // Set component is loaded and has all need data
        if (hasDataInObject(columnDefs) && isLoadedData === false) {
            setIsLoadedData(true);
        }
        //eslint-disable-next-line
    }, [columnDefs]);

    useEffect(() => {
        if (
            gridApi !== null &&
            hasDataInObject(columnDefs) &&
            isLoadedData === true &&
            loadingList === false
        ) {
            updateFilterStatusColumns();
        }
        //eslint-disable-next-line
    }, [gridApi, rowData, loadingList]);

    useEffect(() => {
        return () => {
            // Reset filters when moving between tables
            resetFiltersAccordingRules();

            // Clear old data when move to another page
            setIsLoadedData(false);
            dispatch(calcClearData());
        };
        //eslint-disable-next-line
    }, [indicatorName, activeVersionsIds]);

    const getInheritedFilters = (): FilterStoreItemType => {
        const filterModelCurrentVersion: FilterStoreItemType = localStorageCurrentVersionFilters();
        const mainFilterStorage: FilterStoreItemType = localStoragePortableFilters();

        if (!hasDataInObject(filterModelCurrentVersion)) return mainFilterStorage;

        return generateUniqFilters(mainFilterStorage, filterModelCurrentVersion);
    };

    useEffect(() => {
        // Переключение активного выбора таблицы при клике по графику

        if (gridApi == null) {
            return;
        }
        if (rowData == null) {
            return;
        }
        if (selected_index == null) {
            return;
        }
        if (selected_index[0] == null) {
            return;
        }

        // Находим нужную ноду

        for (const [idx, row_data] of Object.entries(rowData)) {
            // Пробегаем по данным в поисках нужной строки
            let selected: string[] = [];

            for (let p of GRID_PROP_ORDER) {
                if (p in row_data) {
                    selected.push(row_data[p]);
                }
            }
            if (selected == null) {
                break;
            }
            const is_equal = selected.join(',') === selected_index[0].join(',');

            if (is_equal) {
                // Нашли, пока без мультиселекта
                let row_node = gridApi.getDisplayedRowAtIndex(+idx);
                if (row_node != null) {
                    // Меняем селект и проккручиваемся до строки
                    row_node.setSelected(true);
                    gridApi.ensureIndexVisible(+idx);
                }
                break;
            }
        }
    }, [selected_index, gridApi, columnDefs, rowData]);

    // Method for object
    const hasDataInObject = (obj: Object): boolean =>
        (obj !== null && typeof obj === 'object' && Object.keys(obj)?.length > 0) || false;
    const getKeysFromObject = (obj: Object): string[] =>
        (hasDataInObject(obj) && Object.keys(obj)) || [];
    const getDataFromObjectByField = (obj: Object, field: string): [] => obj[field] || [];

    // Table filters API
    const setFilterModel = (params: Object) => gridApi.setFilterModel(params);
    const setSortModel = (sortModel: SortModelType[]) => gridApi.setSortModel(sortModel);

    const fetchListVariantsByColumns = (filterColumns: string[]) => {
        if (filterColumns?.length === 0) return;

        dispatch(
            filterAddListVariantsRequestAction({
                columns: filterColumns,
                version_ids: activeVersionsIds,
                mipairs: [`${moduleName}:${indicatorName}`],
                search_value: null
            })
        );
    };

    const resetFiltersAccordingRules = () => {
        const mainFilterStorage: FilterStoreItemType = localStoragePortableFilters();
        const filterFields = getKeysFromObject(mainFilterStorage);

        const filterForMove = filterFields.reduce((acc, field) => {
            const resultFilters = mainFilterStorage[field].filter(
                (filter: FilterColumnItemType) => {
                    if ('isChecked' in filter)
                        return isNaN(Number(filter.originalValue))
                            ? true
                            : ALLOW_NUMBER_LIST_RULES.includes(filter.originalValue);

                    return true;
                }
            );

            if (resultFilters.length > 0) acc[field] = resultFilters;
            return acc;
        }, {});

        setDataToLocalStorage(portableFiltersKey(), filterForMove);
    };

    const getMatchesPreviousFilters = (): [FilterStoreItemType, FilterStoreItemType] => {
        const inheritedFilters: FilterStoreItemType = getInheritedFilters();
        const inheritedKeys = getKeysFromObject(inheritedFilters);

        if (inheritedKeys.length === 0) return [{}, {}];

        const searchMatchesFilters = (
            variants: FilterListVariant[],
            storageItem: FilterColumnItemType[],
            isMatches: boolean = true
        ): FilterColumnItemType[] => {
            return storageItem.filter((filter: FilterColumnItemType) => {
                if (isConditionFilter(filter)) return isMatches;
                const [result] = variants.filter(
                    (variant) => variant.originalValue === filter.originalValue
                );

                return isMatches === true ? result : !result;
            });
        };

        const notMatchesFilters = inheritedKeys.reduce((acc: {}, field: string) => {
            const previousFilters = searchMatchesFilters(
                listVariablesFilters[field] || [],
                inheritedFilters[field] || [],
                false
            );

            if (previousFilters.length > 0) acc[field] = previousFilters;
            return acc;
        }, {} as FilterStoreItemType);

        const matchesFilters = inheritedKeys.reduce((acc: {}, field: string) => {
            const previousFilters = searchMatchesFilters(
                listVariablesFilters[field] || [],
                inheritedFilters[field] || [],
                true
            );

            if (previousFilters.length > 0) acc[field] = previousFilters;
            return acc;
        }, {}) as FilterStoreItemType;

        return [matchesFilters, notMatchesFilters];
    };

    const updateFilterStatusColumns = () => {
        const filterSortStorage: FilterSortType = localStorageSortFilters();
        const [matchesFilters] = getMatchesPreviousFilters();

        const filterModel: FilterPayloadType = createMainFiltersModel(
            matchesFilters,
            filterSortStorage
        );
        setFilterModel(filterModel);

        const filterSortModel: SortModelType[] = createSortFiltersModel(filterSortStorage);
        setSortModel(filterSortModel);
        setCurrentSortFilters(filterSortStorage);
    };

    const onGridReady = (params: onGridReadyParamsType) => {
        setGridApi(params.api);
        preventUseNativeFilter();
    };

    const preventUseNativeFilter = () => {
        // Prevent using the native filter
        const disabledClasses = [
            'ag-icon ag-icon-desc',
            'ag-icon ag-icon-asc',
            'ag-icon ag-icon-filter',
            'ag-header-cell-text',
            'ag-header-cell-label',
            'ag-header-icon ag-header-label-icon ag-sort-order',
        ];
        document.querySelector('.ag-header')?.addEventListener(
            'click',
            (e: any) => {
                if (disabledClasses.includes(e.target.className)) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
            true
        );
    };

    const createSortFiltersModel = (filterSortModel: FilterSortType): SortModelType[] => {
        if (!filterSortModel) return [];

        return Object.keys(filterSortModel)
            .map((key: string) => ({
                colId: key,
                sort:
                    filterSortModel[key].value === SortVariableEnum['-']
                        ? 'desc'
                        : filterSortModel[key].value === SortVariableEnum['+']
                        ? 'asc'
                        : '',
                sortable: true,
                sortIndex: filterSortModel[key].position,
            }))
            .sort((a, b) => a.sortIndex - b.sortIndex) as SortModelType[];
    };

    const createMainFiltersModel = (
        mainFilterStorage: FilterStoreItemType,
        filterSortStorage: FilterSortType
    ): FilterPayloadType => {
        const filterPayload: FilterPayloadType = {};

        if (hasDataInObject(mainFilterStorage)) {
            Object.keys(mainFilterStorage).forEach(
                (key: string) =>
                    (filterPayload[key] = {
                        filterType: FILTER_TYPE,
                        values: {
                            filters: mainFilterStorage[key],
                        },
                    })
            );
        }

        if (hasDataInObject(filterSortStorage)) {
            Object.keys(filterSortStorage).forEach(
                (key: string) =>
                    (filterPayload[key] = {
                        filterType: FILTER_TYPE,
                        values: {
                            filters: mainFilterStorage[key] || [],
                            sortColumn: filterSortStorage[key],
                        },
                    })
            );
        }

        return filterPayload;
    };

    const clearFilters = () => {
        setCurrentFilters({});
        setCurrentSortFilters({});

        setIsLoadedData(false);

        updateCalculateDataDebounce.run();
        clearLocalStorageData();
    };

    const clearLocalStorageData = () => {
        removeDataFromLocalStorage(portableFiltersKey());
        removeDataFromLocalStorage(sortFiltersKey());
        removeDataFromLocalStorage(currentVersionFiltersKey());
    };

    const applyFilters: ApplyFiltersFn = (
        newColumnFilters: FilterColumnItemType[],
        newSortFilter: SortFilterType | null,
        column: string
    ) => {
        const sortFiltersResult = ((
            currentSortFilters: FilterSortType,
            newSortFilter: SortFilterType
        ) => {
            if (!newSortFilter) {
                if (column in currentSortFilters) delete currentSortFilters[column];
                return currentSortFilters;
            }

            // @ts-ignore
            currentSortFilters[column] = newSortFilter;
            currentSortFilters[column]['position'] = getKeysFromObject(currentSortFilters).reduce(
                (count, key) => {
                    const position = currentSortFilters[key].position + 1;
                    if (position > count) count = position;
                    return count;
                },
                0
            );

            return currentSortFilters;
        })(currentSortFilters, newSortFilter);

        const currentFiltersResult = ((
            currentFilters: FilterStoreItemType,
            newColumnFilters: FilterColumnItemType[]
        ) => {
            if (newColumnFilters?.length === 0) {
                if (column in currentFilters) delete currentFilters[column];
                return currentFilters;
            }
            currentFilters[column] = newColumnFilters;
            return currentFilters;
        })(currentFilters, newColumnFilters);

        // Update table data with new filters
        updateCalculateDataDebounce.run(currentFiltersResult, currentSortFilters);

        // Set previous and current filters to local storage
        const previousAndCurrentFilters = generateUniqFilters(
            currentFiltersResult,
            notMatchesFilters,
            notMatchesFilters
        );

        setCurrentSortFilters(sortFiltersResult);
        setCurrentFilters(currentFiltersResult);

        // Delete HTML element
        for (const sortFiltersKey in sortFiltersResult) {
            delete sortFiltersResult[sortFiltersKey]['icon'];
        }

        // Set all type of filters to local storage
        setDataToLocalStorage(sortFiltersKey(), sortFiltersResult);
        setDataToLocalStorage(currentVersionFiltersKey(), currentFilters);
        setDataToLocalStorage(portableFiltersKey(), previousAndCurrentFilters);
    };

    const generateUniqFilters = (
        currentFilterParams: FilterStoreItemType,
        newFilterParams: FilterStoreItemType,
        notMatchesFilterParams?: FilterStoreItemType
    ): FilterStoreItemType => {
        const currentFilterFields = getKeysFromObject(currentFilterParams);
        const newFilterFields = getKeysFromObject(newFilterParams);
        const notMatchesFiltersFields = getKeysFromObject(notMatchesFilterParams);

        const uniqFiltersKeys = [
            ...new Set([...currentFilterFields, ...newFilterFields, ...notMatchesFiltersFields]),
        ];

        return uniqFiltersKeys.reduce((acc: {}, field: string) => {
            const currentFilterArr = getDataFromObjectByField(currentFilterParams, field);
            const newFilterArr = getDataFromObjectByField(newFilterParams, field);
            const notMatchesFiltersArr = getDataFromObjectByField(notMatchesFiltersFields, field);
            const allFiltersArr = [...currentFilterArr, ...newFilterArr, ...notMatchesFiltersArr];

            allFiltersArr.forEach((filter: FilterColumnItemType) => {
                if (!acc.hasOwnProperty(field)) acc[field] = [];

                const hasFilter = acc[field].some((resultFilter) => {
                    if (isCheckedFilter(filter))
                        return (
                            filter.isChecked === resultFilter.isChecked &&
                            filter.originalValue === resultFilter.originalValue
                        );
                    if (isConditionFilter(filter))
                        return (
                            filter.condition === resultFilter.condition &&
                            filter.value === resultFilter.value
                        );
                    return false;
                });

                if (!hasFilter) acc[field].push(filter);
            });

            return acc;
        }, {});
    };

    const fetchFilterVariants = (currentField: string, searchValue: string) =>
        dispatch(
            getFilterVariantsAction({
                filter_name: currentField,
                version_ids: activeVersionsIds,
                mipairs: [`${moduleName}:${indicatorName}`],
                search_value: searchValue
            })
        );

    const updateCalculateDataDebounce = useDebounceFn(
        (filters: FilterStoreItemType | null = null, currentSortFilters?: FilterSortType) => {
            const currentIndicatorMeta = meta.get(currentIndicator);
            const {groupby, cumsum} = settings;

            const queryParams: IGridRequest = {
                mipairs: [`${moduleName}:${indicatorName}`],
                versions: activeVersionsIds,
                settings: {
                    groupby: currentIndicatorMeta
                        ? currentIndicatorMeta.agg_method &&
                          currentIndicatorMeta.agg_method !== 'None'
                            ? groupby
                            : undefined
                        : groupby,
                    cumsum: currentIndicatorMeta
                        ? cumsum && currentIndicatorMeta.agg_method === 'sum'
                        : cumsum,
                },
                ...(filters && {filters}),
                ...(currentSortFilters && {sortFilters: currentSortFilters}),
            };

            dispatch(saveCalcFilters(queryParams));
            dispatch(calcRequest());
        },
        {
            wait: 1000,
        }
    );

    const scrollDiv = document.getElementsByClassName('ag-body-viewport')[0];

    const handleRowClick = (event: RowSelectedEvent) => {
        // Обработка клика по строчке
        const selectedRows = gridApi.getSelectedRows();
        const selectedIdxs = getIndexValuesByRowsData(selectedRows);

        dispatch(setSelectedIndex(selectedIdxs));

        // Сохранение позиции скролла таблицы
        if (scrollDiv) {
            // @ts-ignore
            setScrollPosition(scrollDiv.scrollTop);
        }

    };

    const handleRowDoubleClick = (event: RowSelectedEvent) => {
        // Обработка двойного клика по строчке
        const selectedRows = gridApi.getSelectedRows();
        
        if (selectedRows.length) {
            gridApi.deselectAll();
        } else {
            gridApi.selectAll();
            gridApi.getRowNode(event.node.id).setSelected(false);
        }

        const newSelectedRows = gridApi.getSelectedRows();
        const selectedIdxs = getIndexValuesByRowsData(newSelectedRows);

        dispatch(setSelectedIndex(selectedIdxs));
        // Почему-то Ag grid сам выделяет первую строку. А это фикс:
        gridApi.getRowNode('0').setSelected(false);

        // Сохранение позиции скролла таблицы
        if (scrollDiv) {
            // @ts-ignore
            setScrollPosition(scrollDiv.scrollTop);
        }
    };

    const handleSelectionChange = () => {
        // Восстановление позиции скролла таблицы
        if (scrollDiv) {
            // @ts-ignore
            scrollDiv.scrollTo(0, scrollPosition);
        }
    };

    return (
        <>
            {(hasDataInObject(currentFilters) || hasDataInObject(currentSortFilters)) && (
                <div style={{width: '200px', marginLeft: '20px', marginBottom: '5px'}}>
                    <Button
                        onClick={clearFilters}
                        label={t('components:tooltip.clear_filter', 'Очистить фильтр')}
                    />
                </div>
            )}
            <LinearProgress className={!isLoading ? 'hidden' : ''} />
            {isLoadedData && (
                <TableWrapper className="ag-theme-balham grid-view">
                    <AgGridReact
                        rowData={rowData}
                        enableRangeSelection={true}
                        enableCellTextSelection={false}
                        copyHeadersToClipboard={true}
                        animateRows={false}
                        onGridReady={onGridReady}
                        rowSelection="multiple"
                        onRowClicked={(event) => handleRowClick(event)}
                        onRowDoubleClicked={(event) => handleRowDoubleClick(event)}
                        onSelectionChanged={handleSelectionChange}
                        rowDeselection={false}
                        rowMultiSelectWithClick={true}
                        suppressColumnMoveAnimation={true}
                        suppressMovableColumns={true}
                        rowBuffer={100}
                        frameworkComponents={{
                            [FILTER_TYPE]: FilterColumn,
                        }}
                    >
                        {columnDefs.map((column) => (
                            <AgGridColumn
                                {...column}
                                key={column.field}
                                colId={column.field} // необходимо для корректного отображения значка фильтра
                                sortable={true}
                                filter={column.filter && FILTER_TYPE}
                                filterParams={{
                                    applyFilters,
                                    fetchFilterVariants,
                                }}
                            />
                        ))}
                    </AgGridReact>
                </TableWrapper>
            )}
            {!data && indicatorError && (
                <div className="no-data-message">
                    {t('components:grid_view.indicatorNoData', 'Данные отсутствуют')}
                </div>
            )}
        </>
    );
};

export default GridView;
