import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import {getConfig} from '../../config-provider';
import {ERRORS_ACTION_TYPES, getErrorsFail, getErrorsSuccess} from "./reducer";

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetErrors(action) {
    try {

        const res = yield call(axios.get, `${API_ENDPOINT}/logs/?${action.payload.entity}=${action.payload.id}`);
        if (res.error) {
            yield put(getErrorsFail(res.error));
        } else {
            yield put(getErrorsSuccess(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getErrorsFail(err.stack!));
        } else {
            yield put(getErrorsFail('An unknown error occured.'));
        }
    }
}

function* watchGetErrorsRequest() {
    yield takeLatest(ERRORS_ACTION_TYPES.GET_ERRORS_REQUEST, handleGetErrors);
}

function* errorsSaga() {
    yield all([fork(watchGetErrorsRequest)]);
}

export default errorsSaga;
