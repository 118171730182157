import {action} from 'typesafe-actions';
import {Reducer} from "redux";

export enum ERRORS_ACTION_TYPES {
    GET_ERRORS_REQUEST = '@@errors/GET_ERRORS_REQUEST',
    GET_ERRORS_SUCCESS = '@@errors/GET_ERRORS_SUCCESS',
    GET_ERRORS_FAIL = '@@errors/GET_ERRORS_FAIL',
    RESET = '@@errors/RESET'
}

export interface IError {
    level: number
    level_text: string
    extra_data: string
    message: string,
    created_at: string,
    id: number,
    logger_name: string,
    extra_data_type
}

export type IErrors = IError[];

export interface IErrorsState {
    data: any;
    readonly loaded: boolean;
    readonly errors?: string | null;
    readonly activeID: number | null
}

export const getErrors = (entity, id) => action(ERRORS_ACTION_TYPES.GET_ERRORS_REQUEST, {
    entity,
    id
});
export const getErrorsSuccess = (data) => action(ERRORS_ACTION_TYPES.GET_ERRORS_SUCCESS, data);
export const getErrorsFail = (message) => action(ERRORS_ACTION_TYPES.GET_ERRORS_FAIL, message);
export const resetErrorsData = () => action(ERRORS_ACTION_TYPES.RESET);

export const initialState: IErrorsState = {
    data: null,
    errors: null,
    loaded: false,
    activeID: null
};


const reducer: Reducer<IErrorsState> = (state = initialState, action) => {
    switch (action.type) {
        case ERRORS_ACTION_TYPES.GET_ERRORS_REQUEST: {
            return {...state, loaded: false, activeID: action.payload};
        }
        case ERRORS_ACTION_TYPES.GET_ERRORS_SUCCESS: {
            return {...state, loaded: true, data: action.payload.results};
        }
        case ERRORS_ACTION_TYPES.GET_ERRORS_FAIL: {
            return {...state, loaded: true, errors: action.payload};
        }
        case ERRORS_ACTION_TYPES.RESET: {
            return initialState
        }
        default: {
            return state;
        }
    }
};

export {reducer as errorsReducer};