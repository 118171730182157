import { Reducer } from 'redux';
import { IStatus, ITask, ITasksState, TasksActionType } from "./types";
import { addAll, addOrUpdate, deleteItem } from "../utils";

export const initialState: ITasksState = {
    tasks: new Map<number, ITask>(),
    show_system_tasks: true,
    show_my_tasks: true,
    selected_statuses: [],
    selected_types: [],
    selected_tasks: [],
    tasks_types: [],
    tasks_by_status: new Map<number, IStatus>(),
    selected_tasks_types: [],
    page: 1,
    loading: false,
    error: null,
    TOTAL_PAGES: 0,
    PER_PAGE: 30,
    showBadge: false,
    tasks_size: 0,
    task_status_table: new Map<number, IStatus>()
};


const reducer: Reducer<ITasksState> = (state = initialState, action): ITasksState => {
    switch (action.type) {
        case TasksActionType.GET_TASKS_REQUEST: {
            return { ...state, loading: true, page: action.payload.page, selected_statuses: action.payload.selected_statuses, selected_types: action.payload.selected_types };
        }
        case TasksActionType.GET_TASKS_SUCCESS: {
            return {
                ...state,
                loading: false,
                page: action.payload.page,
                tasks:  addAll(action.payload.tasks.results),
                TOTAL_PAGES: Math.ceil(action.payload.tasks.count / state.PER_PAGE)
            }
        }
        case TasksActionType.GET_TASKS_ERROR: {
            return { ...state, error: action.payload }
        }
        case TasksActionType.ADD_TASK: {
            return {
                ...state,
                showBadge: true,
                page: action.payload.page
            }
        }
        case TasksActionType.UPDATE_TASK: {
            return {
                ...state,
                tasks: addOrUpdate(state.tasks, action.payload.id, action.payload.task),
                showBadge: true
            }
        }
        case TasksActionType.DELETE_TASK: {
            return {
                ...state,
                tasks: deleteItem(state.tasks, action.payload.id)
            }
        }
        case TasksActionType.HIDE_BADGE: {
            return {
                ...state,
                showBadge: false
            }
        }
        case TasksActionType.GET_STATUS_TABLE: {
            return {
                ...state,
                loading: true
            }
        }
        case TasksActionType.GET_STATUS_TABLE_SUCCESS: {
            const localStorageStatuses = localStorage.getItem('selected_statuses');
            const statuses = localStorageStatuses ? localStorageStatuses.split(',').map(status => Number(status)) : action.payload.map(status => status.id);
            return {
                ...state,
                loading: false,
                task_status_table: addAll(action.payload),
                selected_statuses: statuses
            }
        }
        case TasksActionType.APPEND_TASK_TO_SELECTED: {
            return {
                ...state,
                selected_tasks: [action.payload.id, ...state.selected_tasks],
            }
        }
        case TasksActionType.REMOVE_TASK_FROM_SELECTED: {
            return {
                ...state,
                selected_tasks: state.selected_tasks.filter(
                    item => item !== action.payload.id
                )
            }
        }
        case TasksActionType.APPEND_ALL_TASKS_TO_SELECTED_REQUEST: {
            return {
                ...state,
            }
        }
        case TasksActionType.APPEND_ALL_TASKS_TO_SELECTED_SUCCESS: {
            const tasksIds = action.payload.task_ids;
            return {
                ...state,
                selected_tasks: tasksIds,
                tasks_size: tasksIds.length
            }
        }
        case TasksActionType.REMOVE_ALL_TASKS_FROM_SELECTED: {
            return {
                ...state,
                selected_tasks: [],
            }
        }
        case TasksActionType.REVOKE_SELECTED_TASKS_REQUEST: {
            return {
                ...state
            }
        }
        case TasksActionType.REVOKE_SELECTED_TASKS_SUCCESS: {
            return {
                ...state,
                selected_tasks: [],
            }
        }
        case TasksActionType.HIDE_SYSTEM_TASKS: {
            localStorage.setItem('show_system_tasks', JSON.stringify(true));
            return {
                ...state,
                show_system_tasks: true,
            }
        }
        case TasksActionType.SHOW_SYSTEM_TASKS: {
            localStorage.setItem('show_system_tasks', JSON.stringify(false));
            return {
                ...state,
                show_system_tasks: false,
            }
        }
        case TasksActionType.SHOW_MY_TASKS: {
            localStorage.setItem('show_my_tasks', JSON.stringify(true));
            return {
                ...state,
                show_my_tasks: true,
            }
        }
        case TasksActionType.SHOW_ALL_TASKS: {
            localStorage.setItem('show_my_tasks', JSON.stringify(false));
            return {
                ...state,
                show_my_tasks: false,
            }
        }
        case TasksActionType.SET_SELECTED_FILTERS: {
            localStorage.setItem('selected_statuses', action.payload.selected_statuses.join());
            localStorage.setItem('selected_types', action.payload.selected_types.join());
            return {
                ...state,
                selected_statuses: action.payload.selected_statuses,
                selected_types: action.payload.selected_types,
            }
        }
        case TasksActionType.LOAD_SYSTEM_TASKS_STATE: {
            state[action.payload.filter_parameter] = action.payload.visibility_state
            return {
                ...state
            }
        }
        case TasksActionType.GET_TASKS_SIZE_SUCCESS: {
            return {
                ...state,
                tasks_size: action.payload
            }
        }
        case TasksActionType.GET_TASKS_TYPES_REQUEST: {
            return {
                ...state,
            }
        }
        case TasksActionType.GET_TASKS_TYPES_SUCCESS: {
            const localStorageTypes = localStorage.getItem('selected_types');
            const types = localStorageTypes ? localStorageTypes.split(',').map(type => Number(type)) : action.payload.map(type => type.id);
            return {
                ...state,
                loading: false,
                tasks_types: action.payload,
                selected_types: types
            }
        }
        case TasksActionType.GET_TASKS_STATS_REQUEST: {
            return {
                ...state
            }
        }
        case TasksActionType.GET_TASKS_STATS_SUCCESS: {
            return {
                ...state,
                tasks_by_status: action.payload.tasks_by_status
            }
        }
        case TasksActionType.RESET_FILTERS: {
            const tasksTypes = state.tasks_types;
            const typesIds = [...tasksTypes].map(type => type.id)
            const tasksStatuses = Array.from(state.task_status_table, (x) => x[1]);
            const statusesIds = [...tasksStatuses].map(status => status.id)
            localStorage.setItem('show_system_tasks', JSON.stringify(false));
            localStorage.setItem('show_my_tasks', JSON.stringify(false));
            localStorage.setItem('selected_statuses', statusesIds.join());
            localStorage.setItem('selected_types', typesIds.join());
            return {
                ...state,
                selected_statuses: statusesIds,
                selected_types: typesIds,
                show_my_tasks: false,
                show_system_tasks: false
            }
        }
        case TasksActionType.GET_TASKS_STATE_FROM_LOCALSTORAGE: {
            const localStorageStatuses = localStorage.getItem('selected_statuses');
            const localStorageTypes = localStorage.getItem('selected_types');
            const localStorageShowSystemTasks = localStorage.getItem('show_system_tasks');
            const localStorageShowMyTasks = localStorage.getItem('show_my_tasks');
            const statuses = localStorageStatuses ? localStorageStatuses.split(',').map(status => Number(status)) : [];
            const showSystemTasks = localStorageShowSystemTasks ? Boolean(localStorageShowSystemTasks) : false;
            const showMyTasks = localStorageShowMyTasks ? Boolean(localStorageShowMyTasks) : false;
            const types = localStorageTypes ? localStorageTypes.split(',').map(type => Number(type)) : [];
            return {
                ...state,
                selected_statuses: statuses,
                selected_types: types,
                show_my_tasks: showMyTasks,
                show_system_tasks: showSystemTasks
            }
        }
        default: {
            return state
        }
    }
};

export { reducer as tasksReducer }