export enum ScenarioCalcActionType {
    SCENARIO_CALC_CREATE_REQUEST = '@@scenarioCalc/SCENARIO_CALC_CREATE',
    SCENARIO_CALC_CREATE_SUCCESS = '@@scenarioCalc/SCENARIO_CALC_CREATE_SUCCESS',
    SCENARIO_CALC_CREATE_ERROR = '@@scenarioCalc/SCENARIO_CALC_CREATE_ERROR',
    
    GET_SCENARIOS_LIST_REQUEST = '@@scenarioCalc/GET_SCENARIOS_LIST_REQUEST',
    GET_SCENARIOS_LIST_SUCCESS = '@@scenarioCalc/GET_SCENARIOS_LIST_SUCCESS',
    GET_SCENARIOS_LIST_ERROR = '@@scenarioCalc/GET_SCENARIOS_LIST_ERROR',

    DELETE_SCENARIO = '@@scenarioCalc/DELETE_SCENARIO',
}

export interface IScenario {
    id: number,
    name: string,
    type: string,
    description: string,
    created_at: Date,
}

export interface IScenarioState {
    scenarios: IScenario[] | null;
    readonly loading: boolean;
    readonly error: string | null;
}