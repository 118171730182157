import React from 'react';
import styled from 'styled-components/macro';

type TabProp = {
    text?: string;
    isActive?: boolean;
    disabled?: boolean;
    icon?: any;
    onClick?: any;
    className?: string;
};

export const Tab: React.FC<TabProp> = ({
    className,
    text,
    isActive = false,
    disabled = false,
    icon,
    onClick,
}) => {

    const handleClick = disabled ? () => {} : onClick;

    return (
    <TabElement className={className} isActive={isActive} disabled={disabled} onClick={handleClick}>
        {icon}
        <TabText>{text}</TabText>
    </TabElement>
)};

export const TabElement = styled.div`
    display: flex;
    align-items: center;
    background: ${(props) =>
        props.disabled
            ? props.theme.colors.grey6
            : props.isActive
            ? props.theme.colors.yellow
            : props.theme.colors.grey5};
    color: ${(props) =>
        props.disabled ? props.theme.textColor.lightGrey : props.theme.textColor.black};
    padding: 0 16px;
    height: 36px;
    font-size: 14px;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

    &:hover {
        background: ${(props) =>
            props.disabled
                ? props.theme.colors.grey6
                : props.isActive
                ? props.theme.colors.yellowDark
                : props.theme.colors.grey4};
    }
`;

export const TabText = styled.span`
    text-align: center;
`;
