import { action } from 'typesafe-actions'
import { ImportActionType } from './types'
import { ICalcJob } from '../calcJob/types';

export const importInit = (id: string) =>
    action(ImportActionType.IMPORT_INIT, { id });

export const importRequest = (id: string, file: File) =>
    action(ImportActionType.IMPORT_REQUEST, { id, file });

export const importProgress = (id: string, progress: number) =>
    action(ImportActionType.IMPORT_PROGRESS, { id, progress });

export const importSuccess = (id: string, job: ICalcJob) =>
    action(ImportActionType.IMPORT_SUCCESS, { id, job });

export const importError = (id: string, error: any) =>
    action(ImportActionType.IMPORT_ERROR, { id, error });

export const importReset = (id: string) =>
    action(ImportActionType.IMPORT_RESET, { id });
