import {all, call, delay, fork, put, select, takeLatest} from 'redux-saga/effects'
import axios from '../../axiosMiddleware';
import {versionExtraSuccess, versionExtraError, getCostsInfoSuccess, getCostsInfoError, getAnomalyInfoSuccess, getAnomalyInfoError} from './actions';
import {CurrentCalcContextActionType} from '../currentCalcContext/types'
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');


function* handleExtraRequest(action) {

    const rowId = action.payload.rowId;

    yield delay(400);
    try {
        const url = API_ENDPOINT.concat(`/version_extra/?version=`, rowId.toString());
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(versionExtraError(rowId, res.error))
        } else {
            yield put(versionExtraSuccess(rowId, res.data))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(versionExtraError(rowId, err.stack!))
        } else {
            yield put(versionExtraError(rowId, 'An unknown error occured.'))
        }
    }
}

function* handleCostsInfoRequest(action) {

    const currentIndicator = yield select((state) => state.currentCostsContext.currentIndicator);
    const driverName = yield select((state) => state.currentCostsContext.driverName);
    const comparisonVersionIds = yield select((state) => state.costsCompFilter.selectedRowsIds);
    const currentGroupBy = yield select((state) => state.costsFilter.groupby);
    const outlierCutoff = yield select((state) => state.costsFilter.outlierCutoff);
    const selectedRegions = yield select((state) => state.costsFilter.selectedRegions);
    const selectedProdSiteTypes = yield select((state) => state.costsFilter.selectedProdSiteTypes);

    const indicator = currentIndicator.split(':');
    const body = {
        comparison_version_ids: comparisonVersionIds,
        regions: selectedRegions,
        prod_site_types: selectedProdSiteTypes,
        groupby: currentGroupBy,
        cutoff_quantile: outlierCutoff,
        driver_name: driverName
    };
    const url = `${API_ENDPOINT}/costref/${indicator[0]}/${indicator[1]}/display`
    try {
        const res = yield call(axios.post, url, body);
        res.error ? yield put(getCostsInfoError(res.error)) :
            yield put(getCostsInfoSuccess(res.data));
    } catch (e) {
        if (e instanceof Error) {
            if ((e as any).response?.data?.error === 'ERR_NOT_FOUND') {
                yield put(getCostsInfoError({errorSlug: 'ERR_NOT_FOUND'}));
            } else {
                yield put(getCostsInfoError({errorSlug: 'ERR_UNEXPECTED', stack: e.stack!}));
            }
        } else {
            yield put(getCostsInfoError({errorSlug: 'ERR_UNKNOWN'}));
        }
    }
}

function* handleAnomalyInfoRequest(action) {
    const versionId = action.payload.versionId;
    const indicator = action.payload.indicator;

    const url = `${API_ENDPOINT}/mpl_plot/?v=${versionId}&mipair=${indicator}&type=table`;
    try {
        const res = yield call(axios.get, url);
        res.error ? yield put(getAnomalyInfoError(res.error)) :
            yield put(getAnomalyInfoSuccess(res.data, 'anomalyTree'));
    } catch (e) {
        if (e instanceof Error) {
            yield put(getAnomalyInfoError(e.stack!)); 
        } else {
            yield put(getAnomalyInfoError('An unknown error occured.'));
        }
    }
}

function* watchExtraRequest() {
    yield takeLatest(CurrentCalcContextActionType.VERSION_EXTRA_REQUEST, handleExtraRequest);
}

function* watchCostsInfoRequest() {
    yield takeLatest(CurrentCalcContextActionType.GET_COSTS_INFO_REQUEST, handleCostsInfoRequest);
}

function* watchAnomalyInfoRequest() {
    yield takeLatest(CurrentCalcContextActionType.GET_ANOMALY_INFO_REQUEST, handleAnomalyInfoRequest);
}

export function* versionExtraSaga() {
    yield all([
        fork(watchExtraRequest),
        fork(watchCostsInfoRequest),
        fork(watchAnomalyInfoRequest)
    ])
}
