import * as React from 'react';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

const icons = {
    '+': <ArrowUpward fontSize="small" />,
    '-': <ArrowDownward fontSize="small" />,
};

const SortLabel = ({sortBy, colName}) => {
    const colNames = sortBy.map((col) => col.name);

    if (colNames.includes(colName)) {
        const value = sortBy.find((col) => col.name === colName).value;
        const index = sortBy.findIndex((col) => col.name === colName) + 1;
        return (
            <>
                {icons[value]} {' ' + index}
            </>
        );
    }
    return null;
};

export default SortLabel;
