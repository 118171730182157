import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            fontSize: 12,
            width: '100%',
            textAlign: 'left',
            color: '#586069',
            fontWeight: 500,
        },
        tag: {
            marginTop: 0,
            height: 20,
            fontWeight: 500,
            lineHeight: '15px',
            borderRadius: 2,
        },
        positioned: {
            top: '0 !important',
            left: '0 !important',
            transform: 'none !important',
            zIndex: theme.zIndex.modal + 10,
            color: '#747480',
            backgroundColor: '#F5F5F9',
        },
        popper: {
            position: 'absolute',
            zIndex: theme.zIndex.modal + 10,
            color: '#747480',
            backgroundColor: '#F5F5F9',
        },
        footer: {
            borderTop: '1px solid #CDCDD1',
        },
        footerInfo: {
            display: 'flex',
            flexDirection: 'column',
            color: '#1A1A24',
        },
        footerButton: {
            display: 'flex',
            marginTop: '26px',
        },
        opacity: {
            display: 'flex',
            justifyContent: 'space-between',
            opacity: 0,
        },
        hidden: {
            display: 'none',
        },
        inputBase: {
            fontSize: 14,
            padding: '15px 0 0 0',
            width: '100%',

            '& input': {
                background: '#EEEEF2',
                color: '#747480',
                padding: '19px 16px',
                boxSizing: 'border-box',
                border: 'none',
            },
        },
        paper: {
            position: 'relative',
            boxShadow: 'none',
            color: '#586069',
            fontSize: 12,
            backgroundColor: '#F5F5F9',
        },
        option: {
            padding: '6px 0',

            '&[aria-focus="true"]': {
                backgroundColor: 'transparent',
            },
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        popperDisablePortal: {
            position: 'relative',
        },
        checkbox: {
            padding: 0,
            margin: 0,
        },
        checkboxLabel: {
            paddingLeft: 10,
            color: '#2E2E38',
        },
        headerButtons: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #CDCDD1',
            padding: '20px 0 10px',
        },
        indeterminate: {
            color: '#FF4136',
        },
    })
);
