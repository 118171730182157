import {all, call, delay, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import {ReportsActionType} from "./types";
import {
    getReportsError,
    getReportsSuccess,
    getReportTagsError,
    getReportTagsSuccess,
    retrieveReportError,
    retrieveReportSuccess,
} from "./actions";
import {getConfig} from "../../config-provider";
import {requestSnackDisplay} from "../snackbars/actions";
import i18n from '../../i18n';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');
const PER_PAGE = 30;

function* handleGetRequest(action) {
    yield delay(400);
    const page = action.payload;
    const url = `${API_ENDPOINT}/reporting/reports/?page=`.concat(page.toString(), '&page_size=', PER_PAGE.toString());

    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getReportsError(res.error))
        } else {
            yield put(getReportsSuccess(res.data))
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(getReportsError(error.stack!))
        } else {
            yield put(getReportsError('An unknown error occurred.'))
        }
    }
}

function* handleRetrieveReportRequest(action) {
    yield delay(400);
    const report_id = action.payload.id;
    const url = `${API_ENDPOINT}/reporting/full_kpi_reports/${report_id}/`;

    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(retrieveReportError(res.error))
        } else {
            yield put(retrieveReportSuccess(res.data))
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(retrieveReportError(error.stack!))
        } else {
            yield put(retrieveReportError('An unknown error occurred.'))
        }
    }
}

function* handleOrderReport(action) {
    yield delay(400); //debounce
    const url = `${API_ENDPOINT}/reporting/create-report/${action.payload.reportType}`;
    const body = action.payload.reportArgs;
    try {
        const res = yield call(axios.post, url, body, {headers: {'Content-Type': 'application/json'}});
        res.error ? yield put(requestSnackDisplay('error', `${i18n.t('notifications:reports.error')} ${res.error}`)) :
            yield put(requestSnackDisplay('success', i18n.t('notifications:reports.started')));
    } catch (e) {
        if (e instanceof Error) yield put(requestSnackDisplay('error', `${i18n.t('notifications:reports.error')} ${e.stack}`));
    }
}

function* handleGetTags() {
    try {
        const res = yield call(axios.get, `${API_ENDPOINT}/reporting/report_tags/`, {params: {}});
        if (res.error) {
            yield put(getReportTagsError(res.error))
        } else {
            yield put(getReportTagsSuccess(res.data))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getReportTagsError(err.stack!))
        } else {
            yield put(getReportTagsError('An unknown error occured.'))
        }
    }
}

function* watchRetrieveRequest() {
    yield takeLatest(ReportsActionType.RETRIEVE_REPORT_REQUEST, handleRetrieveReportRequest)
}

function* watchGetRequest() {
    yield takeLatest(ReportsActionType.GET_REPORTS_REQUEST, handleGetRequest)
}

function* watchOrderReportRequest() {
    yield takeLatest(ReportsActionType.ORDER_REPORT_REQUEST, handleOrderReport)
}

function* watchGetTags() {
    yield takeLatest(ReportsActionType.GET_REPORT_TAGS, handleGetTags)
}

function* reportSaga() {
    yield all([
        fork(watchRetrieveRequest),
        fork(watchGetRequest), 
        fork(watchOrderReportRequest), 
        fork(watchGetTags), 
    ])
}

export default reportSaga
