import {action} from 'typesafe-actions';
import {SnackbarsActionType} from "./types";

export const showSnackbar = (type: string, message: string) =>
    action(SnackbarsActionType.SHOW_SNACKBAR, {type, message});

export const hideSnackbar = () => action(SnackbarsActionType.HIDE_SNACKBAR);

export const requestSnackDisplay = (type: string, message: string) => action(SnackbarsActionType.SNACK_DISPLAY_REQUESTED, {
    type,
    message
});