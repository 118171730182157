export enum VersionAttrOrGroupsActionType {
    GET_VERSION_ATTR_OR_GROUPS_REQUEST = '@@versionAttrOrGroups/GET_VERSION_ATTR_OR_GROUPS_REQUEST',
    GET_VERSION_ATTR_OR_GROUPS_SUCCESS = '@@versionAttrOrGroups/GET_VERSION_ATTR_OR_GROUPS_SUCCESS',
    GET_VERSION_ATTR_OR_GROUPS_ERROR = '@@versionAttrOrGroups/GET_VERSION_ATTR_OR_GROUPS_ERROR',
    UPDATE_VERSION_ATTR_OR_GROUPS_REQUEST = '@@versionAttrOrGroups/UPDATE_VERSION_ATTR_OR_GROUPS_REQUEST',
    UPDATE_VERSION_ATTR_OR_GROUPS_SUCCESS = '@@versionAttrOrGroups/UPDATE_VERSION_ATTR_OR_GROUPS_SUCCESS',
    UPDATE_VERSION_ATTR_OR_GROUPS_ERROR = '@@versionAttrOrGroups/UPDATE_VERSION_ATTR_OR_GROUPS_ERROR',
}

export interface IVersionAttribute {
    id: number,
    name: string,
    hidden?: Boolean
}

export interface IVersionAttributes {
    every_version: IVersionAttribute[] | null,
    some_versions: IVersionAttribute[] | null
}

export interface IVersionAttributeState {
    values: IVersionAttributes | null;
    readonly loading: boolean;
    readonly error: string | null;
}