import {IVersionAttributeState, VersionAttrOrGroupsActionType} from "./types";

export const versionAttributesinitialState: IVersionAttributeState = {
    values: {
        every_version: [],
        some_versions: []
    },
    loading: false,
    error: null
};

export const versionGroupsinitialState: IVersionAttributeState = {
    values: {
        every_version: [],
        some_versions: []
    },
    loading: false,
    error: null
};

const initialState = {
    versionAttributes: versionAttributesinitialState,
    versionGroups: versionGroupsinitialState
}

const createAttributesReducesWithNameData = (context) => {
    return (state = initialState[context], action) => {
        const {contextName} = action.payload ? action.payload : '';
        
        if (contextName !== context) return state;

        switch (action.type) {
            case VersionAttrOrGroupsActionType.GET_VERSION_ATTR_OR_GROUPS_REQUEST: {
                return {...state, loading: true}
            }
            case VersionAttrOrGroupsActionType.GET_VERSION_ATTR_OR_GROUPS_SUCCESS: {
                return {
                    ...state,
                    loading: false,
                    values: action.payload.values
                }
            }
            case VersionAttrOrGroupsActionType.GET_VERSION_ATTR_OR_GROUPS_ERROR: {
                return {
                    ...state, error: action.payload
                }
            }
            case VersionAttrOrGroupsActionType.UPDATE_VERSION_ATTR_OR_GROUPS_REQUEST: {
                return {...state, loading: true}
            }
            case VersionAttrOrGroupsActionType.UPDATE_VERSION_ATTR_OR_GROUPS_SUCCESS: {
                return {
                    ...state,
                    loading: false,
                }
            }
            case VersionAttrOrGroupsActionType.UPDATE_VERSION_ATTR_OR_GROUPS_ERROR: {
                return {
                    ...state, error: action.payload
                }
            }
            default: {
                return state
            }
        }
}};

export {createAttributesReducesWithNameData};