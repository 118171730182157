import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import {CalcJobErrorsActionType} from './types';
import {getJobErrorsError, getJobErrorsSuccess} from './actions';
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetJobs(action) {
    try {

        const res = yield call(axios.get, `${API_ENDPOINT}/logs/?job=${action.payload}`);
        if (res.error) {
            yield put(getJobErrorsError(res.error));
        } else {
            yield put(getJobErrorsSuccess(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getJobErrorsError(err.stack!));
        } else {
            yield put(getJobErrorsError('An unknown error occured.'));
        }
    }
}

function* watchGetCalcJobErrorsRequest() {
    yield takeLatest(CalcJobErrorsActionType.GET_JOB_ERRORS_REQUEST, handleGetJobs);
}

function* calcJobsErrorsSaga() {
    yield all([fork(watchGetCalcJobErrorsRequest)]);
}

export default calcJobsErrorsSaga;
