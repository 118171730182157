import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import {Popup} from '../Popup/Popup';
import {ScenarioDetails} from './ScenarioDetails';
import {removeDataFromLocalStorage} from '../../helpers/localStorage';
import {useHistory} from 'react-router';

const TM_FILTER_NAME = 'taxModesFilter';

const localeKeys = {
    taxModesFilter: 'tax_mode',
    emissionScenariosFilter: 'esg',
    costsScenariosFilter: 'cost'
}

interface PassedProps {
    dialogState: any;
    setDialogState: any;
    filterName: string;
    isFullScreenInit?: boolean;
}

type ScenarioDialogProps = PassedProps;

export const ScenarioDialog: React.FC<ScenarioDialogProps> = ({
    dialogState, 
    setDialogState, 
    filterName, 
    isFullScreenInit = true
}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const localeKey = localeKeys[filterName] ?? localeKeys[TM_FILTER_NAME];
    const {editMode, update, scenarioId, parentScenarioName} = dialogState;
    const isTaxMode = filterName === 'taxModesFilter';

    // viewMode - режим просмотра без редактирования
    // fromCopy - режим редактирования копии сценария
    // updtMode - режим редактирования черновика сценария
    // isTaxMode - текущий сценарий явяется НР
    const viewMode = scenarioId && !editMode;
    const fromCopy = parentScenarioName !== null;
    const updtMode = update;

    const [fullscreenMode, setFullscreenMode] = useState(isFullScreenInit);

    const changeFullscreen = () => setFullscreenMode(!fullscreenMode);

    const handleClose = () => {
        // Удаляем данные сценария после редактирования копии или черновика сценария
        // т.к. данные уже хранятся на сервере.
        if (fromCopy || updtMode) {
            removeDataFromLocalStorage(`${localeKey}_InitTree`);
            removeDataFromLocalStorage(`${localeKey}_Draft`);
        }

        isTaxMode && history.push('/tax_regime');

        setDialogState({
            ...dialogState,
            parentScenarioName: null,
            update: false,
            open: false,
        });
    };

    let header = t(`pages:scenarios.dialog.${localeKey}.create`);

    if (viewMode) {
        header = t(`pages:scenarios.dialog.${localeKey}.view`);
    } else if (fromCopy) {
        header = `${t(`pages:scenarios.dialog.${localeKey}.copy`)} ${parentScenarioName}`;
    } else if (updtMode) {
        header = t(`pages:scenarios.dialog.${localeKey}.edit`);
    }

    return (
        <>
            <PopupWrapper
                header={header}
                handleClosePopup={handleClose}
                handleChangeFullscreenMode={changeFullscreen}
                isFullscreen={fullscreenMode}
                allowFullscreen
            >
                <ScenarioDetails
                    handleClose={handleClose}
                    dialogState={dialogState}
                    setDialogState={setDialogState}
                    isFullscreen={fullscreenMode}
                    filterName={filterName}
                />
            </PopupWrapper>
        </>
    );
};

const PopupWrapper = styled(Popup)`
    & > div {
        width: 80%;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        height: 100%;
    }
`;

export const BottomMenu = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 25px 0 0;
    margin-top: auto;
    border-top: 1px solid ${(props) => props.theme.colors.grey2};

    button + button {
        margin-left: 25px;
    }
`;
