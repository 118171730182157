import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import {AppPropertiesAction} from './types';
import {appVersionSuccess} from './action';
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleAppVersionRequest() {
    const res = yield call(axios.get, `${API_ENDPOINT}/app/properties`);
    yield put(appVersionSuccess(res.data));
}

function* watchHandleAppVersionRequest() {
    yield takeLatest(AppPropertiesAction.GET_APP_PROPERTIES_REQUEST, handleAppVersionRequest);
}

export function* appVersionSaga() {
    yield all([fork(watchHandleAppVersionRequest)]);
}
