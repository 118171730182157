/* eslint-disable no-constant-condition */
import * as React from 'react';
import useId from './unstable_useId'

import setRef from '@material-ui/core/utils/setRef';
import useEventCallback from '@material-ui/core/utils/useEventCallback';
import useControlled from '@material-ui/core/utils/useControlled';


function stripDiacritics(string) {
    return typeof string.normalizeMiddleware !== 'undefined'
        ? string.normalizeMiddleware('NFD').replace(/[\u0300-\u036f]/g, '')
        : string;
}

export function createFilterOptions(config = {}) {
    const {
        ignoreAccents = true,
        ignoreCase = true,
        limit,
        matchFrom = 'any',
        stringify,
        trim = false,
    } = config;

    return (options, { inputValue, getOptionLabel }) => {
        let input = trim ? inputValue.trim() : inputValue;
        if (ignoreCase) {
            input = input.toLowerCase();
        }
        if (ignoreAccents) {
            input = stripDiacritics(input);
        }

        const filteredOptions = options.filter((option) => {
            let candidate = (stringify || getOptionLabel)(option);
            if (ignoreCase) {
                candidate = candidate.toLowerCase();
            }
            if (ignoreAccents) {
                candidate = stripDiacritics(candidate);
            }

            return matchFrom === 'start' ? candidate.indexOf(input) === 0 : candidate.indexOf(input) > -1;
        });

        return typeof limit === 'number' ? filteredOptions.slice(0, limit) : filteredOptions;
    };
}

function findIndex(array, comp) {
    for (let i = 0; i < array.length; i += 1) {
        if (comp(array[i])) {
            return i;
        }
    }

    return -1;
}

const defaultFilterOptions = createFilterOptions();

const pageSize = 5;

export default function useAutocomplete(props) {
    const {
        autoComplete = false,
        autoHighlight = false,
        autoSelect = false,
        blurOnSelect = false,
        clearOnBlur = false,
        clearOnEscape = false,
        componentName = 'useAutocomplete',
        debug = false,
        defaultValue = props.multiple ? [] : null,
        disableClearable = false,
        disableCloseOnSelect = false,
        disabledItemsFocusable = false,
        disableListWrap = false,
        filterOptions = defaultFilterOptions,
        filterSelectedOptions = false,
        freeSolo = false,
        getOptionDisabled,
        getOptionLabel = (x) => x,
        getOptionSelected = (option, value) => option === value,
        groupBy,
        handleHomeEndKeys = !props.freeSolo,
        id: idProp,
        includeInputInList = false,
        inputValue: inputValueProp,
        multiple = false,
        onChange,
        onClose,
        onHighlightChange,
        onInputChange,
        onOpen,
        open: openProp,
        openOnFocus = false,
        options,
        selectOnFocus = !props.freeSolo,
        value: valueProp,
    } = props;

    const id = useId(idProp);

    const ignoreFocus = React.useRef(false);
    const firstFocus = React.useRef(true);
    const inputRef = React.useRef(null);
    const listboxRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [focusedTag, setFocusedTag] = React.useState(-1);
    const defaultHighlighted = autoHighlight ? 0 : -1;
    const highlightedIndexRef = React.useRef(defaultHighlighted);

    const [value, setValue] = useControlled({
        controlled: valueProp,
        default: defaultValue,
        name: componentName,
    });
    const [inputValue, setInputValue] = useControlled({
        controlled: inputValueProp,
        default: '',
        name: componentName,
        state: 'inputValue',
    });

    const [focused, setFocused] = React.useState(false);

    const resetInputValue = useEventCallback((event, newValue) => {
        let newInputValue;
        if (multiple) {
            newInputValue = '';
        } else if (newValue == null) {
            newInputValue = '';
        } else {
            const optionLabel = getOptionLabel(newValue);

            if (process.env.NODE_ENV !== 'production') {
                if (typeof optionLabel !== 'string') {
                    const erroneousReturn =
                        optionLabel === undefined ? 'undefined' : `${typeof optionLabel} (${optionLabel})`;
                    console.error(
                        `Material-UI: The \`getOptionLabel\` method of ${componentName} returned ${erroneousReturn} instead of a string for ${JSON.stringify(
                            newValue,
                        )}.`,
                    );
                }
            }

            newInputValue = typeof optionLabel === 'string' ? optionLabel : '';
        }

        if (inputValue === newInputValue) {
            return;
        }

        setInputValue(newInputValue);

        if (onInputChange) {
            onInputChange(event, newInputValue, 'reset');
        }
    });

    React.useEffect(() => {
        // resetInputValue(null, value);
    }, [value, resetInputValue]);

    const [open, setOpenState] = useControlled({
        controlled: openProp,
        default: false,
        name: componentName,
        state: 'open',
    });

    const inputValueIsSelectedValue =
        !multiple && value != null && inputValue === getOptionLabel(value);

    const popupOpen = open;

    const filteredOptions = popupOpen
        ? filterOptions(
            options.filter((option) => {
                if (
                    filterSelectedOptions &&
                    (multiple ? value : [value]).some(
                        (value2) => value2 !== null && getOptionSelected(option, value2),
                    )
                ) {
                    return false;
                }
                return true;
            }),
            { inputValue: inputValueIsSelectedValue ? '' : inputValue, getOptionLabel },
        )
        : [];

    if (process.env.NODE_ENV !== 'production') {
        if (value !== null && !freeSolo && options.length > 0) {
            const missingValue = (multiple ? value : [value]).filter(
                (value2) => !options.some((option) => getOptionSelected(option, value2)),
            );

            if (missingValue.length > 0) {
                console.warn(
                    [
                        `Material-UI: The value provided to ${componentName} is invalid.`,
                        `None of the options match with \`${missingValue.length > 1
                            ? JSON.stringify(missingValue)
                            : JSON.stringify(missingValue[0])
                        }\`.`,
                        'You can use the `getOptionSelected` prop to customize the equality test.',
                    ].join('\n'),
                );
            }
        }
    }

    const focusTag = useEventCallback((tagToFocus) => {

        if (tagToFocus === -1) {
            inputRef.current.focus();
        } else {
            anchorEl.querySelector(`[data-tag-index="${tagToFocus}"]`).focus();
        }
    });

    React.useEffect(() => {
        if (multiple && focusedTag > value.length - 1) {
            setFocusedTag(-1);
            focusTag(-1);
        }
    }, [value, multiple, focusedTag, focusTag]);

    function validOptionIndex(index, direction) {
        if (!listboxRef.current || index === -1) {
            return -1;
        }

        let nextFocus = index;

        while (true) {
            if (
                (direction === 'next' && nextFocus === filteredOptions.length) ||
                (direction === 'previous' && nextFocus === -1)
            ) {
                return -1;
            }

            const option = listboxRef.current.querySelector(`[data-option-index="${nextFocus}"]`);

            const nextFocusDisabled = disabledItemsFocusable
                ? false
                : option && (option.disabled || option.getAttribute('aria-disabled') === 'true');

            if ((option && !option.hasAttribute('tabindex')) || nextFocusDisabled) {
                nextFocus += direction === 'next' ? 1 : -1;
            } else {
                return nextFocus;
            }
        }
    }

    const setHighlightedIndex = useEventCallback((index, reason = 'auto', event) => {
        highlightedIndexRef.current = index;
        if (index === -1) {
            inputRef.current.removeAttribute('aria-activedescendant');
        } else {
            inputRef.current.setAttribute('aria-activedescendant', `${id}-option-${index}`);
        }

        if (!listboxRef.current) {
            return;
        }

        const prev = listboxRef.current.querySelector('[data-focus]');
        if (prev) {
            prev.removeAttribute('data-focus');
        }

        const listboxNode = listboxRef.current.parentElement.querySelector('[role="listbox"]');

        if (!listboxNode) {
            return;
        }

        if (onHighlightChange) {
            onHighlightChange(event, filteredOptions[index], reason);
        }


        const option = listboxRef.current.querySelector(`[data-option-index="${index}"]`);

        if (!option) {
            return;
        }

        option.setAttribute('data-focus', 'true');

    });

    const changeHighlightedIndex = useEventCallback((diff, direction, reason = 'auto', event) => {
        if (!popupOpen) {
            return;
        }

        const getNextIndex = () => {
            const maxIndex = filteredOptions.length - 1;

            if (diff === 'reset') {
                return defaultHighlighted;
            }

            if (diff === 'start') {
                return 0;
            }

            if (diff === 'end') {
                return maxIndex;
            }

            const newIndex = highlightedIndexRef.current + diff;

            if (newIndex < 0) {
                if (newIndex === -1 && includeInputInList) {
                    return -1;
                }

                if ((disableListWrap && highlightedIndexRef.current !== -1) || Math.abs(diff) > 1) {
                    return 0;
                }

                return maxIndex;
            }

            if (newIndex > maxIndex) {
                if (newIndex === maxIndex + 1 && includeInputInList) {
                    return -1;
                }

                if (disableListWrap || Math.abs(diff) > 1) {
                    return maxIndex;
                }

                return 0;
            }

            return newIndex;
        };

        const nextIndex = validOptionIndex(getNextIndex(), direction);
        setHighlightedIndex(nextIndex, reason, event);

        if (autoComplete && diff !== 'reset') {
            if (nextIndex === -1) {
                inputRef.current.value = inputValue;
            } else {
                const option = getOptionLabel(filteredOptions[nextIndex]);
                inputRef.current.value = option;

                const index = option.toLowerCase().indexOf(inputValue.toLowerCase());
                if (index === 0 && inputValue.length > 0) {
                    inputRef.current.setSelectionRange(inputValue.length, option.length);
                }
            }
        }
    });

    React.useEffect(() => {
        if (!popupOpen) {
            return;
        }

        const valueItem = multiple ? value[0] : value;

        if (filteredOptions.length === 0 || valueItem == null) {
            changeHighlightedIndex('reset', 'next');
            return;
        }

        if (!filterSelectedOptions && valueItem != null) {
            const currentOption = filteredOptions[highlightedIndexRef.current];

            if (
                multiple &&
                currentOption &&
                findIndex(value, (val) => getOptionSelected(currentOption, val)) !== -1
            ) {
                return;
            }

            const itemIndex = findIndex(filteredOptions, (optionItem) =>
                getOptionSelected(optionItem, valueItem),
            );

            if (itemIndex === -1) {
                changeHighlightedIndex('reset', 'next');
            } else {
                setHighlightedIndex(itemIndex);
            }
            return;
        }

        if (highlightedIndexRef.current >= filteredOptions.length - 1) {
            setHighlightedIndex(filteredOptions.length - 1);
        }
    }, //eslint-disable-next-line
        [
            //eslint-disable-next-line
            filteredOptions.length === 0,
            value,
            popupOpen,
            filterSelectedOptions,
            changeHighlightedIndex,
            setHighlightedIndex,
            inputValue,
            multiple,
        ]);

    const handleOpen = (event) => {
        if (open) {
            return;
        }

        setOpenState(true);

        if (onOpen) {
            onOpen(event);
        }
    };

    const handleClose = (event, reason) => {
        if (!open) {
            return;
        }

        setOpenState(false);

        if (onClose) {
            onClose(event, reason);
        }
    };

    const handleValue = (event, newValue, reason, details) => {
        if (value === newValue) {
            return;
        }

        if (onChange) {
            onChange(event, newValue, reason, details);
        }

        setValue(newValue);
    };

    const isTouch = React.useRef(false);

    const selectNewValue = (event, option, reasonProp = 'select-option', origin = 'options') => {
        let reason = reasonProp;
        let newValue = option;

        if (multiple) {
            newValue = Array.isArray(value) ? value.slice() : [];

            if (process.env.NODE_ENV !== 'production') {
                const matches = newValue.filter((val) => getOptionSelected(option, val));

                if (matches.length > 1) {
                    console.error(
                        [
                            `Material-UI: The \`getOptionSelected\` method of ${componentName} do not handle the arguments correctly.`,
                            `The component expects a single value to match a given option but found ${matches.length} matches.`,
                        ].join('\n'),
                    );
                }
            }

            const itemIndex = findIndex(newValue, (valueItem) => getOptionSelected(option, valueItem));

            if (itemIndex === -1) {
                newValue.push(option);
            } else if (origin !== 'freeSolo') {
                newValue.splice(itemIndex, 1);
                reason = 'remove-option';
            }
        }

        // resetInputValue(event, newValue);

        handleValue(event, newValue, reason, { option });
        if (!disableCloseOnSelect) {
            handleClose(event, reason);
        }

        if (
            blurOnSelect === true ||
            (blurOnSelect === 'touch' && isTouch.current) ||
            (blurOnSelect === 'mouse' && !isTouch.current)
        ) {
            inputRef.current.blur();
        }
    };

    function validTagIndex(index, direction) {
        if (index === -1) {
            return -1;
        }

        let nextFocus = index;

        while (true) {
            if (
                (direction === 'next' && nextFocus === value.length) ||
                (direction === 'previous' && nextFocus === -1)
            ) {
                return -1;
            }

            const option = anchorEl.querySelector(`[data-tag-index="${nextFocus}"]`);

            if (
                option &&
                (!option.hasAttribute('tabindex') ||
                    option.disabled ||
                    option.getAttribute('aria-disabled') === 'true')
            ) {
                nextFocus += direction === 'next' ? 1 : -1;
            } else {
                return nextFocus;
            }
        }
    }

    const handleFocusTag = (event, direction) => {
        if (!multiple) {
            return;
        }

        handleClose(event, 'toggleInput');

        let nextTag = focusedTag;

        if (focusedTag === -1) {
            if (inputValue === '' && direction === 'previous') {
                nextTag = value.length - 1;
            }
        } else {
            nextTag += direction === 'next' ? 1 : -1;

            if (nextTag < 0) {
                nextTag = 0;
            }

            if (nextTag === value.length) {
                nextTag = -1;
            }
        }

        nextTag = validTagIndex(nextTag, direction);

        setFocusedTag(nextTag);
        focusTag(nextTag);
    };

    const handleClear = (event) => {
        ignoreFocus.current = true;
        setInputValue('');

        if (onInputChange) {
            onInputChange(event, '', 'clear');
        }

        handleValue(event, multiple ? [] : null, 'clear');
    };

    const handleKeyDown = (other) => (event) => {
        if (focusedTag !== -1 && ['ArrowLeft', 'ArrowRight'].indexOf(event.key) === -1) {
            setFocusedTag(-1);
            focusTag(-1);
        }

        switch (event.key) {
            case 'Home':
                if (popupOpen && handleHomeEndKeys) {
                    event.preventDefault();
                    changeHighlightedIndex('start', 'next', 'keyboard', event);
                }
                break;
            case 'End':
                if (popupOpen && handleHomeEndKeys) {
                    event.preventDefault();
                    changeHighlightedIndex('end', 'previous', 'keyboard', event);
                }
                break;
            case 'PageUp':
                event.preventDefault();
                changeHighlightedIndex(-pageSize, 'previous', 'keyboard', event);
                handleOpen(event);
                break;
            case 'PageDown':
                event.preventDefault();
                changeHighlightedIndex(pageSize, 'next', 'keyboard', event);
                handleOpen(event);
                break;
            case 'ArrowDown':
                event.preventDefault();
                changeHighlightedIndex(1, 'next', 'keyboard', event);
                handleOpen(event);
                break;
            case 'ArrowUp':
                event.preventDefault();
                changeHighlightedIndex(-1, 'previous', 'keyboard', event);
                handleOpen(event);
                break;
            case 'ArrowLeft':
                handleFocusTag(event, 'previous');
                break;
            case 'ArrowRight':
                handleFocusTag(event, 'next');
                break;
            case 'Enter':
                if (event.which === 229) {
                    break;
                }
                if (highlightedIndexRef.current !== -1 && popupOpen) {
                    const option = filteredOptions[highlightedIndexRef.current];
                    const disabled = getOptionDisabled ? getOptionDisabled(option) : false;

                    if (disabled) {
                        return;
                    }

                    event.preventDefault();
                    selectNewValue(event, option, 'select-option');

                    if (autoComplete) {
                        inputRef.current.setSelectionRange(
                            inputRef.current.value.length,
                            inputRef.current.value.length,
                        );
                    }
                } else if (freeSolo && inputValue !== '' && inputValueIsSelectedValue === false) {
                    if (multiple) {
                        event.preventDefault();
                    }
                    selectNewValue(event, inputValue, 'create-option', 'freeSolo');
                }
                break;
            case 'Escape':
                if (popupOpen) {
                    event.preventDefault();
                    event.stopPropagation();
                    handleClose(event, 'escape');
                } else if (clearOnEscape && (inputValue !== '' || (multiple && value.length > 0))) {
                    event.preventDefault();
                    event.stopPropagation();
                    handleClear(event);
                }
                break;
            case 'Backspace':
                if (multiple && inputValue === '' && value.length > 0) {
                    const index = focusedTag === -1 ? value.length - 1 : focusedTag;
                    const newValue = value.slice();
                    newValue.splice(index, 1);
                    handleValue(event, newValue, 'remove-option', {
                        option: value[index],
                    });
                }
                break;
            default:
        }

        if (other.onKeyDown) {
            other.onKeyDown(event);
        }
    };

    const handleFocus = (event) => {
        setFocused(true);

        if (openOnFocus && !ignoreFocus.current) {
            handleOpen(event);
        }
    };

    const handleBlur = (event) => {
        if (
            listboxRef.current !== null &&
            document.activeElement === listboxRef.current.parentElement
        ) {
            inputRef.current.focus();
            return;
        }

        setFocused(false);
        firstFocus.current = true;
        ignoreFocus.current = false;

        if (debug && inputValue !== '') {
            return;
        }

        if (autoSelect && highlightedIndexRef.current !== -1 && popupOpen) {
            selectNewValue(event, filteredOptions[highlightedIndexRef.current], 'blur');
        } else if (autoSelect && freeSolo && inputValue !== '') {
            selectNewValue(event, inputValue, 'blur', 'freeSolo');
        } else if (clearOnBlur) {
            resetInputValue(event, value);
        }

        handleClose(event, 'blur');
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value;

        if (inputValue !== newValue) {
            setInputValue(newValue);

            if (onInputChange) {
                onInputChange(event, newValue, 'input');
            }
        }

        if (newValue === '') {
            if (!disableClearable && !multiple) {
                handleValue(event, null, 'clear');
            }
        } else {
            handleOpen(event);
        }
    };

    const handleOptionMouseOver = (event) => {
        const index = Number(event.currentTarget.getAttribute('data-option-index'));
        setHighlightedIndex(index, 'mouse', event);
    };

    const handleOptionTouchStart = () => {
        isTouch.current = true;
    };

    const handleOptionClick = (event) => {
        const index = Number(event.currentTarget.getAttribute('data-option-index'));
        selectNewValue(event, filteredOptions[index], 'select-option');

        isTouch.current = false;
    };

    const handleTagDelete = (index) => (event) => {
        const newValue = value.slice();
        newValue.splice(index, 1);
        handleValue(event, newValue, 'remove-option', {
            option: value[index],
        });
    };

    const handleListboxRef = useEventCallback((node) => {
        setRef(listboxRef, node);

        if (!node) {
            return;
        }

        if (highlightedIndexRef.current === -1 && autoHighlight) {
            changeHighlightedIndex('reset', 'next');
        } else {
            setHighlightedIndex(highlightedIndexRef.current);
        }
    });

    const handlePopupIndicator = (event) => {
        if (open) {
            handleClose(event, 'toggleInput');
        } else {
            handleOpen(event);
        }
    };

    const handleMouseDown = (event) => {
        if (event.target.getAttribute('id') !== id) {
            event.preventDefault();
        }
    };

    const handleClick = () => {
        inputRef.current.focus();

        if (
            selectOnFocus &&
            firstFocus.current &&
            inputRef.current.selectionEnd - inputRef.current.selectionStart === 0
        ) {
            inputRef.current.select();
        }

        firstFocus.current = false;
    };

    const handleInputMouseDown = (event) => {
        if (inputValue === '' || !open) {
            handlePopupIndicator(event);
        }
    };

    let dirty = freeSolo && inputValue.length > 0;
    dirty = dirty || (multiple ? value.length > 0 : value !== null);

    let groupedOptions = filteredOptions;
    if (groupBy) {
        const indexBy = new Map();
        let warn = false;

        groupedOptions = filteredOptions.reduce((acc, option, index) => {
            const group = groupBy(option);

            if (acc.length > 0 && acc[acc.length - 1].group === group) {
                acc[acc.length - 1].options.push(option);
            } else {
                if (process.env.NODE_ENV !== 'production') {
                    if (indexBy.get(group) && !warn) {
                        console.warn(
                            `Material-UI: The options provided combined with the \`groupBy\` method of ${componentName} returns duplicated headers.`,
                            'You can solve the issue by sorting the options with the output of `groupBy`.',
                        );
                        warn = true;
                    }
                    indexBy.set(group, true);
                }

                acc.push({
                    key: index,
                    index,
                    group,
                    options: [option],
                });
            }

            return acc;
        }, []);
    }

    return {
        getRootProps: (other = {}) => ({
            'aria-owns': popupOpen ? `${id}-popup` : null,
            role: 'combobox',
            'aria-expanded': popupOpen,
            ...other,
            onKeyDown: handleKeyDown(other),
            onMouseDown: handleMouseDown,
            onClick: handleClick,
        }),
        getInputLabelProps: () => ({
            id: `${id}-label`,
            htmlFor: id,
        }),
        getInputProps: () => ({
            id,
            value: inputValue,
            onBlur: handleBlur,
            onFocus: handleFocus,
            onChange: handleInputChange,
            onMouseDown: handleInputMouseDown,
            'aria-activedescendant': popupOpen ? '' : null,
            'aria-autocomplete': autoComplete ? 'both' : 'list',
            'aria-controls': popupOpen ? `${id}-popup` : null,
            autoComplete: 'off',
            ref: inputRef,
            autoCapitalize: 'none',
            spellCheck: 'false',
        }),
        getClearProps: () => ({
            tabIndex: -1,
            onClick: handleClear,
        }),
        getPopupIndicatorProps: () => ({
            tabIndex: -1,
            onClick: handlePopupIndicator,
        }),
        getTagProps: ({ index }) => ({
            key: index,
            'data-tag-index': index,
            tabIndex: -1,
            onDelete: handleTagDelete(index),
        }),
        getListboxProps: () => ({
            role: 'listbox',
            id: `${id}-popup`,
            'aria-labelledby': `${id}-label`,
            ref: handleListboxRef,
            onMouseDown: (event) => {
                event.preventDefault();
            },
        }),
        getOptionProps: ({ index, option }) => {
            const selected = (multiple ? value : [value]).some(
                (value2) => value2 != null && getOptionSelected(option, value2),
            );
            const disabled = getOptionDisabled ? getOptionDisabled(option) : false;

            return {
                key: index,
                tabIndex: -1,
                role: 'option',
                id: `${id}-option-${index}`,
                onMouseOver: handleOptionMouseOver,
                onClick: handleOptionClick,
                onTouchStart: handleOptionTouchStart,
                'data-option-index': index,
                'aria-disabled': disabled,
                'aria-selected': selected,
            };
        },
        id,
        inputValue,
        value,
        dirty,
        popupOpen,
        focused: focused || focusedTag !== -1,
        anchorEl,
        setAnchorEl,
        focusedTag,
        groupedOptions,
    };
}