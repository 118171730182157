import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { useTranslation } from "react-i18next";
import { IFilter } from "../store/tableSelector/types";
import { useFilterOptions } from "./tableSelector/useFilterOptions";

const filterName = 'costsScenariosFilter';

export const useCostsFilterMap = () => {

    const { t } = useTranslation(['commons', 'pages']);
    const activeFilters = useSelector<ApplicationState, IFilter>(store => store[filterName].currentFilters);
    
    const namesFilters = useFilterOptions(filterName, 'names');
    const tagsFilters = useFilterOptions(filterName, 'tags');
    const indicatorsFilters = useFilterOptions(filterName, 'indicators');
    const createdAtFilters = useFilterOptions(filterName, 'created_at');
    const statusesFilters = useFilterOptions(filterName, 'status');
    
    const filterMap = new Map([
        [t('commons:name', 'Название'), {
            filterOptions: namesFilters,
            excluded: activeFilters.excluded_names,
            selected: activeFilters.selected_names,
            stateBranch: 'names',
            withExclusion: true
        }],
        [t('commons:tags', 'Атрибуты'), {
            filterOptions: tagsFilters,
            excluded: activeFilters.excluded_tags,
            selected: activeFilters.selected_tags,
            stateBranch: 'tags',
            withExclusion: true
        }],
        [t('commons:included_indicators', 'Содержит показатели'), {
            filterOptions: indicatorsFilters,
            excluded: activeFilters.excluded_indicators,
            selected: activeFilters.selected_indicators,
            stateBranch: 'indicators',
            withExclusion: true
        }],
        [t('commons:creation_date', 'Создано'), {
            filterOptions: createdAtFilters,
            excluded: activeFilters.excluded_created_at,
            selected: activeFilters.selected_created_at,
            stateBranch: 'created_at',
            withExclusion: true
        }],
        [t('commons:status', 'Статус'), {
            filterOptions: statusesFilters,
            excluded: activeFilters.excluded_status,
            selected: activeFilters.selected_status,
            stateBranch: 'status',
            withExclusion: true
        }]
    ]);

    return { filterMap }
};
