import {Reducer} from 'redux';
import {StimulationActionType, IStimulationAnalysisState} from './types';

export const initialState: IStimulationAnalysisState = {
    loading: false,
    all_fund: [],
    base_fund: [],
    new_fund: [],
    update_required: false,
    selected_fund: 'all_fund',
    last_group1: [],
    last_group2: [],
    error: null,
    errorDetails: null,
    portfolioAnalysis: [],
};

const reducer: Reducer<IStimulationAnalysisState> = (state = initialState, action) => {
    switch (action.type) {
        case StimulationActionType.ANALYSIS_LEGACY_REQUEST: {
            let tmp = {
                ...state,
                loading: true,
                error: null,
                errorDetails: null,
            };
            localStorage.setItem('stimulation_analysis', JSON.stringify(tmp));
            return tmp;
        }
        case StimulationActionType.ANALYSIS_SUCCESS: {
            let tmp = {
                ...state,
                loading: false,
                update_required: false,
                all_fund: action.payload.json_files['Общий фонд'].blocks,
                base_fund: action.payload.json_files['Базовый фонд'].blocks,
                new_fund: action.payload.json_files['Новый фонд'].blocks,
                last_group1: action.payload.first_group_version_ids,
                last_group2: action.payload.second_group_version_ids,
            };
            localStorage.setItem('stimulation_analysis', JSON.stringify(tmp));
            return tmp;
        }
        case StimulationActionType.ANALYSIS_ERROR: {
            let error = action.payload.error.response.data.error;
            let message = error ? error.message : 'An unknown error occured.';
            let tmp = {
                ...state,
                loading: false,
                error: message,
                errorDetails: error.details,
            };
            localStorage.setItem('stimulation_analysis', JSON.stringify(tmp));
            return tmp;
        }
        case StimulationActionType.SET_WELL_FUND: {
            let tmp = {
                ...state,
                selected_fund: action.payload.fund,
            };
            localStorage.setItem('stimulation_analysis', JSON.stringify(tmp));
            return tmp;
        }
        case StimulationActionType.SET_UPDATE_REQUIRED: {
            let tmp = {
                ...state,
                update_required: action.payload.required,
            };
            localStorage.setItem('stimulation_analysis', JSON.stringify(tmp));
            return tmp;
        }
        case StimulationActionType.LOAD_STATE: {
            return {
                ...action.payload.state,
            };
        }
        default: {
            return state;
        }
    }
};

export {reducer as stimulationAnalysisReducer};