import {Reducer} from 'redux'
import {ICurrentPlotState, PlotActionType} from './types'

import {encode} from 'base64-arraybuffer'

export const initialState: ICurrentPlotState = {
    data: {
        plots: [],
        raster_data: null,
        is_scalar: false,
        agg_method: {
            name: null,
            tooltip: null
        },
        groupby_available: []
    },
    errors: null,
    loading: false,
    settings: {
        groupby: [],
    }
};

const reducer: Reducer<ICurrentPlotState> = (state = initialState, action) => {
    const decoder = new TextDecoder('utf8')
    switch (action.type) {
        case PlotActionType.CHANGE_SETTINGS: {
            return {...state, settings: {...state.settings, ...action.payload}}
        }
        case PlotActionType.PLOT_REQUEST: {
            return {...state, loading: true}
        }
        case PlotActionType.PLOT_SUCCESS: {
            let buffer = action.payload.data

            if (action.payload.content_type === "image/png") {
                // получили картинку
                // делаем блоб base 65 для показа

                //let blob = new Blob([buffer], {type: "image/png"})
                //let raster_data = URL.createObjectURL(blob) // требует очистки памяти, так что отложим

                let b64 = encode(buffer)

                let raster_data = "data:image/png;base64," + b64

                let res_state = {
                    ...state, loading: false,
                    data: {
                        plots: [],
                        raster_data: raster_data,
                        agg_method: {
                            name: null,
                            tooltip: null
                        },
                        groupby_available: []
                    }, 
                    errors: null
                }
                return res_state

            } else {
                //получили графики. Нужно разложить payload в json, т.к. у axios выставлено responseType: 'arraybuffer'
                let payload = JSON.parse(decoder.decode(buffer))
                let res_state = {...state, loading: false, ...({data: payload}), errors: null}
                return res_state
            }
        }
        case PlotActionType.PLOT_ERROR: {
            return {...state, loading: false, errors: action.payload}
        }
        default: {
            return state
        }
    }
};

export {reducer as currentPlotReducer}
