import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import differenceBy from 'lodash/differenceBy';
import {ApplicationState} from '../../../../store';
import {createAttributeRequest} from '../../../../store/attributes/actions';
import AttributesSummary from './AttributesSummary';
import {BlurredBox} from '../../../BlurredBox';
import {
    updateVersionAttrOrGroupsRequest,
    getVersionAttrOrGroupsRequest,
} from '../../../../store/versionAttributes/actions';
import {IVersionAttributes} from '../../../../store/versionAttributes/types';
import {Button} from '../../../Design/Button';
import AttributeCreate from './AttributeCreate';
import styled from 'styled-components';
import {PopupItem, Text} from '../../../Popup/popup.styles';
import {BottomContainer} from '../../../Sidebar/sidebar.styles';
import {IAttribute} from '../../../../store/attributes/types';
import AttributesLegend from "./AttributesLegend";
import {IVersionGroup} from '../../../../store/versionGroup/types';
import {createVersionGroupRequest} from '../../../../store/versionGroup/actions';

type AttributesComponentProps = {
    initialParameters?: any;
    editMode?: any;
    selection?: any[];
    protectTags?: any[];
    setProtectTags?: Function;
    newTags?: any[];
    setNewTags?: Function;
    tagsToUpdate?: any[];
    setTagsToUpdate?: Function;
    isConfigureDialog?: boolean;
    handleClosePopup?: Function;
    setSimulationState?: Function;
    isScenarioMode?: boolean;
    contextName?: string;
    filterName?: string;
}

export const AttributesComponent: React.FC<AttributesComponentProps> = ({
                                                                            initialParameters = [],
                                                                            editMode = true,
                                                                            selection,
                                                                            isConfigureDialog = false,
                                                                            handleClosePopup,
                                                                            setSimulationState,
                                                                            isScenarioMode = false,
                                                                            contextName = 'versionAttributes',
                                                                            filterName = null
                                                                        }) => {
    const {t} = useTranslation(['components', 'commons', 'pages']);
    const dispatch = useDispatch();

    const attributesGrouped = useSelector<ApplicationState, IVersionAttributes>(
        store => store[contextName].values,
    );

    const attributesSelected = [...attributesGrouped.every_version, ...attributesGrouped.some_versions]

    const attributesAll = useSelector<ApplicationState, IAttribute[]>(
        store => store.attributes.attributes,
    );

    const versionGroupsAll = useSelector<ApplicationState, IVersionGroup[]>(
        store => store.versionGroupList.versionGroups,
    );

    const valuesAll = {
        versionAttributes: attributesAll,
        versionGroups: versionGroupsAll
    }[contextName];

    const ungroupAttributes = (attributes) => {
        const ungroupedAttributes = [];

        for (const [group, list] of Object.entries(attributes)) {
            //@ts-ignore
            const formattedAttrsGroup = list.map(attr => ({...attr, group: group, isNew: false}));
            ungroupedAttributes.push([...formattedAttrsGroup]);
        }

        return ungroupedAttributes.flat();
    };

    const [valuesToAdd, setValuesToAdd] = useState<any[]>([]);
    const [valuesToProtect, setValuesToProtect] = useState<any[]>([]);
    const [valuesToUpdate, setValuesToUpdate] = useState<any[]>([]);


    const submitChanges = () => {
        const preparedTagsData = {
            version_ids: selection,
            to_add: valuesToAdd,
            to_protect: valuesToProtect,
        };
        
        // Костыль для добавления созданного значения. Иначе оно слетает.
        if (configuringAttrs.length > (valuesToAdd.length + valuesToProtect.length)) {
            const toAddMissed = configuringAttrs.filter((value) => {
                return value.group === 'every_version' && !valuesToAdd.includes(value.id)
            }).map(value => value.id);
            const toProtectMissed = configuringAttrs.filter((value) => {
                return value.group === 'some_versions' && !valuesToProtect.includes(value.id)
            }).map(value => value.id);
            preparedTagsData.to_add = [...preparedTagsData.to_add, ...toAddMissed];
            preparedTagsData.to_protect = [...preparedTagsData.to_protect, ...toProtectMissed];
        }

        dispatch(updateVersionAttrOrGroupsRequest(preparedTagsData, contextName));
        setValuesToUpdate([]);

        if (isConfigureDialog) handleClosePopup();
    };

    const [attrsList, setAttrsList] = useState([]);
    const [configuringAttrs, setConfiguringAttrs] = useState([]);

    const [isCreateInputVisible, setCreateInputVisible] = useState(false);
    const [attributeName, setAttributeName] = useState('');
    const [isAttributeCreated, setIsAttributeCreated] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        // @ts-ignore
        const results = differenceBy(valuesAll, configuringAttrs, 'id').filter(attr => attr.name.toLowerCase().includes(value.toLowerCase()))
        setSearchValue(value);
        setAttrsList(results)
    }

    const updateLeftColumnTags = (value) => {
        const nextProtectTags = [...valuesToProtect];
        const updatedProtectTagIndex = nextProtectTags.indexOf(value.id);
        nextProtectTags.splice(updatedProtectTagIndex, 1);
        setValuesToProtect(nextProtectTags);
    }

    const handleClickDeleteItem = (e, index, value) => {
        e.preventDefault();
        const nextConfiguringAttrsList = [...configuringAttrs];
        nextConfiguringAttrsList.splice(index, 1);
        setConfiguringAttrs(nextConfiguringAttrsList);
        if (searchValue.length) {
            if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
                setAttrsList([...attrsList, {...value, group: 'all'}]);
            }
            updateLeftColumnTags(value)
            return;
        }

        if (value.group === 'some_versions') {
            updateLeftColumnTags(value)
        } else if (value.group === 'every_version') {
            const nextNewTags = [...valuesToAdd];
            nextNewTags.splice(index, 1);
            setValuesToAdd(nextNewTags);
        }

        setAttrsList([...attrsList, {...value, group: 'all'}]);
    };

    const onAddItem = (attributeName) => {
        const selected = attrsList.find(s => s.name === attributeName);

        if (selected != null && !initialParameters.includes(selected) && editMode) {
            const selectedIndex = attrsList.indexOf(selected);
            const groupName = !isScenarioMode ? 'every_version' : ''
            const selectedWithIndex = {...selected, index: selectedIndex, group: groupName, isNew: true};

            setValuesToAdd([...valuesToAdd, selectedWithIndex.id]);
            setConfiguringAttrs([...configuringAttrs, selectedWithIndex]);

            const nextAttrList = [...attrsList];
            nextAttrList.splice(selectedIndex, 1);
            setAttrsList(nextAttrList);
        }
    };

    const handleClickCreateItem = (name) => {
        if (contextName === 'versionAttributes') {
            // Если имя атрибута не в формате "Тип: название" задать тип атрибута автоматически
            const splittedName = name.split(':');
            const tagType = filterName === 'taxModesFilter' ? (
                t('commons:tagTypes:tax_regime', 'Налоговый режим')
            ) : t('commons:tagTypes:tech_tag', 'Тех. тег'); 

            if (splittedName.length < 2) {
                name = tagType + ': ' + name;
            }

            dispatch(createAttributeRequest(name, selection));
        } else if (contextName === 'versionGroups') {
            dispatch(createVersionGroupRequest(name));
        }
        
        setIsAttributeCreated(true);
        setCreateInputVisible(false);
    };

    const handleKeyDown = (event, name) => {
        if (event.key === 'Enter') {
            handleClickCreateItem(name);
        }
    };

    const isTagUpdated = (tag) => valuesToUpdate.includes(tag);

    const handleValueChange = (e, value) => {
        const valueId = value.id;

        if (e.target.checked) {
            const tempValues = [...valuesToProtect];
            const updatedTagIndex = valuesToProtect.indexOf(valueId);
            setValuesToUpdate([...valuesToUpdate, valueId]);
            setValuesToAdd([...valuesToAdd, valueId]);
            tempValues.splice(updatedTagIndex, 1);
            setValuesToProtect(tempValues);
        } else {
            const tempTagsToUpdate = [...valuesToUpdate];
            const updatedTagIndex = tempTagsToUpdate.indexOf(valueId);
            const tempNewTags = [...valuesToAdd];
            const tempNewTagsIndex = valuesToAdd.indexOf(valueId);
            tempNewTags.splice(tempNewTagsIndex, 1);
            tempTagsToUpdate.splice(updatedTagIndex, 1);
            setValuesToAdd(tempNewTags);
            setValuesToUpdate(tempTagsToUpdate);
            setValuesToProtect([...valuesToProtect, valueId]);
        }
    };

    useEffect(() => {
        if (!isScenarioMode) {
            const ungroupedAttributes = ungroupAttributes(attributesGrouped);
            const configurableAttributes = ungroupedAttributes.filter(attr => attr.group === 'every_version' || attr.group === 'some_versions');
            const someTagsIds = ungroupedAttributes.filter(attr => attr.group === 'some_versions').map(attr => attr.id);
            const everyTagsIds = ungroupedAttributes.filter(attr => attr.group === 'every_version').map(attr => attr.id);

            setAttrsList(differenceBy(valuesAll, configurableAttributes, 'id'))
            setConfiguringAttrs(configurableAttributes);
            setValuesToProtect(someTagsIds);
            setValuesToAdd(everyTagsIds);
        } else {
            setAttrsList(valuesAll);
            setConfiguringAttrs([]);
            setValuesToProtect([]);
            setValuesToAdd([]);
        }
        //eslint-disable-next-line
    }, [attributesGrouped, valuesAll]);

    useEffect(() => {
        if (isScenarioMode && initialParameters.length) {
            const initialParamsIds = initialParameters.map(param => param.id)
            const attributesLeft = valuesAll.filter(attr => !initialParamsIds.includes(attr.id))
            setConfiguringAttrs(initialParameters)
            setAttrsList(attributesLeft)
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isAttributeCreated) {
            const groupName = !isScenarioMode ? 'every_version' : ''
            const newAttrsListToUpdate = [...attrsList];
            const lastAddedAttribute = {
                ...valuesAll[valuesAll.length - 1],
                group: groupName,
                isNew: true,
            };

            setAttrsList(newAttrsListToUpdate);
            setIsAttributeCreated(false);
            setConfiguringAttrs([...configuringAttrs, lastAddedAttribute]);
        }
        //eslint-disable-next-line
    }, [valuesAll, attrsList]);

    useEffect(() => {
        const attributesLength = attributesSelected.length;
        if (attributesLength && attributeName.length > 0) {
            onAddItem(attributesSelected[attributesLength - 1].name);
            setAttributeName('');
        }
        //eslint-disable-next-line
    }, [attributesSelected]);

    useEffect(() => {
        setSimulationState && setSimulationState((s) => ({
                ...s,
                attributes: attributesSelected
            }
        ))
        //eslint-disable-next-line
    }, [attributesGrouped])

    useEffect(() => {
        if (isScenarioMode && setSimulationState) {
            setSimulationState((s) => ({
                    ...s,
                    attributes: configuringAttrs
                }
            ))
        }
        //eslint-disable-next-line
    }, [configuringAttrs])

    useEffect(() => {
        dispatch(getVersionAttrOrGroupsRequest(selection, contextName));
    }, [dispatch, selection, contextName]);

    const leftColumnTitle = t(`pages:simulationSettings.${contextName}.attribute_list`);
    const rightColumnTitle = t(`pages:simulationSettings.${contextName}.configuration`);
    const searchPlaceholder = t(`pages:simulationSettings.${contextName}.search`);

    return (
        <>
            <ContentWrapper>

                <LeftColumn>
                    <ColumnHeader>
                        {leftColumnTitle}
                        {editMode &&
                            <SearchInput 
                                type="text" 
                                placeholder={searchPlaceholder} 
                                value={searchValue} 
                                onChange={handleSearchInputChange}
                            />
                        }
                    </ColumnHeader>
                    
                    <ColumnContentLeft>
                        <BlurredBox blur={editMode ? 0 : 1}>
                            <PopupItemFlex>
                                {attrsList.map((attr, index) => (
                                    <ListItem key={index} onClick={() => onAddItem(attr.name)}
                                                hidden={attr.hidden} groupName={attr.group}>
                                        {attr.name}
                                    </ListItem>
                                ))}
                            </PopupItemFlex>
                        </BlurredBox>
                    </ColumnContentLeft>

                </LeftColumn>
                
                <RightColumn>
                    <ColumnHeader>
                        {rightColumnTitle}
                    </ColumnHeader>
                    
                    <ColumnContentRight>

                        <AttributesSummaryWrapper>
                            <AttributesSummary
                                initialParameters={configuringAttrs}
                                handleDelete={handleClickDeleteItem}
                                editMode={editMode}
                                handleValueChange={handleValueChange}
                                isTagUpdated={isTagUpdated}
                                isScenarioMode={isScenarioMode}
                            />
                        </AttributesSummaryWrapper>
                        <AttributeCreateWrapper>
                            {editMode && (
                                <AttributeCreate 
                                    isCreateInputVisible={isCreateInputVisible}
                                    handleKeyDown={handleKeyDown}
                                    handleClickCreateItem={handleClickCreateItem}
                                    setCreateInputVisible={setCreateInputVisible}
                                    contextName={contextName}
                                />
                            )}
                        </AttributeCreateWrapper>
                        
                    </ColumnContentRight>

                </RightColumn>
                
            </ContentWrapper>

            {(editMode && !isScenarioMode) && <AttributesLegend contextName={contextName}/>}
                    {(valuesToUpdate && !isConfigureDialog && editMode && !isScenarioMode) && (
                        <ButtonApply
                            onClick={submitChanges}
                            label={t('components:attributesComponent.ok', 'OK')}
                        />
                    )}

            {isConfigureDialog && (
                <BottomContainer>
                    <BottomMenuWrapper>
                        <Text>
                            {t('pages:simulationSettings.selected_versions', 'Выбранные версии') +
                            ': ' +
                            selection.length}
                        </Text>

                        <Button
                            onClick={submitChanges}
                            label={t('components:attributesComponent.ok', 'OK')}
                        />
                    </BottomMenuWrapper>
                </BottomContainer>
            )}
        </>
    );
};

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const LeftColumn = styled.div`
    width: 100%;
    margin-right: 5px;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 5px;
`;

const ColumnContentLeft = styled.div`
    height: calc(100% - 42px);
    overflow: auto;
`;

const ColumnContentRight = styled.div`
    height: calc(100% - 42px);
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const AttributesSummaryWrapper = styled.div`
    overflow: auto;
    height: 100%;
`;

const AttributeCreateWrapper = styled.div`
    height: 50px;
`;

const ButtonApply = styled(Button)`
  margin-left: auto;
`;

const BottomMenuWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 28px;
  border-top: 1px solid #747480;
`;

const SearchInput = styled.input`
  border-radius: 0;
  border: none;
  height: 28px;
  margin-left: auto;
  padding-left: 10px;
  font-size: 14px;
  margin-right: 3px;

  &:focus {
    outline: none;
    border: none;
  }
`

const ColumnHeader = styled.div`
  background: ${(props) => props.theme.colors.black};
  font-size: 16px;
  line-height: 20px;
  height: 38px;
  color: #fff;
  padding: 4px 4px 4px 16px;
  box-sizing: border-box;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

export const ListItem = styled.div`
  background-color: ${props => props.theme.colors.grey4};
  padding: 11px 16px;
  color: #2e2e38;
  width: calc(50% - 2px);
  overflow-x: hidden;
  margin-bottom: 4px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  user-select: none;
  font-size: 14px;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey3};
  }
`;

export const PopupItemFlex = styled(PopupItem)`
  overflow:
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
`;