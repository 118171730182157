import {Reducer} from 'redux';
import {CalcVersionErrorsActionType, ICalcVersionErrorsState} from './types';
import {addOnTop} from "../utils";

export const initialState: ICalcVersionErrorsState = {
    data: new Map(),
    errors: null,
    loaded: false,
    activeID: null
};


const reducer: Reducer<ICalcVersionErrorsState> = (state = initialState, action) => {
    switch (action.type) {
        case CalcVersionErrorsActionType.GET_VERSION_ERRORS_REQUEST: {
            return {...state, loaded: false, activeID: action.payload};
        }
        case CalcVersionErrorsActionType.GET_VERSION_ERRORS_SUCCESS: {
            return {...state, loaded: true, data: addOnTop(state, action.payload.results)}; // addOnTop(state, action.payload)
        }
        case CalcVersionErrorsActionType.GET_VERSION_ERRORS_ERROR: {
            return {...state, loaded: true, errors: action.payload};
        }
        default: {
            return state;
        }
    }
};

export {reducer as calcVersionErrorsReducer};
