import {Reducer} from 'redux';
import {CalcJobErrorsActionType, ICalcJobErrorsState} from './types';


export const initialState: ICalcJobErrorsState = {
    data: new Map(),
    errors: null,
    loaded: false,
    activeID: null
};

function addOnTop(prevData, newData) {
    let newMap = new Map().set(prevData.activeID, newData);
    return new Map([...prevData.data, ...newMap]);
}

const reducer: Reducer<ICalcJobErrorsState> = (state = initialState, action) => {
    switch (action.type) {
        case CalcJobErrorsActionType.GET_JOB_ERRORS_REQUEST: {
            return {...state, loaded: false, activeID: action.payload};
        }
        case CalcJobErrorsActionType.GET_JOB_ERRORS_SUCCESS: {
            return {...state, loaded: true, data: addOnTop(state, action.payload.results)}; // addOnTop(state, action.payload)
        }
        case CalcJobErrorsActionType.GET_JOB_ERRORS_ERROR: {
            return {...state, loaded: true, errors: action.payload};
        }
        default: {
            return state;
        }
    }
};

export {reducer as calcJobErrorsReducer};
