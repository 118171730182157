import { Reducer } from 'redux';
import { CostsFilterActionType, ICostsFilterState } from './types'

export const initialCostsFilterState: ICostsFilterState = {
    loading: false,
    regions: [],
    prodSiteTypes: [],
    selectedRegions: [],
    selectedProdSiteTypes: [],
    groupby: [],
    outlierCutoff: 1,
    settings: {
        count: null,
        show_zero: true,
        page: 1,
        page_size: 10
    }
}

export const costsFilterReducer: Reducer<ICostsFilterState> = (state = initialCostsFilterState, action) => {
    
    switch (action.type) {
        case CostsFilterActionType.GET_OPTIONS_REQUEST: {
            let tmp = {
                ...state,
                loading: true,
            }
            return tmp
        }
        case CostsFilterActionType.GET_OPTIONS_SUCCESS: {
            let tmp = {
                ...state,
                loading: false,
                regions: action.payload.regions,
                prodSiteTypes: action.payload.prod_site_types
            }
            return tmp
        }
        case CostsFilterActionType.GET_OPTIONS_ERROR: {
            let tmp = {
                ...state,
                loading: false,
                regions: action.payload.regions,
                prodSiteTypes: action.payload.prod_site_types
            }
            return tmp
        }
        case CostsFilterActionType.SET_SELECTED_OPTIONS: {
            let tmp = {
                ...state,
                [`selected${action.payload.filter}`]: action.payload.options
            }
            return tmp
        }
        case CostsFilterActionType.SET_COSTS_GROUPBY: {
            let tmp = {
                ...state,
                groupby: action.payload.groupby
            }
            return tmp
        }
        case CostsFilterActionType.CHANGE_SETTINGS: {
            let tmp = {
                ...state, 
                settings: {
                    ...state.settings,
                    ...action.payload
                }
            }
            return tmp
        }
        case CostsFilterActionType.SET_COSTS_OUTLIER_CUTOFF: {
            let tmp = {
                ...state,
                outlierCutoff: action.payload.cutoff
            }
            return tmp
        }
        default: {
            return state
        }
    }
}