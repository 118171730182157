import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {connect, useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {getTasksStateFromLocalStorage, hideBadge} from '../../store/tasks/actions';
import {IUserLanguageState} from '../../store/language/types';
import Sidebar from '../../components/Sidebar/Sidebar';
import {ContentWrapper} from './root-layout.styles';
import TaskQueue from './TaskQueue';
import {FilterStoreItemType} from '../../pages/calculation-page/GridView';
import {getDataFromLocalStorage, setDataToLocalStorage} from '../../helpers/localStorage';
import {userLanguageSelector} from '../../store/language/selectors';
import {RootLoader} from '../../pages/version-page/RootLoader';
import {getAttributesListRequest} from '../../store/attributes/actions';
import {getVersionGroupsListRequest} from '../../store/versionGroup/actions';

export interface IRootLayoutOwnProps {
    children?: any;
}

interface PropsFromState {
    token: string;
    user: any;
}

interface IDialogContextProps {
    isDialogOpen: boolean;
    setIsDialogOpen: Function;
}

type IRootLayoutProps = IRootLayoutOwnProps & PropsFromState;

export const DialogContext = React.createContext({} as IDialogContextProps);

const RootLayout: React.FC<IRootLayoutProps> = ({children}) => {
    const dispatch = useDispatch();

    const language = useSelector<ApplicationState, string>((store) => store.userLanguage.language);

    useEffect(() => {
        dispatch(getAttributesListRequest());
        dispatch(getVersionGroupsListRequest());
        dispatch(getTasksStateFromLocalStorage())
        //eslint-disable-next-line
    }, [language]);

    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const KEY_LOCAL_STORAGE = 'sidebar';
    const localStorageFilterData = (): FilterStoreItemType => getDataFromLocalStorage(KEY_LOCAL_STORAGE);
    const setToLocalStorage = (isShow: boolean): void => setDataToLocalStorage(KEY_LOCAL_STORAGE, isShow);

    // @ts-ignore
    const [isBigSidebar, setIsBigSidebar] = useState<boolean | undefined>(localStorageFilterData() ?? true);
    const [open, setOpen] = React.useState(false);

    const currentLanguage = useSelector<ApplicationState, IUserLanguageState | null>(userLanguageSelector);

    const handleDrawerClose = () => {
        setOpen(false);
        dispatch(hideBadge());
    };

    return (
        <>
            <RootLoader />
            {!currentLanguage.loading && (
                <>
                    <Sidebar
                        setOpen={setOpen}
                        open={open}
                        isBigSidebar={isBigSidebar}
                        setIsBigSidebar={setIsBigSidebar}
                        setToLocalStorage={setToLocalStorage}
                    />

                    <DialogContext.Provider value={{isDialogOpen, setIsDialogOpen}}>
                        {open && <TaskQueue open={open} handleDrawerClose={handleDrawerClose} isBigSidebar={isBigSidebar} />}
                        <ContentWrapper isBigSidebar={isBigSidebar}>{children}</ContentWrapper>
                    </DialogContext.Provider>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({login}: ApplicationState) => ({
    token: login.token,
    user: login.user,
});

const RootLayoutWithStore = connect(mapStateToProps)(RootLayout);

export default withRouter(({children}) => <RootLayoutWithStore children={children} />);
