import React, {useState} from 'react';
import {
    Header,
    SearchInput,
    SecondBlock,
    TopBlock,
    ControlWrapper,
    PanelWrapper
} from './version-page.styles';
import {FlexContainer} from '../../theme/main.styles';
import {useTranslation} from 'react-i18next';
import {TableControl} from './TableControl';
import {Checkbox} from '../../components/Design/Checkboxes/Checkbox';
import {Button} from '../../components/Design/Button';
import {ImportFilePopup} from '../../components/ImportFilePopup/ImportFilePopup';
import {useHistory} from 'react-router';
import {setColumnFilter} from '../../store/tableSelector/actions';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';

import {getConfig} from '../../config-provider';
import {setDataToLocalStorage} from '../../helpers/localStorage';
import axios from '../../axiosMiddleware';
import ColumnSelector from '../../components/ColumnSelector';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

const localeKeys = {
    taxModesFilter: 'tax_mode',
    emissionScenariosFilter: 'esg',
    costsScenariosFilter: 'cost',
    filesFilter: 'files'
}

const tableTitles = {
    vFilter: {path: 'pages:Versions', default_value: 'Версии'},
    taxModesFilter: {path: 'pages:TaxModes', default_value: 'Налоговые режимы'},
    macroScenariosFilter: {path: 'pages:MacroScenariosPage', default_value: 'Макро сценарии'},
    emissionScenariosFilter: {path: 'pages:Emissions', default_value: 'Углеродные сценарии'},
    costsScenariosFilter: {path: 'pages:CostsScenarios', default_value: 'Сценарии изменения затрат'},
    filesFilter: {path: 'pages:Files', default_value: 'Файлы'}
};

type HeaderControlProps = {
    setFormDialogState?: any;
    setDeleteDialogState?: any;
    deleteDialogState?: any;
    setCreateDialogState?: any;
    createDialogState?: any;
    setScenarioDialogState?: any;
    scenarioDialogState?: any;
    checkedRowIds?: any;
    handleClearFilters?: any;
    handleCheckAll?: any;
    handleUncheckAll?: any;
    filterName?: any;
};

export const HeaderControl: React.FC<HeaderControlProps> = React.memo(
    ({
        setFormDialogState,
        setDeleteDialogState,
        deleteDialogState,
        setCreateDialogState = null,
        createDialogState = null,
        setScenarioDialogState,
        scenarioDialogState,
        checkedRowIds,
        handleClearFilters,
        handleCheckAll,
        handleUncheckAll,
        filterName,
    }) => {
        const {t} = useTranslation(['commons', 'pages']);
        const history = useHistory();
        const dispatch = useDispatch();

        const isMine = Boolean(useSelector<ApplicationState, boolean>(store => store[filterName].currentFilters.is_mine));
        const isDonor = Boolean(useSelector<ApplicationState, boolean>(store => store[filterName].currentFilters.is_donor));
        const showDeleted = Boolean(useSelector<ApplicationState, boolean>(store => store[filterName].currentFilters.show_deleted));
        const isPickerMode = Boolean(useSelector<ApplicationState, boolean>(store => store[filterName].isPickerMode));

        const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

        const localeKey = localeKeys[filterName];

        const handleImportPopup = () => {
            history.push('/import');
            // setIsShowPopup(true);
        };

        const handleCheckboxChange = (newValue) => {
            dispatch(setColumnFilter(newValue, [], filterName));
        };

        const handleIsMineChange = () => {
            handleCheckboxChange({is_mine: !isMine});
        };

        const handleIsDonorChange = () => {
            handleCheckboxChange({is_donor: !isDonor});
        };

        const handleShowDeletedChange = () => {
            handleCheckboxChange({show_deleted: !showDeleted});
        };

        // Методы диалога налоговых режимов и углеродных сценариев
        const handleOpenScenarioDialog = async () => {
            const urlTree = `${API_ENDPOINT}/scenario/${localeKey}/empty`;
            const urlEditions = `${API_ENDPOINT}/editions`;

            const res = await Promise.all([
                await axios.post(urlTree),
                await axios.post(urlEditions),
            ]);

            setDataToLocalStorage(`${localeKey}_InitTree`, res[0].data.tree);
            setDataToLocalStorage(`${localeKey}_Editions`, res[1].data.editions);

            setScenarioDialogState({
                ...scenarioDialogState,
                scenarioId: null,
                editMode: true,
                update: false,
                open: true,
            });
        };

        // Методы диалога макро сценариев
        const handleOpenCreateDialog = async () => {
            setCreateDialogState({
                ...createDialogState,
                open: true,
            });
        };

        const importOrCreateButton = (filterName) => {
            const settingButton = (
                <ColumnSelector filterName={filterName}/>
            )

            if (filterName === 'vFilter') {
                return (
                    <>
                        <Button
                            onClick={handleImportPopup}
                            label={t('components:headers.import_file', 'Импортировать файл')}
                        />
                        {settingButton}
                    </>
                );
            } else if (filterName === 'macroScenariosFilter') {
                return (
                    <>
                        <Button
                            onClick={handleOpenCreateDialog}
                            label={t('components:headers.import_file', 'Импортировать файл')}
                        />
                        {settingButton}
                    </>
                );
            } else if (filterName === 'taxModesFilter') {
                return (
                    <>
                        <Button
                            onClick={handleOpenScenarioDialog}
                            label={t('components:headers.create_new', 'Создать новый')}
                        />
                        {settingButton}
                    </>
                );
            } else if (filterName === 'emissionScenariosFilter') {
                return (
                    <>
                        <Button
                            onClick={handleOpenScenarioDialog}
                            label={t('components:headers.create_new', 'Создать новый')}
                        />
                        {settingButton}
                    </>
                );
            } else if (filterName === 'costsScenariosFilter') {
                return (
                    <>
                        <Button
                            onClick={handleOpenScenarioDialog}
                            label={t('components:headers.create_new', 'Создать новый')}
                        />
                        {settingButton}
                    </>
                );
            } else if (filterName === 'filesFilter') {
                return (
                    <>
                        <Button
                            onClick={() => setIsShowPopup(true)}
                            label={t('components:headers.import_file', 'Импортировать файл')}
                        />
                        {settingButton}
                    </>
                );
            }
        };

        return (
            <ControlWrapper>
                {isShowPopup && <ImportFilePopup setHidePopup={() => setIsShowPopup(false)} />}
                <TopBlock>
                    <Header>
                        {t(tableTitles[filterName].path, tableTitles[filterName].default_value)}
                    </Header>
                    <FlexContainer>
                        <SearchInput filterName={filterName} />
                    </FlexContainer>
                </TopBlock>
                <SecondBlock>
                    <FlexContainer>
                        {importOrCreateButton(filterName)}
                        {checkedRowIds.length > 0 && (
                            <TableControl
                                setFormDialogState={setFormDialogState}
                                setDeleteDialogState={setDeleteDialogState}
                                deleteDialogState={deleteDialogState}
                                checkedRowIds={checkedRowIds}
                                filterName={filterName}
                            />
                        )}
                    </FlexContainer>
                    <PanelWrapper>
                        {isPickerMode && <>
                            <Button
                                outline={true}
                                onClick={handleCheckAll}
                                label={t('components:filter.check_all', 'Выбрать все')}
                            />
                            <Button
                                outline={true}
                                onClick={handleUncheckAll}
                                label={t('components:filter.reset_checked', 'Сбросить выбор')}
                            />
                        </>}
                        <Button
                            outline={true}
                            onClick={handleClearFilters}
                            label={t('components:tooltip.clear_filter', 'Очистить фильтр')}
                        />

                        {/* Отображать чекбоксы "is_donor" и "is_mine" только для фильтров версий */}
                        {filterName === 'vFilter' ? (
                            <Checkbox
                                onChange={handleIsDonorChange}
                                checked={isDonor}
                                label={t(
                                    'components:filter.show_not_read',
                                    'Показать нерассчитанные'
                                )}
                            />
                        ) : null}
                        {filterName === 'vFilter' ? (
                            <Checkbox
                                onChange={handleIsMineChange}
                                checked={isMine}
                                label={t('components:filter.show_my', 'Показать мои')}
                            />
                        ) : null}

                        {/* Отображать чекбокс "show_deleted" */}
                        {filterName === 'macroScenariosFilter' ? (
                            <Checkbox
                                onChange={handleShowDeletedChange}
                                checked={showDeleted}
                                label={t(
                                    'components:filter.show_deleted',
                                    'Показать удаленные'
                                )}
                            />
                        ) : null}
                    </PanelWrapper>
                </SecondBlock>
            </ControlWrapper>
        );
    }
);
