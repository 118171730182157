import React from 'react';
import {TableWrapper} from '../pages/version-page/version-page.styles';
import MaterialTable, {MTableAction} from 'material-table';
import {
    versionTableLocalization,
    taxModesTableLocalization,
    portfolioAnalysisTableLocalization,
    macroScenariosLocalization,
    emissionScenariosLocalization,
    tableEmptyMessageEn,
    costsScenariosLocalization,
    filesLocalization,
} from '../helpers/MyAwesomeLib';
import {tableIcons} from './MTableComponents/TableIcons';
import TableHeaderWrapper from './MTableComponents/TableHeaderWrapper';
import {useTranslation} from 'react-i18next';
import {getFilterOptionsRequest} from '../store/tableSelector/actions';
import {useDispatch, useSelector} from 'react-redux';
import {
    versionsRowsSelector,
    taxModesRowsSelector,
    macroScenarioRowsSelector,
    emissionScenarioRowsSelector,
    portfolioAnalysisRowsSelector,
    costsScenarioRowsSelector,
    filesRowsSelector,
} from '../store/tableSelector/selectors';
import TableRow from './TableRow';
import TableCell from './TableCell';
import {ApplicationState} from '../store';
import {createRouteKey} from './Autocomplete/RowsPerTableCell';
import {useHistory} from 'react-router-dom';

import styled from 'styled-components';
import TableContainer from './TableContainer';

const rowSelectors = {
    vFilter: versionsRowsSelector,
    taxModesFilter: taxModesRowsSelector,
    macroScenariosFilter: macroScenarioRowsSelector,
    emissionScenariosFilter: emissionScenarioRowsSelector,
    costsScenariosFilter: costsScenarioRowsSelector,
    portfolioAnalysisFilter: portfolioAnalysisRowsSelector,
    filesFilter: filesRowsSelector
};

const tableLocalizations = {
    vFilter: versionTableLocalization,
    taxModesFilter: taxModesTableLocalization,
    macroScenariosFilter: macroScenariosLocalization,
    emissionScenariosFilter: emissionScenariosLocalization,
    costsScenariosFilter: costsScenariosLocalization,
    portfolioAnalysisFilter: portfolioAnalysisTableLocalization,
    filesFilter: filesLocalization
};

export const Table: React.FC<any> = React.memo(
    ({
        asTree,
        columns,
        onRowSelected,
        onRowUpdate,
        handleFilterChange,
        filterName,
        onRowClick,
        pageName
    }) => {
        const history = useHistory();

        const {i18n} = useTranslation(['commons', 'pages']);
        const dispatch = useDispatch();

        const rows = useSelector<ApplicationState, any>(rowSelectors[filterName]);
        const isPickerMode = useSelector<ApplicationState, boolean>(store => store[filterName].isPickerMode);
        const loading = useSelector<ApplicationState, boolean>(store => store[filterName].loading);
        const rowsPerTableCell = useSelector<ApplicationState, any>(store => store.rowsPerTableCell[createRouteKey(history)]);

        const parentChildData = () =>
            asTree ? (row, rows) => rows.find((a) => a.id === row.parent_version) : undefined;

        const handleLoadFiltersByName = (columnName, filterName) => {
            dispatch(getFilterOptionsRequest(columnName, filterName));
        };

        // Высота строки расчитывается изходя из настройки количества строк в ячейке
        const rowHeight = rowsPerTableCell?.checked ? `${3 * rowsPerTableCell.value}ex` : null;

        return (
            <TableWrapper
                rowCount={rowsPerTableCell?.checked ? rowsPerTableCell.value : 999}
                isLoading={loading}
            >
                <MaterialTable
                    isLoading={loading}
                    parentChildData={parentChildData()}
                    columns={columns}
                    data={rows}
                    onSelectionChange={onRowSelected}
                    options={{
                        paging: false,
                        draggable: false,
                        showTitle: false,
                        sorting: false,
                        toolbar: false,
                        selection: !isPickerMode,
                        search: false,
                        filtering: false,
                        grouping: false,
                        actionsColumnIndex: 1,
                        rowStyle: {
                            fontSize: 12,
                            height: rowHeight
                        },
                        loadingType: 'overlay'
                    }}
                    onRowClick={onRowClick}
                    localization={i18n.language === 'ru' ? tableLocalizations[filterName] : tableEmptyMessageEn }
                    editable={{onRowUpdate}}
                    icons={tableIcons}
                    components={{
                        Header: (props) => (
                            <TableHeaderWrapper
                                {...props}
                                handleFilterChange={handleFilterChange}
                                filterName={filterName}
                                handleLoadFiltersByName={handleLoadFiltersByName}
                                pageName={pageName}
                                cols={columns}
                            />
                        ),
                        Container: (props) => <TableContainer {...props} />,
                        Row: (props) => <TableRow {...props} />,
                        Cell: (props) => <TableCell {...props} />,
                        Action: (props) => (
                            <TableActionWrapper>
                                <MTableAction {...props} />
                            </TableActionWrapper>
                        )
                    }}
                />
            </TableWrapper>
        );
    }
);

const TableActionWrapper = styled.div`
    padding-right: 9px;
    padding-left: 8px;

    button {
        padding: 0px;
    }
`;