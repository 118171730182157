import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

const MacroDescHeaderPrices = (props) => {
    const {macro_urals_price_min, macro_urals_price_max, exch_usd_rub_min, exch_usd_rub_max} = props;
    const {t} = useTranslation(['components', 'pages']);

    return (
        <>
            <HeaderRightSectionPrices>
                <PriceTitle>{t('pages:macroScenarios.urals_price', 'Цена Urals, долл. США/барр.:')}</PriceTitle>
                <PriceMin>min.</PriceMin>
                <PriceMinValue>
                    {macro_urals_price_min || '-'}
                </PriceMinValue>
                <PriceMax>max.</PriceMax>
                <PriceMaxValue>
                    {macro_urals_price_max || '-'}
                </PriceMaxValue>
            </HeaderRightSectionPrices>
            <HeaderRightSectionPricesRight>
                <PriceTitle>{t('pages:macroScenarios.exchange_rate', 'Курс долл. США:')}</PriceTitle>
                <PriceMin>min.</PriceMin>
                <PriceMinValue>
                    {exch_usd_rub_min || '-'}
                </PriceMinValue>{' '}
                <PriceMax>max.</PriceMax>
                <PriceMaxValue>
                    {exch_usd_rub_max || '-'}
                </PriceMaxValue>
            </HeaderRightSectionPricesRight>
        </>
    );
};

const HeaderRightSectionPrices = styled.p``;

const HeaderRightSectionPricesRight = styled.p`
  margin-left: 34px;
`;

const PriceTitle = styled.span`
  margin-right: 8px;
`;

const PriceMin = styled.span`
  color: ${(props) => props.theme.colors.grey2};
  margin-right: 2px;
`;

const PriceMinValue = styled.span`
  background: ${(props) => props.theme.colors.green};
  color: white;
  align-items: center;
  position: relative;
  font-weight: var(--weight-light);
  justify-content: center;
  border-radius: 15px;
  margin-right: 4px;
  padding: 1px 4px;
`;

const PriceMax = styled.span`
  color: ${(props) => props.theme.colors.grey2};
  margin-right: 2px;
`;

const PriceMaxValue = styled.span`
  background: ${(props) => props.theme.colors.red};
  color: white;
  align-items: center;
  position: relative;
  font-weight: var(--weight-light);
  justify-content: center;
  border-radius: 15px;
  margin-right: 4px;
  padding: 1px 4px;
`;

export default React.memo(MacroDescHeaderPrices);

