import styled from "styled-components/macro";

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`

export const EmptyTableMessageWrapper = styled.div`
    padding-top: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(
          45deg
          , #ffffff 38.89%, #EEEEF2 38.89%, #EEEEF2 50%, #ffffff 50%, #ffffff 88.89%, #EEEEF2 88.89%, #EEEEF2 100%);
    background-size: 10px 10px;
`