import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { IFilter } from "../store/tableSelector/types";
import { useTranslation } from "react-i18next";
import { useFilterOptions } from "./tableSelector/useFilterOptions";

export const useVersionFilterMap = (filterName = 'vFilter') => {

    const releaseFilters = useFilterOptions(filterName, 'releases');
    const companiesFilters = useFilterOptions(filterName, 'companies');
    const subsurfaceUsersFilters = useFilterOptions(filterName, 'subsurface_users');
    const licensedSitesFilters = useFilterOptions(filterName, 'licensed_sites');
    const depositsSitesFilters = useFilterOptions(filterName, 'deposits');
    const versionGroupFilters = useFilterOptions(filterName, 'version_groups');
    const tagsFilters = useFilterOptions(filterName, 'tags');
    const percentProdProfitableFilters = useFilterOptions(filterName, 'percent_prod_profitable');
    const createdAtFilters = useFilterOptions(filterName, 'created_at');
    const taxRegimesFilters = useFilterOptions(filterName, 'tax_regimes');
    const taxRegimesAttrFilters = useFilterOptions(filterName, 'tax_regimes_attr');
    const macrosAttrFilters = useFilterOptions(filterName, 'macros_attr');
    const iterationsFilters = useFilterOptions(filterName, 'iterations');
    const wellSwitchOffsFilters = useFilterOptions(filterName, 'wells_switch_offs');
    const regionsFilters = useFilterOptions(filterName, 'regions');
    const usersFilters = useFilterOptions(filterName, 'users');

    const currentFilters = useSelector<ApplicationState, IFilter>(store => store[filterName].currentFilters);
    
    const { t } = useTranslation(['commons', 'pages']);

    const filterMap = new Map([
        [t('commons:licensed_site', 'ЛУ'), {
            filterOptions: licensedSitesFilters,
            excluded: null,
            selected: currentFilters.selected_licensed_sites,
            stateBranch: 'licensed_sites'
        }],
        [t('commons:user', 'Пользователь'), {
            filterOptions: usersFilters,
            excluded: null,
            selected: currentFilters.selected_users,
            stateBranch: 'users',
        }],
        [t('commons:subsurface_user', 'НП'), {
            filterOptions: subsurfaceUsersFilters,
            excluded: null,
            selected: currentFilters.selected_subsurface_users,
            stateBranch: 'subsurface_users'
        }],
        [t('commons:company', 'Компания'), {
            filterOptions: companiesFilters,
            excluded: null,
            selected: currentFilters.selected_companies,
            stateBranch: 'companies'
        }],
        [t('commons:deposit', 'МР'), {
            filterOptions: depositsSitesFilters,
            excluded: null,
            selected: currentFilters.selected_deposits,
            stateBranch: 'deposits'
        }],
        [t('commons:region'), {
            filterOptions: regionsFilters,
            excluded: null,
            selected: currentFilters.selected_regions,
            stateBranch: 'regions'
        }],
        [t('commons:tax_regimes', 'НР'), {
            filterOptions: taxRegimesFilters,
            excluded: currentFilters.excluded_tax_regimes,
            selected: currentFilters.selected_tax_regimes,
            stateBranch: 'tax_regimes',
            withExclusion: true
        }],
        [t('commons:tax_regimes_attr', 'НР, атрибуты'), {
            filterOptions: taxRegimesAttrFilters,
            excluded: currentFilters.excluded_tax_regimes_attr,
            selected: currentFilters.selected_tax_regimes_attr,
            stateBranch: 'tax_regimes_attr',
            withExclusion: true
        }],
        [t('commons:macros_attr', 'Макро'), {
            filterOptions: macrosAttrFilters,
            excluded: currentFilters.excluded_macros_attr,
            selected: currentFilters.selected_macros_attr,
            stateBranch: 'macros_attr',
            withExclusion: true
        }],
        [t('commons:iteration', 'Итер'), {
            filterOptions: iterationsFilters,
            excluded: currentFilters.excluded_iterations,
            selected: currentFilters.selected_iterations,
            stateBranch: 'iterations',
            withExclusion: true
        }],
        [t('commons:wells_switch_off', 'ОНП'), {
            filterOptions: wellSwitchOffsFilters,
            excluded: currentFilters.excluded_wells_switch_offs,
            selected: currentFilters.selected_wells_switch_offs,
            stateBranch: 'wells_switch_offs',
            withExclusion: true
        }],
        [t('commons:version_groups', 'Группы версий'), {
            filterOptions: versionGroupFilters,
            excluded: currentFilters.excluded_version_groups,
            selected: currentFilters.selected_version_groups,
            stateBranch: 'version_groups',
            withExclusion: true
        }],
        [t('commons:tags', 'Атрибуты'), {
            filterOptions: tagsFilters,
            excluded: currentFilters.excluded_tags,
            selected: currentFilters.selected_tags,
            stateBranch: 'tags',
            withExclusion: true
        }],
        [t('commons:percent_prod_profitable', 'Доля рентабельной добычи'), {
            filterOptions: percentProdProfitableFilters,
            excluded: currentFilters.excluded_percent_prod_profitable,
            selected: currentFilters.selected_percent_prod_profitable,
            stateBranch: 'percent_prod_profitable',
            withExclusion: true
        }],
        [t('commons:creation_date', 'Создано'), {
            filterOptions: createdAtFilters,
            selected: currentFilters.selected_created_at,
            excluded: null,
            stateBranch: 'created_at',
        }],
        [t('commons:release_tag', 'Релиз'), {
            filterOptions: releaseFilters,
            excluded: null,
            selected: currentFilters.selected_releases,
            stateBranch: 'releases'
        }]

    ]);

    return { filterMap }
};
