import {action} from 'typesafe-actions';
import {IVersionGroup, VersionGroupActionType} from "./types";

export const createVersionGroupRequest = (name: any) =>
    action(VersionGroupActionType.VERSIONGROUP_CREATE_REQUEST, {name});

export const createVersionGroupSuccess = (version_group: any) =>
    action(VersionGroupActionType.VERSIONGROUP_CREATE_SUCCESS, {version_group});

export const createVersionGroupError = (error: any) =>
    action(VersionGroupActionType.VERSIONGROUP_CREATE_ERROR, {error});

export const getVersionGroupsListRequest = () =>
    action(VersionGroupActionType.GET_VERSIONGROUPS_LIST_REQUEST);

export const getVersionGroupsListSuccess = (versionGroups: IVersionGroup[]) =>
    action(VersionGroupActionType.GET_VERSIONGROUPS_LIST_SUCCESS, {versionGroups});

export const getVersionGroupsListError = (error: any) =>
    action(VersionGroupActionType.GET_VERSIONGROUPS_LIST_ERROR, {error});

export const deleteVersionGroup = (id: number) =>
    action(VersionGroupActionType.DELETE_VERSIONGROUP, {id});