import zipWith from 'lodash/zipWith';
import {FilterColumnItemType} from './FilterColumn';
import {ALLOW_NUMBER_LIST_RULES} from '../../pages/calculation-page/GridView';
import {CheckedFilterItem, ColumnVariableValue, ConditionItemType} from './types';
import {IPaginatedGridResult} from '../../store/currentGrid/types';
import {floatFormat} from '../../helpers/MyAwesomeLib';
import {FilterListVariant} from '../../store/filterVariants/types';

export const AGG_FIELD = 'AGG';

export const isCheckedFilter = (filter: FilterColumnItemType): filter is CheckedFilterItem =>
    'isChecked' in filter;
export const isConditionFilter = (filter: FilterColumnItemType): filter is ConditionItemType =>
    'condition' in filter;

export const getUniqObjectInArrayByField = <
    T extends FilterListVariant[],
    K extends keyof FilterListVariant
>(
    array: T,
    key: K
) => {
    const uniq: FilterListVariant[] = [];

    for (const el of array) {
        const [un] = uniq.filter((f: FilterListVariant) => f[key] === el[key]);
        if (!un) uniq.push(el);
    }
    return uniq;
};

export const changeAGGFloatPointFromArray = (
    filters: CheckedFilterItem[],
    prec: number
): FilterColumnItemType[] => {
    return filters.map((aggFilter: CheckedFilterItem) => {
        if (ALLOW_NUMBER_LIST_RULES.includes(aggFilter.originalValue)) return aggFilter;
        aggFilter.originalValue = Number(aggFilter.originalValue).toFixed(prec);

        return aggFilter;
    });
};

export const roundNumber = (
    filters: (number | string)[],
    prec: number,
    showZero = true
): (number | string)[] => {
    return filters.map((aggValue: number) => {
        if (showZero === false && (aggValue === 0 || aggValue === null)) return '';
        if (showZero === true && aggValue === null) return 0;

        if (ALLOW_NUMBER_LIST_RULES.includes(String(aggValue))) return aggValue;
        return isNaN(aggValue) ? aggValue : Number(aggValue.toFixed(prec));
    });
};

export const roundVariants = (values: (number | string)[], precision: number): ColumnVariableValue[] =>
    values.map((item: number | string) => ({
        originalValue: String(item),
        roundValue:
            typeof item === 'string'
                ? item
                : ALLOW_NUMBER_LIST_RULES.includes(String(item))
                ? String(item)
                : Number(item).toFixed(precision).replace('.', ','),
    }));

export const dataToGrid = (data: IPaginatedGridResult, t, settings, language) => {
    const combineColsWithValues = (obj, rowCount) => {
        const colKeys = Object.keys(obj);
        const data = [];

        for (let i = 0; i < rowCount; i++) {
            let col = {};

            for (let colKey of colKeys) {
                col[colKey] = obj[colKey][i];
            }
            data.push(col);
        }
        return data;
    };

    let {results} = data;
    let {meta, index, values} = results;
    const showAgg = settings.agg_column;
    const precision = settings.precision;
    const showZero = settings.show_zero;

    const roundedValues = Object.keys(values).reduce((acc, field) => {
        acc[field] = roundNumber(values[field], precision, showZero)
        return acc;
    }, {});

    if (results.values?.length === 0) return [[], []];

    // Cloning meta to avoid mutation
    meta = Object.assign({}, meta);

    const aggCol = language === 'ru' ? 'АГГ' : AGG_FIELD;
    const aggInMeta = meta.index_columns.find((i) => i.verbose_name === aggCol);
    if ((aggCol in index || aggInMeta) && !showAgg) {
        delete index[aggCol];
        meta.index_columns = meta.index_columns.filter((i) => i.verbose_name !== aggCol);
    }
    const indexAliases = meta.index_columns.map((i) => i.alias);
    const indexColNames = meta.index_columns.map((i) => i.verbose_name);
    const valuesColNames = meta.values_columns.map((i) => i.verbose_name);
    const indexValues = Object.values(index);

    if (indexValues.length > 0) {
        const formattedIndex = indexValues.reduce((acc, item, index: number) => {
            if (indexAliases[index]) acc[indexAliases[index]] = item;
            return acc;
        }, {});
        // Add AGG col
        if (aggCol in index) formattedIndex['AGG'] = roundNumber(index[aggCol], precision);
        const rowCount = indexValues[0].length;
        index = combineColsWithValues(formattedIndex, rowCount);
        values = combineColsWithValues(roundedValues, rowCount);
    }
    const commonProps = {
        resizable: true,
        minWidth: 40,
        maxWidth: 300,
        editable: false,
        filter: true,
    };
    const indexColDefs = indexColNames.map((col: string, index: number) => ({
        ...commonProps,
        headerName: indexAliases[index] === 'COLOR' ? '' : col,
        field: indexAliases[index] || AGG_FIELD,
        pinned: 'left',
        width: indexAliases[index] === 'COLOR' ? 40 : 90,
        valueFormatter: indexAliases[index] === 'AGG' ? ({value}) => floatFormat(value) : undefined,
        cellRenderer: indexAliases[index] === 'COLOR' ? colorCellRenderer : undefined,
        filter: indexAliases[index] === 'COLOR' ? false : commonProps.filter
    }));
    const valueColDefs = valuesColNames.map((col: string) => ({
        ...commonProps,
        headerName: col,
        field: col,
        width: 90,
        valueFormatter: ({value}) => {
            if (isNaN(parseFloat(value))) {
                return value;
            } else {
                return floatFormat(value);
            }
        },
    }));

    const rowData = zipWith(index, values, (index, value) => ({...index, ...value}));
    return [[...indexColDefs, ...valueColDefs], rowData];
};

export const colorCellRenderer = (props) => {
    return `<div style="display: flex; height: 100%; justify-content: center; align-items: center;">
                <div style="background-color: ${props.value}; width: 10px; height: 10px; border: 1px solid black;">
                </div>
            </div>`;
};
