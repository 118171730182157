export type NodeId = string;

export interface IAnomalyTreeNode {
    id: NodeId;
    title: string;
    value?: any; // Compat
    mipair?: string;
    children?: IAnomalyTreeNode[]
}

export enum AnomalyTreeActionType {
    FETCH_REQUEST = '@@anomaly-tree/FETCH_REQUEST',
    FETCH_SUCCESS = '@@anomaly-tree/FETCH_SUCCESS',
    FETCH_ERROR = '@@anomaly-tree/FETCH_ERROR',
    SET_CURRENT_ANOMALY = '@@anomaly-tree/SET_CURRENT_ANOMALY',
}

export interface AnomalyTreeState {
    readonly loading: boolean
    readonly data: []
    readonly errors?: string
    readonly currentAnomaly: string | null
}
