export enum CalcVersionErrorsActionType {
    GET_VERSION_ERRORS_REQUEST = '@@calcVersionErrors/GET_VERSION_ERRORS_REQUEST',
    GET_VERSION_ERRORS_SUCCESS = '@@calcVersionErrors/GET_VERSION_ERRORS_SUCCESS',
    GET_VERSION_ERRORS_ERROR = '@@calcVersionErrors/GET_VERSION_ERRORS_ERROR',
}

export interface ICalcVersionError {
    level: number
    level_text: string
    extra_data: string
    message: string,
    created_at: string,
    id: number,
    logger_name: string,
    extra_data_type
}

export type ICalcVersionErrors = ICalcVersionError[];

export interface ICalcVersionErrorsState {
    data: any;
    readonly loaded: boolean;
    readonly errors?: string | null;
    readonly activeID: number | null
}
