import React from 'react';
import styled from 'styled-components';
import {Icon} from './icon.styles';

import EditImage from '../../../assets/images/icons/table/icon-edit.svg';
import TreeImage from '../../../assets/images/icons/table/icon-tree.svg';
import FilterImage from '../../../assets/images/icons/table/icon-filter.svg';
import FilterASCImage from '../../../assets/images/icons/table/icon-asc.svg';
import FilterDESCImage from '../../../assets/images/icons/table/icon-desc.svg';
import FilterWhiteImage from '../../../assets/images/icons/table/icon-filter-white.svg';
import FilterYellowImage from '../../../assets/images/icons/table/icon-filter-yellow.svg';
import ColumnFilterImage from '../../../assets/images/icons/table/icon-column-filter.svg';
import IconError from "@material-ui/icons/Error"
import IconWarning from "@material-ui/icons/Warning"
import IconInfo from "@material-ui/icons/Info"

export const EditIcon: React.FC = () => (
    <IconWrapper>
        <IconControl src={EditImage} alt="edit-icon"/>
    </IconWrapper>
);

export const TreeIcon: React.FC = () => (
    <IconWrapper>
        <IconControl src={TreeImage} alt="tree-icon"/>
    </IconWrapper>
);

export const AttentionIconRed: React.FC = () => (
    <IconWrapper>
        <IconControl2><IconError/></IconControl2>
    </IconWrapper>
);

export const AttentionIconYellow: React.FC = () => (
    <IconWrapper>
        <IconControl2><IconWarning/></IconControl2>
    </IconWrapper>
);

export const AttentionIconBlue: React.FC = () => (
    <IconWrapper>
        <IconControl2><IconInfo/></IconControl2>
    </IconWrapper>
);

export const FilterIcon: React.FC = () => (
    <IconWrapper>
        <IconControl src={FilterImage} alt="filter-icon"/>
    </IconWrapper>
);

export const FilterIconWhite: React.FC = () => (
    <IconWrapper>
        <IconControl src={FilterWhiteImage} alt="filter-white-icon"/>
    </IconWrapper>
);

export const FilterIconYellow: React.FC = () => (
    <IconWrapper>
        <IconControl src={FilterYellowImage} alt="filter-yellow-icon"/>
    </IconWrapper>
);

export const FilterASCIcon: React.FC = () => (
    <IconWrapper>
        <SortIconControl src={FilterASCImage} alt="filter-asc-icon"/>
    </IconWrapper>
);

export const FilterDESCIcon: React.FC = () => (
    <IconWrapper>
        <SortIconControl src={FilterDESCImage} alt="filter-desc-icon"/>
    </IconWrapper>
);

export const ColumnFilterIcon: React.FC = () => (
    <IconWrapper>
        <SortIconControl src={ColumnFilterImage} alt="filter-desc-icon"/>
    </IconWrapper>
);

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`;

const IconControl = styled(Icon)`
    width: 30px;
    height: 30px;
`;
const IconControl2 = styled.div`
    width: 30px;
    height: 30px;
`;

const SortIconControl = styled(Icon)`
    width: 15px;
    height: 15px;
`;