import { IAttribute } from "../attributes/types"

export enum FilterActionType {

    GET_FILTER_DATA_REQUEST = '@@filter/GET_FILTER_DATA_REQUEST',
    GET_FILTER_DATA_SUCCESS = '@@filter/GET_FILTER_DATA_SUCCESS',
    GET_FILTER_DATA_ERROR = '@@filter/GET_FILTER_DATA_ERROR',

    SET_SEARCH = '@@filter/SET_SEARCH',
    SET_SELECTED_ROWS = '@@filter/SET_SELECTED_ROWS',
    ADD_TO_SELECTED_ROWS = '@@filter/ADD_TO_SELECTED_ROWS',
    SET_ACTIVE_ROWS = '@@filter/SET_ACTIVE_ROWS',
    SET_COLUMN_FILTER = '@@filter/SET_COLUMN_FILTER',

    SET_ACTIVE_ROWS_REQUEST = '@@filter/SET_ACTIVE_ROWS_REQUEST',
    SET_ACTIVE_ROWS_SUCCESS = '@@filter/SET_ACTIVE_ROWS_SUCCESS',
    CLEAR_ACTIVE_ROWS = '@@filter/CLEAR_ACTIVE_ROWS',
    SET_ACTIVE_ROWS_ERROR = '@@filter/SET_ACTIVE_ROWS_ERROR',

    RESET_FILTERS = '@@filter/RESET_FILTERS',
    LOAD_FILTER = '@@filter/LOAD_FILTER',
    DELETE_FILTER_ROWS_SUCCESS = '@@filter/DELETE_FILTER_ROWS_SUCCESS',
    SET_AS_TREE = '@@filter/SET_AS_TREE',

    GET_FILTER_OPTIONS_REQUEST = '@@filter/GET_FILTER_OPTIONS_REQUEST',
    GET_FILTER_OPTIONS_SUCCESS = '@@filter/GET_FILTER_OPTIONS_SUCCESS',
    GET_FILTER_OPTIONS_ERROR = '@@filter/GET_FILTER_OPTIONS_ERROR',

    HIERARCHY_TREE_REQUEST = '@@filter/HIERARCHY_TREE_REQUEST',
    HIERARCHY_TREE_SUCCESS = '@@filter/HIERARCHY_TREE_SUCCESS',
    HIERARCHY_TREE_ERROR = '@@filter/HIERARCHY_TREE_ERROR',

    SET_ROWS_PER_PAGE = '@@filter/SET_ROWS_PER_PAGE',
    SET_FILTER_CHECKED_IDS = '@@filter/SET_FILTER_CHECKED_IDS',

    SET_CHECKED_ONLY = '@@filter/SET_CHECKED_ONLY',
    SET_IS_PICKER_MODE = '@@filter/SET_IS_PICKER_MODE',
    SET_DISPLAYED_COLUMNS = '@@filter/SET_DISPLAYED_COLUMNS'
}

export interface ITaxMode {
    id: number
    name: string
    created_at: string
    is_draft: boolean
    modified_indicators;
    status;
    tags;
}

export interface IMacroScenario {
    id: number
    name: string
    created_at: string
    description: string
    macro_urals_price_min: string
    macro_urals_price_max: string
    exch_usd_rub_min: string
    exch_usd_rub_max: string
    modified_indicators;
    status;
    tags;
}

export interface IEmissionScenario {
    id: number
    name: string
    description: string
    modified_indicators;
    tags;
    created_at: string
    status;
}

export interface ICostsScenario {
    id: number
    name: string
    description: string
    modified_indicators;
    tags;
    created_at: string
    status;
}

export interface IFile {
    id: number
    file_name: string
    doc_type_label: string
    created_at: string
}

export interface IOptionItem {
    id: number
    name: string
}

export interface ISortItem {
    name: string
    value: string
}

export type IOptionItems = IOptionItem[]
export type ISortItems = ISortItem[]
export type IAttributes = IAttribute[]
export type ITaxModes = ITaxMode[]
export type IMacroScenarios = IMacroScenario[]
export type IEmissionScenarios = IEmissionScenario[]

export interface IFilter {
    // Фильтры версий
    selected_releases?: IOptionItems
    selected_companies?: IOptionItems
    selected_subsurface_users?: IOptionItems
    selected_licensed_sites?: IOptionItems
    selected_deposits?: IOptionItems
    selected_tax_regimes?: IOptionItems
    selected_tax_regimes_attr?: IOptionItems
    selected_macros_attr?: IOptionItems
    selected_iterations?: IOptionItems
    selected_wells_switch_offs?: IOptionItems
    selected_users?: IOptionItems
    selected_regions?: IOptionItems
    selected_version_groups?: IOptionItems
    selected_percent_prod_profitable?: IOptionItems

    excluded_releases?: []
    excluded_companies?: []
    excluded_subsurface_users?: []
    excluded_licensed_sites?: []
    excluded_deposits?: []
    excluded_tax_regimes?: []
    excluded_tax_regimes_attr?: []
    excluded_macros_attr?: []
    excluded_iterations?: []
    excluded_wells_switch_offs?: []
    excluded_users?: []
    excluded_regions?: []
    excluded_version_groups?: []
    excluded_percent_prod_profitable?: []
    
    is_mine?: boolean
    is_donor?: boolean

    // Фильтры налоговых режимов
    selected_tax_modes?: IOptionItems
    selected_indicators?: IOptionItems
    selected_status?: IOptionItems
    
    excluded_tax_modes?: []
    excluded_indicators?: []
    excluded_status?: []

    // Фильтры макро сценариев
    selected_exch_usd_rub_min?: IOptionItems
    selected_exch_usd_rub_max?: IOptionItems
    selected_macro_urals_price_min?: IOptionItems
    selected_macro_urals_price_max?: IOptionItems

    excluded_exch_usd_rub_min?: []
    excluded_exch_usd_rub_max?: []
    excluded_macro_urals_price_min?: []
    excluded_macro_urals_price_max?: []

    // Фильтры файлов
    selected_doc_types?: IOptionItems

    excluded_doc_types?: []
    
    // Общие фильтры
    search?: string
    show_deleted?: boolean
    selected_names?: IOptionItems
    selected_tags?: IAttributes
    selected_created_at?: IOptionItems
    excluded_names?: []
    excluded_tags?: []
    excluded_created_at?: []
    by_blank_filters?: {}
}

export interface IFilterOptions {
    // Фильтры версий
    releases?: IOptionItems
    companies?: IOptionItems
    subsurface_users?: IOptionItems
    licensed_sites?: IOptionItems
    deposits?: IOptionItems
    tax_regimes?: IOptionItems
    tax_regimes_attr?: IOptionItems
    macros_attr?: IOptionItems
    iterations?: IOptionItems
    wells_switch_offs?: IOptionItems
    users?: IOptionItems
    regions?: IOptionItems
    version_groups?: IOptionItems
    percent_prod_profitable?: IOptionItems

    // Фильтры налоговых режимов
    tax_modes?: IOptionItems
    indicators?: IOptionItems
    status?: IOptionItems

    // Фильтры макро сценариев
    exch_usd_rub_min?: IOptionItems
    exch_usd_rub_max?: IOptionItems
    macro_urals_price_min?: IOptionItems
    macro_urals_price_max?: IOptionItems

    // Фильтры файлов
    doc_types?: IOptionItems
    
    // Общие фильтры
    names?: IOptionItems
    tags?: IAttributes
    created_at?: IOptionItems
}

export interface IFilterState {
    currentFilters: IFilter // Текущие фильтры
    currentSorting: ISortItems // Текущая сортировка
    filterOptions: IFilterOptions // Справочники опций фильтра
    pageData: any[] // Данные строк текущей страницы
    rowIds: number[] // Список id строк (без учета пагинации)
    checkedIds: number[] // Список id строк со статусом чекбокса checked 
    selectedRowsData: any[] // Данные выбранных строк (до 100)
    selectedRowsIds: number[] // Данные выбранных строк (без учета пагинации)
    activeRowsData: any[] // Данные активных строк
    activeRowsIds: number[] | null // Id активных строк
    
    totalPages: number // Всего страниц
    perPage: number // Строк на странице
    count: number // Строк на всех страницах
    
    loading: boolean 
    loadingOptions: boolean
    isInitialized: boolean
    isPickerMode: boolean // Режим выбора строк (в диалоге)
    checkedOnly: boolean // Показать только checked
    asTree: boolean // Показать в виде иерархии
    treeData: any[] // Данные строк вида иерархии
    
    filterOrder: string[] // Порядок применения фильтров

    columns: IOptionItems // Все столбцы таблицы
    displayedColumns: IOptionItems // Отображаемые столбцы таблицы
}