export enum ReportTemplatesActionType {
    SET_ACTIVE_REPORT_TEMPLATE = '@@reportTemplates/SET_ACTIVE_REPORT_TEMPLATE',
    SET_ACTIVE_REPORT_TEMPLATE_ID = '@@reportTemplates/SET_ACTIVE_REPORT_TEMPLATE_ID',
    RESET_ACTIVE_REPORT_TEMPLATE = '@@reportTemplates/RESET_ACTIVE_REPORT_TEMPLATE',
    GET_REPORT_ITEMS_LIST = '@@reportTemplates/GET_REPORT_ITEMS_LIST',
    GET_REPORT_ITEMS_LIST_ERROR = '@@reportTemplates/GET_REPORT_ITEMS_LIST_ERROR',
    GET_REPORT_ITEMS_LIST_SUCCESS = '@@reportTemplates/GET_REPORT_ITEMS_LIST_SUCCESS',
}

export interface IItem {
    name: string,
    alias: string
}

export interface ITemplate {
    name: string,
    structure: {
        rows: []
        cols: []
        data
    },
    selectedIndicators,
    id,
    created_at,
    is_global
}