import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RootLayout } from './layouts';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import StartPage from './pages/start-page/StartPage';
import Login from './pages/login-page/LoginPage';
import NotFound from './pages/notFound-page/NotFound';
import { loadFilter } from './store/tableSelector/actions';
import { loadState } from './store/stimulationToolbar/actions';
import { loadTasksVisibilityState } from './store/tasks/actions';
import { userIsAuthenticated, userIsNotAuthenticated } from './helpers/PrivateRoute';

import { ApplicationState } from './store';
import { connect, useDispatch } from 'react-redux';
import { getConfig } from './config-provider';
import { cleanInstall } from './helpers/MyAwesomeLib';
import MyLoadable from './helpers/MyLoadable';
import RolesTable from './pages/roles-page/Roles';
import { MacroDescriptionPage } from './pages/macro-description-page/MacroDescPage';
import EmissionScenariosPage from "./pages/emission-scenarios-page/EmissionScenariosPage";
import CostsScenariosPage from "./pages/costs-scenarios-page/CostsScenariosPage";
import FilesPage from './pages/files-page/FilesPage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);

const CalculationPage = MyLoadable({loader: () => import('./pages/calculation-page/CalculationPage')});
const ComparePage = MyLoadable({loader: () => import('./pages/compare-page/ComparePage')});
const DashboardsPage = MyLoadable({loader: () => import('./pages/dashboards-page/DashboardsPage')});
const DisableWellsPage = MyLoadable({loader: () => import('./pages/disable-wells-page/DisableWellsPage')});
const ImportPage = MyLoadable({loader: () => import('./pages/import-page/ImportPage')});
const VersionsPage = MyLoadable({loader: () => import('./pages/version-page/VersionsPage')});
const AnomaliesPage = MyLoadable({loader: () => import('./pages/anomalies-page/AnomaliesPage')});
const AnalyticsPage = MyLoadable({loader: () => import('./pages/analytics-page/AnalyticsPage')});
const CostsPage = MyLoadable({loader: () => import('./pages/costs-page/CostsPage')});
const ReleaseNotesPage = MyLoadable({loader: () => import('./pages/release-notes-page/ReleaseNotesPage')});
const TaxModesPage = MyLoadable({loader: () => import('./pages/tax-modes-page/TaxModesPage')});
const macroScenariosPage = MyLoadable({ loader: () => import('./pages/macro-scenario-page/MacroScenariosPage') });
const GeoMapPage = MyLoadable({ loader: () => import('./pages/geo-map/GeoMap') });
const StimulationMeasuresPage = MyLoadable({loader: () => import('./pages/stimulation-measures-page/StimulationMeasuresPage')});

const Routes: React.FC<any> = ({ token, auth_enabled, loadFilter, appVersion }) => {
    const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');
    useStyles({});
    const dispatch = useDispatch();

    React.useEffect(() => {
        let previousAppVersion = localStorage.getItem('appVersion');
        let lastUsedHost = localStorage.getItem('lastUsedHost');
        if (appVersion) {
            if (!previousAppVersion || appVersion !== previousAppVersion || lastUsedHost !== API_ENDPOINT) {
                cleanInstall(appVersion);
            }
            let filterString = localStorage.getItem('filter_vFilter');
            let group1FilterString = localStorage.getItem('filter_group1Filter');
            let group2FilterString = localStorage.getItem('filter_group2Filter');
            let stimulationAnalysis = localStorage.getItem('stimulation_analysis');
            let costsCompFilterString = localStorage.getItem('filter_costsCompFilter');
            let taxModesFilterString = localStorage.getItem('filter_taxModesFilter');
            let systemTasksVisibilityState = localStorage.getItem('show_system_tasks');
            let myTasksVisibilityState = localStorage.getItem('show_my_tasks');
            let macroScenariosFilterString = localStorage.getItem('filter_macroScenariosFilter');
            let costsScenariosFilterString = localStorage.getItem('filter_costsScenariosFilter');
            let emissionScenariosFilterString = localStorage.getItem('filter_emissionScenariosFilter');
            let filesFilterString = localStorage.getItem('filter_filesFilter');

            if (filterString) {
                loadFilter(JSON.parse(filterString), 'vFilter');
            }
            if (group1FilterString) {
                loadFilter(JSON.parse(group1FilterString), 'group1Filter');
            }
            if (group2FilterString) {
                loadFilter(JSON.parse(group2FilterString), 'group2Filter');
            }
            if (costsCompFilterString) {
                loadFilter(JSON.parse(costsCompFilterString), 'costsCompFilter');
            }
            if (taxModesFilterString) {
                loadFilter(JSON.parse(taxModesFilterString), 'taxModesFilter');
            }
            if (stimulationAnalysis) {
                dispatch(loadState(JSON.parse(stimulationAnalysis)));
            }
            if (systemTasksVisibilityState) {
                dispatch(loadTasksVisibilityState(JSON.parse(systemTasksVisibilityState), 'show_system_tasks'));
            }
            if (JSON.parse(myTasksVisibilityState)) {
                dispatch(loadTasksVisibilityState(JSON.parse(systemTasksVisibilityState), 'show_my_tasks'));
            }
            if (macroScenariosFilterString) {
                loadFilter(JSON.parse(macroScenariosFilterString), 'macroScenariosFilter');
            }
            if (costsScenariosFilterString) {
                loadFilter(JSON.parse(costsScenariosFilterString), 'costsScenariosFilter');
            }
            if (emissionScenariosFilterString) {
                loadFilter(JSON.parse(emissionScenariosFilterString), 'emissionScenariosFilter');
            }
            if (filesFilterString) {
                loadFilter(JSON.parse(filesFilterString), 'filesFilter');
            }
        }
        //eslint-disable-next-line
    }, [appVersion]);

    return (
        <>
            {token !== null || auth_enabled === false ? (
                <RootLayout>
                    <Switch>
                        {/*<Route path="/" exact component={auth_enabled ? userIsAuthenticated(StartPage) : StartPage} />*/}
                        <Route path="/" exact>
                            <Redirect to="/assets/geo-map" />
                        </Route>
                        <Route
                            path="/calc"
                            component={auth_enabled ? userIsAuthenticated(CalculationPage) : CalculationPage}
                        />
                        <Route
                            path="/anomalies"
                            exact
                            component={auth_enabled ? userIsAuthenticated(AnomaliesPage) : AnomaliesPage}
                        />
                        <Route
                            path="/import"
                            exact
                            component={auth_enabled ? userIsAuthenticated(ImportPage) : ImportPage}
                        />
                        <Route
                            path="/tax_regime/:taxModeId?"
                            exact
                            component={auth_enabled ? userIsAuthenticated(TaxModesPage) : TaxModesPage}
                        />
                        <Route
                            path="/macro"
                            exact
                            component={auth_enabled ? userIsAuthenticated(macroScenariosPage) : macroScenariosPage}
                        />
                        <Route
                            path="/macro_description/:macroId"
                            exact
                            component={auth_enabled ? userIsAuthenticated(MacroDescriptionPage) : MacroDescriptionPage}
                        />
                        <Route
                            path="/versions"
                            exact
                            component={auth_enabled ? userIsAuthenticated(VersionsPage) : VersionsPage}
                        />
                        <Route
                            path="/dash"
                            exact
                            component={auth_enabled ? userIsAuthenticated(DashboardsPage) : DashboardsPage}
                        />
                        <Route
                            path="/compare"
                            component={auth_enabled ? userIsAuthenticated(ComparePage) : ComparePage}
                        />
                        <Route
                            path="/meta"
                            exact
                            component={auth_enabled ? userIsAuthenticated(AnalyticsPage) : AnalyticsPage}
                        />
                        <Route
                            path="/costref"
                            exact
                            component={auth_enabled ? userIsAuthenticated(CostsPage) : CostsPage}
                        />
                        <Route
                            path="/wells"
                            exact
                            component={auth_enabled ? userIsAuthenticated(DisableWellsPage) : DisableWellsPage}
                        />
                        <Route path="/login" exact component={auth_enabled ? userIsNotAuthenticated(Login) : Login} />
                        <Route
                            path="/roles"
                            exact
                            component={auth_enabled ? userIsAuthenticated(RolesTable) : RolesTable}
                        />
                        <Route
                            path="/release_notes"
                            exact
                            component={auth_enabled ? userIsAuthenticated(ReleaseNotesPage) : ReleaseNotesPage}
                        />
                        <Route
                            path="/assets/geo-map"
                            exact
                            component={auth_enabled ? userIsAuthenticated(GeoMapPage) : GeoMapPage}
                        />
                        <Route
                            path="/portfolio_analysis"
                            exact
                            component={auth_enabled ? userIsAuthenticated(StimulationMeasuresPage) : StimulationMeasuresPage}
                        />
                        <Route
                            path="/esg_scenario"
                            exact
                            component={auth_enabled ? userIsAuthenticated(EmissionScenariosPage) : EmissionScenariosPage}
                        />
                        <Route
                            path="/costs_scenario"
                            exact
                            component={auth_enabled ? userIsAuthenticated(CostsScenariosPage) : CostsScenariosPage}
                        />
                        <Route
                            path="/files"
                            exact
                            component={auth_enabled ? userIsAuthenticated(FilesPage) : FilesPage}
                        />
                        <Route component={NotFound} />
                    </Switch>
                </RootLayout>
            ) : (
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Redirect to="/" />
                </Switch>
            )}
        </>
    );
};

const mapStateToProps = ({ login, appProperties }: ApplicationState) => ({
    token: login.token,
    auth_enabled: appProperties.auth_enabled,
    appVersion: appProperties.version,
});

const mapDispatchToProps = {
    loadFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
