import {Reducer} from 'redux';
import {RowsPerTableCellActionType, IRowsPerTableCellState} from './types';

const getInitialState = () => {
    const rowsPerTableCellKeys = Object.keys(localStorage).filter((key) =>
        key.includes('rows_per_table_cell')
    );
    const rowsPerTableCell = {};
    for (const key of rowsPerTableCellKeys) {
        rowsPerTableCell[key] = JSON.parse(localStorage[key]);
    }
    return rowsPerTableCell;
};

export const initialState: IRowsPerTableCellState = {
    ...getInitialState(),
};

const reducer: Reducer<IRowsPerTableCellState> = (
    state = initialState,
    action
): IRowsPerTableCellState => {
    switch (action.type) {
        case RowsPerTableCellActionType.SET_ROWS_PER_TABLE_CELL: {
            const {pageName, num} = action.payload;
            return {
                ...state,
                [pageName]: num,
            };
        }
        default: {
            return state;
        }
    }
};

export {reducer as rowsPerTableCellReducer};
