import {Reducer} from 'redux';
import {GridActionType, ICurrentGridState} from './types';

const defaultData = {
    count: 0,
    page: 1,
    page_size: 100,
    results: {
        meta: {
            index_columns: [],
            values_columns: [],
            values_columns_type: [],
        },
        index: [],
        values: [],
    },
    agg_method: {
        name: null,
        tooltip: null,
    },
    groupby_available: null,
    default_agg_method: null,
};

export const initialState: ICurrentGridState = {
    data: {...defaultData},
    errors: null,
    loading: false,
    settings: {
        precision: 4,
        show_zero: true,
        disable_inject: true,
        page: 1,
        page_size: 100,
        groupby: [],
        orient: 'records',
        cumsum: false,
        agg_column: true,
    },
    selected_rows: [],
    filters: null,
};

const reducer: Reducer<ICurrentGridState> = (state = initialState, action) => {
    switch (action.type) {
        case GridActionType.CHANGE_SETTINGS: {
            return {...state, settings: {...state.settings, ...action.payload}};
        }
        case GridActionType.CALC_REQUEST: {
            return {...state, loading: false};
        }
        case GridActionType.SAVE_FILTERS: {
            return {...state, filters: action.payload};
        }
        case GridActionType.CALC_SUCCESS: {
            return {...state, loading: false, ...action.payload, errors: null};
        }
        case GridActionType.CALC_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case GridActionType.CALC_CLEAR_DATA: {
            return {...state, loading: true, data: defaultData};
        }
        case GridActionType.SET_SELECTED_INDEX: {
            return {...state, selected_rows: action.payload};
        }
        default: {
            return state;
        }
    }
};

export {reducer as currentGridReducer};
