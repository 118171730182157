import {FilterActionType, IFilterState} from './types';
import {ICalcVersion} from '../calcVersion/types';
import {formatDateTime} from '../../helpers/MyAwesomeLib';
import {arraysValuesEqual} from '../../helpers/utils';

const columnsVersionFilter = [
    // {id: 1, name: 'hierarchy'},
    // {id: 2, name: 'id'},
    {id: 3, name: 'percent_prod_profitable'},
    {id: 4, name: 'version_name'}, //
    {id: 5, name: 'creation_date'},
    {id: 6, name: 'company'},
    {id: 7, name: 'subsurface_user'}, //
    {id: 8, name: 'licensed_site'},
    {id: 9, name: 'deposit'},
    {id: 10, name: 'version_groups'}, // 
    {id: 11, name: 'tags'},
    {id: 12, name: 'tax_regimes'},
    {id: 13, name: 'tax_regimes_attr'},
    {id: 14, name: 'macros_attr'},
    {id: 15, name: 'iteration'}, //
    {id: 16, name: 'wells_switch_off'},
    {id: 17, name: 'region'}, //
    {id: 18, name: 'user'},
    {id: 19, name: 'anomalies'}, //
    {id: 20, name: 'release'},
];

const columnsTaxModeFilter = [
    {id: 1, name: 'name'},
    {id: 2, name: 'tax_mode'},
    {id: 3, name: 'tags'},
    {id: 4, name: 'included_indicators'},
    {id: 5, name: 'creation_date'},
    {id: 6, name: 'status'},
];

const columnsMacroScenarioFilter = [
    {id: 1, name: 'name'},
    {id: 2, name: 'description'},
    {id: 3, name: 'exch_usd_rub_min'},
    {id: 4, name: 'exch_usd_rub_max'},
    {id: 5, name: 'macro_urals_price_max'},
    {id: 6, name: 'macro_urals_price_min'},
    {id: 7, name: 'status'},
];

const columnsEmissionScenarioFilter = [
    {id: 1, name: 'name'},
    {id: 2, name: 'description'},
    {id: 3, name: 'tags'},
    {id: 4, name: 'creation_date'},
    {id: 5, name: 'status'},
];

const columnsCostsScenarioFilter = [
    {id: 1, name: 'name'},
    {id: 2, name: 'description'},
    {id: 3, name: 'tags'},
    {id: 4, name: 'included_indicators'},
    {id: 5, name: 'creation_date'},
    {id: 6, name: 'status'},
];

const columnsPortfolioAnalysisFilter = [
    {id: 1, name: 'id'},
    {id: 2, name: 'name'},
    {id: 3, name: 'company_group1'},
    {id: 4, name: 'company_group2'},
    {id: 5, name: 'macro_group1'},
    {id: 6, name: 'macro_group2'},
    {id: 7, name: 'tax_group1'},
    {id: 8, name: 'tax_group2'},
    {id: 9, name: 'created_at'},
    {id: 10, name: 'user'},
];

const columnsFilesFilter = [
    {id: 1, name: 'name'},
    {id: 2, name: 'doc_type'},
    {id: 3, name: 'creation_date'},
];

export const initialVersionFilterState: IFilterState = {
    currentFilters: {},
    currentSorting: [],
    filterOptions: {},
    pageData: [],
    rowIds: [],
    checkedIds: [],
    selectedRowsData: [],
    selectedRowsIds: [],
    activeRowsData: [],
    activeRowsIds: [],
    
    totalPages: 0,
    perPage: 30,
    count: 0,

    loading: false,
    loadingOptions: false,
    isInitialized: false,
    isPickerMode: false,
    checkedOnly: false,
    asTree: false,
    treeData: [],

    filterOrder: [],

    columns: columnsVersionFilter,
    displayedColumns: columnsVersionFilter.filter((col) => ![4, 7, 10, 15, 17, 19].includes(col.id)),
};

export const initialMacroScenariosFilterState: IFilterState = {
    currentFilters: {},
    currentSorting: [],
    filterOptions: {},
    pageData: [],
    rowIds: [],
    checkedIds: [],
    selectedRowsData: [],
    selectedRowsIds: [],
    activeRowsData: [],
    activeRowsIds: [],
    
    totalPages: 0,
    perPage: 30,
    count: 0,

    loading: false,
    loadingOptions: false,
    isInitialized: false,
    isPickerMode: false,
    checkedOnly: false,
    asTree: false,
    treeData: [],

    filterOrder: [],

    columns: columnsMacroScenarioFilter,
    displayedColumns: columnsMacroScenarioFilter
};

export const initialEmissionScenariosFilterState: IFilterState = {
    currentFilters: {},
    currentSorting: [],
    filterOptions: {},
    pageData: [],
    rowIds: [],
    checkedIds: [],
    selectedRowsData: [],
    selectedRowsIds: [],
    activeRowsData: [],
    activeRowsIds: [],
    
    totalPages: 0,
    perPage: 30,
    count: 0,

    loading: false,
    loadingOptions: false,
    isInitialized: false,
    isPickerMode: false,
    checkedOnly: false,
    asTree: false,
    treeData: [],

    filterOrder: [],

    columns: columnsEmissionScenarioFilter,
    displayedColumns: columnsEmissionScenarioFilter 
};

export const initialCostsScenariosFilterState: IFilterState = {
    currentFilters: {},
    currentSorting: [],
    filterOptions: {},
    pageData: [],
    rowIds: [],
    checkedIds: [],
    selectedRowsData: [],
    selectedRowsIds: [],
    activeRowsData: [],
    activeRowsIds: [],
    
    totalPages: 0,
    perPage: 30,
    count: 0,

    loading: false,
    loadingOptions: false,
    isInitialized: false,
    isPickerMode: false,
    checkedOnly: false,
    asTree: false,
    treeData: [],

    filterOrder: [],

    columns: columnsCostsScenarioFilter,
    displayedColumns: columnsCostsScenarioFilter 
};

export const initialTaxModesFilterState: IFilterState = {
    currentFilters: {},
    currentSorting: [],
    filterOptions: {},
    pageData: [],
    rowIds: [],
    checkedIds: [],
    selectedRowsData: [],
    selectedRowsIds: [],
    activeRowsData: [],
    activeRowsIds: [],
    
    totalPages: 0,
    perPage: 30,
    count: 0,

    loading: false,
    loadingOptions: false,
    isInitialized: false,
    isPickerMode: false,
    checkedOnly: false,
    asTree: false,
    treeData: [],

    filterOrder: [],

    columns: columnsTaxModeFilter,
    displayedColumns: columnsTaxModeFilter
};

export const initialReportFilterState: IFilterState = {
    currentFilters: {},
    currentSorting: [],
    filterOptions: {},
    pageData: [],
    rowIds: [],
    checkedIds: [],
    selectedRowsData: [],
    selectedRowsIds: [],
    activeRowsData: [],
    activeRowsIds: [],
    
    totalPages: 0,
    perPage: 30,
    count: 0,

    loading: false,
    loadingOptions: false,
    isInitialized: false,
    isPickerMode: false,
    checkedOnly: false,
    asTree: false,
    treeData: [],

    filterOrder: [],

    columns: [],
    displayedColumns: []
};

export const initialPortfolioAnalysisFilterState: IFilterState = {
    currentFilters: {},
    currentSorting: [],
    filterOptions: {},
    pageData: [],
    rowIds: [],
    checkedIds: [],
    selectedRowsData: [],
    selectedRowsIds: [],
    activeRowsData: [],
    activeRowsIds: [],
    
    totalPages: 0,
    perPage: 30,
    count: 0,

    loading: false,
    loadingOptions: false,
    isInitialized: false,
    isPickerMode: false,
    checkedOnly: false,
    asTree: false,
    treeData: [],

    filterOrder: [],

    columns: columnsPortfolioAnalysisFilter,
    displayedColumns: columnsPortfolioAnalysisFilter
};

export const initialFilesFilterState: IFilterState = {
    currentFilters: {},
    currentSorting: [],
    filterOptions: {},
    pageData: [],
    rowIds: [],
    checkedIds: [],
    selectedRowsData: [],
    selectedRowsIds: [],
    activeRowsData: [],
    activeRowsIds: [],
    
    totalPages: 0,
    perPage: 30,
    count: 0,

    loading: false,
    loadingOptions: false,
    isInitialized: false,
    isPickerMode: false,
    checkedOnly: false,
    asTree: false,
    treeData: [],

    filterOrder: [],

    columns: columnsFilesFilter,
    displayedColumns: columnsFilesFilter
};

const initialState = {
    vFilter: initialVersionFilterState,
    vFilterSelected: initialVersionFilterState,
    group1Filter: initialVersionFilterState,
    group2Filter: initialVersionFilterState,
    costsCompFilter: initialVersionFilterState,
    taxModesFilter: initialTaxModesFilterState,
    macroScenariosFilter: initialMacroScenariosFilterState,
    emissionScenariosFilter: initialEmissionScenariosFilterState,
    costsScenariosFilter: initialCostsScenariosFilterState,
    reportFilter: initialReportFilterState,
    portfolioAnalysisFilter: initialPortfolioAnalysisFilterState,
    filesFilter: initialFilesFilterState
};

const deleteVersions = (versions: ICalcVersion[], ids: [number]) => {
    return [...versions.filter((v) => !ids.includes(v.id))];
};

const deleteVersionIds = (versions: number[], ids: [number]) => {
    return [...versions.filter((v) => !ids.includes(v))];
};

// Функция для составления обновленного массива опций фильтра
const reconcileOptions = (state, newOptions, category, hasBlanks) => {
    let selected = [];
    let excluded = [];

    if (`selected_${category}` in state.currentFilters) {
        selected = state.currentFilters[`selected_${category}`];
    }
    
    if (`excluded_${category}` in state.currentFilters) {
        excluded = state.currentFilters[`excluded_${category}`];
        excluded = excluded.map((id) => {
            const oldOptions = state.filterOptions[category];
            if (oldOptions)
                return oldOptions.find((i) => i.id === id);
            return newOptions.find((i) => i.id === id);
        });
    }

    const combinedOptions = [...newOptions, ...selected, ...excluded];
    const combinedOptionsIds = combinedOptions.map((i) => i.id);

    // Добавление опции (blank) для фильтрации по пустым значениям
    if (hasBlanks) {

        const blankValue = {
            id: '(blank)',
            name: '(blank)'
        }

        if (!combinedOptionsIds.includes(blankValue.id)) {
            combinedOptions.unshift(blankValue);
            combinedOptionsIds.unshift(blankValue.id);
        }
    }

    const uniqueIds = [...new Set(combinedOptionsIds)];
    const reconciledOptions = uniqueIds.map((id) => {
        return combinedOptions.find((i) => i.id === id);
    });

    return reconciledOptions;
};

const getRowData = (state, rowId) => {
    // TODO: Предусмотреть запрос данных если не удалось найти строку по id
    // Возможно, потребуется SET_ACTIVE_ROW_REQUEST
    let result = state.pageData.find((item) => item.id === rowId);
    if (!result) {
        result =  state.selectedRowsData.find((item) => item.id === rowId);
    }

    return result;
};

function onlyExistingCopy(currentObject, importedObject) {
    const newState = Object.assign({...currentObject}, importedObject);
    return newState;
}

// Функция для подготовки опций фильтра из тегов для экрана НР
const prepareTaxModesFilterOptionsFromTags = (filterOptions, column, state, hasBlanks) => {
    const taxModeTags = filterOptions.filter((item) => ['Налоговый режим', 'Tax regime'].includes(item.name.split(':')[0]));
    const taxModeIds = taxModeTags.map((item) => item.id);
    const otherTags = filterOptions.filter((item) => ![...taxModeIds].includes(item.id));

    const taxModes = taxModeTags.map((item) => ({
        id: item.id,
        name: item.name.split(': ')[1],
    }));

    const options = column === 'tags' ? otherTags : taxModes;

    return {
        ...state,
        loadingOptions: false,
        filterOptions: {
            ...state.filterOptions,
            [column]: reconcileOptions(state, options, column, hasBlanks)
        }
    };
}

// Функция для преобразования строк в объекты опций фильтра
const transformStringsToFilterOptions = (filterOptions, column) => {
    const options = filterOptions.map((item) => {
        if (typeof item !== 'object') {
            const option = item.toString();
            return {
                id: option,
                name: option,
                name_short: option,
            };
        } else if (!item.id) {
            return {...item, id: item.value}
        }

        // Удаление названия типа тега для отображения в отдельном столбце
        if (column !== 'tags' && item.value) {
            return {...item, name: item.value};
        }

        return item;
    });

    return options;
}

// Проверка на сброс фильтра по столбцу
const checkWasFilterCleared = (filter, columnName) => {

    if (columnName) {
        const newFilterValues = Object.values(filter);
        const wasCleared = newFilterValues.filter(
            (v: any[]) => v.length
        ).length === 0;
        
        return wasCleared;
    }
    return true;
}

// Проверка на изменение фильтра по столбцу
const checkWasFilterChanged = (state, filter, columnName) => {

    const currentFilters = state.currentFilters;

    if (columnName) {
        const newSelected = filter[`selected_${columnName}`];
        const newExcluded = filter[`excluded_${columnName}`];
        const oldSelected = currentFilters[`selected_${columnName}`] || [];
        const oldExcluded = currentFilters[`excluded_${columnName}`] || [];

        const hasSelectedChanged = !arraysValuesEqual(oldSelected, newSelected, true);
        const hasExcludedChanged = !arraysValuesEqual(oldExcluded, newExcluded, true);
    
        return hasSelectedChanged || hasExcludedChanged;
    }
    return true;
}

const getNewByBlankFilters = (state, filter, columnNames) => {
    const oldFilters = state.currentFilters.by_blank_filters || {};
    const newFilters = {...oldFilters};

    for (const columnName of columnNames) {
        const includeBlanks = filter[`selected_${columnName}`].map(i => i.id).includes('(blank)');
        const excludeBlanks = filter[`excluded_${columnName}`].includes('(blank)');
    
        if (includeBlanks) {
            return {...oldFilters, [columnName]: true};
        } else if (excludeBlanks) {
            return {...oldFilters, [columnName]: false};
        }
        delete newFilters[columnName];
    }

    return newFilters;
}

// Получить новый порядок приоритетности фильтров
const getNewFilterOrder = (state, filter, columnNames) => {

    const oldFilterOrder = state.filterOrder;
    const newFilterOrder = [...oldFilterOrder];

    for (const columnName of columnNames) {
        const hasColumnFilterCleared = checkWasFilterCleared(filter, columnName);
        const hasColumnFilterChanged = checkWasFilterChanged(state, filter, columnName);
    
        if (hasColumnFilterCleared || hasColumnFilterChanged) {
            const index = newFilterOrder.indexOf(columnName);
            if (index > -1) newFilterOrder.splice(index, 1);
        }
    
        if (hasColumnFilterChanged && !hasColumnFilterCleared) {
            newFilterOrder.push(columnName);
        }
    }

    return newFilterOrder;
}

// Получить уникальные названия столбцов из критериев фильтра
const getColumnNamesByFilters = (filter) => {
    const filterKeys = Object.keys(filter);
    const columnNames = filterKeys.map(
        (key) => key.replace('excluded_', '').replace('selected_', ''));

    const uniqueColumnNames = [...new Set(columnNames)];
    return uniqueColumnNames;
}

// Получаем новый порядок сортировки
const getNewSorting = (state, sorting) => {
    const oldSorting = state.currentSorting;
    const newSorting = [...oldSorting];
    const columnNames = oldSorting.map((col) => col.name);
    
    for (const sort of sorting) {
        const colName = sort.name;
        if (columnNames.includes(colName)) {
            const index = newSorting.findIndex((sortItem) => sortItem.name === colName);
            if (index > -1) newSorting.splice(index, 1);
        }
        sort.value && newSorting.push(sort);
    }
    
    return newSorting;
}


function createTableSelectorWithNameData(selectorName = '') {
    return (state = initialState[selectorName], action) => {
        
        const {filterName} = action.payload ? action.payload : '';
        
        if (filterName !== selectorName) return state;

        switch (action.type) {
            case FilterActionType.GET_FILTER_DATA_REQUEST: {
                let tmp = {
                    ...state,
                    loading: true
                };
                return tmp;
            }
            case FilterActionType.GET_FILTER_DATA_SUCCESS: {

                const {pageData, selectedData, rowIds, isPickerMode} = action.payload;

                let tmp = {
                    ...state,
                    pageData: pageData.results,
                    isInitialized: true,
                    rowIds: rowIds,
                    loading: false,
                    totalPages: Math.ceil(pageData.count / state.perPage),
                    count: pageData.count
                };

                if (isPickerMode) {
                    tmp.selectedRowsData = selectedData.results;
                    tmp.selectedRowsIds = selectedData.results.map((row) => row.id);
                }

                return tmp;
            }
            case FilterActionType.SET_COLUMN_FILTER: {

                const {filter, sorting} = action.payload;

                const columnNames = getColumnNamesByFilters(filter);
                const newFilterOrder = getNewFilterOrder(state, filter, columnNames); 
                const newByBlankFilters = getNewByBlankFilters(state, filter, columnNames);

                let tmp = {
                    ...state,
                    currentFilters: {
                        ...state.currentFilters,
                        ...action.payload.filter,
                        by_blank_filters: newByBlankFilters
                    },
                    currentSorting: getNewSorting(state, sorting), // Применяем сортировку
                    loading: true,
                    filterOrder: newFilterOrder
                };

                localStorage.setItem(`filter_${filterName}`, JSON.stringify({...tmp}));
                return tmp;
            }
            case FilterActionType.SET_DISPLAYED_COLUMNS: {
                const newColumns = action.payload.newColumns;

                let tmp = {
                    ...state,
                    displayedColumns: newColumns
                };
                localStorage.setItem(`filter_${filterName}`, JSON.stringify({...tmp}));
                return tmp;
            }
            case FilterActionType.SET_ACTIVE_ROWS: {
                const rowsIds = action.payload.rowsIds;
                let tmp = {
                    ...state,
                    activeRowsIds: [...rowsIds],
                    activeRowsData: rowsIds.map((rowId) => getRowData(state, rowId))
                };
                localStorage.setItem(`filter_${filterName}`, JSON.stringify({...tmp}));
                return tmp;
            }
            case FilterActionType.ADD_TO_SELECTED_ROWS: {
                const rowId = action.payload.rowId;
                const selectedRowIds = state.selectedRowsIds;
                const newSelectedRowIds = selectedRowIds.includes(rowId)
                    ? selectedRowIds
                    : [...selectedRowIds, rowId];

                let tmp = {
                    ...state,
                    checkedIds: newSelectedRowIds,
                    selectedRowsIds: newSelectedRowIds,
                    selectedRowsData: newSelectedRowIds.map((id) =>
                        getRowData(state, id)
                    ).filter(Boolean),
                    activeRowsIds: [rowId],
                    activeRowsData: [getRowData(state, rowId)]
                };
                localStorage.setItem(`filter_${filterName}`, JSON.stringify({...tmp}));
                return tmp;
            }
            case FilterActionType.SET_SELECTED_ROWS: {
                let tmp = {
                    ...state,
                    selectedRowsIds: action.payload.rowIds,
                    selectedRowsData: action.payload.rowIds
                        .map((rowId) => getRowData(state, rowId))
                        .filter(Boolean)
                };
                localStorage.setItem(`filter_${filterName}`, JSON.stringify({...tmp}));
                return tmp;
            }
            case FilterActionType.SET_SEARCH: {
                let tmp = {
                    ...state,
                    currentFilters: {
                        ...state.currentFilters,
                        search: action.payload.value,
                    }
                };
                return tmp;
            }
            case FilterActionType.DELETE_FILTER_ROWS_SUCCESS: {
                return {
                    ...state,
                    pageData: deleteVersions(state.pageData, action.payload.ids),
                    rowIds: deleteVersionIds(state.rowIds, action.payload.ids),
                    checkedIds: deleteVersionIds(state.checkedIds, action.payload.ids),
                    selectedRowsIds: deleteVersionIds(state.selectedRowsIds, action.payload.ids),
                    activeRowsIds: deleteVersionIds(state.activeRowsIds, action.payload.ids)
                };
            }
            case FilterActionType.SET_ACTIVE_ROWS_SUCCESS: {
                let tmp = {
                    ...state,
                    loading: false,
                    activeRowsIds: [action.payload.rowData.id],
                    activeRowsData: [getRowData(state, action.payload.rowData.id)]
                };
                return tmp;
            }
            case FilterActionType.CLEAR_ACTIVE_ROWS: {
                let tmp = {
                    ...state,
                    loading: false,
                    activeRowsIds: [],
                    activeRowsData: []
                };
                return tmp;
            }
            case FilterActionType.LOAD_FILTER: {
                return {
                    ...state,
                    currentFilters: onlyExistingCopy(state.currentFilters, action.payload.filter.currentFilters),
                    currentSorting: action.payload.filter.currentSorting,
                    filterOptions: action.payload.filter.filterOptions,
                    checkedIds: action.payload.filter.checkedIds,
                    selectedRowsData: action.payload.filter.selectedRowsData,
                    selectedRowsIds: action.payload.filter.selectedRowsIds,
                    activeRowsData: action.payload.filter.activeRowsData,
                    activeRowsIds: action.payload.filter.activeRowsIds,
                    perPage: action.payload.filter.perPage,
                    asTree: action.payload.filter.asTree,
                    treeData: action.payload.filter.treeData,
                    filterOrder: action.payload.filter.filterOrder,
                    displayedColumns: action.payload.filter.displayedColumns
                };
            }
            case FilterActionType.GET_FILTER_OPTIONS_REQUEST: {
                let tmp = {
                    ...state,
                    loadingOptions: true
                };
                return tmp;
            }
            case FilterActionType.GET_FILTER_OPTIONS_SUCCESS: {
                const {column, options, hasBlanks, haveBlanks} = action.payload;

                let filterOptions = [...options];
                let _hasBlanks = haveBlanks ? haveBlanks[column] : hasBlanks;

                // Если данные берутся из тегов, распределить их по типам и записать в соответствующие ключи в store
                if (filterName === 'taxModesFilter' && ['tags', 'tax_modes'].includes(column)) {
                    const tmp = prepareTaxModesFilterOptionsFromTags(filterOptions, column, state, _hasBlanks);
                    return tmp; 
                }

                // Фикс формата даты в опциях фильтра столбца "Создано"
                if (column === 'created_at') {
                    filterOptions = filterOptions.map((item) => (
                        formatDateTime(item, 'YYYY-MM-DD', 'DD.MM.YYYY')
                    ));
                }

                // Преобразование в массив объектов с необходимыми свойствами для корректного рендеринга фильтров
                const filterOptionsNormalized = transformStringsToFilterOptions(filterOptions, column);

                let tmp = {
                    ...state,
                    loadingOptions: false,
                    filterOptions:{
                        ...state.filterOptions,
                        [column]: reconcileOptions(state, filterOptionsNormalized, column, _hasBlanks)
                    }
                };

                return tmp;
            }
            case FilterActionType.GET_FILTER_OPTIONS_ERROR: {
                let tmp = {
                    ...state,
                    loadingOptions: false
                };
                return tmp;
            }
            case FilterActionType.SET_AS_TREE: {
                const tmp = {
                    ...state, 
                    asTree: action.payload.asTree
                };
                localStorage.setItem(`filter_${filterName}`, JSON.stringify({...tmp}));
                return tmp;
            }
            case FilterActionType.RESET_FILTERS: {
                let tmp = {
                    ...state,
                    currentFilters: {},
                    currentSorting: [],
                    filterOrder: []
                };
                localStorage.removeItem(`filter_${filterName}`);
                return tmp;
            }
            case FilterActionType.HIERARCHY_TREE_REQUEST: {
                let tmp = {
                    ...state,
                    loading: true,
                    treeData: []
                };
                return tmp;
            }
            case FilterActionType.HIERARCHY_TREE_SUCCESS: {
                let tmp = {
                    ...state,
                    loading: false,
                    treeData: action.payload.data.version_hierarchy
                };
                localStorage.setItem(`filter_${filterName}`, JSON.stringify({...tmp}));
                return tmp;
            }
            case FilterActionType.SET_ROWS_PER_PAGE: {
                let tmp = {
                    ...state,
                    totalPages: Math.ceil(state.count / action.payload.perPage),
                    perPage: action.payload.perPage
                };
                localStorage.setItem(`filter_${filterName}`, JSON.stringify({...tmp}));
                return tmp;
            }
            case FilterActionType.SET_FILTER_CHECKED_IDS: {
                let tmp = {
                    ...state,
                    checkedIds: action.payload.checkedIds,
                };
                return tmp;
            }
            case FilterActionType.SET_CHECKED_ONLY: {
                let tmp = {
                    ...state,
                    checkedOnly: action.payload.checkedOnly,
                };
                return tmp;
            }
            case FilterActionType.SET_IS_PICKER_MODE: {
                let tmp = {
                    ...state,
                    isPickerMode: action.payload.isPickerMode,
                };
                return tmp;
            }
            default: {
                return state;
            }
        }
    };
}

export {createTableSelectorWithNameData};
