import { getConfig } from '../config-provider';

import { requestSnackDisplay } from "../store/snackbars/actions";
import axios from '../axiosMiddleware';
import i18n from '../i18n';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

export const getCookieByName = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const deleteCookieByName = name => {
    document.cookie = name+'=; Max-Age=-99999999;';
}

export const sanitizeToken = token => token.replace(/[^\w|/|-]/g, '');

export function downloadUsingToken(body, dispatcher, to_clipboard = false) {
    const url = `${API_ENDPOINT}/download/issue-download-token`;

    axios.post(url, body, { headers: { 'Content-Type': 'application/json' } }).then(
        (res) => {
            const token = sanitizeToken(res.data["token"]);
            const url = `${API_ENDPOINT}/download/${token}/`; // ?language=${language}

            if (to_clipboard) {
                var textArea = document.createElement("textarea");
                textArea.value = url;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    var successful = document.execCommand('copy');
                    successful ?
                        dispatcher(requestSnackDisplay('success', i18n.t('notifications:copyLink.success'))) :
                        dispatcher(requestSnackDisplay('error', i18n.t('notifications:copyLink.error')));
                } catch (err) {
                    dispatcher(requestSnackDisplay('error', i18n.t('notifications:copyLink.error')));
                }
                document.body.removeChild(textArea);

            } else {
                window.open(url, '_blank');
            }
        },
        (res) => {
            errorHanding(res, dispatcher)
        }
    )
}

function errorHanding(res, dispatcher) {
    try {
        if (res.request.response) {
            const errorLoad = JSON.parse(res.request.response)
            for (let possibleError of ['detail', 'details', 'message', 'error', 'errors', 'error_slug', 'slug']) {
                if (errorLoad[possibleError]) {
                    dispatcher(requestSnackDisplay('error', errorLoad[possibleError]));
                    return 0;
                }
            }
        }
        dispatcher(requestSnackDisplay('error', res.message));
    } catch (er) {
        dispatcher(requestSnackDisplay('error', 'Error while handling error'))
    }
}

export const arraysValuesEqual = (arr1: [], arr2: [], sort = false) => {

    const checkAreValuesEqual = (a1, a2) => {
        for (let i = 0; i < a1.length; i++) {
            if (a1[i] !== a2[i]) {
                return false;
            }
        }
        return true;
    }

    // compare lengths - can save a lot of time 
    if (arr1.length !== arr2.length)
        return false;

    if (sort) {
        const array1 = [...arr1].sort();
        const array2 = [...arr2].sort();
        return checkAreValuesEqual(array1, array2);
    }

    return checkAreValuesEqual(arr1, arr2);
};