import {ISnackbarsState, SnackbarsActionType} from './types'
import {Reducer} from "redux";

export const initialState: ISnackbarsState = {
    snackbarOpen: false,
    snackbarType: null,
    snackbarMessage: null
};
const reducer: Reducer<ISnackbarsState> = (state = initialState, action) => {
    switch (action.type) {
        case SnackbarsActionType.SHOW_SNACKBAR: {
            return {
                ...state,
                snackbarOpen: true,
                snackbarType: action.payload.type,
                snackbarMessage: action.payload.message
            };
        }
        case SnackbarsActionType.HIDE_SNACKBAR: {
            return {
                ...state,
                snackbarOpen: false,
            };
        }
        default: {
            return state;
        }
    }
};

export {reducer as snackbarsReducer}