import React, {useRef, useEffect, useContext} from 'react';
import styled from 'styled-components/macro';
import {CloseIcon, FullscreenExpandIcon, FullscreenShrinkIcon} from '../Design/Icons/IconsOther';
import {useClickAway} from 'ahooks';
import {useHistory, Route} from 'react-router';
import {DialogContext} from "../../layouts/root-layout/RootLayout";

type PopupProps = {
    children: React.ReactNode;
    handleClosePopup: any;
    className?: any;
    header: string;
    closeOutside?: boolean;
    allowFullscreen?: boolean;
    isFullscreen?: boolean;
    handleChangeFullscreenMode?: any;
};

export const Popup: React.FC<PopupProps> = ({
    children,
    handleClosePopup,
    header,
    className,
    closeOutside = true,
    allowFullscreen = false,
    isFullscreen = false,
    handleChangeFullscreenMode,
}) => {
    const ref = useRef();
    const history = useHistory();
    const { setIsDialogOpen } = useContext(DialogContext)

    useClickAway((e) => {
        // TODO: Can someone from litota check it's workability on ConfigureSimulationDialogue screen?
        // Seems like a defective hook
        //if (closeOutside)
        //    handleClosePopup();
    }, ref);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        setIsDialogOpen(true)
        return () => (document.body.style.overflow = 'auto');
        //eslint-disable-next-line
    }, []);

    const closePopup = () => {
        handleClosePopup();
        setIsDialogOpen(false)
        history.push({
            hash: ''
        });
    }

    useEffect(() => {
        history.push({
            hash: 'dialog'
        });

        return history.listen((location, action) => {
            if (action === "POP") handleClosePopup()
        })
      //eslint-disable-next-line
    }, []);

    if (!children) return;

    return (
        <Route
            path={'asd'}
            children={({ match }) => {
            return (
                <ContainerWrapper className={className}>
                    <Wrapper className="popup" ref={ref} fullscreen={allowFullscreen && isFullscreen}>
                        <HeaderWrapper>
                            <Header>{header}</Header>
                            <IconsWrapper>
                                {allowFullscreen ? (
                                    isFullscreen ? (
                                        <div onClick={handleChangeFullscreenMode}>
                                            <IconWrapper>
                                                <FullscreenShrinkIcon />
                                            </IconWrapper>
                                        </div>
                                    ) : (
                                        <div onClick={handleChangeFullscreenMode}>
                                            <IconWrapper>
                                                <FullscreenExpandIcon />
                                            </IconWrapper>
                                        </div>
                                    )
                                ) : (
                                    ''
                                )}
                                <div onClick={closePopup}>
                                    <IconWrapper>
                                        <CloseIcon />
                                    </IconWrapper>
                                </div>
                            </IconsWrapper>
                        </HeaderWrapper>
                        {children}
                    </Wrapper>
                </ContainerWrapper>
            )}
            } />
    );
};

const ContainerWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 36, 0.4);
    z-index: 1003;
`;

const Wrapper = styled.div`
    padding: 35px 30px;
    height: ${(props) => (props.fullscreen ? '100% !important;' : undefined)};
    width: ${(props) => (props.fullscreen ? '100% !important;' : '550px')};
    max-height: ${(props) => (props.fullscreen ? '100% !important;' : '90%')};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 12px 24px rgba(26, 26, 36, 0.4), 0 2px 8px rgba(26, 26, 36, 0.2);
    overflow-x: hidden;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Header = styled.h2`
  font-family: 'EYInterstate Bold', sans-serif;
`;

const IconWrapper = styled.div`
    img {
        width: 15px;
        height: 15px;
    }
    padding-left: 15px;
    cursor: pointer;
`;

const IconsWrapper = styled.div`
    display: flex;
`;
