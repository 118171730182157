import {action} from 'typesafe-actions';
import {IPlotRequest, IPlotResult, IPlotSettings, PlotActionType} from './types';

export const plotChangeSettings = (newSettings: Partial<IPlotSettings>) =>
    action(PlotActionType.CHANGE_SETTINGS, newSettings);

export const plotRequestAction = (request: IPlotRequest) =>
    action(PlotActionType.PLOT_REQUEST, request);

export const plotSuccess = (data: IPlotResult, content_type) =>
    action(PlotActionType.PLOT_SUCCESS, {data, content_type});

export const plotError = (message: string) => action(PlotActionType.PLOT_ERROR, message);
