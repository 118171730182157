import {Reducer} from 'redux';
import {IUpload, IUploadState, UploadActionType} from "./types";

export const initialState: IUploadState = {
    uploads: [],
    loading: false,
    error: null
};

const deleteUpload = (uploads: IUpload[], id: number) => {
    return [...uploads.filter(s => s.id !== id)]
};

const reducer: Reducer<IUploadState> = (state = initialState, action): IUploadState => {
    switch (action.type) {
        case UploadActionType.GET_UPLOADS_LIST_REQUEST: {
            return {...state, loading: true}
        }
        case UploadActionType.GET_UPLOADS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                uploads: [...action.payload.uploads]
            }
        }
        case UploadActionType.GET_UPLOADS_LIST_ERROR: {
            return {
                ...state, error: action.payload
            }
        }
        case UploadActionType.UPLOAD_CREATE_SUCCESS: {
            return {...state}
        }
        case UploadActionType.UPLOAD_CREATE_ERROR: {
            return {...state, error: action.payload}
        }
        case UploadActionType.DELETE_UPLOAD: {
            return {...state, uploads: deleteUpload(state.uploads, action.payload.id)}
        }
        default: {
            return state
        }

    }
};

export {reducer as uploadsReducer}