import styled, {css} from 'styled-components/macro';
import {bigSidebarWidth, minSidebarWidth} from '../../layouts/root-layout/root-layout.styles';
import {getConfig} from "../../config-provider";

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

const bigSidebar = ({isBig}) =>
    isBig &&
    css`
        width: ${bigSidebarWidth}px;
    `;

export const SidebarWrapper = styled.div`
    // border: 2px dashed blue;
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: ${minSidebarWidth}px;
    height: 100%;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.light};
    transition: ${(props) => props.theme.transition.min};
    padding: 20px 0 0 0;
    z-index: 1002;

    ${bigSidebar}
`;

export const ListMenuWrapper = styled.div`
    // border: 1px dashed red;
    margin-top: 25px;
    flex: 1;
    flex-basis: 100%;
    overflow: hidden;
    box-sizing: border-box;
`;

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    // border: 2px dashed yellow;
`;

export const LogoImage = styled.img`
    width: 35px;
    height: 35px;
`;

export const ListMenu = styled.ul`
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 1px solid ${(props) => props.theme.colors.grey1};
    overflow: hidden;
    padding-top: 6px;

    // @media screen and (max-height: 1000px) {
    //     margin-top: 10px;
    //     padding-top: 3px;
    // }
`;

const activeState = css`
    background-color: ${(props) => props.theme.colors.grey1};
    color: ${(props) => props.theme.colors.light};

    > img {
        filter: grayscale(1) brightness(1000%);
    }
`;

const hoverBorder = css`
    box-sizing: border-box;
    border-left: 2px solid ${(props) => props.theme.colors.yellow} !important;
`;

const activeItem = ({isActive}) =>
    isActive &&
    css`
        ${activeState};
        ${hoverBorder}
    `;

const activeHover = css`
    &:hover {
        ${hoverBorder};

        cursor: pointer;
        background-color: ${(props) => props.theme.colors.grey1};
        color: ${(props) => props.theme.colors.light};

        img {
            filter: grayscale(1) brightness(1000%);
        }
    }
`;

const itemMenuStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 12px;
    transition: ${(props) => props.theme.transition.min};
    color: ${(props) => props.theme.colors.grey2};
    border-left: 2px solid ${(props) => props.theme.colors.black};

    @media screen and (max-height: 1000px) {
        padding: 6px 12px;
    }
`;

const minListItem = ({isBig}) =>
    !isBig &&
    css`
        padding: 15px 0;
    `;

export const ListItem = styled.li`
    display: ${props => !props.isCollapseMenuOpen && props.isHidden ? 'none !important' : 'flex'};
    flex-direction: ${props => props.isCollapseMenuOpen && props.isHidden ? 'row !important' : 'column'};
    // position: ${props => props.isCollapseMenuOpen && props.isHidden ? 'absolute !important' : 'relative'};
    left: ${props => props.isCollapseMenuOpen && props.isHidden ? '100px !important' : undefined};
    bottom: ${props => props.isCollapseMenuOpen && props.isHidden ? '0px !important' : undefined};
    position: relative;
    background: ${props => props.isCollapseMenuOpen && props.isHidden ? '#000' : undefined};
  
    ${itemMenuStyle};
    ${activeItem};
    ${activeHover};
    ${minListItem};
    //border: 1px dashed cyan;
    img {
        width: 21px;
        height: 21px;
    }
`;

export const ItemText = styled.span`
    margin-top: 4px;
    font-size: 12px;
    text-align: center;

    @media screen and (max-height: 1000px) {
        margin-top: 3px;
    }
`;

export const ControlPanelWrapper = styled.div`
    text-align: center;
    width: 100%;
    justify-self: flex-end;
`;

export const IconWrapper = styled.div`
    ${itemMenuStyle};
    ${activeHover};

    img {
        ${(props) =>
            !props.isActive &&
            css`
                transform: rotate(180deg);
            `};
    }

    @media screen and (max-height: 1000px) {
        padding: 4px 12px;
    }
`;

export const UserInfoWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    background-color: ${(props) => props.theme.colors.grey1};
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: 15px 0;
    border-left: 2px solid transparent;

    &:hover {
        ${hoverBorder}
    }
`;

export const UserImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 30px;
    height: 30px;
    background-color: ${(props) => props.theme.colors.green};
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.white};
`;

export const UserName = styled.span`
    padding-top: 8px;
    font-size: 12px;
    color: ${(props) => props.theme.textColor.lightGrey};
`;

export const ContainerWrapper = styled.div`
    padding: 0 20px 20px;
`

export const UserMenu = styled.div`
    position: absolute;
    bottom: 0;
    flex-direction: column;
    z-index: 5;
    display: none;
    width: 200px;
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.black};
    font-size: 14px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.35);
    transition: ${(props) => props.theme.transition.min};

    left: ${(props) => (props.isBig ? `${bigSidebarWidth + 7}px` : `${minSidebarWidth + 12}px`)};
    ${(props) =>
        props.isShow &&
        `display: flex; 
    `};

    :before {
        content: '';
        position: absolute;
        left: -5px;
        width: 10px;
        height: 10px;
        background: ${(props) => props.theme.colors.black};
        top: 50%;
        transform: translateY(-50%) rotate(135deg);
    }
`;

export const TopContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey2};

    ${UserImage} {
        font-size: 16px;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
    }
`;

export const UserDescription = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    ${UserName} {
        color: ${(props) => props.theme.colors.white};
        font-size: 16px;
    }
`;

export const UserStatus = styled.span`
    margin-top: 4px;
    color: ${(props) => props.theme.colors.grey2};
    font-size: 13px;
`;

export const MiddleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: self-start;
    padding: 12px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey2};
    color: ${(props) => props.theme.colors.grey4};
    font-size: 12px;

    a {       
        cursor: pointer;
        color: #fff;
        margin-top: 7px;

        &:hover {
            color: ${(props) => props.theme.colors.yellow};
        }
    }
`;

export const UserLogoWrapper = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 18px;
`;

export const UserLogo = styled.div`
  background: url(${API_ENDPOINT}/user/company_logo.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 50px;
  margin: 15px 0  16px;
`;

export const VersionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  span {
    font-family: 'EYInterstate Light',sans-serif;
  }
`

export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0 0 0;
    color: ${(props) => props.theme.colors.black};   
`;

export const LogoutText = styled.span`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.grey4};

    img {
        filter: grayscale(1) brightness(1000%);
    }

    &:hover {
        color: ${(props) => props.theme.colors.yellow};

        img {
            filter: none;
        }
    }
`;

export const SidebarControl = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${(props) => (props.isBigSidebar ? 'row' : 'column')};
    justify-content: space-between;
    margin: 2px 0;
`;

export const ControlContainer = styled.div`
    background-color: ${(props) => props.theme.colors.grey1};
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center ;
    align-items: center;
    cursor: pointer;
    border-left: 2px solid transparent;
  
    ${activeItem};

    &:nth-child(1) {
        margin: ${(props) => (props.isBigSidebar ? '0 1px 0 0' : '0 0 1px 0')};
    }

    &:nth-child(2) {
        margin: ${(props) => (props.isBigSidebar ? '0 1px 0 0' : '1px 0 0 0')};
    }

    &:hover {
        ${hoverBorder};

        img {
            filter: grayscale(1) brightness(1000%);
        }
    }
`;

export const TaskStatsChipContainer = styled.div`
  background-color: ${props => props.theme.colors.grey1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  margin: 2px 3px 2px;
`

export const TaskStatsChip = styled.div`
  font-size: 9px;
  color: ${props => props.highlight ? props.theme.colors.yellow : props.theme.colors.lightGrey};
  flex: 1;
`

export const ShowMoreIconWrapper = styled(IconWrapper)`
  opacity: ${props => props.isTransparent ? 0 : 100};
  position: relative;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
`;

export const MenuCollapseWrapper = styled.ul`
  display: ${props => props.isCollapseMenuOpen ? 'block' : 'none'};
  position: absolute;
  left: ${props => props.isBigSidebar ? '107px' : '45px'};
  width: ${props => props.isBigSidebar ? '230px' : '46px'};
  bottom: 115px;
  background: #000;
  padding: 10px 0;
  z-index: 999;

  :before {
    content: '';
    position: absolute;
    left: -5px;
    width: 10px;
    height: 10px;
    background: #000;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
  }

  ${ListItem} {
    flex-direction: row;
    border-left-color: transparent;
    align-items: end;

    img, svg {
      width: 19px;
      height: 19px;
      margin-right: 12px;
    }

    span {
      text-align: left;
    }
  }
`;
