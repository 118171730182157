import { Reducer } from 'redux'
import { DemoPlotActionType, IDemoPlotsState, IPlot } from './types'
import { FilterActionType } from "../tableSelector/types";

export const initialDemoPlotState: IDemoPlotsState = {
    plots: new Map<string, IPlot[]>(),
    error: new Map<any, any>(),
    loading: new Map<string, boolean>(),
    reportGroup: "PROD"
};

const addData = (prevData: Map<string, IPlot[]>, name, newData: IPlot[]) => {
    return new Map(prevData.set(name, newData))
};

const changeLoading = (prevState: Map<string, boolean>, name, newLoading) => {
    return new Map(prevState.set(name, newLoading))
};

function _unsetM(m: Map<string, any>, v: string) {
    let tmp = new Map(m);
    tmp.delete(v);
    return tmp
}

export const getPlotNameWithSuffix = (name, urlArgs) => {

    // Добавить суфикс если встречаются несколько 
    // графиков с одинаковым именем на одной странице 
    if (urlArgs) {
        const suffix = new URLSearchParams(urlArgs).toString();
        name = `${name}__${suffix}`;
    }

    return name
}

export const demoPlotsReducer: Reducer<IDemoPlotsState> = (state = initialDemoPlotState, action): IDemoPlotsState => {
    switch (action.type) {
        case DemoPlotActionType.INVALIDATE_PLOT: {

            let { name, urlArgs } = action.payload;

            name = getPlotNameWithSuffix(name, urlArgs);

            return {
                ...state,
                loading: _unsetM(state.loading, name),
                plots: _unsetM(state.plots, name),
            }
        }
        case DemoPlotActionType.GET_DEMO_PLOT_REQUEST: {

            let { name, urlArgs } = action.payload;

            name = getPlotNameWithSuffix(name, urlArgs);

            return {
                ...state,
                loading: changeLoading(state.loading, name, true)
            }
        }
        case DemoPlotActionType.GET_DEMO_PLOT_SUCCESS: {

            let { name, urlArgs } = action.payload;

            name = getPlotNameWithSuffix(name, urlArgs);

            return {
                ...state,
                plots: addData(state.plots, name, action.payload),
                loading: changeLoading(state.loading, name, false)
            }
        }
        case DemoPlotActionType.GET_DEMO_PLOT_ERROR: {

            let { name, urlArgs } = action.payload;

            name = getPlotNameWithSuffix(name, urlArgs);

            return {
                ...state,
                error: addData(state.error, name, action.payload.loadingError),
                loading: changeLoading(state.loading, action.payload.name, false)
            }
        }
        case FilterActionType.SET_ACTIVE_ROWS: {
            return {
                ...initialDemoPlotState
            }
        }
        case DemoPlotActionType.SET_REPORT_GROUP: {
            return {
                plots: new Map<string, IPlot[]>(),
                error: new Map<any, any>(),
                loading: new Map<string, boolean>(),
                reportGroup: action.payload.name
            }
        }
        default: {
            return state
        }
    }
};
