import {action} from 'typesafe-actions'
import {ISetWellStateResponse, IWells, WellsActionType} from './types'

export const getWellsRequest = (versionId: number) => action(WellsActionType.GET_WELLS_REQUEST, {versionId});
export const getWellsSuccess = (data: IWells) => action(WellsActionType.GET_WELLS_SUCCESS, {data});
export const getWellsError = (loadingError: any) => action(WellsActionType.GET_WELLS_ERROR, {loadingError});

export const setWellStateRequest = (versionId: number, well: string, state: boolean) => action(WellsActionType.SET_WELL_STATE_REQUEST, {
    versionId,
    well,
    state
});
export const setWellStateSuccess = (resp: ISetWellStateResponse) => action(WellsActionType.SET_WELL_STATE_SUCCESS, {resp});
export const setWellStateError = (loadingError: any) => action(WellsActionType.SET_WELL_STATE_ERROR, {loadingError});
