import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';
import {PopupItem} from '../../../Popup/popup.styles';
import {FlexContainer} from '../../../../theme/main.styles';
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from '../../../../store';
import {IVersionGroup} from '../../../../store/versionGroup/types';
import {createVersionGroupRequest} from '../../../../store/versionGroup/actions';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {InputField} from '../../../Design/InputField';

const SummaryComponent = ({initialParameters, handleDelete}) => {
    function buildSummary(value) {
        return value.name;
    }

    return (
        <PopupItemFlex>
            {initialParameters.length > 0 &&
            initialParameters.map((value, index) => {
                return (
                    <ListItem key={index}>
                        {buildSummary(value)}
                        <DeleteIcon onClick={() => handleDelete(index, value)} />
                    </ListItem>
                );
            })}
        </PopupItemFlex>
    );
};

export const VersionGroupComponent = ({initialParameters, setSimulationState, fullscreenMode, selection}) => {
    const {t} = useTranslation(['components', 'commons', 'pages']);
    const dispatch = useDispatch();

    const versionGroupList = useSelector<ApplicationState, IVersionGroup[]>(
        (store) => store.versionGroupList.versionGroups,
    );
    const [versionGroupName, setVersionGroupName] = useState('');
    const [isCreateInputVisible, setCreateInputVisible] = useState(false);

    useEffect(() => {
        const versionsLength = versionGroupList.length;
        if (versionsLength && versionGroupName.length > 0) {
            onAddItem(versionGroupList[versionsLength - 1].name);
            setVersionGroupName('');
        }
        //eslint-disable-next-line
    }, [versionGroupList]);

    function updateSimulationState(groups) {
        setSimulationState((s) => ({
            ...s,
            groups,
        }));
    }

    function handleClickDeleteItem(index, value) {
        initialParameters.splice(index, 1);
        updateSimulationState(initialParameters);
        versionGroupList[value.index].hidden = false;
    }

    function handleClickCreateItem() {
        dispatch(createVersionGroupRequest(versionGroupName));
        setCreateInputVisible(false);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClickCreateItem();
        }
    };

    function handleUpdateVersionGroupName(e) {
        var value = e.target.value;
        setVersionGroupName(value);
    }

    function onAddItem(versionName) {
        const selected = versionGroupList.find((s) => s.name === versionName);

        if (selected != null && !initialParameters.includes(selected)) {
            const selectedIndex = versionGroupList.indexOf(selected);
            const selectedWithIndex = {...selected, index: selectedIndex};
            initialParameters.push(selectedWithIndex);
            versionGroupList[selectedIndex].hidden = true;
            updateSimulationState(initialParameters);
        }
    }


    return (
        <>
            <ContentWrapper>
                <LeftColumn>

                    <ColumnHeader>
                        {t('pages:simulationSettings.versionGroup.group_list', 'Список доступных групп')}
                    </ColumnHeader>

                    <ColumnContentLeft>
                        <PopupItemFlex>
                            {versionGroupList.map((version, index) => (
                                <ListItem
                                    onClick={() => onAddItem(version.name)}
                                    hidden={version.hidden}
                                    key={index}
                                >
                                    {version.name}
                                </ListItem>
                            ))}
                        </PopupItemFlex>
                    </ColumnContentLeft>
                </LeftColumn>

                <RightColumn>

                    <ColumnHeader>
                        {t('pages:simulationSettings.versionGroup.configuration', 'Конфигурация групп')}
                    </ColumnHeader>

                    <ColumnContentRight>
                        
                        <AttributesSummaryWrapper>
                            <SummaryComponent
                                initialParameters={initialParameters}
                                handleDelete={handleClickDeleteItem}
                            />
                        </AttributesSummaryWrapper>
                    
                        <AttributeCreateWrapper>
                            <FlexCreate>
                                {!isCreateInputVisible ? (
                                    <ToggleCreateInputButton
                                        onClick={() => setCreateInputVisible(true)}
                                    >
                                        {t(
                                            'pages:simulationSettings.versionGroup.create',
                                            'Создать новую группу',
                                        )}
                                    </ToggleCreateInputButton>
                                ) : (
                                    <CreateControlsWrapper>
                                        <InputField
                                            value={versionGroupName}
                                            onChange={handleUpdateVersionGroupName}
                                            onKeyDown={handleKeyDown}
                                            placeholder={t(
                                                'pages:simulationSettings.versionGroup.create',
                                                'Создать новую группу',
                                            )}
                                        />
                                        <FlexCreateIcons>
                                            <AddItemIcon onClick={handleClickCreateItem} />
                                            <CloseEditIcon
                                                onClick={() => setCreateInputVisible(false)}
                                            />
                                        </FlexCreateIcons>
                                    </CreateControlsWrapper>
                                )}
                            </FlexCreate>
                        </AttributeCreateWrapper>
                    </ColumnContentRight>

                </RightColumn>


            </ContentWrapper>
        </>
    );
};

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const LeftColumn = styled.div`
    width: 100%;
    margin-right: 5px;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 5px;
`;

const ColumnContentLeft = styled.div`
    height: calc(100% - 42px);
    overflow: auto;
`;

const ColumnContentRight = styled.div`
    height: calc(100% - 42px);
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const AttributesSummaryWrapper = styled.div`
    overflow: auto;
    height: 100%;
`;

const AttributeCreateWrapper = styled.div`
    height: 50px;
`;


const ColumnHeader = styled.div`
  background: ${(props) => props.theme.colors.black};
  font-size: 16px;
  line-height: 20px;
  height: 38px;
  color: #fff;
  padding: 4px 4px 4px 16px;
  box-sizing: border-box;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

export const ListItem = styled.div`
  background-color: ${props => props.theme.colors.grey4};
  padding: 11px 16px;
  color: #2e2e38;
  width: calc(50% - 2px);
  overflow-x: hidden;
  margin-bottom: 4px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  user-select: none;
  font-size: 14px;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey3};
  }
`;

export const PopupItemFlex = styled(PopupItem)`
  overflow:
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
`;

export const ToggleCreateInputButton = styled.button`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.grey1};
  position: relative;
  background: none;
  border: 0;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 5px;

  &::before {
    content: '+';
    margin-right: 5px;
  }
`;

export const FlexCreate = styled(FlexContainer)`
  display: flex;
  align-items: center;
  margin-top: auto;
  justify-content: space-between;
  padding-top: 12px;
  border-top: 1px solid #cdcdd1;
`;

export const FlexCreateIcons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
`;

export const CreateControlsWrapper = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;

  & input {
    width: 100%;
    height: 32px;
  }
`;

export const AddItemIcon = styled(CheckIcon)`
  background: ${(props) => props.theme.colors.green};
  border-radius: 50%;
  fill: #fff;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  padding: 1px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const CloseEditIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  fill: ${(props) => props.theme.colors.grey1};
  cursor: pointer;
`;

export const DeleteIcon = styled(CloseIcon)`
  position: absolute;
  right: 5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;
