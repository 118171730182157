import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import queryString from 'query-string';

import { VersionCardActionType } from "./types";
import { versionCardError, versionCardSuccess } from "./actions";
import { getConfig } from '../../config-provider';
import { versionExtraSuccess } from '../currentCalcContext/actions';


const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');


function* handleCreateRequest(action) {

    const { id } = action.payload;
    const qs = queryString.stringify({version_id: id});

    const urlData = `${API_ENDPOINT}/calc/versions_details/${id}/`;
    const urlExtra = `${API_ENDPOINT}/version_extra?${qs}`;

    try {
        const [res1, res2] = yield all([
            call(axios.get, urlData),
            call(axios.get, urlExtra)
        ]) 
        if (res1.error) {
            yield put(versionCardError(res1.error))
        } else {
            yield put(versionCardSuccess(res1.data));
            yield put(versionExtraSuccess(id, res2.data));
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(versionCardError(e.stack!))
        } else {
            yield put(versionCardError("An unknown error occurred."))
        }
    }
};

function* watchCreateRequest() {
    yield takeLatest(VersionCardActionType.VERSION_CARD_REQUEST, handleCreateRequest)
};

export function* versionCardSaga() {
    yield all([
        fork(watchCreateRequest)
    ])
};
