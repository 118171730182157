import { all, call, delay, fork, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import { FilterActionType } from './types';
import {
  setActiveRowsError,
  setActiveRowsSuccess,
  hierarchyTreeError,
  hierarchyTreeSuccess,
  getFilterDataError,
  getFilterDataSuccess,
  getFilterDataRequest,
  getFilterOptionsError,
  getFilterOptionsSuccess,
} from './actions';
import { getConfig } from '../../config-provider';
import { formatDateTime } from '../../helpers/MyAwesomeLib';
import {StimulationActionType} from '../stimulationToolbar/types';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

const GROUP_1_FILTER_NAME = 'group1Filter';
const GROUP_2_FILTER_NAME = 'group2Filter';

const filterUrls = {
  vFilter: `${API_ENDPOINT}/calc/versions_filter`,
  vFilterSelected: `${API_ENDPOINT}/calc/versions_filter`,
  group1Filter: `${API_ENDPOINT}/calc/versions_filter`,
  group2Filter: `${API_ENDPOINT}/calc/versions_filter`,
  costsCompFilter: `${API_ENDPOINT}/calc/versions_filter`,
  taxModesFilter: `${API_ENDPOINT}/scenario/tax_mode`,
  macroScenariosFilter: `${API_ENDPOINT}/scenario/macro`,
  emissionScenariosFilter: `${API_ENDPOINT}/scenario/esg`,
  costsScenariosFilter: `${API_ENDPOINT}/scenario/cost`,
  portfolioAnalysisFilter: `${API_ENDPOINT}/reporting/full_kpi_reports`,
  filesFilter: `${API_ENDPOINT}/file-manager/files`,
};

const removeBlanksFromQuery = (filters) => {
  const resultFilters = {...filters};
  const keys = Object.keys(filters).filter((key) => key.includes('selected') || key.includes('excluded'));
  keys.forEach((key) => {
    resultFilters[key] = resultFilters[key].filter((value) => value !== '(blank)');
    resultFilters[key] = resultFilters[key].filter((value) => value.id !== '(blank)');
  });

  return resultFilters;
}

function filterToQueryArgs(filters: any) {
  const query = {};
  const tag_id__in = [];
  const tag_id__not_in = [];

  const f = removeBlanksFromQuery(filters);

  if (f.is_donor) {
    query['is_donor'] = true;
  }
  if (f.is_mine) {
    query['is_mine'] = true;
  }
  if (!f.show_deleted) {
    query['is_active'] = true;
  }
  if (f.ids_in) {
    query['ids_in'] = f.ids_in.length ? f.ids_in.join(',') : '0,0';
  }
  if (f.search) {
    query['search'] = f.search;
  }
  if (f.selected_regions?.length) {
    query['region_id__in'] = f.selected_regions.map((x) => x.id).join(',');
  }
  if (f.selected_deposits?.length) {
    query['deposit_id__in'] = f.selected_deposits.map((x) => x.id).join(',');
  }
  if (f.selected_releases?.length) {
    query['release_id__in'] = f.selected_releases.map((x) => x.id).join(',');
  }
  if (f.selected_subsurface_users?.length) {
    query['subsurface_user_id__in'] = f.selected_subsurface_users.map((x) => x.id).join(',');
  }
  if (f.selected_licensed_sites?.length) {
    query['licensed_site_id__in'] = f.selected_licensed_sites.map((x) => x.id).join(',');
  }
  if (f.selected_companies?.length) {
    query['company_id__in'] = f.selected_companies.map((x) => x.id).join(',');
  }
  if (f.selected_created_at?.length) {
    // Строки с датами приводятся в исходный формат "YYYY-MM-DD"
    query['created_at__in'] = f.selected_created_at.map((x) => x.id).map(v => formatDateTime(v, 'DD.MM.YYYY', 'YYYY-MM-DD')).join(',');
  }
  if (f.selected_users?.length) {
    query['user_id__in'] = f.selected_users.map((x) => x.id).join(',');
  }
  if (f.selected_names?.length) {
    query['name__in'] = f.selected_names.map((x) => x.id).join(',');
  }
  if (f.excluded_names?.length) {
    query['name__not_in'] = f.excluded_names.join(',');
  }
  if (f.selected_macro_urals_price_min?.length) {
    query['macro_urals_price_min__in'] = f.selected_macro_urals_price_min.map((x) => x.id).join(',');
  }
  if (f.excluded_macro_urals_price_min?.length) {
    query['macro_urals_price_min__not_in'] = f.excluded_macro_urals_price_min.join(',');
  }
  if (f.selected_macro_urals_price_max?.length) {
    query['macro_urals_price_max__in'] = f.selected_macro_urals_price_max.map((x) => x.id).join(',');
  }
  if (f.excluded_macro_urals_price_max?.length) {
    query['macro_urals_price_max__not_in'] = f.excluded_macro_urals_price_max.join(',');
  }
  if (f.selected_exch_usd_rub_min?.length) {
    query['exch_usd_rub_min__in'] = f.selected_exch_usd_rub_min.map((x) => x.id).join(',');
  }
  if (f.excluded_exch_usd_rub_min?.length) {
    query['exch_usd_rub_min__not_in'] = f.excluded_exch_usd_rub_min.join(',');
  }
  if (f.selected_exch_usd_rub_max?.length) {
    query['exch_usd_rub_max__in'] = f.selected_exch_usd_rub_max.map((x) => x.id).join(',');
  }
  if (f.excluded_exch_usd_rub_max?.length) {
    query['exch_usd_rub_max__not_in'] = f.excluded_exch_usd_rub_max.join(',');
  }
  if (f.selected_tax_regimes?.length) {
    query['tax_regimes__in'] = f.selected_tax_regimes.map((x) => x.id).join(',');
  }
  if (f.selected_tax_regimes_attr?.length) {
    tag_id__in.push(f.selected_tax_regimes_attr.map((x) => x.id));
  }
  if (f.selected_macros_attr?.length) {
    tag_id__in.push(f.selected_macros_attr.map((x) => x.id));
  }
  if (f.selected_iterations?.length) {
    tag_id__in.push(f.selected_iterations.map((x) => x.id));
  }
  if (f.selected_wells_switch_offs?.length) {
    tag_id__in.push(f.selected_wells_switch_offs.map((x) => x.id));
  }
  if (f.selected_tags?.length) {
    tag_id__in.push(f.selected_tags.map((x) => x.id));
  }
  if (f.selected_version_groups?.length) {
    query['version_group_id__in'] = f.selected_version_groups.map((x) => x.id).join(',');
  }
  if (f.selected_tax_modes?.length) {
    tag_id__in.push(f.selected_tax_modes.map((x) => x.id));
  }
  if (f.selected_indicators?.length) {
    query['modified_indicators__in'] = f.selected_indicators.map((x) => x.id).join(',');
  }
  if (f.selected_status?.length) {
    query['status__in'] = f.selected_status.map((x) => x.id).join(',');
  }
  if (f.selected_doc_types?.length) {
    query['doc_type__in'] = f.selected_doc_types.map((x) => x.id).join(',');
  }
  if (f.selected_percent_prod_profitable?.length) {
    query['percent_prod_profitable__in'] = f.selected_percent_prod_profitable.map((x) => x.id).join(',');
  }
  if (f.excluded_tags?.length) {
    tag_id__not_in.push(f.excluded_tags);
  }
  if (f.excluded_tax_regimes?.length) {
    query['tax_regimes__not_in'] = f.excluded_tax_regimes.join(',');
  }
  if (f.excluded_tax_regimes_attr?.length) {
    tag_id__not_in.push(f.excluded_tax_regimes_attr);
  }
  if (f.excluded_macros_attr?.length) {
    tag_id__not_in.push(f.excluded_macros_attr);
  }
  if (f.excluded_iterations?.length) {
    tag_id__not_in.push(f.excluded_iterations);
  }
  if (f.excluded_wells_switch_offs?.length) {
    tag_id__not_in.push(f.excluded_wells_switch_offs);
  }
  if (f.excluded_tax_modes?.length) {
    tag_id__not_in.push(f.excluded_tax_modes);
  }
  if (f.excluded_indicators?.length) {
    query['modified_indicators__not_in'] = f.excluded_indicators.join(',');
  }
  if (f.excluded_status?.length) {
    query['status__not_in'] = f.excluded_status.join(',');
  }
  if (f.excluded_created_at?.length) {
    // Строки с датами приводятся в исходный формат "YYYY-MM-DD"
    query['created_at__not_in'] = f.excluded_created_at.map(v => formatDateTime(v, 'DD.MM.YYYY', 'YYYY-MM-DD')).join(',');
  }
  if (f.excluded_version_groups?.length) {
    query['version_group_id__not_in'] = f.excluded_version_groups.join(',');
  }
  if (f.excluded_doc_types?.length) {
    query['doc_type__not_in'] = f.excluded_doc_types.join(',');
  }
  if (f.excluded_percent_prod_profitable?.length) {
    query['percent_prod_profitable__not_in'] = f.excluded_percent_prod_profitable.join(',');
  }

  // Добавляем критерии фильтрации по тегам
  if (tag_id__in?.length) {
    query['tag_id__in'] = tag_id__in.join(',');
  }
  if (tag_id__not_in?.length) {
    query['tag_id__not_in'] = tag_id__not_in.join(',');
  }
  
  // Добавляем критерии фильтрации по пустым значениям
  if (f.by_blank_filters) {
    const keys = Object.keys(f.by_blank_filters);
    for (const key of keys) {
      query[`${key}__isnull`] = f.by_blank_filters[key];
    }
  }

  return query;
}


const sortingToQueryArgs = (sorting) => {
  return sorting.map((sort) => `${sort.name}${sort.value}`);
}


function* handleFilterOptionsRequest(action) {
  const {filterName, column} = action.payload;
  const currentFilters = yield select((state) => state[filterName].currentFilters);
  const checkedOnly = yield select((state) => state[filterName].checkedOnly);
  const checkedIds = yield select((state) => state[filterName].checkedIds);
  const filterOrder = yield select((state) => state[filterName].filterOrder);

  const lastFilter = filterOrder[filterOrder.length - 1];

  const filtersApplied = { ...currentFilters };

  // Если запрашиваемый фильтр является последним примененным,
  // запросить опции с учетом фильтрации по столбцам кроме него самого
  if (column === lastFilter) {
    delete filtersApplied[`selected_${lastFilter}`];
    delete filtersApplied[`excluded_${lastFilter}`];
    delete filtersApplied.by_blank_filters[lastFilter];
  }
  
  if (checkedOnly) {
    filtersApplied.ids_in = checkedIds;
  }

  const body = { ...filterToQueryArgs(filtersApplied) };

  let url = `${filterUrls[filterName]}/__filters__/${column}`;

  // Замена url для получения опций фильтра столбца индикаторов (экран НР)
  if (column === 'indicators') {
    url = `${filterUrls[filterName]}/__filters__/modified_indicators`;
  }

  // Замена url для опций фильтра, берущихся из тегов (экран НР)
  if (column === 'tax_modes' || column === 'tags') {
    url = `${filterUrls[filterName]}/__filters__/tags`;
  }

  try {
    const res = yield call(axios.post, url, body);
    if (res.error) {
      yield put(getFilterOptionsError(res.error, filterName));
    } else {
      const {values, has_blanks, have_blanks} = res.data;
      if ([GROUP_1_FILTER_NAME, GROUP_2_FILTER_NAME].includes(filterName)) {
        // Для корректного отображения состояния исключающих фильтров для фильтров группы 1 и группы 2
        yield put(getFilterOptionsSuccess(column, values, GROUP_1_FILTER_NAME, has_blanks, have_blanks));
        yield put(getFilterOptionsSuccess(column, values, GROUP_2_FILTER_NAME, has_blanks, have_blanks));
      } else {
        yield put(getFilterOptionsSuccess(column, values, filterName, has_blanks, have_blanks));
      } 
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(getFilterOptionsError(err.stack!, filterName));
    } else {
      yield put(getFilterOptionsError('An unknown error occured.', filterName));
    }
  }
}

function* handleFilterChange(action) {
  let { filterName, page } = action.payload;
  let pageSize = action.payload.page_size;

  const perPage = yield select((state) => state[filterName].perPage);

  page = page ? page : 1;
  pageSize = pageSize ? pageSize : perPage;

  yield delay(250); //debounce
  yield put(getFilterDataRequest(page, pageSize, filterName));
};

function* handleFilterDataRequest(action) {

  const {page, pageSize, filterName} = action.payload;

  const pathname = yield select((state) => state.router.location.pathname);
  const currentFilters = yield select((state) => state[filterName].currentFilters);
  const currentSorting = yield select((state) => state[filterName].currentSorting);
  const checkedOnly = yield select((state) => state[filterName].checkedOnly);
  const isPickerMode = yield select((state) => state[filterName].isPickerMode);
  const checkedIds = yield select((state) => state[filterName].checkedIds);
  const selectedIds = yield select((state) => state[filterName].selectedRowsIds);
  const selectedVersionIds = yield select((state) => state.vFilter.selectedRowsIds);

  const isVersionPage = pathname.includes('/versions');
  
  const dataUrl = filterUrls[filterName];
  const idsUrl = `${dataUrl}/__filters__/ids`;

  const filtersToApply = {
    ...currentFilters, 
    ...(checkedOnly && isPickerMode && !isVersionPage) && { ids_in: checkedIds }
  };

  // Payload для запроса данных строк таблицы Material на отдельном экране. 
  // Например, "Версии", "Налоговый режим", "Макро сценарии" и т.д.
  const body1 = {
    ...filterToQueryArgs(filtersToApply),
    sort_by: sortingToQueryArgs(currentSorting),
    page_size: pageSize,
    page: page
  };

  // Payload для запроса данных по выбранным строкам 
  // таблицы Material для корректной работы табов версий.
  const body2 = {
    ...filterToQueryArgs({ids_in: selectedIds}),
    sort_by: sortingToQueryArgs(currentSorting),
    page_size: 100,
    page: 1
  };
  
  // Payload для запроса списка id всех строк подходящих под текущий фильтр таблицы Material.
  // Необходимо для работы кнопки "Выбрать все" в диалоге выбора версий. 
  const body3 = {
    ...filterToQueryArgs({...currentFilters}),
  };

  try {
    // Для дропдауна версий запрашиваем только данные по версиям выбранным пользователем.
    if (filterName === 'vFilterSelected') {

      const body = {
        ...filterToQueryArgs({ids_in: selectedVersionIds}),
        page_size: pageSize,
        page: page
      };

      const res = yield call(axios.post, dataUrl, body);
      const pageData = res.data;

      yield put(getFilterDataSuccess(pageData, [], [], filterName, isPickerMode));
    
    // Для диалога выбора версий запрашиваем данные строк, данные выбранных версий и список id версий.
    } else if (isPickerMode) {

      const [res1, res2, res3] = yield all([
        call(axios.post, dataUrl, body1),
        call(axios.post, dataUrl, body2),
        call(axios.post, idsUrl, body3)
      ]);
  
      const pageData = res1.data;
      const selectedData = res2.data;
      const rowIds = res3.data.version_ids;
  
      yield put(getFilterDataSuccess(pageData, selectedData, rowIds, filterName, isPickerMode));
    
    // Во всех остальных случаях запрашиваем только данные строк текущей страницы таблицы.
    } else {

      const res = yield call(axios.post, dataUrl, body1);
      const pageData = res.data;

      yield put(getFilterDataSuccess(pageData, [], [], filterName, isPickerMode));

    }

  } catch (err) {
    if (err instanceof Error) {
      yield put(getFilterDataError(err.stack!, filterName));
    } else {
      yield put(getFilterDataError('An unknown error occured.', filterName));
    }
  }
};

function* handleHierarchyTreeRequest(action) {
  const url = `${API_ENDPOINT}/calc/versions_hierarchy`;
  const body = { version_id: action.payload.rowId };
  const filterName = action.payload.filterName;
  try {
    const res = yield call(axios.post, url, body, { headers: { 'Content-Type': 'application/json' } });
    res.error
      ? yield put(hierarchyTreeError(res.error))
      : yield put(hierarchyTreeSuccess(res.data, filterName));
  } catch (e) {
    if (e instanceof Error) yield put(hierarchyTreeError(e.stack!));
  }
}

function* handleSetActiveRowRequest(action) {
  const filterName = action.payload.filterName;
  try {
    const res = yield call(axios.get, `${API_ENDPOINT}/calc/versions/${action.payload.version_id}/`);
    if (res.error) {
      yield put(setActiveRowsError(res.error));
    } else {
      yield put(setActiveRowsSuccess(res.data, filterName));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(setActiveRowsError(err.stack!));
    } else {
      yield put(setActiveRowsError('An unknown error occured.'));
    }
  }
}

function* watchFilterDataRequest() {
  yield takeEvery(FilterActionType.GET_FILTER_DATA_REQUEST, handleFilterDataRequest);
};

function* watchHierarchyTreeRequest() {
  yield takeLatest(FilterActionType.HIERARCHY_TREE_REQUEST, handleHierarchyTreeRequest);
}

function* watchFilterOptionsRequest() {
  yield takeLatest(FilterActionType.GET_FILTER_OPTIONS_REQUEST, handleFilterOptionsRequest)
}

function* watchFilterChange() {
  yield takeEvery(FilterActionType.SET_COLUMN_FILTER, handleFilterChange);
  yield takeEvery(FilterActionType.SET_SEARCH, handleFilterChange);
  yield takeEvery(FilterActionType.RESET_FILTERS, handleFilterChange);
}

function* watchSetActiveRowRequest() {
  yield takeLatest(FilterActionType.SET_ACTIVE_ROWS_REQUEST, handleSetActiveRowRequest);
}

function* watchPortfolioAnalysisListChange() {
  yield takeLatest([StimulationActionType.WS_PORTFOLIO_ANALYSIS_SUCCESS, StimulationActionType.ANALYSIS_DELETION_SUCCESS], handleFilterChange)
}

export function* tableSelectorSaga() {
  yield all([
    fork(watchFilterDataRequest),
    fork(watchFilterChange),
    fork(watchFilterOptionsRequest),
    fork(watchSetActiveRowRequest),
    fork(watchHierarchyTreeRequest),
    fork(watchPortfolioAnalysisListChange)
  ]);
}
