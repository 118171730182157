import {action} from 'typesafe-actions';
import {IIndicatorMeta, IndicatorMetaActionType} from './types';

export const indicatorMetaFetchRequest = (indicatorNames: string[]) =>
    action(IndicatorMetaActionType.FETCH_REQUEST, {indicatorNames});

export const clearMetaFetchRequest = () => action(IndicatorMetaActionType.CLEAR_ACTION);

export const indicatorMetaFetchSuccess = (indicatorNames: string[], data: IIndicatorMeta) =>
    action(IndicatorMetaActionType.FETCH_SUCCESS, {indicatorNames, data});

export const indicatorMetaFetchError = (message: string) => action(IndicatorMetaActionType.FETCH_ERROR, {message});

export const indicatorMetaRefresh = () => action(IndicatorMetaActionType.REFRESH_ACTION, {});
