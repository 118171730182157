import { action } from 'typesafe-actions';
import { StimulationActionType } from './types';

export const analysisRequest = (
    name: string, 
    generate_name: boolean, 
    group1: number[], 
    group2: number[], 
    group1Name: string, 
    group2Name: string
) => 
    action(StimulationActionType.ANALYSIS_REQUEST, { name, generate_name, group1, group2, group1Name, group2Name });
export const analysisLegacyRequest = (group1: number[], group2: number[]) => 
    action(StimulationActionType.ANALYSIS_LEGACY_REQUEST, { group1, group2 });
export const analysisSuccess = (pageData: any) => 
    action(StimulationActionType.ANALYSIS_SUCCESS, pageData);
export const analysisError = (error: any) => 
    action(StimulationActionType.ANALYSIS_ERROR, { error });

export const analysisDeletionRequest = (ids: number[]) =>
    action(StimulationActionType.ANALYSIS_DELETION_REQUEST, { ids });
export const analysisDeletionSuccess = (filterName = 'portfolioAnalysisFilter') =>
    action(StimulationActionType.ANALYSIS_DELETION_SUCCESS, {filterName});
export const analysisDeletionError = (error: any) =>
    action(StimulationActionType.ANALYSIS_DELETION_ERROR, { error });

export const wsPortfolioAnalysisSuccess = (filterName) =>
    action(StimulationActionType.WS_PORTFOLIO_ANALYSIS_SUCCESS, {filterName});

export const setWellFund = (fund: string) =>
    action(StimulationActionType.SET_WELL_FUND, { fund });
export const setUpdateRequired = (required: boolean) => 
    action(StimulationActionType.SET_UPDATE_REQUIRED, { required });
export const loadState = (state: any) => 
    action(StimulationActionType.LOAD_STATE, { state });