import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Popper, ClickAwayListener, Paper, TextField, Button} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import styled from 'styled-components';
import {DownIcon} from '../Design/Icons/IconsOther';

const PopperWrapper = ({id, open, anchorEl, handleClickAway, handleTimelineChange}) => {
    const {t} = useTranslation();

    const [how, setHow]: [string, any] = useState('to_year');
    const [inputValue, setInputValue]: [number, any] = useState();

    const handleHowChange = (e, newHow) => (newHow ? setHow(newHow) : null);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Popper id={id} open={open} anchorEl={anchorEl} style={{zIndex: 1003, border: '1px solid grey'}}>
                <Paper>
                    <Box display="flex" flexDirection="column" p={3}>
                        <ToggleButtonGroup
                            onChange={handleHowChange}
                            style={{justifyContent: 'center'}}
                            value={how}
                            exclusive
                        >
                            <ToggleButton value="to_year">
                                {t('pages:scenarios.cellsAdder.to_year')}
                            </ToggleButton>
                            <ToggleButton value="by_count">
                                {t('pages:scenarios.cellsAdder.by_count')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <TextField onBlur={(e) => setInputValue(e.target.value)} type="number" />
                        <Box display="flex" justifyContent="center" mt={2}>
                            <Button onClick={() => handleTimelineChange(how, +inputValue, true)}>
                                {t('pages:scenarios.cellsAdder.add')}
                            </Button>
                            <Button onClick={() => handleTimelineChange(how, +inputValue, false)}>
                                {t('pages:scenarios.cellsAdder.remove')}
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Popper>
        </ClickAwayListener>
    );
};

export const ScenarioCellsAdder = ({handleTimelineChange}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => setAnchorEl(anchorEl ? null : e.currentTarget);
    const handleClickAway = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : null;

    return (
        <div>
            <IconWrapper onClick={handleClick} style={{cursor: 'pointer'}}>
                <DownIcon />
            </IconWrapper>
            {open && (
                <PopperWrapper
                    {...{
                        id,
                        open,
                        anchorEl,
                        handleClickAway,
                        handleTimelineChange,
                    }}
                />
            )}
        </div>
    );
};

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background: ${(props) => props.theme.colors.black};

    img {
        width: 8px;
        height: 8px;
    }
`;
