import {all, call, delay, fork, put, takeEvery} from 'redux-saga/effects'
import {hideSnackbar, showSnackbar} from "./actions";
import {SnackbarsActionType} from "./types";
import * as _ from "lodash";

const MAX_SNACKS = 1;
const SNACK_DISPLAY_TIME = 4000;

let pendingSnacks = [];
let activeSnacks = [];
let firstSnack = {};
//eslint-disable-next-line
let remainingSnacks = [];

function* displaySnack(snack) {
    if (activeSnacks.length >= MAX_SNACKS) {
        throw new Error("can't display more than " + MAX_SNACKS + " at the same time");
    }

    activeSnacks = [...activeSnacks, snack];
    yield put(showSnackbar(snack.type, snack.message));
    yield delay(SNACK_DISPLAY_TIME);
    yield put(hideSnackbar());
    pendingSnacks = _.without(pendingSnacks, firstSnack);
    activeSnacks = _.without(activeSnacks, snack);
    [firstSnack, ...remainingSnacks] = pendingSnacks;
}

function* snackRequestsWatcher() {
    yield takeEvery(SnackbarsActionType.SNACK_DISPLAY_REQUESTED, snackScheduler);
}

function* snackScheduler(action) {
    const newSnack = action.payload;
    pendingSnacks = [...pendingSnacks, newSnack];
    [firstSnack, ...remainingSnacks] = pendingSnacks;
    while (pendingSnacks.length) {
        const canDisplaySnack = activeSnacks.length < MAX_SNACKS && pendingSnacks.length;
        if (canDisplaySnack) {
            yield call(displaySnack, firstSnack)

        } else {
            yield delay(50)
        }
    }
}


function* snackbarSaga() {
    yield all([fork(snackRequestsWatcher)])
}

export default snackbarSaga