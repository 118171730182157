export enum StimulationActionType {
    ANALYSIS_REQUEST = '@@stimulation/ANALYSIS_REQUEST',
    ANALYSIS_LEGACY_REQUEST = '@@stimulation/ANALYSIS_LEGACY_REQUEST',
    ANALYSIS_SUCCESS = '@@stimulation/ANALYSIS_SUCCESS',
    ANALYSIS_ERROR = '@@stimulation/ANALYSIS_ERROR',
    ANALYSIS_DELETION_REQUEST = '@@stimulation/ANALYSIS_DELETION_REQUEST',
    ANALYSIS_DELETION_SUCCESS = '@@stimulation/ANALYSIS_DELETION_SUCCESS',
    ANALYSIS_DELETION_ERROR = '@@stimulation/ANALYSIS_DELETION_ERROR',

    WS_PORTFOLIO_ANALYSIS_SUCCESS = '@@stimulation/WS_PORTFOLIO_ANALYSIS_SUCCESS',

    SET_WELL_FUND = '@@stimulation/SET_WELL_FUND',
    SET_UPDATE_REQUIRED = '@@stimulation/SET_UPDATE_REQUIRED',
    LOAD_STATE = '@@stimulation/LOAD_STATE',
}

export interface IStimulationAnalysisState {
    all_fund: any
    base_fund: any
    new_fund: any
    loading: boolean
    update_required: boolean
    selected_fund: string
    last_group1: number[]
    last_group2: number[]
    error: string
    errorDetails: any
    portfolioAnalysis: any
}