import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import {CalcJobActionType, ICalcJob} from './types';
import {fetchCalcJobsError, fetchCalcJobsSuccess} from './actions';
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');
const PER_PAGE = 30;

function* handleFetchRequest(action) {
    const page = action.payload;
    const url = `${API_ENDPOINT}/calc/jobs/?page=`.concat(page.toString(), '&page_size=', PER_PAGE.toString());

    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(fetchCalcJobsError(res.error));
        } else {
            yield put(fetchCalcJobsSuccess(res.data as ICalcJob[]));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchCalcJobsError(err.stack!));
        } else {
            yield put(fetchCalcJobsError('An unknown error occurred.'));
        }
    }
}

function* handleDeleteCalcJob(action) {
    const {id, delete_version} = action.payload;
    yield call(axios.delete, `${API_ENDPOINT}/calc/jobs/${id}/?delete_version=${delete_version}`);
}

function* watchFetchRequest() {
    yield takeLatest(CalcJobActionType.FETCH_REQUEST, handleFetchRequest);
}

function* watchDeleteCalcJob() {
    yield takeLatest(CalcJobActionType.DELETE_CALC_JOB, handleDeleteCalcJob);
}

export function* deleteCalcJobSaga() {
    yield all([fork(watchDeleteCalcJob)]);
}

function* calcJobSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default calcJobSaga;
