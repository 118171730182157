import {all, call, delay, fork, put, takeEvery, select} from 'redux-saga/effects'
import axios from '../../../axiosMiddleware';
import {ActiveDataView} from '../../currentCalcContext/types'
import {setActiveDataView} from '../../currentCalcContext/actions'
import {IndicatorMetaActionType} from './types'
import {indicatorMetaFetchError, indicatorMetaFetchSuccess} from './actions'
import {getConfig} from '../../../config-provider';
import * as queryString from 'query-string';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleFetch(action) {
    const indicatorNames = action.payload.indicatorNames;
    const isGridViewActive = yield select((state) => state.currentCalculationContext.activeDataView[ActiveDataView.GRID]);
    const isPlotViewActive = yield select((state) => state.currentCalculationContext.activeDataView[ActiveDataView.PLOT]);

    let query = queryString.stringify({mipairs: indicatorNames}, {arrayFormat: 'comma'});

    yield delay(400);
    try {
        const res = yield call(axios.get, `${API_ENDPOINT}/calculate/meta?${query}`);

        if (res.error) {
            yield put(indicatorMetaFetchError(res.error))
        } else {
            yield put(indicatorMetaFetchSuccess(indicatorNames, res.data));
            
            if (res.data.indicators[0].agg_method === null) {
                if (isGridViewActive && isPlotViewActive) {
                    yield put(setActiveDataView(ActiveDataView.PLOT));
                } else if (isPlotViewActive) {
                    yield put(setActiveDataView(ActiveDataView.GRID));
                    yield put(setActiveDataView(ActiveDataView.PLOT));
                }
            }
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(indicatorMetaFetchError(err.stack!))
        } else {
            yield put(indicatorMetaFetchError('An unknown error occured.'))
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
    yield takeEvery(IndicatorMetaActionType.FETCH_REQUEST, handleFetch)
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* indicatorMetaSaga() {
    yield all([fork(watchFetchRequest)])
}

export default indicatorMetaSaga

