import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import {LoginActionType} from './types';
import {loginError, loginSuccess, userError, userSuccess} from './action';
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleUserLoaded() {
    try {
        const res = yield call(axios.get, `${API_ENDPOINT}/auth/me`);
        if (res.error) {
            yield put(userError(res.error));
        } else {
            yield put(userSuccess(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(userError(err.stack!));
        } else {
            yield put(loginError('Auth error'));
        }
    }
}

function* handleUserLogin(action) {
    const {username, password} = action.payload.formData;
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res = yield call(
            axios.post,
            `${API_ENDPOINT}/auth/login`,
            {username, password},
            config
        );
        if (res.error || res.status >= 401) {
            yield put(loginError(res.error));
        } else {
            yield put(loginSuccess(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(loginError(err.stack!));
        } else {
            yield put(loginError('Login error.'));
        }
    }
}

/////////////////////////
function* watchHandleUserLoaded() {
    yield takeLatest(LoginActionType.GET_USER_REQUEST, handleUserLoaded);
}

export function* UserLoadedSaga() {
    yield all([fork(watchHandleUserLoaded)]);
}

function* watchHandleUserLogin() {
    yield takeLatest(LoginActionType.LOGIN, handleUserLogin);
}

export function* userLoginSaga() {
    yield all([fork(watchHandleUserLogin)]);
}
