import {Reducer} from 'redux';
import {LanguageActionType, IUserLanguageState} from './types';

export const initialLanguageState: IUserLanguageState = {
    language: null,
    error: null,
    loading: false
};

export const userLanguageReducer: Reducer<IUserLanguageState> = (state = initialLanguageState, action) => {

    switch (action.type) {
        case LanguageActionType.GET_USER_LANGUAGE_REQUEST: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case LanguageActionType.GET_LANGUAGE_SUCCESS: {
            localStorage.setItem('language', action.payload.language['language'])
            return {
                ...state,
                language: action.payload.language['language'],
                loading: false,
                error: null,
            };
        }
        case LanguageActionType.GET_LANGUAGE_ERROR: {
            return {
                loading: false,
                error: null,
            };
        }
        case LanguageActionType.SET_USER_LANGUAGE_REQUEST: {
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: null,
            };
        }
        case LanguageActionType.SET_LANGUAGE_SUCCESS: {
            return {
                ...state,
                language: state['user_language'],
                loading: false,
                error: null,
            };
        }
        default: {
            return state;
        }
    }
};