import React from 'react';
import {MaterialTooltip} from '../../components/Design/Tooltip';
import Link from '../../components/Design/Link';

export const createCellFromArray = (rowData) => {
    const tooltip = [];

    const data = rowData.map((lc) => {
        tooltip.push(lc);
        return (
            <div key={lc as string}>
                {lc}
                <br />
            </div>
        );
    });

    return {tooltip, data};
};

export const createArrayDataForCell = (rowData, values, hasSplit = false, field = null) => {
    const ids = rowData.map((i) => {
        if (typeof i === 'object') return i.id;
        return i;
    });
    const currentTags = values.filter((v) => ids.includes(v.id));
    const tooltip = [];

    const result = currentTags.map((v) => {
        let text = v[field ?? 'name'];
        let tltp = field === 'name_short' ? v['name'] : text;
        if (hasSplit) {
            text = text.split(': ')[1];
            tltp = tltp.split(': ')[1];
        }
        tooltip.push(tltp.trim());

        return (
            <div key={v.name}>
                {text.trim()}
                <br />
            </div>
        );
    });

    return createMinimizeCell(result, formatTooltip(tooltip));
};

export const createLinkCellData = (values, hasSplit = false, field = null, onClick) => {
    const tooltip = [];

    const result = values.map((v) => {
        let text = v[field ?? 'name'];
        let tltp = field === 'name_short' ? v['name'] : text;
        if (hasSplit) {
            text = text.split(': ')[1];
            tltp = tltp.split(': ')[1];
        }
        tooltip.push(tltp.trim());

        return (
            <div key={v.name}>
                <Link
                    onClick={() => onClick(v.id)}
                    text={text.trim()}
                />
                <br />
            </div>
        );
    });

    return createMinimizeCell(result, formatTooltip(tooltip));
};

export const formatTooltip = (tooltip) => {
    const linesLimit = 15;
    const isContentOverflow = tooltip.length > linesLimit;
    const limitedContent = tooltip.slice(0, linesLimit);
    isContentOverflow && limitedContent.push('...')

    return limitedContent.map((el, index) => <div key={index}>{el}</div>);
};

export const createMinimizeCell = (cellData, tooltip = cellData) => {
    const title = tooltip ?? '';
    return (
        <MaterialTooltip title={title} disablePortal>
            <div className="table-minimize-value">
                {cellData}
            </div>
        </MaterialTooltip>
    );
};
