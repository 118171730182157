import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import { IScenario, ScenarioCalcActionType } from "./types";
import {
    calculateScenarioError,
    calculateScenarioSuccess,
    getScenariosListError,
    getScenariosListSuccess
} from "./actions";
import { getConfig } from '../../config-provider';
import { requestSnackDisplay } from "../snackbars/actions";
import i18n from '../../i18n';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetRequest() {
    const url = `${API_ENDPOINT}/scenario/scenarios/`;
    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getScenariosListError(res.error))
        } else {
            yield put(getScenariosListSuccess(res.data as IScenario[]))
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(getScenariosListError(e.stack!))
        } else {
            yield put(getScenariosListError("An unknown error occurred."))
        }
    }
}

function* handleDeleteRequest(action) {
    const id = action.payload.id;
    yield call(axios.delete, `${API_ENDPOINT}/scenario/scenarios/${id}/`);
}

function* handleCalcCreateRequest(action) {
    // TODO: Найти правильный подход к пересчету по сценариям
    const url = `${API_ENDPOINT}/calculations/run-calc/`;
    const body = {
        base_versions: action.payload.version_ids,
        scenarios: {
            macro: action.payload.scenario_ids,
            tax: [],
            cost: [],
            esg: []
        },
    };

    try {
        const res = yield call(axios.post, url, body, { headers: { 'Content-Type': 'application/json' } });
        if (res.error) {
            yield put(calculateScenarioError(res.error))
        } else {
            yield put(calculateScenarioSuccess(res.data as number));
            yield put(requestSnackDisplay('success', i18n.t('notifications:recalculation')));
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(calculateScenarioError(e.stack!))
        } else {
            yield put(calculateScenarioError("An unknown error occurred."))
        }
    }
}

function* watchGetRequest() {
    yield takeLatest(ScenarioCalcActionType.GET_SCENARIOS_LIST_REQUEST, handleGetRequest)
}

function* watchDeleteRequest() {
    yield takeLatest(ScenarioCalcActionType.DELETE_SCENARIO, handleDeleteRequest)
}

function* watchCalcCreateRequest() {
    yield takeLatest(ScenarioCalcActionType.SCENARIO_CALC_CREATE_REQUEST, handleCalcCreateRequest)
}

export function* scenarioCalcSaga() {
    yield all([
        fork(watchGetRequest),
        fork(watchCalcCreateRequest),
        fork(watchDeleteRequest)
    ])
}

