import {Moment} from 'moment';

export enum CalcJobActionType {
    FETCH_REQUEST = '@@calcJobs/FETCH_REQUEST',
    FETCH_SUCCESS = '@@calcJobs/FETCH_SUCCESS',
    FETCH_ERROR = '@@calcJobs/FETCH_ERROR',
    ADD_CALC_JOB = '@@calcJobs/ADD_CALC_JOB',
    UPDATE_CALC_JOB = '@@calcJobs/UPDATE_CALC_JOB',
    DELETE_CALC_JOB = '@@calcJobs/DELETE_CALC_JOB',
    DELETE_CALC_JOB_DONE = '@@calcJobs/DELETE_CALC_JOB_DONE',
}

export interface ICalcJob {
    id: number;
    num?: number;
    release: string;
    status: string;
    calc_version: number | null;
    source_name: string;
    set_version_name: string | null;
    has_errors: boolean;
    has_warnings: boolean;
    has_info: boolean;
    error: string | null;
    user: number | null;
    user_name: string | null;
    created_at: Moment | null;
    started_at: Moment | null;
    duration: number | null;
    error_description: string | null;
}

export interface ICalcJobsState {
    calcJobs: Map<number, ICalcJob> | null;
    readonly loading: boolean;
    readonly error: string | null;
    readonly TOTAL_PAGES: number;
    readonly PER_PAGE: number;
}
