import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import {GridActionType} from './types';
import {calcError, calcSuccess} from './actions';
import {getConfig} from '../../config-provider';
import {ConditionEnum, FilterStoreItemType} from '../../components/FilterColumn/types';
import {FilterColumnItemType} from '../../components/FilterColumn/FilterColumn';
import {FilterSortType, SortVariableEnum} from '../../components/FilterColumn/SortComponent';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

export function createFiltersQuery(filters: FilterStoreItemType) {
    if (!filters) return;
    const resObject = {};

    for (const field in filters) {
        const currentFilter: FilterColumnItemType[] = [...filters[field]];

        for (const filter of currentFilter) {
            if ('isChecked' in filter) {
                const field = `${filter.field}__in`;
                resObject[field] = `${resObject[field] ? resObject[field] + ',' : ''}${filter.originalValue}`;
            }

            if ('condition' in filter)
                resObject[`${filter.field}__${ConditionEnum[filter.condition]}`] = `${filter.value}`;
        }
    }

    return resObject;
}

export function createSortQuery(sortFilters: FilterSortType): string[] {
    if (!sortFilters) return [];
    const result: any = Object.entries(sortFilters).sort((a, b) => a[1].position - b[1].position);
    return result.map(
        ([key, obj]) => `${obj.value === SortVariableEnum['-'] ? SortVariableEnum['-'] : ''}${key}`
    );
}

function* handleCalc() {
    try {
        const mipairs = yield select((store) => store.currentGrid.filters.mipairs);
        const version_ids = yield select((store) => store.currentGrid.filters.versions);
        const settings = yield select((store) => store.currentGrid.settings);
        const filters = yield select((store) => store.currentGrid.filters.filters);
        const sortFilters = yield select((store) => store.currentGrid.filters.sortFilters);

        const {groupby, disable_inject, cumsum, precision, agg_column, page, page_size, show_zero} = settings;
        const _groupby = groupby && groupby.length && groupby.join(',');
        const order_by = createSortQuery(sortFilters).join(',');
        const filterQuery = filters && {...createFiltersQuery(filters)};

        const body = {
            ...filterQuery,
            order_by: order_by ? order_by : undefined,
            groupby: _groupby ? _groupby : undefined,
            version_ids,
            mipairs,
            disable_inject,
            agg_column,
            cumsum,
            precision,
            page,
            page_size,
            show_zero
        };

        const url = `${API_ENDPOINT}/calculate/data`;

        const res = yield call(axios.post, url, body);
        if (res.error) {
            yield put(calcError(res.error));
        } else {
            yield put(calcSuccess(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(calcError(err.stack!));
        } else {
            yield put(calcError('An unknown error occured.'));
        }
    }
}

function* watchCalcRequest() {
    yield takeLatest(GridActionType.CALC_REQUEST, handleCalc);
}

function* calcSaga() {
    yield all([fork(watchCalcRequest)]);
}

export default calcSaga;
