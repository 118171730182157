import {action} from 'typesafe-actions'
import {IWellPlots, WellsActionType} from './types'

export const getWellPlotRequest = (versionId: number, well: string) => action(WellsActionType.GET_WELL_PLOT_REQUEST, {
    versionId,
    well
});
export const getWellPlotSuccess = (well: string, data: IWellPlots) => action(WellsActionType.GET_WELL_PLOT_SUCCESS, {
    well,
    data
});
export const getWellPlotError = (loadingError: any) => action(WellsActionType.GET_WELL_PLOT_ERROR, {loadingError});
