export enum LoginActionType {
    GET_USER_REQUEST = '@@login/GET_USER_REQUEST',
    GET_USER_SUCCESS = '@@login/GET_USER_SUCCESS',
    GET_USER_ERROR = '@@login/GET_USER_ERROR',
    LOGIN = '@@login/LOGIN',
    LOGIN_ERROR = '@@login/LOGIN_ERROR',
    LOGIN_SUCCESS = '@@login/LOGIN_SUCCESS',
    LOGOUT = '@@login/LOGOUT'
}

export interface ILogin {
    user: IUser;
    token: string;
}

export interface IUser {
    language: string;
    username: string;
    email: string;
    id: number;
}

export interface ILoginState {
    readonly user: IUser | null;
    readonly isAuth: boolean;
    readonly error?: any;
    readonly loading: boolean;
    readonly token: string | null;
}
