import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {useDispatch} from "react-redux";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Checkbox} from '../../components/Design/Checkboxes/Checkbox';
import {hideSystemTasks, showAllTasks, showMyTasks, showSystemTasks} from "../../store/tasks/actions";
import {FilterIconWhite, FilterIconYellow} from "../../components/Design/Icons/IconsEditTable";

const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            width: 340,
            borderRadius: 0,
        },
    },
    classes: {paper: "filters-select-menu"}
};

const FiltersSelect = ({initialStatuses, initialTypes, statusesList, typesList, onChange, t, placeholder, tasksState, isFilterSelected, setIsFilterSelected}) => {
    const dispatch = useDispatch();

    const [selectedStatuses, setSelectedStatuses] = useState<number[]>(initialStatuses);
    const [selectedTypes, setSelectedTypes] = useState<number[]>(initialTypes);
    const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const onFilterChange = (event: any, group: string) => {
        if (!event.target) return false;
        const id = Number(event.target.value);

        if (group === 'status') handleStatusFilterChange(id)
        if (group === 'type') handleTypeFilterChange(id)
    }

    const handleStatusFilterChange = (id) => {
        const valueIndex = selectedStatuses.indexOf(id)
        if (valueIndex >= 0) {
            const newValues = [...selectedStatuses];
            newValues.splice(valueIndex, 1)
            setSelectedStatuses(newValues);
        } else {
            setSelectedStatuses([...selectedStatuses, id]);
        }

        setIsFilterChanged(true)
    }

    const handleTypeFilterChange = (id) => {
        const valueIndex = selectedTypes.indexOf(id)
        if (valueIndex >= 0) {
            const newValues = [...selectedTypes];
            newValues.splice(valueIndex, 1)
            setSelectedTypes(newValues);
        } else {
            setSelectedTypes([...selectedTypes, id]);
        }

        setIsFilterChanged(true)
    }

    const handleIconClick = () => {
        setOpen(true)
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            const MultiselectMenuEl = document.querySelectorAll('.filters-select-menu')[0];
            const MultiselectButtonEl = document.getElementById('filters-select-button');
            if (!e.path.includes(MultiselectMenuEl) && !e.path.includes(MultiselectButtonEl)) {
                setOpen(false)
            }
        };
        document.addEventListener('click', handleClickOutside, false);
        return () => document.removeEventListener('click', handleClickOutside, false);
    }, []);

    useEffect(() => {
        if (isFilterChanged) {
            onChange(selectedStatuses, selectedTypes)
        }
        //eslint-disable-next-line
    }, [selectedStatuses, selectedTypes, tasksState.show_my_tasks, tasksState.show_system_tasks])

    useEffect(() => {
        setSelectedStatuses(initialStatuses)
        setSelectedTypes(initialTypes)
        setIsFilterChanged(false)
    }, [initialStatuses, initialTypes])

    useEffect(() => {
        const statusesIds = statusesList.map(status => status.id)
        const typesIds = typesList.map(type => type.id)
        if (typesIds.length !== selectedTypes.length || statusesIds.length !== selectedStatuses.length || tasksState.show_my_tasks || tasksState.show_system_tasks) {
            setIsFilterSelected(true)
        } else {
            setIsFilterSelected(false)
        }
        //eslint-disable-next-line
    }, [selectedStatuses, statusesList, tasksState.show_my_tasks, tasksState.show_system_tasks]);

    return (
        <FormControlStyled>
            <FilterIconWrapper $isFilterSelected={isFilterSelected} onClick={handleIconClick}
                               id="filters-select-button">
                {isFilterSelected ? <FilterIconYellow/> : <FilterIconWhite/>}
            </FilterIconWrapper>
            <SelectStyled
                multiple
                displayEmpty
                value={selectedStatuses}
                $isFilterSelected={isFilterSelected}
                input={<OutlinedInput/>}
                renderValue={() => placeholder}
                MenuProps={MenuProps}
                open={open}
                id="multiselect-control"
            >
                <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>
                <MenuItem style={{padding: 0}}>
                    <CheckboxStyled
                        checked={tasksState.show_system_tasks}
                        onChange={() => {
                            tasksState.show_system_tasks
                                ? dispatch(showSystemTasks())
                                : dispatch(hideSystemTasks());
                            setIsFilterChanged(true);
                        }}
                        label={t('components:drawer.show_system_tasks', 'Показывать системные')}
                    />
                </MenuItem>
                <MenuItem style={{padding: 0}}>
                    <CheckboxStyled
                        checked={tasksState.show_my_tasks}
                        onChange={() => {
                            tasksState.show_my_tasks ? dispatch(showAllTasks()) : dispatch(showMyTasks());
                            setIsFilterChanged(true);
                        }}
                        label={t('components:drawer.show_my_tasks', 'Только мои')}
                    />
                </MenuItem>
                <Separator/>
                <MenuItem value="" disabled>
                    <FilterInnerSubheader>{t('commons:task.task_states')}</FilterInnerSubheader>
                </MenuItem>
                {statusesList.map((variant, index) => (
                    <MenuItem key={index} style={{padding: 0}}>
                        <CheckboxStyled checked={selectedStatuses.indexOf(Number(variant.id)) > -1}
                                        label={t(`commons:task.${variant.alias}`)}
                                        value={variant.id} onChange={(e) => onFilterChange(e, 'status')}/>
                    </MenuItem>
                ))}
                <Separator/>
                <MenuItem value="" disabled>
                    <FilterInnerSubheader>{t('commons:task.tasks_types')}</FilterInnerSubheader>
                </MenuItem>
                {Boolean(tasksState.tasks_types.length) && tasksState.tasks_types.map((type, index) => (
                    <MenuItem key={type + index} style={{padding: 0}}>
                        {type.name && <CheckboxStyled checked={selectedTypes.indexOf(Number(type.id)) > -1}
                                                      label={type.name}
                                                      value={type.id} onChange={(e) => onFilterChange(e, 'type')}/>}
                    </MenuItem>
                ))}
            </SelectStyled>
        </FormControlStyled>
    );
}

const SelectStyled = styled(Select)`
  border: 1px solid transparent;
  border-radius: 0;
  color: #fff;
  position: relative;

  & fieldset {
    border: none;
    color: #fff;
  }

  .MuiSelect-icon {
    fill: #fff;
  }

  .MuiSelect-selectMenu {
    padding: 10px 5px;
  }

  .MuiSelect-selectMenu, .MuiSelect-icon {
    visibility: hidden;
  }
`;

const FormControlStyled = styled(FormControl)`
  width: 38px;
  height: 38px;
  margin-left: auto;
`;

const CheckboxStyled = styled(Checkbox)`
  width: 100%;
  padding: 6px 16px;
`;

const Separator = styled.hr`
  border-color: #fff;
  margin: 5px 0 4px;
`;

const FilterIconWrapper = styled.div`
  border: 1px solid #fff;
  position: absolute;
  z-index: 99;
  width: 38px;
  height: 38px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  ${props => props.$isFilterSelected && `border-color: ${props.theme.colors.yellow}`};
  ${props => props.$isFilterSelected && css`
    path {
      fill: ${props.theme.colors.yellow} !important;
    }`};
`;

const FilterInnerSubheader = styled.div`
  font-size: 14px;
`

export default FiltersSelect;