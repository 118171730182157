import axios from '../../../axiosMiddleware';
import {computeUrl} from '../utils';
import {getConfig} from "../../../config-provider";

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

const api = (method, action) => {
    const scope = action.options.scope ? action.options.scope : '';
    const url = `${API_ENDPOINT}/${scope}${computeUrl(method, action)}`;

    const headers = method === 'GET'
        ? {}
        : {
            'Content-Type': 'application/json',
        };

    let data;
    const config = {method, url, headers, data};

    if (method !== 'GET') {
        config.data = action.body;
    }

    return axios
        .request(config)
        .then(response => response.data);
};

export default api;
