import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        ns: ['commons', 'components', 'pages', 'notifications'],
        lng: 'ru',
        fallbackLng: 'ru',
        whitelist: ['en', 'ru'],
        saveMissing: false,
        useDataAttrOptions: true,
        saveMissingTo: 'all',
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}/translation.json',
        }
    });
export default i18n