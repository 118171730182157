import {Reducer} from 'redux';
import {IReportsState, ReportsActionType} from "./types";
import {addAll, addOnTopJobAndAlike, addOrUpdate, deleteItem} from "../utils";

export const initialState: IReportsState = {
    reports: null,
    loading: false,
    error: null,
    TOTAL_PAGES: 0,
    PER_PAGE: 30,
    reportTags: [],
    reportBuffer: []
};


const reducer: Reducer<IReportsState> = (state = initialState, action): IReportsState => {
    switch (action.type) {
        case ReportsActionType.RETRIEVE_REPORT_REQUEST: {
            return {...state, error: null, loading: true};
        }
        case ReportsActionType.RETRIEVE_REPORT_SUCCESS: {
            const report_data = action.payload;
            const index = state.reportBuffer.indexOf(report_data);
            
            const newReportBuffer = state.reportBuffer;
            if (index === -1)
                newReportBuffer.push(report_data)
            
            return {
                ...state,
                reportBuffer: newReportBuffer,
                loading: false
            };
        }
        case ReportsActionType.RETRIEVE_REPORT_ERROR: {
            return {
                ...state, 
                error: action.payload,
                loading: false
            };
        }
        case ReportsActionType.GET_REPORTS_REQUEST: {
            return {...state, error: null, loading: true};
        }
        case ReportsActionType.GET_REPORT_TAGS_SUCCESS: {
            return {...state, error: null, reportTags: action.payload.tags};
        }
        case ReportsActionType.GET_REPORTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                reports: addAll(action.payload.results),
                TOTAL_PAGES: Math.ceil(action.payload.count / state.PER_PAGE)
            };
        }
        case ReportsActionType.GET_REPORTS_ERROR: {
            return {...initialState, error: action.payload};
        }
        case ReportsActionType.ADD_REPORT: {
            return {
                ...state,
                reports: addOnTopJobAndAlike(state.reports, action.payload)
            };
        }

        case ReportsActionType.UPDATE_REPORT: {
            return {
                ...state,
                reports: addOrUpdate(state.reports, action.payload.id, action.payload.report)
            };
        }
        case ReportsActionType.DELETE_REPORT: {
            return {
                ...state,
                reports: deleteItem(state.reports, action.payload.id)
            };
        }
        case ReportsActionType.DELETE_REPORT_DONE: {
            return {
                ...state,
                reports: deleteItem(state.reports, action.payload.id)
            };
        }
        case ReportsActionType.CLEAR_REPORT_BUFFER: {
            return {
                ...state,
                reportBuffer: []
            }
        }
        default: {
            return state;
        }
    }
};

export {reducer as reportReducer};