import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CreateColumnsCosts} from './CreateColumnsCosts';
import {VersionPageWrapper} from '../version-page/version-page.styles';
import {ApplicationState} from '../../store';
import {getPage, resetPage as resetTaxModesPage, setPage} from '../../store/pagination';
import {Pagination} from '../../components/Pagination';
import {appPropertiesSelector} from '../../store/appProperties/selectors';
import {IAppProperties} from '../../store/appProperties/types';
import {
    setColumnFilter,
    resetFilters,
    getFilterDataRequest
} from '../../store/tableSelector/actions';
import {HeaderControl} from '../version-page/HeaderControl';
import axios from '../../axiosMiddleware';
import {getConfig} from '../../config-provider';
import {setDataToLocalStorage} from '../../helpers/localStorage';
import {CostsTableActionsWrapper} from './CostsTableActionsWrapper';
import {useTranslation} from 'react-i18next';
import {IOptionItems} from '../../store/tableSelector/types';
import {IAttribute} from '../../store/attributes/types';
import {DialogContext} from "../../layouts/root-layout/RootLayout";
import {requestSnackDisplay} from '../../store/snackbars/actions';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

interface Row {
    name: string;
    description;
    tags;
    indicators;
    creation_date;
    status;
}

const paginatorName = 'costsScenarios';
const filterName = 'costsScenariosFilter';

const CostsScenariosPage = React.memo(() => {
    const dispatch = useDispatch();
    const {t} = useTranslation(['commons', 'components']);
    const { setIsDialogOpen } = useContext(DialogContext)

    const language = useSelector<ApplicationState, string>((store) => store.userLanguage.language);
    const appProps = useSelector<ApplicationState, IAppProperties>(appPropertiesSelector);
    const pagination = useSelector<ApplicationState, any>((store) => store.pagination);
    const perPage = useSelector<ApplicationState, number>((store) => store[filterName].perPage);
    const totalPages = useSelector<ApplicationState, number>((store) => store[filterName].totalPages);
    const loading = useSelector<ApplicationState, boolean>((store) => store[filterName].loading);
    const count = useSelector<ApplicationState, number>((store) => store[filterName].count);
    const showColumns = useSelector<ApplicationState, IOptionItems>((store) => store[filterName].displayedColumns);
    const tagsBuffer = useSelector<ApplicationState, IAttribute[]>((store) => store.attributes.attributes);

    const {app_environment} = appProps;

    const currentPage = getPage(pagination, paginatorName);

    const [checkedRowIds, setCheckedRowIds] = useState<Row[]>([]);

    const [page, setCurrentPage] = useState(1);
    const [deleteDialogState, setDeleteDialogState] = useState({
        selection: null,
        open: false,
    });

    const [scenarioDialogState, setScenarioDialogState] = useState({
        parentScenarioName: null,
        scenarioId: null,
        editMode: true,
        update: false,
        open: false,
    });

    useEffect(() => {
        if (currentPage !== page) setCurrentPage(currentPage);
        //eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        handleRequestEditions();
        //eslint-disable-next-line
    }, []);

    const handleRequestEditions = async () => {
        const url = `${API_ENDPOINT}/editions`;
        const res = await axios.post(url);

        setDataToLocalStorage('cost_Editions', res.data.editions);
    };

    const resetPage = () => {
        setCurrentPage(1);
        dispatch(resetTaxModesPage(paginatorName));
    };

    const handleClearFilters = () => {
        setCurrentPage(1);
        dispatch(resetFilters(filterName));
        dispatch(setPage({key: paginatorName}));
        clearSelectedRows();
    };

    const handleFilterChange = (data, sorting, columnName) => {
        const {pendingValue: selected, excluded} = data;

        resetPage();
        dispatch(
            setColumnFilter(
                {
                    [`selected_${columnName}`]: selected,
                    [`excluded_${columnName}`]: excluded,
                }, sorting, filterName
            )
        );
    };

    const columns = useMemo(
        () => CreateColumnsCosts(t, tagsBuffer, showColumns),
        //eslint-disable-next-line
        [tagsBuffer, app_environment, showColumns, t]
    );

    const onRowSelected = (rows) => {
        setCheckedRowIds(rows.map(row => row.id));
    };

    const clearSelectedRows = () => {
        setCheckedRowIds([]);
    };

    const handleSubmitScenarioDelete = async (scenarioIds) => {
        const url = `${API_ENDPOINT}/scenario/bulk-delete`;
        const body = {scenario_ids: scenarioIds};
        axios
            .delete(url, {data: body})
            .then((res) => {
                const {cannot_be_deleted_ids = []} = res.data;
                const snackType = cannot_be_deleted_ids.length ? 'warning' : 'success';
                const snackMessage = cannot_be_deleted_ids.length ? 
                    t('pages:scenarios.snack.cost.notAllDeleted', {ids: cannot_be_deleted_ids.join(', ')}) :
                    t('pages:scenarios.snack.cost.deleteSuccess');

                dispatch(requestSnackDisplay(snackType, snackMessage));
                dispatch(getFilterDataRequest(currentPage, perPage, filterName))
            }).catch(() => {
                dispatch(requestSnackDisplay('error', t('pages:scenarios.snack.cost.deleteError')));
            });

        clearSelectedRows();
        handleClose();
        setIsDialogOpen(false);
    };

    const handleClose = () => setDeleteDialogState({...deleteDialogState, open: false});

    const handleViewScenario = async (e, rowData) => {
        const {id} = rowData;

        const urlTree = `${API_ENDPOINT}/scenario/cost/${id}/`;
        const urlEditions = `${API_ENDPOINT}/editions`;

        const res = await Promise.all([await axios.get(urlTree), await axios.post(urlEditions)]);

        const details = res[0].data.tree;
        const isDraft = details.is_draft;

        setDataToLocalStorage('cost_Details', {...details, id});
        setDataToLocalStorage('cost_Editions', res[1].data.editions);

        if (isDraft) {
            // Задаем начальное состояние сценария при редактировании черновика
            setDataToLocalStorage('cost_InitTree', details);
        }

        setScenarioDialogState({
            ...scenarioDialogState,
            scenarioId: id,
            editMode: Boolean(isDraft),
            update: Boolean(isDraft),
            open: true,
        });
    };

    return (
        <VersionPageWrapper>
            <HeaderControl
                setDeleteDialogState={setDeleteDialogState}
                deleteDialogState={deleteDialogState}
                setScenarioDialogState={setScenarioDialogState}
                scenarioDialogState={scenarioDialogState}
                checkedRowIds={checkedRowIds}
                handleClearFilters={handleClearFilters}
                filterName={filterName}
            />
            <CostsTableActionsWrapper
                columns={columns}
                onRowSelected={onRowSelected}
                handleFilterChange={handleFilterChange}
                setDeleteDialogState={setDeleteDialogState}
                deleteDialogState={deleteDialogState}
                scenarioDialogState={scenarioDialogState}
                setScenarioDialogState={setScenarioDialogState}
                handleSubmitScenarioDelete={handleSubmitScenarioDelete}
                onRowClick={handleViewScenario}
                filterName={filterName}
            />
            <Pagination
                clearSelectedRows={clearSelectedRows}
                pagination={pagination}
                perPage={perPage}
                totalPages={totalPages}
                selection={checkedRowIds.length}
                count={count}
                loading={loading}
                language={language}
                filterName={filterName}
            />
        </VersionPageWrapper>
    );
});

export default CostsScenariosPage;
