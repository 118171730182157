export enum TasksActionType {
    GET_TASKS_REQUEST = '@@tasks/GET_TASKS_REQUEST',
    GET_TASKS_SUCCESS = '@@tasks/GET_TASKS_SUCCESS',
    GET_TASKS_ERROR = '@@tasks/GET_TASKS_ERROR',

    GET_STATUS_TABLE = '@@/GET_STATUS_TABLE',
    GET_STATUS_TABLE_SUCCESS = '@@/GET_STATUS_TABLE_SUCCESS',

    ADD_TASK = '@@tasks/ADD_TASK',
    UPDATE_TASK = '@@tasks/UPDATE_TASK',
    DELETE_TASK = '@@tasks/DELETE_TASK',

    REVOKE_SELECTED_TASKS_REQUEST = '@@tasks/REVOKE_SELECTED_TASKS_REQUEST',
    REVOKE_SELECTED_TASKS_SUCCESS = '@@tasks/REVOKE_SELECTED_TASKS_SUCCESS',

    APPEND_TASK_TO_SELECTED = '@@tasks/APPEND_TASK_TO_SELECTED',
    REMOVE_TASK_FROM_SELECTED = '@@tasks/REMOVE_TASK_FROM_SELECTED',
    APPEND_ALL_TASKS_TO_SELECTED_REQUEST = '@@tasks/APPEND_ALL_TASKS_TO_SELECTED_REQUEST',
    APPEND_ALL_TASKS_TO_SELECTED_SUCCESS = '@@tasks/APPEND_ALL_TASKS_TO_SELECTED_SUCCESS',
    REMOVE_ALL_TASKS_FROM_SELECTED = '@@tasks/REMOVE_ALL_TASKS_FROM_SELECTED',

    HIDE_SYSTEM_TASKS = '@@tasks/HIDE_SYSTEM_TASKS',
    SHOW_SYSTEM_TASKS = '@@tasks/SHOW_SYSTEM_TASKS',
    LOAD_SYSTEM_TASKS_STATE = '@@tasks/LOAD_SYSTEM_TASKS_STATE',

    SHOW_MY_TASKS = '@@tasks/SHOW_MY_TASKS',
    SHOW_ALL_TASKS = '@@tasks/SHOW_ALL_TASKS',
    LOAD_MY_TASKS_STATE = '@@tasks/LOAD_MY_TASKS_STATE',

    HIDE_BADGE = '@@tasks/HIDE_BADGE',

    SET_SELECTED_FILTERS = '@@tasks/SET_SELECTED_FILTERS',
    GET_TASKS_SIZE_REQUEST = '@@tasks/GET_TASKS_SIZE_REQUEST',
    GET_TASKS_SIZE_SUCCESS = '@@tasks/GET_TASKS_SIZE_SUCCESS',
    GET_TASKS_SIZE_ERROR = '@@tasks/GET_TASKS_SIZE_ERROR',

    GET_TASKS_TYPES_REQUEST = '@@tasks/GET_TASKS_TYPES_REQUEST',
    GET_TASKS_TYPES_SUCCESS = '@@tasks/GET_TASKS_TYPES_SUCCESS',
    GET_TASKS_TYPES_ERROR = '@@tasks/GET_TASKS_TYPES_ERROR',

    GET_TASKS_STATS_REQUEST = '@@tasks/GET_TASKS_STATS_REQUEST',
    GET_TASKS_STATS_SUCCESS = '@@tasks/GET_TASKS_STATS_SUCCESS',

    GET_TASKS_STATE_FROM_LOCALSTORAGE = '@@tasks/GET_TASKS_STATE_FROM_LOCALSTORAGE',

    RESET_FILTERS = '@@tasks/RESET_FILTERS'
}

export interface ITask {
    id: number,
    created_by?: IUser,
    related_calc_info: ICalcInfo,
    status?: number,
    task_type: TTask_type,
    created_at: string,
    started_at?: string | null,
    finished_at?: string | null,
    payload_label?: string | null,
    system_task: boolean,
    task_type_verbose_name: string,
    has_errors: boolean,
    has_warnings: boolean
    has_info: boolean;
}

export interface ICalcInfo {
    calcversion_id: number | null,
    calcversion_parent_version_id: number | null,
}

export interface IUser {
    id: number,
    username: string,
    email: string
}

export interface ITasksState {
    tasks: Map<number, ITask> | null;
    show_system_tasks: boolean;
    show_my_tasks: boolean;
    selected_tasks: number[],
    selected_statuses: number[],
    selected_types: number[],
    tasks_types: [],
    selected_tasks_types: [],
    page?: number,
    tasks_size: number,
    readonly loading: boolean;
    readonly error: string | null;
    readonly TOTAL_PAGES: number;
    readonly PER_PAGE: number;
    showBadge: boolean,
    tasks_by_status: Map<number, IStatus>,
    task_status_table: Map<number, IStatus>
}

export interface IStatus {
    id: number,
    name: string,
    alias: string
}

export type TTask_type =
    "GENERIC"
    | "CALCULATE_ON_IMPORT"
    | "CALCULATE_SCENARIO"
    | "anomaly"
    | "ANALYTICS_REPORT"