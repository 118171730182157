import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {getLanguageRequest, setLanguageRequest} from '../../store/language/action';
import {ApplicationState} from '../../store';
import {IUserLanguageState} from '../../store/language/types';
import styled from 'styled-components/macro';

type LanguageType = 'ru' | 'en';
const languageList: LanguageType[] = ['ru', 'en'];

const LanguageSelector = () => {
    const dispatch = useDispatch();
    const {i18n} = useTranslation();

    const userLanguageState = useSelector<ApplicationState, IUserLanguageState | null>((store) => store.userLanguage);

    useEffect(() => {
        if (
            userLanguageState === null ||
            (userLanguageState.loading === false && userLanguageState.language === null)
        ) {
            dispatch(getLanguageRequest());
        }
        //eslint-disable-next-line
    }, [userLanguageState]);

    const changeUserProfileLanguage = (language: LanguageType) => {
        dispatch(setLanguageRequest(language));
        i18n.changeLanguage(language);
    };

    const handleUpdateLanguages = (language) => {
        i18n.changeLanguage(language);
    };

    const language = useSelector<ApplicationState, string | null>((store) => store.userLanguage.language);

    useEffect(() => {
        handleUpdateLanguages(language);
        //eslint-disable-next-line
    }, [language]);

    const languageHandler = (lang: LanguageType) => {
        changeUserProfileLanguage(lang);
    };

    return (
        <LanguageWrapper>
            {languageList.map((lang: LanguageType) => (
                <InputWrapper key={lang}>
                    <InputItem
                        type="radio"
                        id={lang}
                        value={lang}
                        checked={lang === userLanguageState.language}
                        onChange={(e) => languageHandler(lang)}
                    />
                    <Label htmlFor={lang}>{lang}</Label>
                </InputWrapper>
            ))}
        </LanguageWrapper>
    );
};

const LanguageWrapper = styled.div`
    &:only-child {
        margin-left: auto;
    }
    display: flex;
    justify-content: space-around;
`;

const Label = styled.label`
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    
    &:hover {
        text-decoration: underline;
    }
`;

const InputWrapper = styled.div`
    &:last-child {
        margin-left: 15px;
    }
`;

const InputItem = styled.input`
    display: none;

    &:checked + ${Label} {
        color: ${(props) => props.theme.colors.yellow};
        
        &:hover {
            text-decoration: none;
            cursor: default;
        }
    }
`;

export default LanguageSelector;
