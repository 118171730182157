import React, {useContext} from 'react';
import {Table} from '../../components/NewTable';
import {ScenarioDialog} from '../../components/ScenarioComponents/ScenarioDialog';
import {ScenariosDeleteDialog} from '../../components/ScenarioComponents/ScenariosDeleteDialog';
import {DialogContext} from "../../layouts/root-layout/RootLayout";

export const CostsTableActionsWrapper: React.FC<any> = ({
    columns,
    onRowSelected,
    handleFilterChange,
    setDeleteDialogState,
    deleteDialogState,
    scenarioDialogState,
    setScenarioDialogState,
    filterName,
    onRowClick,
    handleSubmitScenarioDelete,
}) => {
    const { setIsDialogOpen } = useContext(DialogContext)
    const handleCloseDelete = () => {
        setIsDialogOpen(false)
        setDeleteDialogState({...deleteDialogState, open: false})
    };

    return (
        <>
            <Table
                columns={columns}
                onRowSelected={onRowSelected}
                handleFilterChange={handleFilterChange}
                onRowClick={onRowClick}
                filterName={filterName}
            />
            {/* Компоненты диалоговых окон для фильтров углеродных сценариев */}
            {filterName === 'costsScenariosFilter' && (
                <>
                    {scenarioDialogState?.open && (
                        <ScenarioDialog
                            dialogState={scenarioDialogState}
                            setDialogState={setScenarioDialogState}
                            filterName={filterName}
                        />
                    )}
                    {deleteDialogState.open && (
                        <ScenariosDeleteDialog
                            dialogState={deleteDialogState}
                            handleClose={handleCloseDelete}
                            handleSubmit={handleSubmitScenarioDelete}
                            filterName={filterName}
                        />
                    )}
                </>
            )}
        </>
    );
};
