import {action} from 'typesafe-actions';
import {ActiveDataView, CurrentCalcContextActionType, ICalcSettings} from './types';

export const setCurrentIndicator = (
    indicator: string, 
    treeNodeId: string | null = null, 
    driverName = null, 
    treeName = 'indicatorTree'
) =>
    action(CurrentCalcContextActionType.SET_CURRENT_INDICATOR, {indicator, treeNodeId, driverName, treeName});

export const loadCurrentIndicator = (treeName = 'indicatorTree') =>
    action(CurrentCalcContextActionType.LOAD_CURRENT_INDICATOR, {treeName});

export const setActiveDataView = (dataView: ActiveDataView, treeName = 'indicatorTree') =>
    action(CurrentCalcContextActionType.SET_ACTIVE_DATA_VIEW, {dataView, treeName});

export const setCurrentObject = (object) =>
    action(CurrentCalcContextActionType.SET_CURRENT_OBJECT, object);

export const setCurrentWell = (well) =>
    action(CurrentCalcContextActionType.SET_CURRENT_WELL, well);

export const setProfitType = (profit) =>
    action(CurrentCalcContextActionType.SET_PROFIT, profit);

export const versionExtraRequest = (version_id) =>
    action(CurrentCalcContextActionType.VERSION_EXTRA_REQUEST, {version_id});

export const versionExtraSuccess = (version, data, treeName = 'indicatorTree') =>
    action(CurrentCalcContextActionType.VERSION_EXTRA_SUCCESS, {version, data, treeName});

export const versionExtraError = (version, data) =>
    action(CurrentCalcContextActionType.VERSION_EXTRA_ERROR, {version, data});

export const getCostsInfoRequest = (treeName = 'costsTree') => 
    action(CurrentCalcContextActionType.GET_COSTS_INFO_REQUEST, {treeName});

export const getCostsInfoSuccess = (costsInfo: Map<any, any>, treeName = 'costsTree') =>
    action(CurrentCalcContextActionType.GET_COSTS_INFO_SUCCESS, {costsInfo, treeName});

export const getCostsInfoError = (loggingError: any, treeName = 'costsTree') =>
    action(CurrentCalcContextActionType.GET_COSTS_INFO_ERROR, {loggingError, treeName});

export const changeSettings = (newSettings: Partial<ICalcSettings>, treeName = 'costsTree') =>
    action(CurrentCalcContextActionType.CHANGE_SETTINGS, {newSettings, treeName});

export const getAnomalyInfoRequest = (versionId: number, indicator: string, treeName='anomalyTree') => 
    action(CurrentCalcContextActionType.GET_ANOMALY_INFO_REQUEST, {versionId, indicator, treeName});

export const getAnomalyInfoSuccess = (anomalyInfo: any, treeName='anomalyTree') => 
    action(CurrentCalcContextActionType.GET_ANOMALY_INFO_SUCCESS, {anomalyInfo, treeName});

export const getAnomalyInfoError = (loadingError: any, treeName='anomalyTree') => 
    action(CurrentCalcContextActionType.GET_ANOMALY_INFO_ERROR, {loadingError, treeName});
