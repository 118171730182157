import {all, fork, put, select, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import {PlotActionType} from './types';
import {plotError, plotSuccess} from './actions';
import {getConfig} from '../../config-provider';
import {createFiltersQuery, createSortQuery} from '../currentGrid/sagas';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handlePlotRequest(request) {
    try {
        const indicator = yield select((store) => store.currentCalculationContext.currentIndicator);
        const versionIds = yield select((store) => store.vFilter.activeRowsIds);
        const settings = yield select((store) => store.currentGrid.settings);
        const filters = yield select((store) => store.currentGrid.filters.filters);
        const sortFilters = yield select((store) => store.currentGrid.filters.sortFilters);

        const {width, height, dpi} = request.payload;
        const {groupby, disable_inject, cumsum, precision, agg_column} = settings;
        const _groupby = groupby && groupby.length && groupby.join(',');
        const order_by = createSortQuery(sortFilters).join(',');
        const filterQuery = filters && {...createFiltersQuery(filters)};

        const version_ids = versionIds.sort((a, b) => a - b);
        const plotParams = {width, height, dpi};
        const body = {
            ...filterQuery,
            ...plotParams,
            order_by: order_by ? order_by : undefined,
            groupby: _groupby ? _groupby : undefined,
            mipairs: [indicator],
            version_ids,
            disable_inject,
            agg_column,
            cumsum,
            precision
        };

        const url = `${API_ENDPOINT}/calculate/plot`;

        const res = yield axios.post(url, body, {responseType: 'arraybuffer'});

        if (res.error) {
            yield put(plotError(res.error));
        } else {
            yield put(plotSuccess(res.data, res.headers['content-type']));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(plotError(err.stack!));
        } else {
            yield put(plotError('An unknown error occured.'));
        }
    }
}

function* watchPlotRequest() {
    yield takeLatest(PlotActionType.PLOT_REQUEST, handlePlotRequest);
}

function* currentPlotSaga() {
    yield all([fork(watchPlotRequest)]);
}

export default currentPlotSaga;
