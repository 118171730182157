export enum AppPropertiesAction {
    GET_APP_PROPERTIES_REQUEST = '@@appProperties/GET_APP_PROPERTIES_REQUEST',
    GET_APP_PROPERTIES_SUCCESS = '@@appProperties/GET_APP_PROPERTIES_SUCCESS'
}

export interface IAppProperties {
    version: string;
    user_logo?: string;
    auth_enabled: boolean;
    app_environment: string;
    anomaly_enabled: boolean;
    uplift1_enabled: boolean;
    wells_switchoff_enabled: boolean;
    uplift2_enabled: boolean;
    enable_multilang: boolean;
    residual_value_enabled?: boolean;
    is_expired: boolean;
    expire_date:  string;
    is_expired_warning: boolean;
    expire_date_warning:  string;
    download_limit: number;
    loading: boolean;
    version_back?: string;
}
