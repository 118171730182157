import React from 'react';
import {FormControl, MenuItem, Select as MSelect} from '@material-ui/core';
import {MaterialTooltip} from './Tooltip';
import styled, {css} from 'styled-components/macro';

type DropdownProps = {
    className?: string;
    variants: (number | string)[];
    onChange: any;
    value: number | string;
    placeholder?: string;
    outline?: boolean;
    disabled?: boolean;
    width?: number;
    inline?: boolean;
    empty?: boolean;
    emptyBg?: boolean;
    onOpen?: Function;
    isHtmlItems?: boolean;
};

export const Select: React.FC<DropdownProps> = React.memo(
    ({
        className,
        variants,
        onChange,
        value,
        placeholder,
        width,
        inline = false,
        disabled = false,
        empty = false,
        emptyBg = false,
        onOpen,
        isHtmlItems = false,
    }) => {
        return (
            <MaterialTooltip title={String(value)}>
                <SelectGroupWrapper isInline={inline}>
                    <SelectGroup>
                        {placeholder && inline && (
                            <Label isInline={inline}>
                                {placeholder}
                            </Label>
                        )}
                        <SelectElementWrapper isEmptyBg={emptyBg} width={width}>
                            <SelectElement
                                className={className}
                                value={value}
                                disabled={disabled}
                                onOpen={onOpen}
                                onChange={(e) => onChange(e)}
                                placeholder={placeholder}
                                inputProps={{'aria-label': 'Without label'}}
                                displayEmpty={empty}
                            >
                                {placeholder && (
                                    <MenuItem value="" disabled>
                                        {placeholder}
                                    </MenuItem>
                                )}

                                {variants.map((variant: any, index: number) => (
                                    <MenuItem key={index} value={isHtmlItems ? variant.value : variant}>
                                        {isHtmlItems ? variant.element : variant}
                                    </MenuItem>
                                ))}
                            </SelectElement>
                        </SelectElementWrapper>
                    </SelectGroup>
                </SelectGroupWrapper>
            </MaterialTooltip>
        );
    }
);

const SelectGroup = styled(FormControl)`
    display: flex;
    font-size: ${(props) => props.theme.fontSize.min};

    .MuiInput-underline:after,
    .MuiInput-underline:before {
        border: 0;
    }

    .MuiSelect-select.MuiSelect-select {
        font-size: 14px;
        padding-right: 16px;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border: 0;
    }

    .MuiSelect-select:focus {
        background: none;
    }
`;

const SelectGroupWrapper = styled.div`
    width: '100%';
    .MuiFormControl-root {
        flex-direction: ${(props) => (props.isInline ? 'row' : 'column')};
        align-items: ${(props) => (props.isInline ? 'baseline' : 'flex-start')};
    }
`;

const Label = styled.span`
    margin-bottom: ${(props) => (props.isInline ? '0' : '5px')};
    margin-right: ${(props) => (props.isInline ? '10px' : '0')};
    font-size: 12px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.grey1};
    align-self: center;
`;

const SelectElement = styled(MSelect)`
    color: ${(props) =>
        !props.disabled ? props.theme.textColor.grey1 : props.theme.textColor.grey5};
    border: none;
    height: 40px;

    ${(props) =>
        !props.outline &&
        css`
            width: 100%;
            padding: 10px 5px;
            background-color: ${(props) => props.theme.colors.grey5};
        `};
`;

const SelectElementWrapper = styled.div`
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};

    > .MuiInputBase-formControl {
        ${(props) =>
            props.isEmptyBg &&
            css`
                background-color: transparent;
            `}

        ${(props) =>
            props.width &&
            css`
                max-width: ${(props) => (props.width ? `${props.width}px` : `auto`)};
            `};
    }
`;
