import {action} from 'typesafe-actions';
import {GridActionType, IGridRequest, IGridSettings, IPaginatedGridResult, IRowIndex} from './types';

export const calcRequest = () => action(GridActionType.CALC_REQUEST);
export const saveCalcFilters = (request: IGridRequest) => action(GridActionType.SAVE_FILTERS, request);
export const calcClearData = () => action(GridActionType.CALC_CLEAR_DATA);

export const changeSettings = (newSettings: Partial<IGridSettings>) =>
    action(GridActionType.CHANGE_SETTINGS, newSettings);

export const calcSuccess = (data: IPaginatedGridResult) =>
    action(GridActionType.CALC_SUCCESS, {data});

export const calcError = (message: string) => action(GridActionType.CALC_ERROR, message);

export const setSelectedIndex = (idx: IRowIndex) => action(GridActionType.SET_SELECTED_INDEX, idx)