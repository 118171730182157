import { action } from 'typesafe-actions';
import { IStatus, ITask, TasksActionType } from "./types";

export const getTasksRequest = (page, selected_statuses?: number[], selected_types?: number[], searchQuery?:string) => action(TasksActionType.GET_TASKS_REQUEST, {page, selected_statuses, selected_types, searchQuery});

export const getTasksSuccess = (tasks: ITask[], page?) =>
    action(TasksActionType.GET_TASKS_SUCCESS, {tasks, page});

export const addTask = (task: ITask, page?) => action(TasksActionType.ADD_TASK, {task, page});

export const updateTask = (id: number, task: ITask) =>
    action(TasksActionType.UPDATE_TASK, { id, task });

export const deleteTask = (id: number) =>
    action(TasksActionType.DELETE_TASK, { id });

export const getTasksError = error => action(TasksActionType.GET_TASKS_ERROR, { error });

export const hideBadge = () => action(TasksActionType.HIDE_BADGE);

export const getStatusTableRequest = () => action(TasksActionType.GET_STATUS_TABLE);

export const getStatusTableSuccess = (taskStatusTable: IStatus[]) => action(TasksActionType.GET_STATUS_TABLE_SUCCESS, taskStatusTable);

export const appendTaskToSelected = (task: ITask) => action(TasksActionType.APPEND_TASK_TO_SELECTED, task);

export const removeTaskFromSelected = (task: ITask) => action(TasksActionType.REMOVE_TASK_FROM_SELECTED, task);

export const appendAllTasksToSelectedRequest = (selected_statuses: number[], selected_types: number[], searchQuery: string) => action(TasksActionType.APPEND_ALL_TASKS_TO_SELECTED_REQUEST, {selected_statuses, selected_types, searchQuery});

export const appendAllTasksToSelectedSuccess = (tasks: number[]) => action(TasksActionType.APPEND_ALL_TASKS_TO_SELECTED_SUCCESS, tasks);

export const removeAllTasksFromSelected = () => action(TasksActionType.REMOVE_ALL_TASKS_FROM_SELECTED);

export const revokeSelectedTasksRequest = (tasks: number[]) => action(TasksActionType.REVOKE_SELECTED_TASKS_REQUEST, tasks)

export const revokeSelectedTasksSuccess = () => action(TasksActionType.REVOKE_SELECTED_TASKS_SUCCESS)

export const hideSystemTasks = () => action(TasksActionType.HIDE_SYSTEM_TASKS);

export const showSystemTasks = () => action(TasksActionType.SHOW_SYSTEM_TASKS);

export const setSelectedFilters = (selected_statuses: number[], selected_types: number[]) => action(TasksActionType.SET_SELECTED_FILTERS, {selected_statuses, selected_types});

export const getTasksSizeRequest = (selected_statuses: number[], selected_types: number[], searchQuery: string) => action(TasksActionType.GET_TASKS_SIZE_REQUEST, {selected_statuses, selected_types, searchQuery})

export const getTasksSizeSuccess = (size) => action(TasksActionType.GET_TASKS_SIZE_SUCCESS, size)

export const getTasksSizeError = (error) => action(TasksActionType.GET_TASKS_SIZE_ERROR, error)

export const loadTasksVisibilityState = (visibility_state: boolean, filter_parameter: string) => action(TasksActionType.LOAD_SYSTEM_TASKS_STATE,
    { "visibility_state": visibility_state, 'filter_parameter': filter_parameter });

export const showMyTasks = () => action(TasksActionType.SHOW_MY_TASKS);

export const showAllTasks = () => action(TasksActionType.SHOW_ALL_TASKS);

export const getTasksTypesRequest = () => action(TasksActionType.GET_TASKS_TYPES_REQUEST);

export const getTasksTypesSuccess = (types) => action(TasksActionType.GET_TASKS_TYPES_SUCCESS, types);

export const getTasksStatsRequest = (selected_statuses: number[], selected_types: number[], searchQuery: string) => action(TasksActionType.GET_TASKS_STATS_REQUEST, {selected_statuses, selected_types, searchQuery});

export const getTasksStatsSuccess = (stats) => action(TasksActionType.GET_TASKS_STATS_SUCCESS, stats);

export const getTasksStateFromLocalStorage = () => action(TasksActionType.GET_TASKS_STATE_FROM_LOCALSTORAGE);

export const resetFilters = () => action(TasksActionType.RESET_FILTERS);