import React from 'react';
import {ScenarioTree} from './ScenarioTree';
import {AttributesComponent} from '../VersionsComponents/ConfigureSimulationDialog/Tabs/AttributesComponent';
import { PopupItem } from '../Popup/popup.styles';
import styled from 'styled-components/macro';
import { ScenarioSummary } from './ScenarioSummary';
import { useTranslation } from 'react-i18next';


type PopupContentProps = {
    activeTabId: number;
    editMode: any;
    treeData: any;
    verboseIndex: any;
    scenarioName: string;
    editionId: number;
    contents: ContentType[];
    isFullscreen?: boolean;
    attrCompProps: any; // Пропсы компонента AttributesComponent
    filterName: string;
};

export type ContentType = {
    id: number;
    tabName: string;
    text?: string;
};


export const PopupContent: React.FC<PopupContentProps> = ({
    activeTabId,
    editMode,
    treeData,
    verboseIndex,
    scenarioName,
    editionId,
    contents,
    attrCompProps,
    isFullscreen,
    filterName
}) => {

    const { t } = useTranslation();
    const { attributes, setAttrCompSt } = attrCompProps;
    const tabNames = contents.map(item => item.tabName);
    
    return (
        <>
            {contents.map((content) => (
                activeTabId === content.id && <React.Fragment key={content.id}>
                    {!['attributes', 'summary'].includes(content.tabName) && (
                        <ScenarioTree
                            treeData={treeData}
                            verboseIndex={verboseIndex}
                            editionId={editionId}
                            treeName={content.tabName}
                            editMode={editMode}
                            fullscreenMode={isFullscreen}
                            filterName={filterName}
                        />
                    )}
                    {content.tabName === 'attributes' && (
                        <AttributesComponent
                            initialParameters={attributes}
                            setSimulationState={setAttrCompSt}
                            isScenarioMode
                            editMode={editMode}
                            filterName={filterName}
                        />
                    )}
                    {content.tabName === 'summary' && (
                        <>
                            <PopupItem>
                                <h3>{t('pages:scenarios.dialog.tax_mode.summary')}</h3>
                            </PopupItem>
                            <ScrollPopupSummary> 
                                <ScenarioSummary
                                    attributes={attributes}
                                    scenarioName={scenarioName}
                                    tabNames={tabNames}
                                    editionId={editionId}
                                    editMode={editMode}
                                    filterName={filterName}
                                />
                            </ScrollPopupSummary>
                        </>
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

// Данные значения высоты необходимы для получения
// одинаковой высоты диалога сценария на всех вкладках
const ScrollPopupItem = styled(PopupItem)`
    overflow-x: hidden;
`;

const ScrollPopupSummary = styled(ScrollPopupItem)`
    margin-top: 1em;
    height: 100%;
    background-color: ${(props) => props.theme.colors.grey6};
`;
