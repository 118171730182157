import {createMuiTheme} from "@material-ui/core/styles";

export const EY_COLORS_RANDOM = [
    // https://eygb.sharepoint.com/sites/TheBrandingZone/SitePages/ColorInInformationDesign.aspx
    '#42C9C2',
    '#FF6D00',
    '#240D75',
    '#004F4F',
    '#13652A',
    '#2DB757',
    '#082C65',
    '#0D7575',
    '#FF810A',
    '#3D108A',
    //'#189D3E',
    //'#351C21',
    '#EB4F00',
    //'#168736',
    '#922B73',
    '#35A4E8',
    '#F95D54',
    '#750E5C',
    '#27ACAA',
    //'#FF4136',
    //'#BC2F00',
    '#B9251C',
    '#5A0A42',
    '#542EA5',
    //'#0A095A',
    '#42152D',
    //'#15173E',
    '#7A130D',
    '#E0362C',
    '#34C768',
    '#109090',
    '#155CB4',
    '#F76900',
    '#188CE5',
    //'#1777CF'
]

export const ADVANCED_COLORS = {
    maroon: [
        '#C981B2',
        '#B14891',
        '#922B73',
        '#750E5C',
        '#5A0A42',
        '#42152D',
        '#351C21',
    ],
    red: [
        '#FF9A91',
        '#FF736A',
        '#F95D54',
        '#FF4136',
        '#E0362C',
        '#B9251C',
        '#7A130D',
    ],
    orange: [
        '#FFB46A',
        '#FF9831',
        '#FF810A',
        '#FF6D00',
        '#F76900',
        '#EB4F00',
        '#BC2F00',
    ],
    green: [
        '#8CE8AD',
        '#57E188',
        '#34C768',
        '#2DB757',
        '#189D3E',
        '#168736',
        '#13652A',
    ],
    teal: [
        '#93F0E6',
        '#60E6E1',
        '#42C9C2',
        '#27ACAA',
        '#109090',
        '#0D7575',
        '#004F4F',
    ],
    blue: [
        '#87D3F2',
        '#4EBEEB',
        '#35A4E8',
        '#188CE5',
        '#1777CF',
        '#155CB4',
        '#082C65',
    ],
    purple: [
        '#9C82D4',
        '#724BC3',
        '#542EA5',
        '#3D108A',
        '#240D75',
        '#0A095A',
        '#15173E',
    ]
}
const colors = {
    white: '#FFFFFF',
    light: '#f6f6fa',
    black: '#1A1A24',
    grey1: '#2E2E38',
    grey2: '#747480',
    grey3: '#CDCDD1',
    grey4: '#E7E7EA',
    grey5: '#EEEEF2',
    grey6: '#F6F6F9',
    grey7: '#CCCCD1',
    grey8: '#FAFAFC',
    lightGrey: '#B3B3BC',
    yellow: '#FFE600',
    yellowDark: '#F6DF08',
    green: '#168736',
    red: '#FF4236',
    blue: '#188CE5',
    orange: '#FF9831'
}

export const EYtheme = {
    colors,
    advanced_colors: ADVANCED_COLORS,
    background: {
        bg2: colors.grey1,
        selected: colors.grey1,
    },
    fontSize: {
        min: '12px',
        normal: '14px',
        medium: '16px',
        head: '24px',
    },
    textColor: {
        black: '#2E2E38',
        grey: '#747480',
        lightGrey: '#B3B3BC',
        white: '#FFFFFF',
    },
    transition: {
        min: '.12s',
        normal: '.25s',
    },
};

export const matTheme = createMuiTheme({
    palette: {
        primary: {
            main: colors.yellow,
        },
        secondary: {
            main: colors.grey1,
        },
    },
});