import React from 'react';
import styled from 'styled-components';
import {Checkbox, Checkmark} from '../../../Design/Checkboxes/Checkbox';
import {DeleteIcon} from './VersionGroupComponent';

const AttributesSummaryListItem = ({
    handleDelete,
    editMode = true,
    index,
    value,
    handleValueChange,
    isTagUpdated,
    isScenarioMode,
}) => {
    const buildSummary = (value) => value.name;
    const isChecked = isTagUpdated(value.id);

    return (
        <ListItem key={index} groupName={value.group}>
            {buildSummary(value)}

            {editMode ? (
                <>
                    <DeleteIcon onClick={(e) => handleDelete(e, index, value)} />
                    {!isScenarioMode && (
                        <ListItemCheckbox
                            checked={isChecked || false}
                            onChange={(e) =>
                                value.group !== 'every_version' && handleValueChange(e, value)
                            }
                            isSmaller
                            isDarkGrey={value.group === 'every_version' && !value.isNew}
                            isYellow={value.isNew}
                        />
                    )}
                </>
            ) : null}
        </ListItem>
    );
};

const ListItem = styled.label`
    background-color: ${(props) => props.theme.colors.grey4};
    padding: 11px 16px;
    color: #2e2e38;
    width: calc(50% - 2px);
    overflow-x: hidden;
    margin-bottom: 4px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    user-select: none;
    font-size: 14px;
    display: ${(props) => (props.hidden ? 'none' : 'flex')};
    align-items: center;
`;

export const ListItemCheckbox = styled(Checkbox)`
    margin-left: auto;
    margin-right: 10px;

    ${Checkmark} {
        position: relative;
    }

    &:after {
        content: '';
        position: absolute;
        border: solid
            ${(props) =>
                props.isBlack || props.isGreen || props.isDarkGrey
                    ? props.theme.colors.white
                    : props.theme.colors.black};
        ${(props) =>
            props.isSmaller
                ? `
            right: 6px;
            top: 3px;
            width: 3px;
            height: 7px;
            border-width: 0 1px 1px 0;
        `
                : `
            right: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border-width: 0 2px 2px 0;
        `}
        transform: rotate(45deg);
    }
`;

export default React.memo(AttributesSummaryListItem);
