import styled from 'styled-components/macro';
import {InputField} from '../Design/InputField';
import {Checkbox} from '../Design/Checkboxes/Checkbox';
import {Tab, TabElement, TabText} from '../Design/Tab';

export const FilterWrapper = styled.div`
    width: 250px;
    padding: 20px;
    font-size: 12px;
    background: ${(props) => props.theme.colors.grey6};
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%),
        0px 1px 14px 0px rgb(0 0 0 / 12%);

    ${TabElement} {
        height: auto;
    }

    ${TabText} {
        margin-left: 5px;
    }
`;

export const FilterHeader = styled.h3`
    font-weight: normal;
`;

export const InputSearch = styled(InputField)`
    margin-top: 15px;
    width: 100%;
`;

export const HeaderButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 0 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.black};
`;

export const TextButton = styled.span`
    cursor: pointer;
    color: ${(props) => props.theme.textColor.grey};
    font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
    padding: 0;

    &:hover {
        font-weight: bold;
    }
`;

export const CheckboxWrapper = styled(Checkbox)`
    padding: 8px 0;

    span:last-child {
        padding-left: 30px;
    }
`;

export const ConditionItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    select {
        padding: 5px;
    }

    input {
        width: 150px;
        margin-left: 5px;
    }
`;

export const ConditionList = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
    max-height: 80px;
    overflow-y: scroll;

    ${ConditionItem} + ${ConditionItem} {
        margin-top: 3px;
    }
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const FormWrapper = styled.div`
    flex-direction: column;
    padding-left: 4px;
    padding-bottom: 4px;
    max-height: 120px;
    overflow: hidden;
`;

export const ButtonControlWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

export const RemoveIconWrapper = styled.div`
    img {
        width: 35px;
        height: 35px;
    }
`;

export const PlusWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 10px;

    img {
        width: 35px;
        height: 35px;
    }
`;

export const SortWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
`;

export const SortItem = styled.div`
    display: flex;
    align-items: center;

    & ~ div {
        margin-left: 3px;
    }
`;

export const TabCustom = styled(Tab)`
    padding: 8px 14px;
`;

export const SortInput = styled.input`
    display: none;

    &:checked + label {
        font-weight: bold;
    },
`;
