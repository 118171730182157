import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {InputField} from '../Design/InputField';
import {Checkbox} from '../Design/Checkboxes/Checkbox';
import {getDataFromLocalStorage, setDataToLocalStorage} from '../../helpers/localStorage';
import {useHistory} from 'react-router-dom';
import {History} from 'history';
import {setRowsPerTableCell} from '../../store/rowsPerTableCell/actions';

export const createRouteKey = (history: History) => {
    const {pathname} = history.location;
    return `${pathname}:rows_per_table_cell`;
};

export const getRowsPerTableCell = (history: History) => {
    return getDataFromLocalStorage(createRouteKey(history));
};

export const saveRowsPerTableCell = ({
    history,
    isChecked,
    maxValue,
    dispatch,
}: {
    history: History;
    isChecked: boolean;
    maxValue: string;
    dispatch: any;
}) => {
    if (Number(maxValue) <= 10) {
        // Запись в localStorage
        setDataToLocalStorage(createRouteKey(history), {
            checked: isChecked,
            value: maxValue,
        });
        // Запись в store для реактивного рендера компонентов
        dispatch(
            setRowsPerTableCell(createRouteKey(history), {
                checked: isChecked,
                value: maxValue,
            })
        );
    }
};

export const DEFAULT_LINES: string = '4';

export const RowsPerTableCell = ({isChecked, setIsChecked, maxValue, setMaxValue}) => {
    const history = useHistory();
    const {t} = useTranslation(['components']);

    const checkboxHandler = (checked): void => {
        setIsChecked(checked);

        setDataToLocalStorage(createRouteKey(history), {
            checked: checked,
            value: maxValue,
        });
    };

    const inputHandler = (value: string): void => {
        if ((value === '' || !isNaN(Number(value))) && value !== '0') {
            setMaxValue(value);
        }
    };

    return (
        <MaxFilterWrapper>
            <Checkbox
                checked={isChecked}
                onChange={() => checkboxHandler(!isChecked)}
                label={t('components:filter.count_strings', 'Число строк')}
            />
            <MaxInputWrapper
                disabled={!isChecked}
                placeholder={t('components:filter.count_strings', 'Число строк')}
                value={maxValue}
                onChange={(e) => inputHandler(e.target.value)}
            />
        </MaxFilterWrapper>
    );
};

const MaxFilterWrapper = styled.div`
    margin: 15px 0;
`;

const MaxInputWrapper = styled(InputField)`
    margin-top: 15px;
    width: 100%;
`;
