import {computeSchema} from '../utils';

export const schemaToCompute = {
    template: {
        define: ['template'],
    },
    company: {
        define: ['company']
    },
    test: {
        define: ['test']
    }
};

export const entitiesSchema = computeSchema(schemaToCompute);


