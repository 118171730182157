import {createMinimizeCell} from '../../utils/materialTable/createTable';

export const CreateColumnsFiles = (t, columns) => {
    return [
        {
            title: t('commons:name', 'Название'),
            field: 'name',
            filtering: false,
            editable: 'never' as 'never',
            headerStyle: {
                width: '300px',
            },
            cellStyle: {
                width: '300px',
            },
            hidden: !columns.map((col) => col.name).includes('name'),
            render: (rowData) => {
                return createMinimizeCell(rowData.name);
            },
        },
        {
            title: t('commons:doc_type', 'Тип файла'),
            field: 'doc_type',
            filtering: true,
            editable: 'never' as 'never',
            headerStyle: {
                width: '300px',
            },
            cellStyle: {
                width: '300px',
            },
            hidden: !columns.map((col) => col.name).includes('doc_type'),
            render: (rowData) => {
                return createMinimizeCell(rowData.doc_type);
            },
        },
        {
            title: t('commons:creation_date', 'Создано'),
            field: 'created_at',
            filtering: true,
            sorting: true,
            editable: 'never' as 'never',
            headerStyle: {
                width: '300px',
            },
            cellStyle: {
                width: '300px',
            },
            hidden: !columns.map((col) => col.name).includes('creation_date'),
            render: (rowData) => {
                return createMinimizeCell(rowData.created_at);
            },
        },
    ];
};
