import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { useTranslation } from "react-i18next";
import { IFilter } from "../store/tableSelector/types";
import { useFilterOptions } from "./tableSelector/useFilterOptions";

const filterName = 'filesFilter';

export const useFilesFilterMap = () => {

    const { t } = useTranslation(['commons', 'pages']);
    const activeFilters = useSelector<ApplicationState, IFilter>(store => store[filterName].currentFilters);
    
    const docTypeFilters = useFilterOptions(filterName, 'doc_types');
    const createdAtFilters = useFilterOptions(filterName, 'created_at');
    
    const filterMap = new Map([
        [t('commons:doc_type', 'Тип файла'), {
            filterOptions: docTypeFilters,
            excluded: activeFilters.excluded_doc_types,
            selected: activeFilters.selected_doc_types,
            stateBranch: 'doc_types',
            withExclusion: true
        }],
        [t('commons:creation_date', 'Создано'), {
            filterOptions: createdAtFilters,
            excluded: activeFilters.excluded_created_at,
            selected: activeFilters.selected_created_at,
            stateBranch: 'created_at',
            withExclusion: true
        }]
    ]);

    return { filterMap }
};
