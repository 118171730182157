export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

export function getValueByKey(object, value) {
    return object[value];
}

export function getValues(object) {
    return Object.keys(object).map(function(key){
        return object[key];
    });
}