import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import axios from '../../axiosMiddleware';

import {DeltaPlotActionType} from './types'
import {getDeltaPlotError, getDeltaPlotSuccess} from './actions'
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleDeltaPlotRequest(action) {
    const {versions, miPair} = action.payload;
    try {
        const res = yield call(axios.get, `${API_ENDPOINT}/delta_plot/`, {
            params: {
                versions,
                miPair
            }
        });
        if (res.error) {
            yield put(getDeltaPlotError(res.error))
        } else {
            yield put(getDeltaPlotSuccess(res.data.plots))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(getDeltaPlotError(err.stack!))
        } else {
            yield put(getDeltaPlotError('An unknown error occured.'))
        }
    }
}

function* watchDeltaPlotRequest() {
    yield takeLatest(DeltaPlotActionType.GET_DELTA_PLOT_REQUEST, handleDeltaPlotRequest)
}

export function* deltaPlotSaga() {
    yield all([fork(watchDeltaPlotRequest)])
}
