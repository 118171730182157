import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';
import {getConfig} from '../../config-provider';
import {getReportItemsListError, getReportItemsListSuccess} from './actions'
import {IItem, ReportTemplatesActionType} from "./types";

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetRequest() {
    const url = `${API_ENDPOINT}/reporting/constructor/available-items/`;
    try {
        const res = yield call(axios.get, url);
        if (res.error) {
            yield put(getReportItemsListError(res.error))
        } else {
            yield put(getReportItemsListSuccess(res.data as IItem[]))
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(getReportItemsListError(e.stack!))
        } else {
            yield put(getReportItemsListError("An unknown error occurred."))
        }
    }
}

function* watchGetRequest() {
    yield takeLatest(ReportTemplatesActionType.GET_REPORT_ITEMS_LIST, handleGetRequest)
}

export function* reportItemsSaga() {
    yield all([fork(watchGetRequest)])
}