import { Reducer } from 'redux';
import { PermissionsAction, IPermissions } from './types';

export const initialState: IPermissions = {
    permissions: [],
    adminMenu: false,
    error: "",
}

const reducer: Reducer<IPermissions> = (state = initialState, action) => {
    switch (action.type) {
        case PermissionsAction.GET_PERMISSIONS_REQUEST: {
            return { ...state}
        }
        case PermissionsAction.GET_PERMISSIONS_SUCCESS: {
            return {
                permissions: action.payload.permissions,
                adminMenu: action.payload.permissions.includes('adminview.can-manage-roles'),
                error: "",
            }
        }
        case PermissionsAction.GET_PERMISSIONS_ERROR: {
            return {
                error: action.payload.error,
                adminMenu: false,
                permissions: [],

            }
        }
        default: {
            return state;
        }
    }

}

export { reducer as permissionsReducer }