import React from 'react';
import { useVersionFilterMap } from '../../hooks/useVersionFilterMap';
import { useTaxModeFilterMap } from '../../hooks/useTaxModeFilterMap';
import { useMacroScenarioFilterMap } from '../../hooks/useMacroScenarioFilterMap';
import { useTranslation } from 'react-i18next';
import MTableHeader from './MTableCustomHeader'
import { usePortfolioAnalysisFilterMap } from '../../hooks/usePortfolioAnalysisFilterMap';
import { useEmissionFilterMap } from '../../hooks/useEmissionFilterMap';
import { useCostsFilterMap } from '../../hooks/useCostsFilterMap';
import { useFilesFilterMap } from '../../hooks/useFilesFilterMap';
import { useSelector } from 'react-redux';
import { ISortItems } from '../../store/tableSelector/types';
import { ApplicationState } from '../../store';

const filterMaps = {
    vFilter: useVersionFilterMap,
    vFilterSelected: useVersionFilterMap,
    taxModesFilter: useTaxModeFilterMap,
    macroScenariosFilter: useMacroScenarioFilterMap,
    emissionScenariosFilter: useEmissionFilterMap,
    costsScenariosFilter: useCostsFilterMap,
    group1Filter: useVersionFilterMap,
    group2Filter: useVersionFilterMap,
    costsCompFilter: useVersionFilterMap,
    portfolioAnalysisFilter: usePortfolioAnalysisFilterMap,
    filesFilter: useFilesFilterMap
}

const TableHeaderWrapper = (props) => {
    const { t } = useTranslation(['commons', 'pages']);
    const { filterName } = props;
    const { filterMap } = filterMaps[filterName](filterName);
    
    const sortBy = useSelector<ApplicationState, ISortItems>((store) => store[filterName].currentSorting);
    
    const propsCombined = { ...props, filterMap, sortBy, t };

    return <MTableHeader {...propsCombined} />
}

export default TableHeaderWrapper;