import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from '../../axiosMiddleware';

import {ScenarioActionType} from './types';
import {
    getScenarioDetailsSuccess,
    getScenarioDetailsError,
    editScenarioNameSuccess,
    editScenarioNameError,
    editScenarioDescriptionSuccess,
    editScenarioDescriptionError,
} from './actions';
import {getConfig} from '../../config-provider';

const API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function* handleGetScenarioDetailsRequest(action) {
    const macroId = action.payload.macroId;
    const params = action.payload.params;
    const url = `${API_ENDPOINT}/scenario/macro/${macroId}/`;
    try {
        const res = yield call(axios.get, url, {params: params});
        if (res.error) {
            yield put(getScenarioDetailsError(res.error));
        } else {
            yield put(getScenarioDetailsSuccess(res.data));
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(getScenarioDetailsError(e.stack!));
        } else {
            yield put(getScenarioDetailsError('An unknown error occurred.'));
        }
    }
}

function* handleEditScenarioNameRequest(action) {
    const macroId = action.payload.macroId;
    const url = `${API_ENDPOINT}/scenario/macro/${macroId}/`;
    const body = {
        name: action.payload.name,
    };

    try {
        const res = yield call(axios.patch, url, body, {headers: {'Content-Type': 'application/json'}});
        if (res.error) {
            yield put(editScenarioNameError(res.error));
        } else {
            yield put(editScenarioNameSuccess(body.name as string));
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(editScenarioNameError(e.stack!));
        } else {
            yield put(editScenarioNameError('An unknown error occurred.'));
        }
    }
}

function* handleEditScenarioDescriptionRequest(action) {
    const macroId = action.payload.macroId;
    const url = `${API_ENDPOINT}/scenario/macro/${macroId}/`;

    const body = {
        description: action.payload.description,
    };

    try {
        const res = yield call(axios.patch, url, body, {headers: {'Content-Type': 'application/json'}});
        if (res.error) {
            yield put(editScenarioDescriptionError(res.error));
        } else {
            yield put(editScenarioDescriptionSuccess(body.description as string));
        }
    } catch (e) {
        if (e instanceof Error) {
            yield put(editScenarioDescriptionError(e.stack!));
        } else {
            yield put(editScenarioDescriptionError('An unknown error occurred.'));
        }
    }
}

function* watchGetScenarioDetailsRequest() {
    yield takeLatest(ScenarioActionType.GET_SCENARIO_DETAILS_REQUEST, handleGetScenarioDetailsRequest);
}

function* watchEditScenarioNameRequest() {
    yield takeLatest(ScenarioActionType.EDIT_SCENARIO_NAME_REQUEST, handleEditScenarioNameRequest);
}

function* watchGetMacroScenariosRequest() {
    yield takeLatest(ScenarioActionType.EDIT_SCENARIO_DESCRIPTION_REQUEST, handleEditScenarioDescriptionRequest);
}

export function* scenarioSaga() {
    yield all([
        fork(watchGetScenarioDetailsRequest),
        fork(watchEditScenarioNameRequest),
        fork(watchGetMacroScenariosRequest),
    ]);
}

